import { Grid, Box, Stack, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../../components/customText/Text';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import CircularProgress from '../../../../components/progressbar/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import AnalyticStyles from './AnalyticStyles';
import ReactApexChart from 'react-apexcharts';
import { PieChart } from '@mui/x-charts/PieChart';
import AnalyticsApiTimesheet from '../../../../apis/admin/timesheets/AnalyticsApiTimesheet';
import { BlackToolTip, NoPermission, addErrorMsg, addLoader, capitalizeAndAddSpace, removeLoader } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import Tooltip from "@mui/material/Tooltip";
import infoCircle from '../../../../assets/images/info-circle.png';
import CommonApi from '../../../../apis/CommonApi';
import emptyDashboard from '../../../../assets/svg/dashboard/empty-dashboard.svg';


function ClientAnalystics() {
    const navigate = useNavigate();
    const classes = AnalyticStyles();
    const [active, setActive] = useState('USD');
    // const yearsList = [
    //     {
    //         id: '2024',
    //         value: '2024'
    //     },
    //     {
    //         id: '2023',
    //         value: '2023'
    //     },
    //     {
    //         id: '2022',
    //         value: '2022'
    //     },
    //     {
    //         id: '2021',
    //         value: '2021'
    //     }
    // ]

    const quarterList = require('../../../../utils/jsons/QuarterList.json');
    const location = useLocation();
    const data = location && location.state.data
    const dropdown = location && location.state.dropdown
    const [loader, setLoader] = useState(false);
    const [dollar, setDollar] = useState({
        series: [],
        labels: [],
        colors: [],
        dates: []
    })
    const [hours, setHours] = useState({
        series: [],
        labels: [],
        colors: [],
        dates: []
    })
    const [donutSeries, setDonutSeries] = useState([]);
    const [yearsList, setYearsList] = useState([]);
    const [state, setState] = useState({
        selectedYear: dropdown.selectedYear,
        selectQuarter: dropdown.selectQuarter,
        selectQuarterName: ''
    })
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    useEffect(() => {
        if (dropdown.selectQuarter == '' || dropdown.selectQuarter == null || dropdown.selectQuarter == undefined) {
            state['selectQuarterName'] = ''
            setState({ ...state });
        } else {
            let a = quarterList.filter((item) => item.id == dropdown.selectQuarter)
            state['selectQuarterName'] = a[0].value
            setState({ ...state });
        }
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
            addLoader(true);
            clientCard(state.selectedYear);
            defaultYears();
        }// eslint-disable-next-line
    }, [])

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYearsList(res.data.data);
            }
        })
    }


    const clientCard = (args) => {
        setLoader(true);
        let changeQuarter = state.selectQuarter == 'All' ? '' : state.selectQuarter
        AnalyticsApiTimesheet.getClientCardDetails(args, changeQuarter, data.id).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                setLoader(false);
                setCardData(res.data.data.client_details);
                dollar['series'] = res.data.data.billed_amount_in_dollars.series
                dollar['labels'] = res.data.data.billed_amount_in_dollars.labels
                dollar['dates'] = res.data.data.billed_amount_in_dollars.dates
                dollar['colors'] = res.data.data.billed_amount_in_dollars.colors
                setDollar({ ...dollar });
                hours['series'] = res.data.data.billed_amount_in_hours.series
                hours['labels'] = res.data.data.billed_amount_in_hours.labels
                hours['dates'] = res.data.data.billed_amount_in_hours.dates
                hours['colors'] = res.data.data.billed_amount_in_hours.colors
                setHours({ ...hours });
                setDonutSeries([...res.data.data.employee_by_visa_types.series])
            } else {
                setLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const changeHandler = (e) => {
        if (e.target.name == 'selectQuarter') {
            let name = quarterList.filter((i) => i.id == e.target.value)
            state[e.target.name] = e.target.value
            state['selectQuarterName'] = name[0].value
            setState({ ...state });
            addLoader(true);
            clientCard(state.selectedYear);
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
            addLoader(true);
            clientCard(e.target.value);
        }

    }

    const handleTab = (args) => {
        setActive(args);
        addLoader(true);
        clientCard(state.selectedYear);
    }

    const areaOptions = {
        series: dollar.series,
        bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
                total: {
                    enabled: true,
                    formatter: function (val) {
                        return val > 0 ? val : '';
                    },
                    style: {
                        fontSize: '13px',
                        fontWeight: 600,
                        colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
                    }
                },
            },
            distributed: true
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        formatter: function (val) {
                            return val != 0 ? active == 'hours' ? val : '$' + val : '';
                        },
                        style: {
                            fontSize: '13px',
                            fontWeight: 400,
                            color: '#1D2129'
                        }
                    }
                }
            },
        },
        chart: {
            height: 350,
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
            width: 0,
            curve: 'straight'
        },
        fill: {
            colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            total: {
                enabled: true,
                formatter: function (val) {
                    return val > 0 ? val : '';
                },
                style: {
                    fontSize: '13px',
                    fontWeight: 600,
                    colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
                }
            },
            enabled: false,
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const category = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];
                return `
                  <div style="padding: 10px; color: black; background: #fff; border-radius: 5px;">
                    <strong>${category}</strong>:  $ ${value}
                  </div>
                `;
            },
            style: {
                fontSize: '14px',
                fontFamily: 'Quicksand',
                background: 'fff'
            },
        },
        states: {
            hover: {
                filter: {
                    type: 'none'
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none'
                }
            }
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: dollar.labels,
            labels: {
                show: true,
                style: {
                    fontSize: '10px',
                    fontWeight: 400,
                }
            }
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            style: {
                color: "#999",
                fontSize: "14px"
            }
        },
    }

    const usdOptions = {
        series: hours.series,
        chart: {
            height: 350,
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
            width: 0,
            curve: 'straight'
        },
        fill: {
            colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
        },
        legend: {
            show: false
        },
        states: {
            hover: {
                filter: {
                    type: 'none'
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none'
                }
            }
        },
        dataLabels: {
            total: {
                enabled: true,
                formatter: function (val) {
                    return val > 0 ? `${val} Hrs` : '';
                },
                style: {
                    fontSize: '13px',
                    fontWeight: 600,
                    colors: ['#9747FF', '#165DFF', '#14C9C9', '#F7BA1E', '#F9A791'],
                }
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        formatter: function (val) {
                            return val != 0 ? active == 'hours' ? `${val} Hrs` : '$' + val : '';
                        },
                        style: {
                            fontSize: '13px',
                            fontWeight: 400,
                            color: '#1D2129'
                        }
                    }
                }
            },
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const category = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];
                return `
                  <div style="padding: 10px; color: black; background: #fff; border-radius: 5px;">
                    <strong>${category}</strong>: ${value} Hrs
                  </div>
                `;
            },
            style: {
                fontSize: '14px',
                fontFamily: 'Quicksand',
                background: 'fff'
            },
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: hours.labels,
            labels: {
                show: true,
                style: {
                    fontSize: '10px',
                    fontWeight: 400,
                }
            }
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            style: {
                color: "#999",
                fontSize: "14px"
            }
        },
    }

    const donutoptions = {
        legend: {
            show: false,
        },
        labels: ['CPT', 'GC', 'H1-B', 'OPT', 'USC'],
        dataLabels: {
            enabled: false,
        },
        colors: ["#3772C6", "#FDC943", "#7FC9CA", "#7DBAFF", "#FF8C42"],
        states: {
            hover: false,
            active: false,
        },
        tooltip: { enabled: false },
        stroke: {
            width: 5,
        },
        chart: {
            type: 'donut',
            //sparkline: {
            //    enabled: false
            //}
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: false,
                roundedCorners: true,
                donut: {
                    size: "75%",
                    background: 'transparent',
                },
            }
        },
        noData: {
            text: "No Data Found",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
            }
        }
    }

    const hoursList = [
        { color: '#9747FF', label: 'Week 1' },
        { color: '#165DFF', label: 'Week 2' },
        { color: '#14C9C9', label: 'Week 3' },
        { color: '#F7BA1E', label: 'Week 4' },
        { color: '#F9A791', label: 'Week 5' }
    ]

    const [cardData, setCardData] = useState({
        id: '',
        client_name: '',
        logo_url: '',
        profile_percentage: '',
        reference_id: '',
        total_billed: '',
        total_hours_logged: '',
        total_pay: '',
        total_placements: ''
    })

    // Check if all series data contains only "0.00"
    const isAllZeroData = areaOptions?.series.every((item) =>
        item.data.every((value) => parseFloat(value) === 0)
    );

    const isAllZeroHoursData = usdOptions?.series.every((item) =>
        item.data.every((value) => parseFloat(value) === 0)
    );

    return (
        <Grid container className={classes.paddingLeft} pt={2} pb={2}>
            {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) ?
                <>
                    <Grid item container lg={11.5} xs={11.5} mb={2}>
                        <Grid item lg={9} xs={6} display='flex' flexDirection='row' gap={1} alignItems='center'>
                            <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={() => navigate('/client/timesheets-analytics', { state: { activeState: 'client', from: "client" } })} />
                            <Text boldblack600 pl={1}>Client Analytics</Text>
                        </Grid>
                        <Grid item container lg={3} xs={6} spacing={2}>
                            <Grid item lg={5} xs={6}>
                                <CustomSelect
                                    sx={{
                                        '.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select': {
                                            // 'min-height': '30px',
                                            'minWidth': 'auto',
                                        }
                                    }}
                                    viewDrop
                                    scrollTrue={true}
                                    name='selectedYear'
                                    options={yearsList}
                                    value={state.selectedYear}
                                    onChange={changeHandler}
                                />
                            </Grid>
                            <Grid item lg={7} xs={6}>
                                <CustomSelect
                                    sx={{
                                        '.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select': {
                                            // 'min-height': '35px',
                                            'minWidth': 'auto'
                                        }
                                    }}
                                    viewDrop
                                    scrollTrue={true}
                                    name='selectQuarter'
                                    options={quarterList}
                                    value={state.selectQuarter}
                                    onChange={changeHandler}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={11.5} xs={11.5} className={classes.Clientaccordian}>
                        <Grid item lg={3} xs={3}>
                            <Box display='flex' alignItems='center' gap={2}>
                                {
                                    loader ?
                                        <Skeleton animation='wave' width="40px" height='65px' style={{ borderRadius: '50%' }} /> :
                                        <CircularProgress
                                            percentage={cardData.profile_percentage}
                                            color={cardData.profile_percentage >= 76 ? 'green' : cardData.profile_percentage <= 75 && cardData.profile_percentage >= 51 ? 'yellow' : cardData.profile_percentage <= 50 ? "#FFBF00" : ''}
                                            src={cardData.logo_url}
                                            name={cardData.logo_url == undefined || cardData.logo_url == null || cardData.logo_url == '' ? cardData.client_name && cardData.client_name[0] : ''}
                                            imgWidth='50px !important'
                                            imgHeight='50px !important'
                                            avatarWidth='50px !important'
                                            avatarHeight='50px !important'
                                            value={cardData.profile_percentage}
                                            size={58}
                                            tooltip
                                            tooltipContent={
                                                <Box p={'3px 0px'} width='100%'>
                                                    <Text mediumBoldWhite400>{`Profile Completion - ${cardData.profile_percentage}%`}</Text>
                                                </Box>
                                            }
                                        />
                                }
                                <Box>
                                    {
                                        loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                            <Text mediumBoldBlack noWrap>
                                                {
                                                    cardData.client_name ? cardData.client_name.length > 16 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(cardData.client_name)} placement="top" arrow>
                                                            {capitalizeAndAddSpace(cardData.client_name.slice(0, 16)) + capitalizeAndAddSpace(cardData.client_name.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : capitalizeAndAddSpace(cardData.client_name) : '--'
                                                }
                                            </Text>
                                    }
                                    {

                                        loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                            <Text mediumGreybold noWrap sx={{ paddingTop: '8px' }}>{cardData.reference_id}</Text>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container spacing={2} lg={9} xs={9}>
                            <Grid item lg={2.5} xs={2.5}>
                                <Text largeLabel noWrap>Total Placements</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_placements}</Text>
                                }
                            </Grid>
                            <Grid item lg={2.5} xs={2.5}>
                                <Text largeLabel noWrap>Total Logged Hours</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_hours_logged || cardData.total_hours_logged == 0 ? `${cardData.total_hours_logged} hrs` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2.5} xs={2.5}>
                                <Text largeLabel noWrap>Total Billed</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_billed || cardData.total_billed == 0 ? `${LocalStorage.getCurrencySymbol()} ${cardData.total_billed}` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel noWrap>Total Pay</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_pay || cardData.total_pay == 0 ? `${LocalStorage.getCurrencySymbol()} ${cardData.total_pay}` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel noWrap>Margin</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_margin || cardData.total_margin == 0 ? `${LocalStorage.getCurrencySymbol()} ${cardData.total_margin}` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={0.5} xs={0.5} container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Tooltip
                                    placement="bottom-end"
                                    arrow
                                    PopperProps={{ disablePortal: true }}
                                    sx={{ fontSize: '14px !important' }}
                                    title={<Text smallWhite>The Total pay and Margin values are approximated and not accurate for salaried employees.</Text>}
                                >
                                    <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={11.5} xs={11.5} spacing={2} mt={3}>
                        <Grid item container lg={9} sm={12} xs={12} sx={{ paddingRight: '20px' }} alignItems='center'>
                            <Grid item xl={8} lg={8} xs={7}>
                                <Text black14>Billed {active == 'USD' ? 'Amount' : 'Hours'} {state.selectQuarter == '' ? '' : <span style={{ color: '#0C75EB' }}>{state.selectQuarterName}</span>}</Text>
                            </Grid>
                            <Grid item xl={4} lg={4} xs={5} textAlign='end'>
                                <Box className={classes.tabBg}>
                                    <Box className={active == 'USD' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('USD')}>
                                        {
                                            active == 'USD' ?
                                                <Text largeWhite400>In USD ($)</Text> :
                                                <Text blackHeader>In USD ($)</Text>
                                        }
                                    </Box>
                                    <Box className={active == 'hours' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('hours')}>
                                        {
                                            active == 'hours' ?
                                                <Text largeWhite400>In Hours (hrs)</Text> :
                                                <Text blackHeader>In Hours (hrs)</Text>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={12} xs={12} mt={2}>
                                {
                                    active === 'hours' ?
                                        <>
                                            {isAllZeroHoursData ?
                                                ""
                                                :
                                                <Grid item container lg={12} xs={12} justifyContent='end'>
                                                    {
                                                        hoursList.map((series, key) => (
                                                            <Grid item lg={1} py={2}>
                                                                <Stack spacing={1} direction="row" alignItems='center'>
                                                                    <Box sx={{ height: "12px", width: "12px", backgroundColor: series.color, borderRadius: "50%" }}></Box>
                                                                    <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                                        <Text smallLabelblack>{series.label}</Text>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            }
                                        </> :
                                        ""
                                }

                                {
                                    active === 'USD' ?
                                        <>
                                            {isAllZeroData ?
                                                ""
                                                :
                                                <Grid item container lg={12} xs={12} justifyContent='end'>
                                                    {
                                                        hoursList.map((series, key) => (
                                                            <Grid item lg={1} py={2}>
                                                                <Stack spacing={1} direction="row" alignItems='center'>
                                                                    <Box sx={{ height: "12px", width: "12px", backgroundColor: series.color, borderRadius: "50%" }}></Box>
                                                                    <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                                        <Text smallLabelblack>{series.label}</Text>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            }
                                        </> : ''
                                }


                                {
                                    active === 'hours' ?
                                        <>
                                            {isAllZeroHoursData ?
                                                <Grid item container lg={11.5} xs={11} spacing={2} justifyContent={"center"}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                        <img src={emptyDashboard} alt='emptyDashboard' />
                                                        <Text popupHead1 pt={2}>No data found!</Text>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <ReactApexChart
                                                    options={usdOptions}
                                                    series={isAllZeroHoursData ? [] : usdOptions.series}
                                                    type="bar"
                                                    height='300vh'
                                                />
                                            }
                                        </> : ""
                                }
                                {
                                    active === 'USD' ?
                                        <>
                                            {isAllZeroData ?
                                                <Grid item container lg={11.5} xs={11} spacing={2} justifyContent={"center"}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                        <img src={emptyDashboard} alt='emptyDashboard' />
                                                        <Text popupHead1 pt={2}>No data found!</Text>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <ReactApexChart
                                                    options={areaOptions}
                                                    series={isAllZeroData ? [] : areaOptions.series}
                                                    type="bar"
                                                    height='300vh'
                                                />}
                                        </> : ''
                                }

                                {/* {
                                    active === 'hours' ?
                                        <ReactApexChart
                                            options={usdOptions}
                                            series={usdOptions.series}
                                            type="bar"
                                            height='300vh'
                                        /> :
                                        <ReactApexChart
                                            options={areaOptions}
                                            series={areaOptions.series}
                                            type="bar"
                                            height='300vh'
                                        />
                                } */}
                            </Grid>
                        </Grid>
                        <Grid item container lg={3} sm={12} xs={12} justifyContent='center' textAlign='center' className={classes.boxShadow}>
                            <Grid item lg={12} xs={12}>
                                <Text mediumBoldBlack>Employee Division By Visa Type</Text>
                            </Grid>
                            <Grid item container xl={10} lg={10} xs={12} textAlign='center' justifyContent='center' style={{ marginLeft: '80px', }}>
                                <PieChart
                                    colors={donutoptions.colors} // Use palette
                                    width={200}
                                    height={200}
                                    slotProps={{ legend: { hidden: true } }}
                                    series={[
                                        {
                                            data: donutSeries,
                                            innerRadius: 52,
                                            outerRadius: 70,
                                            paddingAngle: 1,
                                            cornerRadius: 6,
                                            startAngle: 0,
                                            endAngle: 360,
                                        }]}
                                />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Grid container lg={12} xs={12} justifyContent='center' p='0px 10px'>
                                    {
                                        donutSeries.map((series, key) => (
                                            <Grid item lg={6} xs={6} py={2} textAlign='center'>
                                                <Stack spacing={1} direction="row" alignItems='center'>
                                                    <Box sx={{ height: "12px", width: "12px", backgroundColor: series.color, borderRadius: "50%" }}></Box>
                                                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                                        <Text mediumBoldGrey>
                                                            {series.label.length > 16 ?
                                                                <BlackToolTip title={series.label} placement="top" arrow>
                                                                    {series.label.slice(0, 16) + (series.label.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                : series.label ? series.label : '--'
                                                            }
                                                        </Text>
                                                        <Text LargeBlack>{series.value}</Text>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <Grid item lg={9} xs={6} display='flex' flexDirection='row' gap={1} alignItems='center'>
                        <Text boldblack600 pl={1}>Client Analytics</Text>
                    </Grid>
                    {NoPermission()}
                </>
            }
        </Grid>
    )
}

export default ClientAnalystics