import { makeStyles } from "@mui/styles"
import { blue, btnBgGreen, btnBgGrey, btnBgRed, btnTxtBlack, defaultColor, grey, greyText, lightGrey, lightOrange, orange, purple } from "../../theme"
import { useTheme } from "@mui/material"

const TextStyles = makeStyles((theme) => ({
    mediumLabel: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: "#737373 !important",
        // textTransform: 'capitalize !important',
        fontWeight: `${500} !important`,
        // paddingTop: "5px",
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${500} !important`,
        }
    },
    mediumLabelColor: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade5} !important`,
        // textTransform: 'capitalize !important',
        fontWeight: `${500} !important`,
        // paddingTop: "5px",
        opacity: 1
    },
    smallLabel: {
        font: '11px Quicksand !important',
        letterSpacing: '0px',
        color: "#707070 !important",
        // textTransform: 'capitalize !important',
        // fontWeight: '500 !important',
        opacity: 1
    },
    largeBlue: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`
        }
    },
    veryLargeBlue: {
        font: '44px Quicksand !important',
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${700} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '40px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    smallnavyBlue: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `#4A5568 !important`,
        fontWeight: `${400} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    smallBlue: {
        font: "10px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${550} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('lg')]: {
            font: "9px Quicksand !important",
        }
    },
    smallBlack: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('lg')]: {
            font: '10px Quicksand !important',
        },
    },
    smallBlack14: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade7} !important`
    },
    smallBlackBold: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    boldblack22: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '20px Quicksand !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    blackFont14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        color: `${btnTxtBlack.shade1} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
        }
    },
    verySmallBoldBlack: {
        font: '11px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade1} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,

    },
    largeBlack: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    largeLabel: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: "#525252 !important",
        opacity: 1,
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    largeLabel700: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGrey.shade8} !important`,
        opacity: 1,
        fontWeight: `${700} !important`,
    },
    largeLabel400: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGrey.shade8} !important`,
        opacity: 1,
        fontWeight: `${400} !important`,
    },
    veryLargeLabel: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: `#262626 !important`,
        fontWeight: `${700} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    BrowmnMnStepperText: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade4} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    mediumBlack: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        [useTheme().breakpoints.down('sm')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    headerBlack: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    smallGrayLabel: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: "#cbcbcb !important",
        opacity: 1,
    },
    largeGreen: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: "#28A745 !important",
        opacity: 1
    },
    mediumGreen: {
        font: '21px Quicksand !important',
        letterSpacing: '0px',
        color: "#15803D !important",
        fontWeight: `${500} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '19px Quicksand !important',
            fontWeight: `${500} !important`,
        }
    },
    largeBoldGreen: {
        font: '21px Quicksand !important',
        letterSpacing: '0px',
        color: "#15803D !important",
        fontWeight: `${700} !important`,
        opacity: 1
    },
    mediumOrange: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightOrange} !important`,
        opacity: 1,
        fontWeight: `${400} !important`
    },
    mediumOrangeRehire: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightOrange} !important`,
        opacity: 1,
        fontWeight: `${600} !important`
    },
    smallOrange: {
        font: '10px Quicksand !important',
        letterSpacing: '0px',
        color: `${orange} !important`,
        opacity: 1
    },
    largeOrange: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: `${orange} !important`,
        opacity: 1
    },
    smallWhite: {
        font: '10px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        opacity: 1
    },
    mediumWhite: {
        font: '13px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '11px Quicksand !important',
        }
    },
    mediumWhite400: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        opacity: 1,
        fontWeight: `${400} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    mediumBoldWhite400: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        opacity: 1,
        fontWeight: `${400} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    mediumGrey: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        fontWeight: `${700} !important`
    },
    red: {
        font: '11px Quicksand !important',
        color: `${btnBgRed.shade4} !important`,
    },
    errorText: {
        fontFamily: "Quicksand !important",
        fontSize: "12px !important",
        color: "red",
        textAlign: "left"
    },
    checkboxlable: {
        fontWeight: "500 !important",
        font: '14px Quicksand !important',
        color: "#101828 !important"
    },
    BlackExtraDark: {
        fontWeight: `${400} !important`,
        font: '15px Quicksand !important',
        color: "#262626 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    largeBldBlack: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade2} !important`,
        opacity: 1,
        fontWeight: `${700} !important`,
        textTransform: 'capitalize !important',
    },
    veryLargeBlack: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade2} !important`,
        opacity: 1,
        fontWeight: `${500} !important`,
        textTransform: 'capitalize !important',
    },
    largeGrey: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
    },
    mediumOverView: {
        font: '14px  Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade5} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    mediumViewAmt: {
        font: '18px  Quicksand !important',
        fontWeight: "600 !important",
        color: `#262626 !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    smallGrey: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
        }
    },
    smallGreyFont: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        opacity: 1,
        fontWeight: `${400} !important`
    },
    largeWhite400: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        fontWeight: `${400} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    largeWhite: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        fontWeight: `${600} !important`,
        opacity: 1
    },
    veryLargeWhite: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        fontWeight: `${700} !important`,
        opacity: 1
    },
    LargeBlack: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        color: "#000000 !important",
        fontWeight: `${700} !important`,
        opacity: 1
    },
    largeBoldBlack: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: "#2A3042 !important",
        fontWeight: `${500} !important`,
        opacity: 1
    },
    mediumBlackColor: {
        font: '14px Quicksand !important',
        fontWeight: `${700} !important`,
        color: `#000000 !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    boldblack600: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade8} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '20px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    mediumBoldBlack400: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade8} !important`,
        fontWeight: `${400} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },

    smallRed: {
        font: "12px Quicksand !important",
        letterSpacing: '0px',
        color: `${btnBgRed.shade6} !Important`,
        fontWeight: `${600} !important`,
        opacity: 1
    },
    mediumBlue: {
        font: "12px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${400} !important`,
        opacity: 1
    },

    largeLightBlue: {
        font: "15px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${700} !Important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    analyticsViewAll: {
        font: "14px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${600} !Important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    largeBoldBlue: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${600} !important`,
        opacity: 1
    },
    profileTitle: {
        font: '15px  Quicksand !important',
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        },
    },
    overViewLable: {
        font: '15px  Quicksand !important',
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade5} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    overViewAmount: {
        font: '22px  Quicksand !important',
        fontWeight: "600 !important",
        color: `#fff !important`,
    },
    //TimeSheet Text Styles
    cardTitle: {
        color: `${btnTxtBlack.shade4} !important`,
        font: "15px Quicksand !important",
        fontWeight: `${400} !important`,
        opacity: 1
        // outline: 'none !important'
    },
    smallBlue1: {
        font: "14px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('lg')]: {
            font: "9px Quicksand !important",
        }
    },
    mediumBlue400: {
        font: "15px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${400} !important`,
        opacity: 1,
    },
    boldBlackfont600: {
        font: '17px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade2} !important`,
        opacity: 1,
        fontWeight: `${600} !important`,
        textTransform: 'capitalize !important',
    },
    largeGreyTxt: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGrey.shade6} !important`,
        textTransform: 'capitalize !important',
        opacity: 1
    },
    largeGrey16: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGrey.shade6} !important`,
        // textTransform: 'capitalize !important',
        opacity: 1,
        fontWeight: `${600} !important`,
    },
    greyLabel: {
        font: '12px  Quicksand !important',
        fontWeight: `${600} !important`,
        color: `#737373 !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    greysmallLabel: {
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`,
        color: `#737373 !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },

    mediumBlue600: {
        font: '12px  Quicksand !important',
        fontWeight: `${600} !important`,
        color: `#0C75EB !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    //BalanceSheet styles
    boldBlackfont16: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade2} !important`,
        opacity: 1,
        fontWeight: `${600} !important`,
        textTransform: 'capitalize !important',
    },
    verySmallBlack: {
        font: '10px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: '500 !important',
        color: `${btnTxtBlack.shade4} !important`
    },
    boldBlackfont22: {
        font: '22px Quicksand !important',
        color: `#092333 !important`,
        opacity: 1,
        fontWeight: `${600} !important`,
    },
    violet600: {
        font: '22px Quicksand !important',
        color: `#092333 !important`,
        opacity: 1,
        fontWeight: `${600} !important`,
    },
    //popupStyles
    popupHead1: {
        color: "#262626 !important",
        font: '18px Quicksand !important',
        fontWeight: `${700} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '15px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    popupHead2: {
        color: "#737373 !important",
        font: '13px Quicksand !important',
        fontWeight: `${400} !important`,
        textAlign: 'center !important'
    },
    mediumRed: {
        font: '14px  Quicksand !important',
        letterSpacing: '0px',
        color: "#FF5656 !important",
        opacity: 1,
        fontWeight: `${600} !important`,
    },
    //style for header in org config (Add new role)
    blackHeader18: {
        font: "18px Quicksand !important",
        fontWeight: "600 !important",
        color: "#171717",
        [useTheme().breakpoints.down('xl')]: {
            font: '15px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    mediumBlack14: {
        font: '14px Quicksand !important',
        fontWeight: `${600} !important`,
        color: `#262626 !important`,
        whiteSpace: 'pre-wrap',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    blackHeader: {
        font: '15px Quicksand !important',
        color: '#181A18 !important',
        fontWeight: `${400} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    blackHeader600: {
        font: '15px Quicksand !important',
        color: '#181A18 !important',
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    mediumBoldBlack2: {
        font: '14px Quicksand !important',
        color: '#000000 !important',
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    lightGrey3: {
        font: '12px Quicksand !important',
        color: '#737373 !important',
        fontWeight: '500 !important'
    },
    mediumGrey2: {
        font: '14px Quicksand !important',
        color: '#404040 !important',
        fontWeight: `${400} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    blackHeader1: {
        font: '15px Quicksand !important',
        color: '#171717 !important',
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    blackHeader2: {
        font: '15px Quicksand !important',
        color: '#171717 !important',
        fontWeight: `${400} !important`,
    },
    infoText: {
        font: "12px Quicksand !important",
        fontWeight: "400 !important",
    },

    mediumBoldWhite: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `#ffffff !important`,
        fontWeight: `${600} !important`,
        opacity: 1
    },
    mediumBoldBlack: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `#262626  !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    mediumBoldGrey: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `#525252  !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    smallBoldBlack: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `#262626  !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: "12px Quicksand !important",
            fontWeight: `${600} !important`,
        },
    },
    black400: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `#262626  !important`,
        fontWeight: `${400} !important`,
        opacity: 1
    },
    employeeAdded: {
        font: '12px Quicksand !important',
        color: 'rgba(23, 23, 23, 1) !important',
        fontWeight: '400 !important',
    },
    defaultmediumText: {
        font: '13px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${500} !important`,
        color: `${defaultColor} !important`,
        cursor: 'default !important'
    },
    lightPurpleMedium: {
        font: '13px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${500} !important`,
        color: `${purple} !important`,
    },
    offBoardHeadFont: {
        font: '14px Quicksand !important',
        fontWeight: `${600} !important`,
        color: '#262626 !important',
        paddingTop: '-10px',
        paddingBottom: '10px',
        lineHeight: '16.8px !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    primaryFlagFont: {
        font: '10px Quicksand !important',
        fontWeight: `${600} !important`,
        color: '#22C55E !important',
        padding: '5px 12px',
        borderRadius: '10px',
        lineHeight: '14.4px !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '8px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    offBoardBodyFont: {
        font: '14px Quicksand !important',
        fontWeight: `${400} !important`,
        color: '#737373 !important',
        letterSpacing: '0px',
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '11px Quicksand !important',
            fontWeight: `${400} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    offBoardBodyGreen: {
        font: '12px Quicksand !important',
        fontWeight: `${600} !important`,
        color: '#16A34A !important',
        lineHeight: '14.4px !important'
    },
    offBoardHeadFontMedium: {
        font: '14px Quicksand !important',
        fontWeight: `${400} !important`,
        color: '#262626 !important',
        paddingTop: '-10px',
        paddingBottom: '10px',
        lineHeight: '16.8px !important'
    },
    // Client Module
    largeHeaderText: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade2} !important`,
        opacity: 1,
        fontWeight: `${700} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '19px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    largeGrey14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    mediumBoldBlue: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${700} !important`,
        opacity: 1
    },
    boldGrey14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${greyText} !important`,
        opacity: 1,
        fontWeight: `${700} !important`
    },
    mediumGreyHeader: {
        font: '12px  Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade5} !important`,
    },
    mediumGreyHeader600: {
        font: '12px  Quicksand !important',
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade5} !important`,
    },
    notificationread: {
        font: '14px  Quicksand !important',
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade12} !important`,
    },
    notificationreadSmall: {
        font: '12px  Quicksand !important',
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade13} !important`,
    },
    mediumGreyHeader1: {
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade1} !important`,
    },
    mediumGreybold: {
        font: '12px Quicksand !important',
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade1} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    blue14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${700} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    grey16: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `#737373 !important`,
        fontWeight: `${400} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    largeBlack700: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    black14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    smallLabelblack: {
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade10} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    mediumBoldBlack700: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade8} !important`,
        fontWeight: `${700} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    mediumGrey700: {
        font: '12px Quicksand !important',
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade1} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    grey700: {
        font: '12px Quicksand !important',
        fontWeight: `${700} !important`,
        color: `${grey} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    mediumGreen700: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGreen.shade2}`,
        opacity: 1,
        fontWeight: `${700} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    smallLabelblack16: {
        font: '15px Quicksand !important',
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade10} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    largeblack40: {
        font: '40px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade4} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '38px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    smallBlack400: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    largeWhite18: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        fontWeight: `${700} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '15px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    mediumBoldBlack600: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade8} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    dashboardblack14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade11} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    headerText14: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade1} !important`,
        fontWeight: `${600} !important`,
        opacity: 1
    },
    dashboardblack12: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade11} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    largeBlack22: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '20px Quicksand !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '12px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    largeBlack28: {
        font: '26px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '24px Quicksand !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '18px Quicksand !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('md')]: {
            font: '14px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    mediumBlack700: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade9} !important`,
    },
    smallGrey10: {
        font: '10px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        opacity: 1,
        fontWeight: `${600} !important`
    },
    smallGrey400: {
        font: '10px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGrey} !important`,
        opacity: 1,
        fontWeight: `${400} !important`
    },
    black12: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: `#262626  !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    grey18px: {
        font: '15px Quicksand !important',
        fontWeight: `${400} !important`,
        color: `#737373 !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    black18px: {
        font: '15px Quicksand !important',
        fontWeight: `${600} !important`,
        color: `#404040 !important`,
    },
    largeWhite20: {
        font: '18px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('xl')]: {
            font: '16x Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    greyDark22: {
        font: '22px  Quicksand !important',
        fontWeight: `${700} !important`,
        color: `${btnTxtBlack.shade5} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '20px Quicksand !important',
            fontWeight: `${700} !important`,
        }
    },
    greensmall: {
        font: '12px  Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnBgGreen.shade3} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    RegularBlack1: {
        font: '15px  Quicksand !important',
        fontWeight: `${500} !important`,
        color: `#171717 !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${500} !important`,
        }
    },
    largeWhite1: {
        font: '60px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        fontWeight: `${600} !important`,
        opacity: 1
    },
    largeOrange16: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `${orange} !important`,
        fontWeight: `${600} !important`,
        opacity: 1
    },
    mediumBlack1: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    mediumBlack600: {
        font: '16px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
        }
    },
    tourText: {
        font: '12px Quicksand !important',
        color: '#344054 !important',
        fontWeight: `${600} !important`,
        opacity: 1
    },
    greyLabel14: {
        font: '14px Quicksand !important',
        fontWeight: `${600} !important`,
        color: `${lightGrey} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    blueText16: {
        font: "16px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('lg')]: {
            font: "10px Quicksand !important",
        }
    },
    largeBlack20: {
        font: '20px Quicksand !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: `${600} !important`,
        color: `${btnTxtBlack.shade9} !important`,
        [useTheme().breakpoints.down('lg')]: {
            font: '13px Quicksand !important',
        },
    },
    greyColor16: {
        font: '16px  Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade5} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    mediumBoldWhite600: {
        font: '12px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        opacity: 1,
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${400} !important`,
        }
    },
    largeWhite16: {
        font: '16px Quicksand !important',
        letterSpacing: '0px',
        color: "#FFFFFF !important",
        opacity: 1,
        fontWeight: `${700} !important`
    },

    largeOrange22: {
        font: '22px Quicksand !important',
        letterSpacing: '0px',
        color: "#FF5C00 !important",
        fontWeight: `${600} !important`,
        opacity: 1
    },
    lightGrey10: {
        font: '10px Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnBgGrey.shade9}`,
    },
    smallblackLabel10: {
        font: '10px Quicksand !important',
        fontWeight: `${400} !important`,
        color: `${btnTxtBlack.shade10} !important`
    },
    offBoardingText: {
        font: '15px Quicksand !important',
        letterSpacing: '0px',
        color: `#FFC52E !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`
        }
    }
}))

export default TextStyles
