import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PayrollDashboardStyles = makeStyles(() => ({
    tabBg: {
        display: "flex", background: "#F4F8FF", height: '51px', borderRadius: "12px !important", width: '100% !important', alignItems: 'center', textAlign: 'center', padding: "12px 10px !important", gap: 3,
        [useTheme().breakpoints.up('md')]: {
            minWidth: '300px !important'
        }
    },
    ActiveBg: {
        background: "#0C75EB", height: '35px', padding: "6px 15px", borderRadius: "8px", cursor: "pointer", alignItems: 'center', textAlign: 'center', width: '100%'
    },
    inactiveBg: {
        background: "transparent", padding: "12px 12px 12px 10px", borderRadius: "8px", cursor: "pointer", width: '100%'
    },
    defaultName: {
        color: '#262626', font: '12px Quicksand !important', fontWeight: '600px', border: '0.5px solid #C7CCD3', padding: '12px', cursor: 'pointer'
    },
    activeName: {
        color: '#FFFFFF', font: '12px Quicksand !important', fontWeight: '600px', border: '0.5px solid none', padding: '12px', background: '#0C75EB !important', cursor: 'pointer'
    },
    upcomingPayroll: {
        position: "relative",
        minHeight: "80px",
        display: "flex",
        alignItems: "center",
        width: '100%',
        marginTop: '25px',
        // margin: "20px auto"
    },
    payperiod: {
        background: 'linear-gradient(302.55deg, #ABDCFF -19.63%, #0396FF 110.44%)',
        borderRadius: "40px",
        padding: "0px 12px",
        color: "#ffffff",
        textAlign: "center",
        width: {sm:"20%", xs: "100%"},
        minHeight: '40px',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center'
    },
    currentCycle: {
        flexGrow: 1,
        background: 'linear-gradient(311.01deg, #90F7EC -16.28%, #1FA194 76.75%)',
        position: "relative",
        width: {sm:"60%", xs: "100%"},
        minHeight: '40px !important',
        borderRadius: "40px",
        padding: '0px 12px',
        alignItems: 'center',
        zIndex: 2,
        // left: '200px'
    },
    checkDate: {
        background: "#FFF1F1",
        borderRadius: "40px",
        padding: "0px 12px",
        color: "#ffffff",
        textAlign: "center",
        width: {sm:"20%", xs: "100%"},
        minHeight: '40px',
    },
    currentDate: {
        background: '#30AEA180',
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    },
    choosePeriod: {
        border: '1px solid #C7CCD3',
        height: '36px',
        minWidth: '160px',
        borderRadius: '8px',
        padding: '0px 12px',
        alignItems: 'center !important',
        cursor: 'pointer !important'
    },
    calendarBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    pendingBg: {
        background: '#D1E1FF',
        borderRadius: '50%',
        height: '10px',
        width: '10px'
    },
    RecoveredBg: {
        background: '#0C75EB',
        borderRadius: '50%',
        height: '10px',
        width: '10px'
    },
    bgcolor: {
        font: '16px Quicksand !important', fontWeight: '600px !important', borderRadius: '27px', minWidth: '30px', padding: '0px 4px',
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: '25px'
        },
    },
    paddingLeft: {
        paddingLeft: '116px',     
        [useTheme().breakpoints.down('md')]: {
            paddingLeft: '50px !important',
        },
        [useTheme().breakpoints.down('xs')]: {
            paddingLeft: '30px !important',
        },
    }
}))

export default PayrollDashboardStyles