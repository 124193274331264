import { Box, Chip, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Typography, Checkbox, SwipeableDrawer, FormControlLabel, Badge, Avatar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/customButton/Button'
import DashboardStyles from './DashboardStyles';
import Text from '../../../../components/customText/Text';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalStorage from '../../../../utils/LocalStorage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from '@mui/icons-material/Clear';
import Search from '../../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import infoCircle from '../../../../assets/images/info-circle.png';
import Component87 from '../../../../assets/svg/Component87.svg';
import documentSVG from '../../../../assets/svg/document.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { NoDataFound, addLoader, capitalizeAndAddSpace, removeLoader, dateFormat, addErrorMsg, BlackToolTip, NoPermission, openDocumentInNewtab, addWarningMsg, addLoaderPlanExpired, removeExpiredLoader, ListLoadingIcon, addSuccessMsg } from '../../../../utils/utils';
import TimesheetApi from '../../../../apis/admin/timesheets/TimesheetApi';
import Pending from '../../../../assets/employee/Orange-PendingIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import CircleIcon from '@mui/icons-material/Circle';
import { validate_emptyField } from '../../../../components/Validation';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Date from '../../../../components/datePicker/Date';
import TourTimesheet from '../../../../components/tourGuideDetails/TourTimesheet';
import moment from 'moment';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import FileSaver from 'file-saver';
import ActivityLog from '../../employees/userprofile/ActivityLog';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import TourGuideConfigApi from '../../../../apis/configurations/tourguideConfig/TourGuideConfigApi'
import CustomCircularProgress from '../../../../components/progressbar/CircularProgress';
import arrowDown from '../../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../../assets/svg/clients/blackarrowUp.svg';
import quickBooks from '../../../../assets/svg/QuickbooksSync.svg'
import zohoBooks from '../../../../assets/svg/ZohobooksSync.svg';
import zohosyncIcon from '../../../../assets/svg/zohosyncIcon.svg';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import CommonApi from '../../../../apis/CommonApi';
import { filterStorage } from '../../../../utils/FilterStorage';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

function TimesheetDashboard() {

    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const classes = DashboardStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const [pagination, setPagination] = useState({ total: "6" });
    const [anchorEl, setAnchorEl] = useState(null);
    const openFilter = Boolean(anchorEl);
    const timeSheetCycles = require('../../../../utils/jsons/Cycle.json');
    const [timesheets, setTimesheets] = useState([]);
    const [drawer, setDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cycleDropdown, setCycleDropdown] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [placementsDropdown, setPlacementDropdown] = useState([]);
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const activityOpen = Boolean(activityAnchorEl);
    const [activityLoad, setActivityLoad] = useState(false);
    const [activityTotal, setActivityTotal] = useState()
    const [activityData, setActivityData] = useState([]);
    const [placementSearch, setPlacementSearch] = useState("");
    const [placementLoading, setPlacementLoading] = useState(false);
    const [startTour, setStartTour] = useState(false);
    const data = location && location.state;
    const [timeSheetsCount, setTimesheetsCount] = useState({ drafted: "", pending_approval: "", approved: "", rejected: "" })
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: '',
        placement_ids: [],
        timesheet_cycles: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        employee_id: "",
        sort_column: "ts.created_at",
        sort_order: "desc"
    });

    const [filterState, setFilterState] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        placement_ids: [],
        timesheet_cycles: [],
    })

    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
    })
    const [activityFilter, setActivityFilter] = useState({ search: "" })
    const [listLoading, setListLoading] = useState(false);
    const [openQuickbookArrow, setOpenQuickbookArrow] = useState(false);
    const [openZohoArrow, setOpenZohoArrow] = useState(false);
    const [syncQuickbooksList, setSyncQuicbooksList] = useState(false);
    const [syncZohoList, setSyncZohoList] = useState(false);
    const [searchInvoice, setSearchInvoice] = useState('');
    const [searchZoho, setSearchZoho] = useState('');    
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedClientsZoho, setSelectedClientsZoho] = useState([]);
    const [syncTimesheets, setSyncTimesheets] = useState([]);
    const [QBButtonLoader, setQBButtonLoader] = useState(false);
    const [ZHButtonLoader, setZHButtonLoader] = useState(false);
    const [connectedAppsList, setConnectedAppsList] = useState({
        isZohoIntegrated: false,
        isQuickBookIntegrated: false
    })

    useEffect(() => {
        if (LocalStorage.getRedirectedModule() !== null && (LocalStorage.getRedirectedModule() == 'timesheet-deleted' || LocalStorage.getRedirectedModule() == 'timesheet-missing')) {
            addWarningMsg('Timesheet is deleted');
        }
        if (LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'multi_timesheet_draft') {
            filterData.status = "Draft"
            setFilterData(filterData);
            filterStorage.setFilter("timesheets", filterData)
            // filterState.placement_ids = [LocalStorage.getTsPlacementID()];
            // setFilterState(filterState);
        }
        const appliedFilters = filterStorage.getFilter('timesheets')
        if (appliedFilters && appliedFilters !== null && appliedFilters !== undefined) {
            if (appliedFilters.status !== "") {
                setFilterData(appliedFilters);
                if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
                    handleGetAllTimesheets(appliedFilters);
                }
            } else {
                if (data && data.status == 'Pending Approval') {
                    filterData.status = "Pending Approval";
                } else if (data && data.status == 'Approved') {
                    filterData.status = "Approved"
                } else if (data && data.status == 'Rejected') {
                    filterData.status = "Rejected"
                } else {
                    filterData.status = "Draft"
                }
                setFilterData(filterData);
                if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
                    handleGetAllTimesheets(filterData);
                }
            }
        } else {
            if (data && data.status == 'Pending Approval') {
                filterData.status = "Pending Approval";
            } else if (data && data.status == 'Approved') {
                filterData.status = "Approved"
            } else if (data && data.status == 'Rejected') {
                filterData.status = "Rejected"
            } else {
                filterData.status = "Draft"
            }
            setFilterData(filterData);
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
                handleGetAllTimesheets(filterData);
            }
        }

        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
            getPendingActionsTimesheets();
            setCycleDropdown(timeSheetCycles);
            handleGetAllPlacements(placementSearch);
        }
        navigate("/timesheet", { replace: true });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getPlanCheck();
        connectedApps();
        // eslint-disable-next-line
    }, [])


    const connectedApps = () => {
        CommonApi.connectedApps().then((res) => {
            if (res.data.statusCode === 1003) {
                setConnectedAppsList(res.data.data);
            }
        })
    }

    const getTourGuideDetails = async () => {
        let response = await TourGuideConfigApi.getApi();
        if (((LocalStorage.getStartTour() && LocalStorage.getStartTour().timesheets == true) || (response.data.statusCode == 1003 && response.data.data?.TIMESHEETS == false)) && LocalStorage.getUserData().admin_login == true) {
            LocalStorage.setStartTour({ 'timesheets': true });
            setStartTour(true);
        }

    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                    filterStorage.clearAllFilters();
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
                if (!res.data.plan_not_set && !res.data.plan_expired) {
                    if (LocalStorage.getUserData().admin_login && (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
                        getTourGuideDetails();
                    }
                    else {
                        LocalStorage.setStartTour({ 'timesheets': false });

                    }
                }
            }
        })
    }

    /*******   APi Integrations starts here  ******/

    const getPendingActionsTimesheets = () => {
        TimesheetApi.getCardListingDetails().then((res) => {
            if (res.data.statusCode === 1003) {
                setTimesheetsCount(res.data.data)
            }
        })
    }

    const handleGetAllTimesheets = (param) => {
        if (timesheets.length < 6) {
            setLoading(true);
        }
        TimesheetApi.timesheetListView(param).then((res) => {
            setLoading(false);
            removeLoader();
            setListLoading(false);
            setDrawer(null);
            if (res.data.statusCode === 1003) {
                setTimesheets(res.data.data);
                setPagination({ total: res.data.pagination ? res.data.pagination.total : "0" });
                getTotalTimesheets(res.data.pagination.total)
            }
        })
    }

    const getTotalTimesheets = (args, search) => {
        const data = {
            limit: args,
            page: 1,
            search: search ? search : "",
            status: 'Draft',
            placement_ids: [],
            timesheet_cycles: [],
            start_date_from: "",
            start_date_to: "",
            end_date_from: "",
            end_date_to: "",
            employee_id: "",
            sort_column: "ts.created_at",
            sort_order: "desc"
        }
        TimesheetApi.timesheetListView(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setSyncTimesheets(res.data.data);
            }
        })
    }

    const handleDownloadExport = (param) => {
        if(timesheets.length == 0){
            addErrorMsg('No data to export');
            return false;
        }
        addLoader(true);
        TimesheetApi.exportTimesheets(param).then((response) => {
            removeLoader();
            if (response.data.statusCode == 1003) {
                if (response.data.data.length > 0) {
                    FileSaver.saveAs(response.data.data[0].file_path);
                }
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const handleGetAllPlacements = (search) => {
        setPlacementLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            employee_id: '',
            search: search,
            timesheet_status: [],
            is_listing: true
        }
        TimesheetApi.getAllPlacements(data).then((res) => {
            setPlacementLoading(false);
            if (res.data.statusCode === 1003) {
                setPlacementDropdown(res.data.data)
            }
        })
    }

    /*******   APi Integrations Ends here  ******/

    const handleSelectChange = (value) => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        filterState.timesheet_cycles = [];
        filterState.placement_ids = []
        filterState.start_date_from = '';
        filterState.start_date_to = '';
        filterState.end_date_from = '';
        filterState.end_date_to = '';
        setFilterState({ ...filterState });
        if (value !== filterData.status) {
            filterData.timesheet_cycles = [];
            filterData.placement_ids = []
            filterData.status = value;
            filterData.start_date_from = '';
            filterData.start_date_to = '';
            filterData.end_date_from = '';
            filterData.end_date_to = '';
            filterData.search = '';
            filterData.sort_column = 'ts.created_at';
            filterData.sort_order = 'desc';
            filterData.limit = 10;
            filterData.page = 1;
            setFilterData({ ...filterData });
            setLoading(true);
            handleGetAllTimesheets(filterData);
            filterStorage.setFilter("timesheets", filterData)
        }
    }

    const handleSearch = (e) => {
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
        handleGetAllTimesheets(filterData);
        filterStorage.setFilter("timesheets", filterData)
    }

    const handleSearchClear = () => {
        filterData.search = '';
        filterData.limit = 10;
        filterData.page = 1;
        setFilterData({ ...filterData });
        addLoader(true);
        setLoading(true);
        handleGetAllTimesheets(filterData);
        filterStorage.setFilter("timesheets", filterData)
    }

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        filterData.sort_column = sort_column;
        filterData.sort_order = sort_order;
        setFilterData({ ...filterData });
        addLoader(true);
        setLoading(true);
        handleGetAllTimesheets(filterData);
        filterStorage.setFilter("timesheets", filterData)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
        setPlacementSearch("");
    }

    const handleDeleteChip = (id, name) => {
        if (name === "start_date" || name === "end_date") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            // if (filterState[name].includes(id)) {
            //     filterState[name].splice(filterState[name].findIndex(item => item === parseInt(id)), 1)
            // }
            setFilterState(newFilterState);
        }
        // if (name === "start_date" || name === "end_date") {
        //     // if (name === "start_date") {
        //     //     filterState.start_date_from = "";
        //     //     filterState.start_date_to = "";
        //     // }
        //     // if (name === "end_date") {
        //     //     filterState.end_date_from = "";
        //     //     filterState.end_date_to = "";
        //     // }
        //     // setFilterState({ ...filterState })

        //     filterState[name + "_from"] = "";
        //     filterState[name + "_to"] = "";
        //     setFilterState({ ...filterState });
        // } else {
        //     const newFilterState = { ...filterState };
        //     const updatedArray = newFilterState[name].filter(item => item !== id);
        //     newFilterState[name] = updatedArray;
        //     // if (filterState[name].includes(id)) {
        //     //     filterState[name].splice(filterState[name].findIndex(item => item === parseInt(id)), 1)
        //     // }
        //     setFilterState(newFilterState);
        // }

    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {

        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        // filterState.timesheet_cycles = [];
        // filterState.placement_ids = []
        // filterState.start_date_from = '';
        // filterState.start_date_to = '';
        // filterState.end_date_from = '';
        // filterState.end_date_to = '';
        // setFilterState({ ...filterState });
        setFilterState(prevState => ({
            ...prevState,
            timesheet_cycles: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            placement_ids: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            start_date_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            start_date_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            end_date_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            end_date_to: '' // Update the state with the new array
        }));
        // filterData.timesheet_cycles = [];
        // filterData.placement_ids = []
        // filterData.start_date_from = '';
        // filterData.start_date_to = '';
        // filterData.end_date_from = '';
        // filterData.end_date_to = '';
        // setFilterData({ ...filterData });
        // setDrawer(null);
        // handleGetAllTimesheets(filterData);
    }

    const dateChange = (e, keyName) => {
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event));
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'start_date_to':
                datesError.start_date_to = validate_emptyField(input.value)
                break;
            case 'end_date_to':
                datesError.end_date_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handleApplyFilters = () => {
        if (filterState.start_date_from != "" && filterState.start_date_to == "" && filterState.end_date_from != "" && filterState.end_date_to == "") {
            datesError.start_date_to = "Please select the To date.";
            datesError.end_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.start_date_from != "" && filterState.start_date_to == "") {
            datesError.start_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.end_date_from != "" && filterState.end_date_to == "") {
            datesError.end_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.start_date_from !== "" && filterState.start_date_to !== "") {
            if (moment(filterState.start_date_from, dateFormat()).isSameOrBefore(moment(filterState.start_date_to, dateFormat()))) {
                filterData.start_date_from = filterState.start_date_from;
                filterData.start_date_to = filterState.start_date_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.start_date_from = '';
            filterData.start_date_to = '';
        }
        if (filterState.end_date_from !== "" && filterState.end_date_to !== "") {
            if (moment(filterState.end_date_from, dateFormat()).isSameOrBefore(moment(filterState.end_date_to, dateFormat()))) {
                filterData.end_date_from = filterState.end_date_from;
                filterData.end_date_to = filterState.end_date_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.end_date_from = '';
            filterData.end_date_to = '';
        }
        filterData.placement_ids = filterState.placement_ids;
        filterData.timesheet_cycles = filterState.timesheet_cycles;
        filterData.limit = 10;
        filterData.page = 1;
        setFilterData({ ...filterData });
        addLoader(true)
        handleGetAllTimesheets(filterData);
        filterStorage.setFilter("timesheets", filterData)
    }

    const handleOpenDrawer = () => {
        handleGetAllPlacements("");
        if (filterData.start_date_from !== "" && filterData.start_date_to !== "") {
            filterState.start_date_from = filterData.start_date_from;
            filterState.start_date_to = filterData.start_date_to;
            setSelectedFilter(1);
        } else {
            filterState.start_date_from = '';
            filterState.start_date_to = '';
        }
        if (filterData.end_date_from !== "" && filterData.end_date_to !== "") {
            filterState.end_date_from = filterData.end_date_from;
            filterState.end_date_to = filterData.end_date_to;
            setSelectedFilter(2);
        } else {
            filterState.end_date_from = '';
            filterState.end_date_to = '';
        }
        if (filterData.placement_ids.length > 0) {
            setSelectedFilter(3);
        }
        if (filterData.timesheet_cycles.length > 0) {
            setSelectedFilter(4);
        }
        if (filterData.start_date_from == "" && filterData.start_date_to == "" && filterData.end_date_from == "" && filterData.end_date_to == "" && filterData.timesheet_cycles.length == 0 && filterData.placement_ids.length == 0) {
            setSelectedFilter(null);
        }
        filterState.placement_ids = filterData.placement_ids;
        filterState.timesheet_cycles = filterData.timesheet_cycles;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError });
        setPlacementSearch("");
        setDrawer("filter");
    }

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
        getActivity();
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    const getActivity = () => {
        var search = activityFilter.search;
        setActivityLoad(true);

        TimesheetApi.timesheetsActivityTrack(search).then((response) => {
            setActivityLoad(false);
            if (response.data.statusCode === 1003) {
                setActivityData(response.data.data);
                if (response.data.pagination) {
                    setActivityTotal(response.data.pagination.total)
                }
            } else {
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(response.data.message);
                }
            }
        })
    }

    const downloadActivity = () => {
        addLoader();
        TimesheetApi.downLoadActivity().then((response) => {
            removeLoader();
            if (response.data.statusCode === 1003) {
                if (response.data.data.length > 0) {
                    let url = response.data.data[0].file_path
                    let fileName = url.split('/exports/')
                    FileSaver.saveAs(response.data.data[0].file_path, fileName[1]);
                }
            } else {
                addErrorMsg(response.data.message);
            }
        })
    }

    const handlePlacementSearch = (e, args) => {
        if (args == 'invoice') {
            setSearchInvoice(e.target.value);
            getTotalTimesheets(pagination.total, e.target.value);
        } else if (args == 'zoho') {
            setSearchZoho(e.target.value);
            getTotalTimesheets(pagination.total, e.target.value);
        } else {
            setPlacementSearch(e.target.value);
            handleGetAllPlacements(e.target.value);
        }
    }

    const handlePlacementClear = (args) => {
        if (args == 'invoice') {
            setSearchInvoice('');
            getTotalTimesheets(pagination.total);
        } else if (args == 'zoho') {
            setSearchZoho('');
            getTotalTimesheets(pagination.total);
        } else {
            setPlacementSearch("");
            handleGetAllPlacements("");
        }
    }
    const loadMoreData = () => {
        // addLoader(true);
        setListLoading(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        handleGetAllTimesheets(filterData);
        filterStorage.setFilter("timesheets", filterData)
    }
    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    // setOpenBacKLoader(true);

                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleFilterDrawerClose = () => {
        filterState.timesheet_cycles = [];
        filterState.placement_ids = []
        filterState.start_date_from = '';
        filterState.start_date_to = '';
        filterState.end_date_from = '';
        filterState.end_date_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const handleNavigateTimesheet = (param) => {
        LocalStorage.removeNotificationId();
        LocalStorage.removeRedirectedModule()
        if (filterData.status === "Draft") {
            navigate("/timesheet/edit-timesheet", { state: { id: param, status: "Draft" } })
        } else if (filterData.status === "Pending Approval") {
            navigate("/timesheet/edit-timesheet", { state: { id: param, status: "Pending Approval" } })
        } else if (filterData.status === "Approved") {
            navigate("/timesheet/edit-timesheet", { state: { id: param, status: "Approved" } })
        } else if (filterData.status === "Rejected") {
            navigate("/timesheet/edit-timesheet", { state: { id: param, status: "Rejected" } })
        }
    }

    function formatValue(value) {
        var decimalPart = parseFloat(value) % 1;
        if (decimalPart === 0) {
            return Math.floor(value);
        }
        else {
            return value; // For other cases, return the original value
        }
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    useEffect(() => {
        getActivity();
        // eslint-disable-next-line
    }, [activityFilter]);

    const quickbookHandler = (e, args) => {
        if (args == 'zoho') {
            setOpenZohoArrow(e.currentTarget);
        } else {
            setOpenQuickbookArrow(e.currentTarget);
        }
    }

    const handleQBSync = () => {
        setQBButtonLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            sync_item_ids: selectedClients
        }
        TimesheetApi.quickbookIntegration(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setQBButtonLoader(false);
                setSelectedClients([]);
                addSuccessMsg(res.data.message);
                getTotalTimesheets(pagination.total, filterData.search);               
            } else {
                addErrorMsg(res.data.message);
                setQBButtonLoader(false);
            }
        })
    };

    const checkHandler = (e, args, val) => {
        const checked = e.target.checked;
        if (args == 'all') {
            if (checked) {
                let ids = syncTimesheets.map((client) => client.timesheet_id)
                setSelectedClients([...ids]);
            } else {
                setSelectedClients([]);
            }
        } else {
            const updatedArray = [...selectedClients];
            const finalValue = val.timesheet_id;
            const index = updatedArray.findIndex(item => item === finalValue);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            } else {
                const newItem = syncTimesheets.find(item => item.timesheet_id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem.timesheet_id);
                }
            }
            setSelectedClients([...updatedArray]);
        }
    }

    const checkHandlerZoho = (e, args, val) => {
        const checked = e.target.checked;
        if (args == 'all') {
            if (checked) {
                let ids = syncTimesheets.map((client) => client.timesheet_id)
                setSelectedClientsZoho([...ids]);
            } else {
                setSelectedClientsZoho([]);
            }
        } else {
            const updatedArray = [...selectedClientsZoho];
            const finalValue = val.timesheet_id;
            const index = updatedArray.findIndex(item => item === finalValue);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            } else {
                const newItem = syncTimesheets.find(item => item.timesheet_id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem.timesheet_id);
                }
            }
            setSelectedClientsZoho([...updatedArray]);
        }
    }

    const handleZHSync = () => {
        setZHButtonLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            sync_item_ids: selectedClientsZoho
        }
        TimesheetApi.zohoIntegration(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setZHButtonLoader(false);
                setSelectedClientsZoho([]);
                addSuccessMsg(res.data.message);
                getTotalTimesheets(pagination.total, filterData.search);              
            } else {
                addErrorMsg(res.data.message);
                setZHButtonLoader(false);
            }
        })
    }

    return (<>
        {startTour ? <TourTimesheet startTour={startTour} setStartTour={() => setStartTour(false)} /> :
            <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>
                <Box sx={{ width: "90%" }} >
                    {
                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                            <Box style={{ padding: "20px 10px 10px 10px" }}>
                                <Grid container>
                                    <Grid item lg={7} md={7}>
                                        <Box className={classes.btnBox}>
                                            <Button saveBtn endIcon={(filterData.status == "Draft" || timeSheetsCount.drafted == "" || timeSheetsCount.drafted == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.drafted}</Text></Box>} onClick={() => handleSelectChange("Draft")} className={filterData.status == "Draft" ? classes.activeButton : classes.inActiveButton}>Drafted</Button>
                                            <Button saveBtn endIcon={(filterData.status == "Pending Approval" || timeSheetsCount.pending_approval == "" || timeSheetsCount.pending_approval == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.pending_approval}</Text></Box>} onClick={() => handleSelectChange("Pending Approval")} className={filterData.status == "Pending Approval" ? classes.pendingActiveButton : classes.pendingInActiveButton} sx={{ marginLeft: "8px" }}>Pending Approval</Button>
                                            <Button saveBtn endIcon={(filterData.status == "Approved" || timeSheetsCount.approved == "" || timeSheetsCount.approved == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.approved}</Text></Box>} onClick={() => handleSelectChange("Approved")} className={filterData.status == "Approved" ? classes.approvedActiveButton : classes.approvedInActiveButton} sx={{ marginLeft: "8px" }}>Approved</Button>
                                            <Button saveBtn endIcon={(filterData.status == "Rejected" || timeSheetsCount.rejected == "" || timeSheetsCount.rejected == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.rejected}</Text></Box>} onClick={() => handleSelectChange("Rejected")} className={filterData.status == "Rejected" ? classes.rejectedActiveButton : classes.rejectedInActiveButton} sx={{ marginLeft: "8px" }}>Rejected</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5} md={5} container alignItems={"center"} justifyContent={"end"} columnGap={2}>
                                        <button title='Activity' type="button" className={classes.borderButton} onClick={(event) => handleActivityClick(event)}><AccessTimeIcon sx={{ height: "20px", width: "22px" }} /></button>
                                        <Button outlineBlue sx={{ width: "104px !important" }} onClick={() => { navigate('/timesheet/summary'); filterStorage.clearAllFilters(); }}>Summary</Button>
                                        {/* {
                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_create" && item.is_allowed == true))) ? */}
                                        <Button sx={{
                                            padding: "0px 15px !important", height: "40px !important", width: "174px !important",
                                            "@media (min-width: 800px) and (max-width: 1400px)": {
                                                width: "144px !important"
                                            }
                                        }} addButton id="add-timesheet" onClick={() => { navigate("/timesheet/add-timesheet"); filterStorage.clearAllFilters(); }} startIcon={<AddIcon />}>Add Timesheet</Button>
                                        {/* :
                                            ""
                                    } */}
                                    </Grid>
                                    <Grid item lg={7} md={7} pt={3}>
                                        <div style={{ height: "44px", border: "1.5px solid #E2E5E6", width: "269px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                            <input
                                                type="text"
                                                value={filterData.search}
                                                className={classes.searchInput}
                                                onChange={handleSearch}
                                                placeholder="Search by Name / Timesheet ID"
                                                onDrop={(event) => {
                                                    event.preventDefault(); // Prevent the drop action
                                                }}
                                                onDragOver={(event) => {
                                                    event.preventDefault(); // Prevent default drag behavior
                                                }}
                                            />
                                            <Box sx={{ paddingRight: "15px !important" }}>
                                                {
                                                    filterData.search !== "" ?
                                                        <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                        :
                                                        <img src={Search} alt="Search" draggable="false" />
                                                }
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid item lg={5} md={5} pt={3} container alignItems={"center"} justifyContent={"end"} columnGap={2}>
                                        {
                                            filterData.status == "Approved" ?
                                                <>
                                                    {
                                                        connectedAppsList.isQuickBookIntegrated &&
                                                        <Box display='flex' flexDirection='row' gap={2} className={classes.quickbooksbg}>
                                                            <img src={quickBooks} alt='quickbooks' />
                                                            {
                                                                openQuickbookArrow ?
                                                                    <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                                    <img src={arrowDown} aria-controls="status-menu" alt='quickbooks' className={classes.arrowSize} onClick={quickbookHandler} />
                                                            }
                                                            <Menu
                                                                anchorEl={openQuickbookArrow}
                                                                id="status-menu"
                                                                open={openQuickbookArrow}
                                                                onClose={() => { setOpenQuickbookArrow(null) }}
                                                                PaperProps={{
                                                                    elevation: 0,
                                                                    sx: {
                                                                        overflow: "visible",
                                                                        boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                                        mt: 3,
                                                                        width: '200px'
                                                                    }
                                                                }}
                                                                transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                            >
                                                                <MenuItem className={classes.menuBg} onClick={() => setSyncQuicbooksList(true)}><Text smallBlack>Sync Timesheets to Quickbooks</Text></MenuItem>
                                                            </Menu>
                                                        </Box>
                                                    }
                                                    {
                                                        connectedAppsList.isZohoIntegrated &&
                                                        <Box display='flex' flexDirection='row' gap={2} className={classes.zohobooksbg}>
                                                            <img src={zohoBooks} alt='zohobooks' />
                                                            {
                                                                openZohoArrow ?
                                                                    <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                                    <img src={arrowDown} alt='zohobooks' className={classes.arrowSize} onClick={(e) => quickbookHandler(e, 'zoho')} />
                                                            }
                                                            <Menu
                                                                anchorEl={openZohoArrow}
                                                                id="status-menu"
                                                                open={openZohoArrow}
                                                                onClose={() => { setOpenZohoArrow(null) }}
                                                                PaperProps={{
                                                                    elevation: 0,
                                                                    sx: {
                                                                        overflow: "visible",
                                                                        boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                                        mt: 3,
                                                                        width: '200px'
                                                                    }
                                                                }}
                                                                transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                            >
                                                                <MenuItem className={classes.menuBg} onClick={() => setSyncZohoList(true)}><Text smallBlack>Sync Timesheets to Zohobooks</Text></MenuItem>
                                                            </Menu>
                                                        </Box>
                                                    }
                                                </> : ''
                                        }
                                        <button title="Export" type="button" className={classes.borderButton1} onClick={() => handleDownloadExport(filterData.status)}><img src={NewDownloadcloud} alt="Userplus" /></button>
                                        <button title="Filter" type="button" className={classes.borderButton1} onClick={() => { handleOpenDrawer() }}>
                                            <Badge badgeContent={filterData.timesheet_cycles.length + filterData.placement_ids.length + ((filterData.start_date_from !== "" && filterData.start_date_to !== "") && 1) + ((filterData.end_date_from !== "" && filterData.end_date_to !== "") && 1)} color="error">
                                                <FilterListIcon sx={{ color: filterData.timesheet_cycles.length || filterData.placement_ids.length || (filterData.start_date_from !== "" && filterData.start_date_to !== "") || (filterData.end_date_from !== "" && filterData.end_date_to !== "") ? "#2BEA2B" : "#737373" }} />
                                            </Badge>
                                        </button>
                                        <Text offBoardBodyFont>
                                            Count
                                            <HtmlTooltip
                                                placement="bottom"
                                                arrow
                                                title={
                                                    <React.Fragment>
                                                        <Box>
                                                            <Typography className={classes.profileTooltipText}>
                                                                Total Timesheets count is <br />based on the filters applied
                                                            </Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                }
                                            >
                                                <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                            </HtmlTooltip>
                                            {` - ${pagination ? pagination.total ? pagination.total : "" : ""} ${filterData.status}`}</Text>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems='center' padding={"0px 40px"} pt={2} sx={{ borderBottom: "1px solid #F3EFEF !important" }}>
                                    <Grid container item lg={4} md={4} justifyContent={"start"} alignItems={"center"}>
                                        <Text mediumBlack>Employee Name</Text>
                                        <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                            <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "emp.display_name" || filterData.sort_column == "ts.created_at") ? "#2BEA2B" : "" }} />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={openFilter}
                                            onClose={handleCloseFilter}
                                            onClick={handleCloseFilter}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: "visible",
                                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                    mt: 0.5,
                                                    ml: -2,
                                                    padding: "5px",
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "#EAECF0 !important",
                                                    }
                                                },
                                            }}
                                            transformOrigin={{ horizontal: "left", vertical: "top" }}
                                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                        >
                                            <MenuItem className={classes.menuItem} sx={{ background: filterData.sort_column == 'ts.created_at' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('ts.created_at', 'desc')}> Newest First</MenuItem>
                                            <MenuItem className={classes.menuItem} sx={{ background: filterData.sort_column == 'ts.created_at' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('ts.created_at', 'asc')}> Oldest First</MenuItem>
                                            <MenuItem className={classes.menuItem} sx={{ background: filterData.sort_column == 'emp.display_name' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.display_name', 'asc')}> Name (A-Z)</MenuItem>
                                            <MenuItem className={classes.menuItem} sx={{ background: filterData.sort_column == 'emp.display_name' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('emp.display_name', 'desc')}> Name (Z-A)</MenuItem>
                                        </Menu>
                                    </Grid>
                                    {filterData.status === "Draft" && <Grid item lg={2} md={2}><Text mediumBlack ml={"-5px"}>Timesheet Cycle</Text></Grid>}
                                    <Grid item lg={2.1} md={2.5}><Text mediumBlack ml={"-8px"}>From Period - To Period</Text></Grid>
                                    {filterData.status !== "Draft" && <Grid item lg={1} md={1}><Text mediumBlack ml={"-8px"}>Hours</Text></Grid>}
                                    <Grid item lg={2} md={2}><Text mediumBlack ml={"-10px"}>Placement</Text></Grid>
                                    {filterData.status !== "Draft" && <Grid item lg={1} md={1}><Text mediumBlack>Attachment</Text></Grid>}
                                    <Grid item lg={1} md={1} textAlign='end'><Text mediumBlack>Action</Text></Grid>
                                </Grid>

                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        )) :
                                        <>
                                            {
                                                timesheets.length > 0 ?
                                                    <Box sx={{
                                                        maxHeight: "59.4vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start', marginTop: '5px',
                                                        paddingLeft: '5px', paddingRight: '5px',
                                                        "@media (min-height: 850px)": {
                                                            maxHeight: '68.9vh'
                                                        }
                                                    }} ref={scrollBox} onScroll={handleScroll}>
                                                        {
                                                            timesheets.map((place, key) => (
                                                                <Box sx={{
                                                                    width: "100%", height: "74px !important", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", '&:hover': {
                                                                        borderRadius: '8px',
                                                                        transition: "all .3s ease",
                                                                        boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
                                                                    },
                                                                    margin: "4px 0px !important",
                                                                }} mt={2}>
                                                                    <Grid container item lg={12} md={12} alignItems='center' padding={"0px 40px"}>
                                                                        <Grid item container lg={4} md={4} textAlign='start' pt={1} justifyContent='space-between'>
                                                                            <Box display='flex' alignItems='center' gap={2} width={'50%'} flexGrow={1}>
                                                                                <CustomCircularProgress
                                                                                    percentage={place.profile_progress}
                                                                                    color={place.profile_progress >= 76 ? 'green' : place.profile_progress <= 75 && place.profile_progress >= 51 ? 'yellow' : place.profile_progress <= 50 ? "#FFBF00" : ''}
                                                                                    src={place.profile_picture_url}
                                                                                    name={place.profile_picture_url == undefined || place.profile_picture_url == null || place.profile_picture_url == '' ? place.employee_name && capitalizeAndAddSpace(place.employee_name[0]) : ''}
                                                                                    imgWidth='50px !important'
                                                                                    imgHeight='50px !important'
                                                                                    avatarWidth='50px !important'
                                                                                    avatarHeight='50px !important'
                                                                                    value={place.profile_progress}
                                                                                    size={58}
                                                                                    tooltip
                                                                                    tooltipContent={
                                                                                        <Box p={'3px 0px'} width='100%'>
                                                                                            <Text mediumBoldWhite400>{`Profile Completion - ${place.profile_progress}%`}</Text>
                                                                                        </Box>
                                                                                    }
                                                                                />
                                                                                <Box>
                                                                                    <Text mediumBoldBlack noWrap>
                                                                                        {place.employee_name === "" ? "--" :
                                                                                            capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                    {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                capitalizeAndAddSpace(place.employee_name)
                                                                                        }
                                                                                        <HtmlTooltip title={place.employee_e_verified == 1 ? 'E-Verified' : 'E-verification is pending'} placement="right" arrow>
                                                                                            {(place.employee_e_verified == 1 || place.employee_e_verified == 2) && <img src={place.employee_e_verified == 1 ? Component87 : place.employee_e_verified == 2 ? Pending : ''} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />}
                                                                                        </HtmlTooltip>
                                                                                    </Text>
                                                                                    <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {place.placement_reference_id ? place.placement_reference_id : "--"} &#128900; {place.timesheet_reference_id ? place.timesheet_reference_id : "--"}</Text>
                                                                                </Box>
                                                                            </Box>
                                                                            {/* {(filterData.status == "Approved"&& appIntegrations?.connection_status == "connected" && appIntegrations?.redirect_to == "") &&
                                                                                <Box display='flex' alignItems='center' justifyContent='start' width={'50%'} paddingLeft={'15%'}>
                                                                                    {((place?.zoho_migration_status == "Not Migrated" || place?.zoho_migration_status == "Error") && currentSyncingId !== place?.timesheet_id) &&
                                                                                        <Button zohosyncnow onClick={(e) => handleZohosync(e, place?.timesheet_id)}><img alt='zohoicon' src={Zohosyncnow} className={classes.zohoIcon} />Sync Now</Button>}

                                                                                {(syncing && currentSyncingId == place?.timesheet_id) &&
                                                                                    <Button zohosyncing  ><img alt='zohoicon' src={Zohosyncing} className={classes.zohoIcon} />Syncing...</Button>}
                                                                                {(place?.zoho_migration_status == "Migrated" && currentSyncingId !== place?.timesheet_id) && <>
                                                                                    <Button zohosynced  >Synced<span className={classes.syncedSpan}><img alt='zohoicon' src={Zohosynced} className={classes.zohoIcon} /></span></Button>
                                                                                    <Box display='flex' alignItems='center' justifyContent='center'>
                                                                                        <img alt='zohoicon' src={Zohorefresh} className={classes.zohoRefresh} onClick={(e) => handleZohosync(e,place?.timesheet_id)} />
                                                                                    </Box>
                                                                                </>}
                                                                            </Box>} */}
                                                                        </Grid>
                                                                        {filterData.status === "Draft" && <Grid item lg={2.1} md={2.1} textAlign='start'> <Text smallBoldBlack noWrap >{place.timesheet_cycle}</Text></Grid>}
                                                                        <Grid item lg={2.1} md={2.5} textAlign='start' ml={"-8px"}> <Text smallBoldBlack noWrap >{place.start_date} - {place.end_date}</Text></Grid>
                                                                        {filterData.status !== "Draft" && <Grid container item lg={1} md={1} textAlign='start'> <Text smallBoldBlack noWrap >{formatValue(place.billable_hours)}</Text>
                                                                            {place.ot_hours && place.ot_hours !== "0.00" ? <p style={{ color: "#15803D", fontStyle: "Quicksand", fontSize: "12px", fontWeight: 600, paddingTop: "-10px" }}>&nbsp;+{formatValue(place.ot_hours)}</p> : ""}
                                                                        </Grid>}
                                                                        <Grid item lg={2} md={2} textAlign='start'> <Text smallBoldBlack noWrap >{place.placement_reference_id} ({place.client_name.length > 16 ?
                                                                            <HtmlTooltip title={place.client_name} placement="right" arrow>
                                                                                {place.client_name.slice(0, 16) + (place.client_name.length > 16 ? "..." : "")}
                                                                            </HtmlTooltip>
                                                                            :
                                                                            place.client_name})</Text></Grid>
                                                                        {filterData.status !== "Draft" &&
                                                                            <Grid item lg={1} md={1} textAlign='center'>
                                                                                {
                                                                                    place.documents.length > 0 ?
                                                                                        <BlackToolTip
                                                                                            title={
                                                                                                <Box p={.5}>
                                                                                                    {
                                                                                                        place.documents.map((docs, index) => (
                                                                                                            <Text onClick={() => { openInNewTab(docs, docs.document_url) }} smallWhite sx={{ cursor: "pointer !important", padding: '2px' }}> {docs.document_name ? docs.document_name : ""}</Text>
                                                                                                        ))
                                                                                                    }
                                                                                                </Box>


                                                                                            }


                                                                                            placement={"bottom-start"}
                                                                                            arrow
                                                                                        >
                                                                                            <img src={documentSVG} alt="documentSVG" style={{ height: "21px", width: "21px" }} />
                                                                                        </BlackToolTip>
                                                                                        : "--"}
                                                                            </Grid>}
                                                                        <Grid item lg={1} md={1} textAlign='end'>
                                                                            {
                                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                                                                    <Text onClick={() => { handleNavigateTimesheet(place.timesheet_id) }} defaultmediumText className={classes.linkText} noWrap>View</Text>
                                                                                    :
                                                                                    <Text sx={{ color: "#737373 !important" }} defaultmediumText className={classes.linkText} noWrap>View</Text>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box >
                                                            ))
                                                        }
                                                        {
                                                            listLoading && (
                                                                <ListLoadingIcon />
                                                            )
                                                        }
                                                    </Box>
                                                    :
                                                    timesheets.length == 0 ?
                                                        <Box>
                                                            {NoDataFound(`${filterData.status === "Draft" ? "No timesheets has been Drafted" : filterData.status === "Pending Approval" ? "No timesheet approvals are pending" : filterData.status === "Approved" ? "No timesheets has been created/Approved" : "No timesheets has been Rejected"}`, 'No Timesheets Here Yet')}
                                                        </Box>
                                                        : ''
                                            }
                                        </>
                                }

                            </Box>
                            :
                            <>
                                {NoPermission()}
                            </>
                    }
                </Box>

                <SwipeableDrawer
                    anchor={'right'}
                    open={drawer}
                    onClose={() => handleFilterDrawerClose()}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    {
                        drawer === "filter" ?
                            <Box width={'660px'} height={'100vh'} >
                                <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                    <Text headerBlack>Filters</Text>
                                </Box>

                                <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                    <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>

                                        {
                                            filterState.start_date_from !== "" && filterState.start_date_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.start_date_from} - {filterState.start_date_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "start_date")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }

                                        {
                                            filterState.end_date_from !== "" && filterState.end_date_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.end_date_from} - {filterState.end_date_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "end_date")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            placementsDropdown.map((item, key) => (
                                                filterState.placement_ids.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{item.reference_id} (  {item.client_name === "" ? "--" : item.client_name && item.client_name.length > 16 ?
                                                        <BlackToolTip title={item.client_name} placement="right" arrow>
                                                            {item.client_name.slice(0, 16) + (item.client_name.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        item.client_name
                                                    } )</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "placement_ids")}
                                                    deleteIcon={<CloseIcon />} />
                                            ))
                                        }
                                        {
                                            cycleDropdown.map((item, key) => (
                                                filterState.timesheet_cycles.includes(item.id) &&
                                                <Chip key={key}
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "timesheet_cycles")}
                                                    deleteIcon={<CloseIcon />}
                                                />
                                            ))
                                        }
                                    </Box>
                                    {
                                        ((filterState.start_date_from !== "" && filterState.start_date_to !== "") || (filterState.end_date_from !== "" && filterState.end_date_to !== "") || filterState.placement_ids.length > 0 || filterState.timesheet_cycles.length > 0) ?
                                            <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                            :
                                            ''
                                    }
                                </Box>

                                <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                    <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                        <List component="nav" aria-label="secondary mailbox folder">
                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.start_date_from !== "" && filterState.start_date_to ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.start_date_from !== "" && filterState.start_date_to ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 1 ? <Text smallBlue1 ml={3}>Timesheet Start Date</Text> : <Text mediumBlack ml={3}>Timesheet Start Date</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterState.end_date_from !== "" && filterState.end_date_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.end_date_from !== "" && filterState.end_date_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Timesheet End Date</Text> : <Text ml={3} mediumBlack>Timesheet End Date</Text>} />
                                            </ListItem>
                                            <Divider className={classes.divider} />
                                            <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterState.placement_ids.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.placement_ids.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Placements</Text> : <Text ml={3} mediumBlack>Placements</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={filterState.timesheet_cycles.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{filterState.timesheet_cycles.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Timesheet Cycle</Text> : <Text ml={3} mediumBlack>Timesheet Cycle</Text>} />
                                            </ListItem>
                                            <Divider />
                                        </List>

                                    </Box>
                                    <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                        {
                                            selectedFilter == 1 ?
                                                <Grid container alignItems={"center"}>
                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                        <Date
                                                            labelText={`From`}
                                                            name='start_date_from'
                                                            value={filterState.start_date_from}
                                                            onChange={(value) => dateChange(value, 'start_date_from')}
                                                            height='56px'
                                                        />
                                                    </Grid>
                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                        &#8594;
                                                    </Grid>
                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                        <Date
                                                            labelText={`To`}
                                                            name='start_date_to'
                                                            value={filterState.start_date_to}
                                                            onChange={(value) => dateChange(value, 'start_date_to')}
                                                            height='56px'
                                                            minDate={moment(filterState.start_date_from).format(dateFormat())}
                                                            disabled={filterState.start_date_from == ""}
                                                            error={datesError.start_date_to}
                                                        />
                                                        <Box sx={{ height: "0px" }}>{datesError.start_date_to ? <Text red>{datesError.start_date_to ? datesError.start_date_to : ''}</Text> : ''}</Box>
                                                    </Grid>
                                                </Grid> :
                                                selectedFilter == 2 ?
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`From`}
                                                                name='end_date_from'
                                                                value={filterState.end_date_from}
                                                                onChange={(value) => dateChange(value, 'end_date_from')}
                                                                height='56px'
                                                            />
                                                        </Grid>
                                                        <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                            &#8594;
                                                        </Grid>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`To`}
                                                                name='end_date_to'
                                                                value={filterState.end_date_to}
                                                                onChange={(value) => dateChange(value, 'end_date_to')}
                                                                height='56px'
                                                                minDate={moment(filterState.end_date_from).format(dateFormat())}
                                                                disabled={filterState.end_date_from == ""}
                                                                error={datesError.end_date_to}
                                                            />
                                                            <Box sx={{ height: "0px" }}>{datesError.end_date_to ? <Text red>{datesError.end_date_to ? datesError.end_date_to : ''}</Text> : ''}</Box>
                                                        </Grid>
                                                    </Grid> :
                                                    selectedFilter == 3 ?
                                                        <>
                                                            <Box sx={{ margin: "10px 0px", minHeight: "50px", border: "1.5px solid #E2E5E6", width: "100%", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                                <input
                                                                    type="text"
                                                                    value={placementSearch}
                                                                    className={classes.searchInput}
                                                                    onChange={handlePlacementSearch}
                                                                    placeholder="Search by Client Name / Placement ID"
                                                                />
                                                                <Box sx={{ paddingRight: "15px !important" }}>
                                                                    {
                                                                        placementSearch !== "" ?
                                                                            <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handlePlacementClear()} />
                                                                            :
                                                                            <img src={Search} alt="Search" />
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ height: "60vh", overflow: "auto" }}>
                                                                {
                                                                    placementLoading ?
                                                                        <Box width={"100%"} height={'76vh'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                            <img src={Loader} alt="Loader" width={'70px'} height={'70px'} />
                                                                        </Box>
                                                                        :
                                                                        <>
                                                                            {
                                                                                placementSearch !== "" && placementsDropdown.length === 0 ?
                                                                                    <Box >
                                                                                        {NoDataFound("", "No Data Found", "40%")}
                                                                                    </Box> :
                                                                                    <>

                                                                                        {
                                                                                            placementsDropdown.length > 0 && placementsDropdown.map((item, index) => (
                                                                                                <Grid container alignItems={"center"} pb={2} pl={1}>
                                                                                                    <FormControlLabel
                                                                                                        key={index}
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                size="small"
                                                                                                                name={"placement_ids"}
                                                                                                                value={item.id}
                                                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                                icon={<CheckBorderIcon />}
                                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                                checked={filterState.placement_ids.includes(item.id)} />}
                                                                                                        label={<Text mediumBlack nowrap>{item.reference_id} (  {item.client_name === "" ? "--" : item.client_name && item.client_name.length > 16 ?
                                                                                                            <BlackToolTip title={item.client_name} placement="right" arrow>
                                                                                                                {item.client_name.slice(0, 16) + (item.client_name.length > 16 ? "..." : "")}
                                                                                                            </BlackToolTip>
                                                                                                            :
                                                                                                            item.client_name
                                                                                                        } )</Text>}
                                                                                                    />
                                                                                                </Grid>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                }
                                                            </Box>
                                                        </>
                                                        : selectedFilter == 4 ?
                                                            <>
                                                                {
                                                                    cycleDropdown.length > 0 && cycleDropdown.map((item, index) => (
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            {
                                                                                item.id != 5 &&
                                                                                <>
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                size="small"
                                                                                                name={"timesheet_cycles"}
                                                                                                value={item.id}
                                                                                                onChange={(e) => handleChangeCheckBox(e)}
                                                                                                icon={<CheckBorderIcon />}
                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                checked={filterState.timesheet_cycles.includes(item.id)} />}
                                                                                        label={<Text mediumBlack noWrap>{item.value}</Text>}
                                                                                    />
                                                                                </>
                                                                            }

                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </> :
                                                            <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                <Grid container>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                        <Text veryLargeLabel>Gear Up!</Text>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                            // nofilterplacement
                                        }
                                    </Box>
                                </Box>

                                <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                    <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                    <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                </Box>
                            </Box >
                            : null
                    }

                </SwipeableDrawer>

                <SwipeableDrawer
                    anchor={'right'}
                    open={activityOpen}
                    onClose={() => { handleActivityClose() }}
                    // onOpen={toggleDrawer(true, state.view)}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >
                    <ActivityLog
                        placeholder={"Search by Timesheet / Documents / Task"}
                        activityTotal={activityTotal}
                        activityData={activityData}
                        activityFilter={activityFilter}
                        setActivityFilter={setActivityFilter}
                        downloadActivity={downloadActivity}
                        employee={{ id: "" }}
                        setLoading={setActivityLoad} loading={activityLoad} />
                </SwipeableDrawer>
                <ReusablePopup iconHide white openPopup={syncQuickbooksList} setOpenPopup={setSyncQuicbooksList} onClose={() => { setSyncQuicbooksList(false); setSelectedClients([]); setOpenQuickbookArrow(null) }} max1200>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text headerBlack>Sync Timesheets to Quickbooks</Text>
                        </Grid>
                        <>
                            <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' mt={1}>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{ width: '100% !important' }}>
                                    <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: '45%' }}>
                                        <input
                                            className={classes.EmployeesSearchInput}
                                            name="searchInvoice"
                                            onChange={(e) => handlePlacementSearch(e, 'invoice')}
                                            placeholder='Search by Invoice ID'
                                            value={searchInvoice}
                                        />
                                        <Box sx={{ paddingRight: "15px !important" }}>
                                            {
                                                searchInvoice == '' ?
                                                    <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handlePlacementClear('invoice')} />
                                            }
                                        </Box>
                                    </Box>
                                    {
                                        selectedClients.length > 0 ?
                                            <Box>
                                                <LoadingButton addButton180 loading={QBButtonLoader} onClick={handleQBSync}>Sync Timesheets</LoadingButton>
                                            </Box> : ''
                                    }
                                </Box>
                            </Grid>
                            <Grid item container xs={12} mt={2}>
                                <Grid item xs={0.5}>
                                    <Checkbox value={syncTimesheets.length == selectedClients.length}
                                        checked={syncTimesheets.length == selectedClients.length}
                                        sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        onClick={(e) => checkHandler(e, 'all')}
                                    />
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Text mediumBlack14>Employee Name</Text>
                                </Grid>
                                <Grid item container xs={9} spacing={2} justifyContent='center'>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Timesheet period</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Hours</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Placement</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Timesheet Cycle</Text>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} my={0.8}>
                                <Divider />
                            </Grid>
                            {
                                syncTimesheets.length > 0 && syncTimesheets.map((item, index) => (
                                    <Grid item container xs={12}>
                                        <Grid item xs={0.5}>
                                            <Checkbox value={selectedClients.some(i => i === item.timesheet_id)}
                                                checked={selectedClients.some(i => i === item.timesheet_id)}
                                                sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                checkedIcon={<CheckedIcon />}
                                                onClick={(e) => checkHandler(e, 'each', item)}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='start'>
                                                <Avatar
                                                    alt="Q"
                                                    src={quickBooks}
                                                    className={item.quickbook_migration_status == 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                />
                                                <Text analyticsViewAll>
                                                    {item.employee_name === "" ? "-" : capitalizeAndAddSpace(item.employee_name).length > 12 ?
                                                        <HtmlTooltip title={capitalizeAndAddSpace(item.employee_name)} placement="right" arrow>
                                                            {capitalizeAndAddSpace(item.employee_name).slice(0, 12) + (capitalizeAndAddSpace(item.employee_name).length > 12 ? "..." : "")}
                                                        </HtmlTooltip>
                                                        :
                                                        capitalizeAndAddSpace(item.employee_name)
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item container xs={9} columnSpacing={2} justifyContent='center' alignItems='center'>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.start_date == '' && item.end_date == '' ? '-' : `${item.start_date}-${item.end_date}`}</Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{formatValue(item.billable_hours)}
                                                    {item.ot_hours && item.ot_hours !== "0.00" ? <p style={{ color: "#15803D", fontStyle: "Quicksand", fontSize: "12px", fontWeight: 600, paddingTop: "-10px" }}>&nbsp;+{formatValue(item.ot_hours)}</p> : ''}
                                                </Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{(item.placement_reference_id == '' && item.client_name == '') ? '-' : `${item.placement_reference_id}-[${item.client_name}]`}</Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.timesheet_cycle ? item.timesheet_cycle : '-'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </>
                    </Grid>
                </ReusablePopup>
                <ReusablePopup iconHide white openPopup={syncZohoList} setOpenPopup={setSyncZohoList} onClose={() => { setSyncZohoList(false); setSelectedClientsZoho([]); setOpenZohoArrow(null) }} max1200>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text headerBlack>Sync Timesheets to Zohobooks</Text>
                        </Grid>
                        <>
                            <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' mt={1}>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{ width: '100% !important' }}>
                                    <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: '45%' }}>
                                        <input
                                            className={classes.EmployeesSearchInput}
                                            name="searchZoho"
                                            onChange={(e) => handlePlacementSearch(e, 'zoho')}
                                            placeholder='Search by Invoice ID'
                                            value={searchZoho}
                                        />
                                        <Box sx={{ paddingRight: "15px !important" }}>
                                            {
                                                searchZoho == '' ?
                                                    <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handlePlacementClear('zoho')} />
                                            }
                                        </Box>
                                    </Box>
                                    {
                                        selectedClientsZoho.length > 0 ?
                                            <Box>
                                                <LoadingButton addButton180 loading={ZHButtonLoader} onClick={handleZHSync}>Sync Timesheets</LoadingButton>
                                            </Box> : ''
                                    }
                                </Box>
                            </Grid>
                            <Grid item container xs={12} mt={2}>
                                <Grid item xs={1}>
                                    <Checkbox value={syncTimesheets.length == selectedClientsZoho.length}
                                        checked={syncTimesheets.length == selectedClientsZoho.length}
                                        sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        onClick={(e) => checkHandlerZoho(e, 'all')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Text mediumBlack14>Employee Name</Text>
                                </Grid>
                                <Grid item container xs={9} spacing={2} justifyContent='center'>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Timesheet period</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Hours</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Placement</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Timesheet Cycle</Text>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} my={0.8}>
                                <Divider />
                            </Grid>
                            {
                                syncTimesheets.length > 0 && syncTimesheets.map((item) => (
                                    <Grid item container xs={12}>
                                        <Grid item xs={1}>
                                            <Checkbox value={selectedClientsZoho.some(i => i === item.timesheet_id)}
                                                checked={selectedClientsZoho.some(i => i === item.timesheet_id)}
                                                sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                checkedIcon={<CheckedIcon />}
                                                onClick={(e) => checkHandlerZoho(e, 'each', item)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='start'>
                                                <Avatar
                                                    alt="Z"
                                                    src={zohosyncIcon}
                                                    className={item.zoho_migration_status == 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                />
                                                <Text mediumBlack14>
                                                    {item.employee_name === "" ? "-" : capitalizeAndAddSpace(item.employee_name).length > 12 ?
                                                        <HtmlTooltip title={capitalizeAndAddSpace(item.employee_name)} placement="right" arrow>
                                                            {capitalizeAndAddSpace(item.employee_name).slice(0, 12) + (capitalizeAndAddSpace(item.employee_name).length > 12 ? "..." : "")}
                                                        </HtmlTooltip>
                                                        :
                                                        capitalizeAndAddSpace(item.employee_name)
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item container xs={9} columnSpacing={2} justifyContent='center' alignItems='center'>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.start_date == '' && item.end_date == '' ? '-' : `${item.start_date}-${item.end_date}`}</Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{formatValue(item.billable_hours)}
                                                    {item.ot_hours && item.ot_hours !== "0.00" ? <p style={{ color: "#15803D", fontStyle: "Quicksand", fontSize: "12px", fontWeight: 600, paddingTop: "-10px" }}>&nbsp;+{formatValue(item.ot_hours)}</p> : ''}
                                                </Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{(item.placement_reference_id == '' && item.client_name == '') ? '-' : `${item.placement_reference_id}-[${item.client_name}]`}</Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.timesheet_cycle ? item.timesheet_cycle : '-'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </>
                    </Grid>
                </ReusablePopup>
            </Box >}
    </>
    )
}

export default TimesheetDashboard