import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Autocomplete, Chip, TextField, Slide, DialogContent, Dialog, Stack } from '@mui/material';
import Text from '../../../components/customText/Text';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Date from '../../../components/datePicker/Date';
import Button from '../../../components/customButton/Button';
import Checkbox from '@mui/material/Checkbox';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import info from '../../../assets/svg/fi_info.svg'
import ViewStyles from './ViewStyles';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/closeIcon.svg';
import { ReactComponent as RemoveSvg } from '../../../assets/svg/removeIcon.svg';
import { isValid, validate_emptyField } from '../../../components/Validation';
import approvalDelete from '../../../assets/svg/approvalDelete.svg';
import moment from 'moment';
import { addErrorMsg, addSuccessMsg, addWarningMsg, dateFormat } from '../../../utils/utils';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';
import LoadingButton from '../../../components/customButton/LoadingButton';
import LocalStorage from '../../../utils/LocalStorage';
import { ErrorMessages } from '../../../utils/ErrorMessages';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "15px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TimeSheetConfigurationForm(props) {
    // eslint-disable-next-line
    const { edit, setEdit, state, setState, employees, placementID, getPlacementTimesheetData } = props;
    const classes = ViewStyles();

    const [timesheetCycleDropdown, setTimesheetCycleDropdown] = useState([]);
    const [dayslist, setDayslist] = useState([]);
    const [deletedLevels, setDeletedLevels] = useState([]);
    const [deletedchips, setDeletedchips] = useState([]);
    const [error, setError] = useState({});
    const [deleteLevelOpen, setDeleteLevelOpen] = useState(false);
    const [deleteLevelIndex, setDeleteLevelIndex] = useState(null);
    const timeSheetCycles = require('../../../utils/jsons/Cycle.json');
    const days = require('../../../utils/jsons/Days.json');

    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        setTimesheetCycleDropdown(timeSheetCycles);
        setDayslist(days);
        // eslint-disable-next-line
    }, []);

    const dateChange = (e, name) => {
        let date = e.$d;
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({ ...state, [name]: moment(date).format(dateFormat()) });
        handleValidations(event.target);
    }

    const handleChange = (e) => {
        if (e.target.name == "timesheet_cycle") {
            setState({ ...state, [e.target.name]: e.target.value, timesheet_start_day: "" })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
        handleValidations(e.target);
    };

    const handleCheck = (e) => {
        setState({ ...state, is_timesheet_attachment_mandatory: e.target.checked })
    }

    const handleChangeLevels = (e, newArr, index) => {
        if (!["", null, undefined].includes(e.target.value)) {
            let { id, value } = newArr[newArr.length - 1];
            let approvals = state.approval_levels
            let approverIdsArr = approvals[index].approval_users;
            if (approverIdsArr.filter((i) => i.employee_id == id).length == 0) {
                approverIdsArr.push({ id: "", employee_id: id, value: value, employee_name: value });
                setState({ ...state, approval_levels: approvals })
            }
        }
    }

    const handleChangeTime = (e) => {
        let input = e.target.value.replace(/\D/g, "").substring(0, 5);
        const first = input.substring(0, 2);
        const second = input.substring(2, 5);
        if (input.length > 2) {
            setState({ ...state, [e.target.name]: `${first}:${second}` });
        } else {
            setState({ ...state, [e.target.name]: input });
        }

        if (input.length > 2) {
            var mm = parseInt(second);
            if (mm > 59) {
                if (first < 23) {
                    var sec = second - 60;
                    var fOne = parseInt(first) + 1;
                    if (fOne < 10) {
                        setState({ ...state, [e.target.name]: `0${fOne}:${sec}` });
                    } else {
                        setState({ ...state, [e.target.name]: `${fOne}:${sec}` });
                    }

                    handleValidations(e.target);
                } else {
                    setState({ ...state, [e.target.name]: `${first}:${59}` });
                    handleValidations(e.target);
                }
            } else {
                setState({ ...state, [e.target.name]: `${first}:${second}` });
                handleValidations(e.target);
            }
        } else if (input.length >= 0) {
            var hh = parseInt(input);
            if (hh > 23) {
                state[e.target.name] = "23";
            } else {
                state[e.target.name] = input;
            }
            setState({ ...state });
            handleValidations(e.target);
        }
    };


    const handleSubmit = () => {
        
        let errors = validateAll();
        if (isValid(errors)) {
            if (state.approval_levels.length > 0) {
                for (var i in state.approval_levels) {
                    if (state.approval_levels[i].approval_users.length == 0) {
                        state.approval_levels.splice(i, 1);
                    }
                }
                setState({ ...state });
            }
            setSaveLoading(true);
            if (deletedchips.length > 0) {
                state.deleted_approver_ids = deletedchips;
                setState({ ...state });
            }
            if (deletedLevels.length > 0) {
                state.deleted_level_ids = deletedLevels;
                setState({ ...state });
            }
            const data = {
                request_id: LocalStorage.uid(),
                placement_id: state.placement_id,
                timesheet_details: state
            }
            PlacementApi.timeSheetConfigUpdate(data).then((res) => {
                if (res.data.statusCode === 1003) {
                    props.setformEdit(false);
                    setSaveLoading(false);
                    addSuccessMsg("Timesheet configuration details updated successfully");
                    getPlacementTimesheetData(state.placement_id);
                    setEdit(false);
                } else {
                    setSaveLoading(false);
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            let err = { errors };
            err = errors;
            setError(err);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const validateAll = () => {
        let { default_hours, timesheet_next_cycle_start } = state;
        let errors = {};
        errors.default_hours = validate_emptyField(default_hours);
        errors.timesheet_next_cycle_start = validate_emptyField(timesheet_next_cycle_start);
        return errors;
    };

    const handleDeleteLevelOpen = (index) => {
        setDeleteLevelOpen(true);
        setDeleteLevelIndex(index);
    }


    const handleRemoveLevel = (index) => {
        let arr = state.approval_levels;
        let deletedLevelArr = deletedLevels;
        if (arr[index].id !== '') {
            deletedLevelArr.push(arr[index].id);
        }
        arr.splice(index, 1);
        arr.forEach((ele, key) => {
            ele.rank = key + 1;
            ele.level = key + 1;
        });
        setState({ ...state, approval_levels: arr });
        setDeletedLevels([...deletedLevelArr]);
        setDeleteLevelOpen(false);
        setDeleteLevelIndex(null);
    }

    const handleDeleteChipLevels = (key, index) => {
        let approvals = state.approval_levels;
        let newArr = approvals[index].approval_users;
        let deletedChipsArr = deletedchips;
        if (newArr[key].id !== '') {
            deletedChipsArr.push(newArr[key].id);
        }
        newArr.splice(key, 1);
        if (newArr.length == 0) {
            let deletedLevelArr = deletedLevels;
            if (approvals[index].id !== '') {
                deletedLevelArr.push(approvals[index].id);
            }
            setDeletedLevels([...deletedLevelArr]);
        }

        setState({ ...state, approval_levels: approvals });
        setDeletedchips([...deletedChipsArr]);
    }

    const handleValidations = (input) => {
        let err = error;
        switch (input.name || input.tagName) {
            case "timesheet_cycle":
                err.timesheet_cycle = validate_emptyField(input.value);
                err.timesheet_start_day = "";
                break;
            case "timesheet_start_day":
                if (state.timesheet_cycle == 1 || state.timesheet_cycle == 2) {
                    err.timesheet_start_day = validate_emptyField(input.value);
                }
                break;
            case "timesheet_next_cycle_start":
                err.timesheet_next_cycle_start = validate_emptyField(input.value);
                break;
            case "default_hours":
                err.default_hours = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const handleAddNewLevel = () => {
        let arr = state.approval_levels;
        arr.push({
            id: "",
            level: arr.length + 1,
            approval_users: [],
        },);
        setState({ ...state, approval_levels: arr });
    }

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container p={3} alignItems={"center"}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Text largeBlue>Timesheet Configuration</Text>
                    </Grid>
                    <Grid container columnSpacing={4} pt={6}>
                        <Grid item lg={4} md={4} sm={4}>
                            <Select
                                name='timesheet_cycle'
                                value={state.timesheet_cycle}
                                onChange={(e) => handleChange(e)}
                                options={timesheetCycleDropdown}
                                label={'Timesheet Cycle'}
                                helperText={error.timesheet_cycle ? <Text red> {error.timesheet_cycle ? error.timesheet_cycle : ""}</Text> : ""}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'default_hours',
                                    value: state.default_hours,
                                    inputProps: { maxLength: 5 }
                                }}
                                clientInput
                                handleChange={handleChangeTime}
                                error={error.default_hours}
                                labelText={`Default Hours`}
                            />
                            <Text errorText> {error.default_hours ? error.default_hours : ""}</Text>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4}>
                            <Date
                                labelText={`Actual Start Date`}
                                name='timesheet_start_date'
                                value={state.timesheet_start_date}
                                onChange={(value) => dateChange(value, 'timesheet_start_date')}
                                height='56px'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} pt={3}>
                            <Date
                                labelText={`Effective Start Date`}
                                name='timesheet_next_cycle_start'
                                value={state.timesheet_next_cycle_start}
                                onChange={(value) => dateChange(value, 'timesheet_next_cycle_start')}
                                minDate={state.timesheet_start_date !== "" && moment(state.timesheet_start_date).isAfter(moment()) ? state.timesheet_start_date : moment().add(1, 'days').format(dateFormat())}
                                height='56px'
                                error={error.timesheet_next_cycle_start}
                            />
                            <Text errorText> {error.timesheet_next_cycle_start ? error.timesheet_next_cycle_start : ""}</Text>
                        </Grid>
                        {
                            ["Weekly", "Bi Weekly"].includes(state.timesheet_cycle) ?
                                <Grid item lg={4} md={4} sm={6} xs={12} pt={3}>
                                    <Select
                                        name='timesheet_start_day'
                                        value={state.timesheet_start_day}
                                        onChange={(e) => { handleChange(e) }}
                                        options={dayslist}
                                        label={'Day start from'}
                                        helperText={error.timesheet_start_day ? <Text red> {error.timesheet_start_day ? error.timesheet_start_day : ""}</Text> : ""}
                                    />
                                </Grid>
                                : null
                        }
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} pt={4}>
                        <Text largeBlue>Timesheet Approval Configuration
                            <HtmlTooltip
                                placement="bottom"
                                arrow
                                title={
                                    <React.Fragment>
                                        <Box>
                                            <Typography className={classes.profileTooltipText}>
                                                If a single user at the<br />current level approves the<br />timesheet, it will progress <br />to the next level of<br />Approvers</Typography>
                                        </Box>
                                    </React.Fragment>
                                }
                            >
                                <img src={info} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                            </HtmlTooltip>
                        </Text>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {
                            state.approval_levels.length > 0 && state.approval_levels.map((i, key) => (
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box my={2} >
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                            <div style={{ width: '100%', paddingTop: i.approval_users.length > 0 ? '16px' : '0px', minHeight: '69px', display: 'flex', alignItems: "center", }}>
                                                <Autocomplete
                                                    multiple
                                                    // freeSolo
                                                    limitTags={5}
                                                    id="multiple-limit-tags"
                                                    options={employees}
                                                    getOptionLabel={(option) => option.value}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.uniqueIdentifier}>
                                                            {option.value}
                                                        </li>
                                                    )}
                                                    value={i.approval_users}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label={`Level ${key + 1} Approvers`} pt={2} />
                                                    )}
                                                    onChange={(e, newArr) => handleChangeLevels(e, newArr, key)}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, keyId) => (
                                                            <Chip
                                                                {...getTagProps({ keyId })}
                                                                key={keyId}
                                                                label={option && option.employee_name}
                                                                sx={{ gap: '6px', m: "4px 6px", p: "4px", font: "12px Quicksand", fontWeight: 500, }}
                                                                onDelete={() => handleDeleteChipLevels(keyId, key)}
                                                                deleteIcon={<DeleteIcon />}
                                                            />
                                                        ))
                                                    }
                                                    sx={{
                                                        width: '100%',
                                                        "& .MuiInputBase-root": {
                                                            cursor: "pointer",
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            cursor: "pointer",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none"
                                                        },
                                                        "& .MuiAutocomplete-endAdornment": {
                                                            display: "none"
                                                        },
                                                        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                            transform: "translate(10px, 16px) scale(1);"
                                                        },
                                                        "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                            color: "#737373",
                                                            fontSize: "14px",
                                                            fontFamily: "Quicksand",
                                                            fontWeight: 400,
                                                        },
                                                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                            color: "#737373",
                                                            fontSize: "15px",
                                                            fontFamily: "Quicksand",
                                                            fontWeight: 500,
                                                        },
                                                        "&.Mui-focused .MuiInputLabel-outlined": {
                                                            color: "#737373",
                                                            fontSize: "10px",
                                                            fontFamily: "Quicksand",
                                                            fontWeight: 400,
                                                            transform: i.approval_users.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                        },
                                                    }}
                                                />
                                            </div>
                                            {
                                                state.approval_levels.length > 1 ?
                                                    <Box mx={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", }}>
                                                        <RemoveSvg onClick={() => handleDeleteLevelOpen(key)} style={{ cursor: "pointer" }} />
                                                    </Box> : null
                                            }
                                        </Box>
                                    </Box>
                                </Grid>

                            ))
                        }
                    </Grid>
                    {
                        (state.approval_levels.length > 0 && state.approval_levels.length < 5 && state.approval_levels[state.approval_levels.length - 1].approval_users.length > 0) &&
                        <Grid item lg={12} md={12} sm={12}>
                            <Button onClick={() => { handleAddNewLevel() }} addNewFull>Add New Level</Button>
                        </Grid>
                    }


                    <Grid item container lg={12} md={12} sm={12} pt={4} alignItems={"center"}>
                        <Checkbox
                            sx={{ paddingLeft: "0px !important" }}
                            size='small'
                            checked={state.is_timesheet_attachment_mandatory}
                            value={state.is_timesheet_attachment_mandatory}
                            onClick={(e) => { handleCheck(e) }}
                            icon={<CheckBorderIcon />}
                            checkedIcon={<CheckedIcon />}
                        />
                        <Text mediumGreyHeader1 sx={{ font: "15px Quicksand !important", color: "#1A141F !important" }}>Make timesheet attachment mandatory</Text>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} pt={3} justifyContent={"space-between"}>
                        <Button blackCancel1 onClick={() => { setEdit(false); props.setformEdit(false); getPlacementTimesheetData(state.placement_id) }}>Cancel</Button>
                        <LoadingButton saveBtn loading={saveLoading} onClick={() => handleSubmit()}>Save</LoadingButton>
                    </Grid>
                </Grid>
            </Grid>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={deleteLevelOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "30px 0px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} >
                        <img src={approvalDelete} alt='approvalDelete' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want delete the level {deleteLevelIndex !== null ? `- ${deleteLevelIndex + 1}` : ""} Approval</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button outlineRedCancel sx={{ width: "122px !important", height: "44px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }} onClick={() => { setDeleteLevelOpen(false); setDeleteLevelIndex(null) }} >
                                Cancel
                            </Button>
                            <Button sx={{ width: "122px !important", height: "44px !important" }} smallBlue redBackground onClick={() => { handleRemoveLevel(deleteLevelIndex) }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </Grid>
    )
}

export default TimeSheetConfigurationForm