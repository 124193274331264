import { Box, Grid, Divider, Avatar, AvatarGroup, } from "@mui/material";
import React, { useEffect, useRef } from "react";
import DasboardStyles from '../../views/admin/dashboard/DasboardStyles';
import ReactApexChart from "react-apexcharts";
import Text from '../../components/customText/Text'
import CustomSelect from '../../components/customSelect/CustomSelect'
import { capitalizeAndAddSpace, BlackToolTip, NoDataFound, BorderLinearProgress } from "../../utils/utils";
import LocalStorage from "../../utils/LocalStorage";
import billed from '../../assets/svg/dashboard/billed.svg';
import pay from '../../assets/svg/dashboard/pay.svg';
import margin from '../../assets/svg/dashboard/margin.svg';
import Chart from 'react-apexcharts';
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import TourGuideConfigApi from "../../apis/configurations/tourguideConfig/TourGuideConfigApi";
import Verified from '../../assets/svg/Component87.svg';
import Pending from '../../assets/employee/Orange-PendingIcon.svg';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useNavigate } from "react-router-dom";
import { employee_URL } from "../../config/development";
import moment from "moment";

export default function TourDashboard({ setOpenPopup, startTour, setStartTour }) {
    const classes = DasboardStyles();
    const currency = LocalStorage.getCurrencySymbol();
    const scrollRef = useRef();
    const navigate = useNavigate();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const companies = [
        {
            id: "0c530632-a84b-4ad7-a14d-08d18ade58d1",
            name: "ABC",
            placement_count: "14",
            percentage: "100.00",
            employees: [
                {
                    id: "66530df2-3ae2-4476-bed1-e6bef1a29e64",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "99a32399-4fa4-4335-89be-e5b1f1cac7dd",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6a1ab9f4-6c96-43a6-9f70-c826ed1ff703",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "26010b00-9ecc-473f-a6ad-744d54171674",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "e99677ce-bebd-4c46-9864-99c37b5d51e2",
                    gender: "Female",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "163ef573-23fe-4d53-bc1d-b5285fba2b82",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "151188f8-2c61-47fa-a82f-b501db3a64a2",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "c94d4160-6ffb-4b61-b2f1-85a681248193",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "455fd1bc-b4f1-424e-afc7-db7fe25efdac",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "549897d2-94dc-4fb8-8e85-c3a74e9fc9e1",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "b0c4994d-de88-452c-8c27-9ef6c5db138f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "b762b535-b003-4406-8f13-b882a78de4a4",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                }
            ]
        },
        {
            id: "0c530632-a84b-4ad7-a14d-08d18ade58d1",
            name: "EFG",
            placement_count: "14",
            percentage: "100.00",
            employees: [
                {
                    id: "b0c4994d-de88-452c-8c27-9ef6c5db138f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "99a32399-4fa4-4335-89be-e5b1f1cac7dd",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6a1ab9f4-6c96-43a6-9f70-c826ed1ff703",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "26010b00-9ecc-473f-a6ad-744d54171674",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "e99677ce-bebd-4c46-9864-99c37b5d51e2",
                    gender: "Female",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "163ef573-23fe-4d53-bc1d-b5285fba2b82",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "151188f8-2c61-47fa-a82f-b501db3a64a2",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },

            ]
        }, {
            id: "0c530632-a84b-4ad7-a14d-08d18ade58d1",
            name: "HIJ",
            placement_count: "14",
            percentage: "100.00",
            employees: [
                {
                    id: "66530df2-3ae2-4476-bed1-e6bef1a29e64",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },

                {
                    id: "163ef573-23fe-4d53-bc1d-b5285fba2b82",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "151188f8-2c61-47fa-a82f-b501db3a64a2",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },

                {
                    id: "b0c4994d-de88-452c-8c27-9ef6c5db138f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "b762b535-b003-4406-8f13-b882a78de4a4",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                }
            ]
        }, {
            id: "0c530632-a84b-4ad7-a14d-08d18ade58d1",
            name: "KLN",
            placement_count: "14",
            percentage: "100.00",
            employees: [


                {
                    id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "c94d4160-6ffb-4b61-b2f1-85a681248193",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "455fd1bc-b4f1-424e-afc7-db7fe25efdac",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },

                {
                    id: "b0c4994d-de88-452c-8c27-9ef6c5db138f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
            ]
        }, {
            id: "0c530632-a84b-4ad7-a14d-08d18ade58d1",
            name: "OPQ",
            placement_count: "14",
            percentage: "100.00",
            employees: [
                {
                    id: "66530df2-3ae2-4476-bed1-e6bef1a29e64",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "99a32399-4fa4-4335-89be-e5b1f1cac7dd",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "6a1ab9f4-6c96-43a6-9f70-c826ed1ff703",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },
                {
                    id: "26010b00-9ecc-473f-a6ad-744d54171674",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },

                {
                    id: "c94d4160-6ffb-4b61-b2f1-85a681248193",
                    gender: "Male",
                    profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                },

            ]
        },

    ]

    const tsData = {
        drafted: "1347",
        pending_approval: "59",
        approved: "54",
        rejected: "12"
    }
    const yearDropdwn = {
        year: moment().year(),
        placementYear: moment().year()
    };

    const financial = {
        total_clients: 19,
        total_placements: 36,
        total_billed: 2963800.75,
        total_hours_logged: 2805.58,
        total_pay: 355945.58,
        margin: 2607855.17,
        financialData: {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ],
            dates: [
                {
                    start: "2024-01-01",
                    end: "2024-01-31"
                },
                {
                    start: "2024-02-01",
                    end: "2024-02-29"
                },
                {
                    start: "2024-03-01",
                    end: "2024-03-31"
                },
                {
                    start: "2024-04-01",
                    end: "2024-04-30"
                },
                {
                    start: "2024-05-01",
                    end: "2024-05-31"
                },
                {
                    start: "2024-06-01",
                    end: "2024-06-30"
                },
                {
                    start: "2024-07-01",
                    end: "2024-07-31"
                },
                {
                    start: "2024-08-01",
                    end: "2024-08-31"
                },
                {
                    start: "2024-09-01",
                    end: "2024-09-30"
                },
                {
                    start: "2024-10-01",
                    end: "2024-10-31"
                },
                {
                    start: "2024-11-01",
                    end: "2024-11-30"
                },
                {
                    start: "2024-12-01",
                    end: "2024-12-31"
                }
            ],
            series: [
                {
                    name: "Total Bill",
                    data: [
                        "0.00",
                        "0.00",
                        "0.00",
                        "9071.75",
                        "1183043.00",
                        "946941.00",
                        "720873.00",
                        "92520.00",
                        "12032.00",
                        "1720.00",
                        "0.00",
                        "0.00"
                    ]
                },
                {
                    name: "Total Pay",
                    data: [
                        "0.00",
                        "0.00",
                        "0.00",
                        "8584.43",
                        "170639.80",
                        "70062.51",
                        "84836.12",
                        "45365.11",
                        "5295.38",
                        "8.38",
                        "0.00",
                        "0.00"
                    ]
                },
                {
                    name: "Margin",
                    data: [
                        "0.00",
                        "0.00",
                        "0.00",
                        "487.32",
                        "1012403.20",
                        "876878.49",
                        "636036.88",
                        "47154.89",
                        "6736.62",
                        "1711.62",
                        "0.00",
                        "0.00"
                    ]
                }
            ]
        },
        employeesData: {
            series: [
                87,
                71
            ],
            labels: [
                "consultant",
                "contractor"
            ],
            total_employees: 158
        },
        topCompanies: [
            {
                id: "0c530632-a84b-4ad7-a14d-08d18ade58d1",
                name: "ABC",
                placement_count: "14",
                percentage: "100.00",
                employees: [
                    {
                        id: "66530df2-3ae2-4476-bed1-e6bef1a29e64",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "99a32399-4fa4-4335-89be-e5b1f1cac7dd",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "6a1ab9f4-6c96-43a6-9f70-c826ed1ff703",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "26010b00-9ecc-473f-a6ad-744d54171674",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "e99677ce-bebd-4c46-9864-99c37b5d51e2",
                        gender: "Female",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "163ef573-23fe-4d53-bc1d-b5285fba2b82",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "151188f8-2c61-47fa-a82f-b501db3a64a2",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "c94d4160-6ffb-4b61-b2f1-85a681248193",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "455fd1bc-b4f1-424e-afc7-db7fe25efdac",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "549897d2-94dc-4fb8-8e85-c3a74e9fc9e1",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "b0c4994d-de88-452c-8c27-9ef6c5db138f",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "b762b535-b003-4406-8f13-b882a78de4a4",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    }
                ]
            },
            {
                id: "0cf12f7b-f30b-4143-9b8b-255cebf5a314",
                name: "EFG",
                placement_count: "11",
                percentage: "78.57",
                employees: [
                    {
                        id: "d79421e5-a17d-4072-a836-52e6985969cd",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "ddea7491-55bb-4679-b110-7ba9993afbd6",
                        gender: "Female",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "74e94191-d017-48d5-b261-d5e07d5b45fe",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "491a20cc-39d4-4f8a-aa7b-33505d7dafd6",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "b0c4994d-de88-452c-8c27-9ef6c5db138f",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "9a649fd5-bf2d-4bff-b360-7c2fdce478e0",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "d0d6b4ce-9f67-4b83-a738-2a03a80cf591",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "df29be01-08b2-4d65-bbc9-c9ccf2c72ac6",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "253ab843-8cd9-45ff-968b-c07b8a3aefde",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "c94d4160-6ffb-4b61-b2f1-85a681248193",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    }
                ]
            },
            {
                id: "93add272-5c72-427d-be36-a147a5e1130e",
                name: "HIJ",
                placement_count: "10",
                percentage: "71.43",
                employees: [
                    {
                        id: "b4ee9fe3-48e5-47e1-9b4c-f606a3087491",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "12e91aa4-69e4-48c2-b0b7-bb8f8f234bec",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "cd8e1550-daa8-4c65-8cc0-eb9f996c45c5",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "491a20cc-39d4-4f8a-aa7b-33505d7dafd6",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "e6a0ab9a-6d4b-468c-8b0d-d3df6d01c5f5",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "a67d8e5e-14a2-4e2c-bfa4-5a7962a685d8",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "46025fc5-7b25-411a-9326-6d007f97da6d",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "bb4b540f-584b-43aa-9e2c-738213ca41d6",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "23944e4b-f31b-4309-b675-431c3dd76c66",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "8f05d8e8-50b2-4037-a49f-8255341f2564",
                        gender: "Female",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    }
                ]
            },
            {
                id: "105f0779-0594-46f3-b19c-25dee03a11e6",
                name: "KLN",
                placement_count: "7",
                percentage: "50.00",
                employees: [
                    {
                        id: "fd95d8e8-5d56-4838-98ce-9958f7eebe34",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "c94d4160-6ffb-4b61-b2f1-85a681248193",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "9a54983c-aa89-4918-bdb4-ca07d6924b54",
                        gender: "Female",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "df29be01-08b2-4d65-bbc9-c9ccf2c72ac6",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "f5308334-10a5-483b-b427-04dd3c3029b8",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "da0db6d9-48f6-4650-8978-6b1a1fb34608",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    }
                ]
            },
            {
                id: "f64d1639-6c13-4ef6-aa2f-caefae5ebcc4",
                name: "OPQR",
                placement_count: "7",
                percentage: "50.00",
                employees: [
                    {
                        id: "da0db6d9-48f6-4650-8978-6b1a1fb34608",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "ddea7491-55bb-4679-b110-7ba9993afbd6",
                        gender: "Female",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "6fa41994-57e2-46eb-aa81-9993bd4d7a0f",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "d6c98bef-818d-407c-85aa-203df7f7efa2",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "d0d6b4ce-9f67-4b83-a738-2a03a80cf591",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "b4ee9fe3-48e5-47e1-9b4c-f606a3087491",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    },
                    {
                        id: "99a32399-4fa4-4335-89be-e5b1f1cac7dd",
                        gender: "Male",
                        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`
                    }
                ]
            }
        ],
        timesheetData: [
            {
                drafted: "1347",
                pending_approval: "59",
                approved: "54",
                rejected: "12"
            }
        ],
        timesheetSeries: [
            "1347",
            "59",
            "54",
            "12"
        ],
        timesheetColors: [
            "#62B2FD",
            "#FFC25C",
            "#9BDFC4",
            "#F99BAB"
        ]
    }
    const monthsOptions = [
        {
            id: 1,
            value: 'January'
        },
        {
            id: 2,
            value: 'Febraury'
        },
        {
            id: 3,
            value: 'March'
        },
        {
            id: 4,
            value: 'April'
        },
        {
            id: 5,
            value: 'May'
        },
        {
            id: 6,
            value: 'June'
        },
        {
            id: 7,
            value: 'July'
        },
        {
            id: 8,
            value: 'August'
        }, {
            id: 9,
            value: 'September'
        },
        {
            id: 10,
            value: 'October'
        },
        {
            id: 11,
            value: 'November'
        },
        {
            id: 12,
            value: 'December'
        }
    ]
    const financialOptions = {
        series: financial.financialData.series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#0C75EB', '#F6BA1E', '#8763FC'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#0C75EB', '#F6BA1E', '#8763FC'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            x: {
                formatter: function (val) {
                    return monthsOptions[val - 1].value + ' ' + yearDropdwn.year;
                }
            },
            y: {
                formatter: function (val) {
                    return "$" + val;
                }
            },
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: financial.financialData.labels
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return "$ " + val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        colors: ['#0C75EB', '#F6BA1E', '#8763FC']
    }
    const totalEmp = {
        consultant: 87,
        contractor: 71,
        total_employees: 158,
        series: [87, 71],
        labels: [
            "consultant",
            "contractor"
        ]
    }
    const pieData = {
        options: {
            dataLabels: {
                enabled: false
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false,
            },
            labels: totalEmp.labels,
            colors: ['#9BDFC4', '#F99BAB'],
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    // w is the chart context containing various properties including series
                                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                },
                            },
                        },
                    },
                },
            },
        },
        series: totalEmp.series,
        labels: totalEmp.labels
    };
    const linearProgress = [
        {
            bgColor: '#0095FF',
            barColor: '#CDE7FF'
        },
        {
            bgColor: '#00E096',
            barColor: '#D4FFEB'
        },
        {
            bgColor: '#884DFF',
            barColor: '#E7DBFF'
        },
        {
            bgColor: '#FF8F0D',
            barColor: '#FFEBD4'
        },
        {
            bgColor: '#D34040',
            barColor: '#FFC8C8'
        },
        {
            bgColor: '#0095FF',
            barColor: '#CDE7FF'
        },
        {
            bgColor: '#00E096',
            barColor: '#D4FFEB'
        },
        {
            bgColor: '#884DFF',
            barColor: '#E7DBFF'
        },
        {
            bgColor: '#FF8F0D',
            barColor: '#FFEBD4'
        },
        {
            bgColor: '#D34040',
            barColor: '#FFC8C8'
        },
    ]


    const placementDetails = {
        placement_details: {
            series: [
                {
                    name: "Addition",
                    data: [
                        0,
                        0,
                        0,
                        7,
                        27,
                        59,
                        41,
                        3,
                        6,
                        13,
                        0,
                        0
                    ]
                },
                {
                    name: "Attrition",
                    data: [
                        0,
                        0,
                        0,
                        1,
                        5,
                        15,
                        14,
                        1,
                        1,
                        5,
                        0,
                        0
                    ]
                }
            ],
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ],
            placement_addition: 156,
            placement_attrition: 42
        },
        distinct_placements_added: [
            {
                name: "ABC",
                count: 0
            },
            {
                name: "EAD",
                count: 0
            },
            {
                name: "EAD",
                count: 0
            },
            {
                name: "HRT",
                count: 0
            },
            {
                name: "H4E",
                count: 0
            },
            {
                name: "GCR",
                count: 0
            },
            {
                name: "QWE",
                count: 0
            },
            {
                name: "VFR",
                count: 0
            },
            {
                name: "GRE",
                count: 0
            },
            {
                name: "EFT",
                count: 1
            },
            {
                name: "YUI",
                count: 2
            },
            {
                name: "RGE",
                count: 67
            }
        ],
        distinct_placements_completed: [
            {
                name: "QWG",
                count: 0
            },
            {
                name: "YUYT",
                count: 0
            },
            {
                name: "RTY",
                count: 0
            },
            {
                name: "JHY",
                count: 0
            },
            {
                name: "RTYH",
                count: 0
            },
            {
                name: "BNGT",
                count: 0
            },
            {
                name: "YHT",
                count: 0
            },
            {
                name: "RFT",
                count: 0
            },
            {
                name: "YTRGY",
                count: 0
            },
            {
                name: "RFGTR",
                count: 1
            },
            {
                name: "EDF",
                count: 0
            },
            {
                name: "IOO",
                count: 24
            }
        ],
        placements_added: [
            {
                name: "EDF",
                count: 0
            },
            {
                name: "MNH",
                count: 0
            },
            {
                name: "HYG",
                count: 0
            },
            {
                name: "NHU",
                count: 0
            },
            {
                name: "DFM",
                count: 0
            },
            {
                name: "IKL",
                count: 0
            },
            {
                name: "TGY",
                count: 0
            },
            {
                name: "RFG",
                count: 0
            },
            {
                name: "POPG",
                count: 0
            },
            {
                name: "BGHM",
                count: 10
            },
            {
                name: "RFGRT",
                count: 4
            },
            {
                name: "WER",
                count: 142
            }
        ],
        placements_completed: [
            {
                name: "BHU",
                count: 0
            },
            {
                name: "FRTG",
                count: 0
            },
            {
                name: "RGHYG",
                count: 0
            },
            {
                name: "FETD",
                count: 0
            },
            {
                name: "HGD",
                count: 0
            },
            {
                name: "QWE",
                count: 0
            },
            {
                name: "FHH",
                count: 0
            },
            {
                name: "ASD",
                count: 0
            },
            {
                name: "EWRE",
                count: 0
            },
            {
                name: "SEF",
                count: 1
            },
            {
                name: "MHJ",
                count: 0
            },
            {
                name: "GER",
                count: 58
            }
        ],
        total_distinct_placements_added: 70,
        total_distinct_placements_completed: 25,
        total_placements_added: 156,
        total_placements_completed: 59,
        placement_addition: 156,
        placement_attrition: 42
    }

    const placementsAdded = [

        {
            name: "KHJ",
            count: 0
        },
        {
            name: "RYT",
            count: 0
        },
        {
            name: "REF",
            count: 1
        },
        {
            name: "POYJ",
            count: 2
        },
        {
            name: "RTH",
            count: 67
        }
    ]

    const placementsCompleted = [

        {
            name: "BVB",
            count: 0
        },
        {
            name: "RGBH",
            count: 0
        },
        {
            name: "QWEM",
            count: 1
        },
        {
            name: "NMMJ",
            count: 0
        },
        {
            name: "IYKT",
            count: 58
        }
    ]

    const tsSeries = {
        tsSeriesData: [],
        tsColors: []
    }
    tsSeries.tsColors = financial.timesheetColors
    for (let i = 0; i < financial.timesheetSeries.length; i++) {
        tsSeries.tsSeriesData.push(Number(financial.timesheetSeries[i]));
    }
    const timesheetOptions = {
        series: tsSeries.tsSeriesData,
        options: {
            chart: {
                width: '100%',
                type: 'pie',
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            labels: ["Drafted", "Pending Approval", "Approved", "Rejected"],
            colors: tsSeries.tsColors,
            stroke: {
                width: 0,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: 0
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
    }
    const remainders = [
        {
            id: "19103511-c2e3-4cb2-bacb-c27904b64529",
            employee_id: "12e91aa4-69e4-48c2-b0b7-bb8f8f234bec",
            display_name: "ABCD",
            employee_reference_id: "CON-2",
            employee_e_verified: 1,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "100.00",
            placement_id: null,
            placement_reference_id: null,
            reference_id: "CON",
            slug: "general-details",
            entity: "Employee",
            start_date: null,
            end_date: null,
            client_name: null,
            client_reference_id: null,
            status: "Requested",
            employee_type: "W2",
            created_at: "2024-10-16T07:13:56.542Z",
            new_requests: "1",
            request_type: "Profile Update Request"
        },
        {
            id: "62397aeb-ec7b-4ee7-a903-d394784eef07",
            employee_id: "b4ee9fe3-48e5-47e1-9b4c-f606a3087491",
            display_name: "EFGH",
            employee_reference_id: "CON",
            employee_e_verified: 1,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "78.57",
            placement_id: "fbbf221f-3312-44f6-8291-c4849f430735",
            placement_reference_id: "PLS-21",
            reference_id: "TS-1427",
            slug: "Weekly",
            entity: "Timesheet",
            start_date: "10/01/2024",
            end_date: "10/07/2024",
            client_name: "DFG",
            client_reference_id: "CLT-6",
            status: "Pending Approval",
            employee_type: null,
            created_at: "2024-10-08T05:05:56.457Z",
            new_requests: "59",
            request_type: "Timesheet Update"
        },
        {
            id: "90024625-37c9-4a9a-90b7-9087f6ce0e34",
            employee_id: "b4ee9fe3-48e5-47e1-9b4c-f606a3087491",
            display_name: "IJKL",
            employee_reference_id: "CON",
            employee_e_verified: 1,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "78.57",
            placement_id: "e2bd9ad5-c9f9-4e46-bc93-1b1803032574",
            placement_reference_id: "PLS-20",
            reference_id: "TS-1428",
            slug: "Weekly",
            entity: "Timesheet",
            start_date: "10/01/2024",
            end_date: "10/07/2024",
            client_name: "DSF",
            client_reference_id: "CLT-10",
            status: "Pending Approval",
            employee_type: null,
            created_at: "2024-10-08T04:57:22.477Z",
            new_requests: "59",
            request_type: "Timesheet Update"
        },
        {
            id: "02c4c4a5-7c84-4507-b673-058e9e5e8b30",
            employee_id: "f5308334-10a5-483b-b427-04dd3c3029b8",
            display_name: "MNO",
            employee_reference_id: "CON-124",
            employee_e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "55.56",
            placement_id: "caa31787-fbfb-4a99-9c7f-cf6b86d32ff4",
            placement_reference_id: "PLS-146",
            reference_id: "TS-1435",
            slug: "Weekly",
            entity: "Timesheet",
            start_date: "10/07/2024",
            end_date: "10/13/2024",
            client_name: "ABC",
            client_reference_id: "CLT-15",
            status: "Pending Approval",
            employee_type: null,
            created_at: "2024-10-08T04:56:40.085Z",
            new_requests: "59",
            request_type: "Timesheet Update"
        },
        {
            id: "3fe4565a-e65a-4a7b-b608-1e408b85d328",
            employee_id: "b4ee9fe3-48e5-47e1-9b4c-f606a3087491",
            display_name: "PQRST",
            employee_reference_id: "CON-19",
            employee_e_verified: 1,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "78.57",
            placement_id: "599f7e6b-f79a-4956-9bff-63f1c3901d2c",
            placement_reference_id: "PLS-19",
            reference_id: "TS-1429",
            slug: "Weekly",
            entity: "Timesheet",
            start_date: "10/01/2024",
            end_date: "10/07/2024",
            client_name: "RFT",
            client_reference_id: "CLT-11",
            status: "Pending Approval",
            employee_type: null,
            created_at: "2024-10-07T05:38:34.233Z",
            new_requests: "59",
            request_type: "Timesheet Update"
        },

    ]


    const years = [
        {
            id: 2024,
            value: 2024
        },
        {
            id: 2023,
            value: 2023
        },
        {
            id: 2022,
            value: 2022
        },
        {
            id: 2021,
            value: 2021
        },
        {
            id: 2020,
            value: 2020
        },
        {
            id: 2019,
            value: 2019
        },
        {
            id: 2018,
            value: 2018
        },
        {
            id: 2017,
            value: 2017
        },
        {
            id: 2016,
            value: 2016
        },
        {
            id: 2015,
            value: 2015
        },
        {
            id: 2014,
            value: 2014
        },
        {
            id: 2013,
            value: 2013
        },
        {
            id: 2012,
            value: 2012
        },
        {
            id: 2011,
            value: 2011
        },
        {
            id: 2010,
            value: 2010
        }
    ]

    const cashflowData = {
        series: [
            {
                name: "Addition",
                data: [
                    0,
                    0,
                    0,
                    7,
                    27,
                    59,
                    41,
                    3,
                    6,
                    13,
                    0,
                    0
                ]
            },
            {
                name: "Attrition",
                data: [
                    0,
                    0,
                    0,
                    1,
                    5,
                    15,
                    14,
                    1,
                    1,
                    5,
                    0,
                    0
                ]
            }
        ],
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        addition: [0,
            0,
            0,
            7,
            27,
            59,
            41,
            3,
            6,
            13,
            0,
            0],
        attrition: [0,
            0,
            0,
            1,
            5,
            15,
            14,
            1,
            1,
            5,
            0,
            0]

    }
    const areaOptions = {
        series: [
            {
                name: 'Addition',
                data: cashflowData.addition
            },
            {
                name: 'Attrition',
                data: cashflowData.attrition
            }
        ],
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#14C9C9', '#F7BA1E'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#14C9C9', '#F7BA1E'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            x: {
                formatter: function (val) {
                    return monthsOptions[val - 1].value + ' ' + yearDropdwn.year;
                }
            },
            colors: ['#14C9C9', '#F7BA1E'],
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: cashflowData.labels
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        colors: ['#14C9C9', '#F7BA1E'],
        // tooltip: {
        //     x: {
        //         format: 'dd/MM/yy HH:mm'
        //     },
        // },
    }


    const steps = [
        {
            element: '#Financialchart', popover: {
                title: 'Financial Performance', description: 'View the financial performance graph to track and analyze key metrics over time for better decision-making.', side: "right",
                align: 'center'
            }
        },
        {
            element: '#employeechart', popover: {
                title: 'Emp data', description: 'Check the employee division graph for a visual breakdown of workforce distribution across departments.', side: "right",
                align: 'start'
            }
        },
        {
            element: '#clientchart', popover: {
                title: 'Clients', description: 'Access client data to view and manage information about your clients, including contact details and interaction history.', side: "right",
                align: 'start'
            }
        },
        {
            element: '#additionattrition', popover: {
                title: 'Addition and Attrition', description: 'Monitor the addition and attrition rates to track employee growth and turnover over time.', side: "right",
                align: 'start'
            }
        },
        {
            element: '#timesheets', popover: {
                title: 'Timesheets', description: 'Filter timesheets data by status to view and manage entries such as submitted, approved, or pending.', side: "right",
                align: 'start'
            }
        }, {
            element: '#actionsbar', popover: {
                title: 'Pending Actions', description: 'Check the pending actions for the employees.', side: "left",
                align: 'start'
            }
        },

    ];


    if (((LocalStorage.getUserData().plan_not_set == false && LocalStorage.getUserData().org_config_set && LocalStorage.getUserData().super_admin) || (rolePermission !== "" && rolePermission.some(item => item.slug == "mivi_chat_bot_view" && item.is_allowed == true)))) {
        steps.push(...[{
            element: '#miviicon', popover: {
                title: 'Mivi icon', description: 'Tap the chat bot icon for instant assistance and answers to your questions!', side: "left",
                align: 'start'
            }
        },
        {
            element: '#mivisearchbar', popover: {
                title: 'Chat Screen Text Bar', description: 'Use the search bar to quickly find answers or explore topics within the chat bot!', side: "left",
                align: 'start'
            }
        },])
    }

    //   {
    //       element: '#scriptquestion', popover: {
    //         title: 'Mivi chat bot', description: 'Tap the chat bot icon for instant assistance and answers to your questions!', side: "left",
    //         align: 'start'
    //       }
    //     },


    steps.push(...[{
        element: '#globalsearch', popover: {
            title: 'Search Bar', description: 'Use the universal search bar to quickly find information across all modules and data in the system.', side: "bottom",
            align: 'center'
        }
    },])




    if (LocalStorage.getUserData().super_admin && LocalStorage.getUserData().plan_name != null && LocalStorage.getUserData().org_config_set) {
        steps.push(...[{
            element: '#planstatus', popover: {
                title: 'Upgrade Button', description: `Click 'Upgrade Plan' to unlock additional features and expand your service options.`, side: "left",
                align: 'start'
            }
        }])
    }
    steps.push(...[{
        element: '#avatar-click-box', popover: {
            title: 'User Profile', description: 'Keep your profile updated to enjoy a personalized experience!', side: "left",
            align: 'center'
        }
    }])


    useEffect(() => {
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 0,
            stageRadius: 0,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 15,

            onPopoverRender: (popover, opts) => {//creates the popover with required design 

                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    })
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        event.stopPropagation();
                        if (opts.config.steps[currentStep - 1].popover.title == 'Chat Screen Text Bar') {
                            const miviIcon = document.getElementById('suggestion');
                            miviIcon.click()
                            setTimeout(() => { driverObj.movePrevious() }, 300)
                        } else if (opts.config.steps[currentStep - 1].popover.title == 'Mivi icon') {
                            setOpenPopup(false)
                            driverObj.movePrevious();
                        }
                        else {
                            driverObj.movePrevious();
                        }
                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    setOpenPopup(false);
                    if (scrollRef.current) {
                        scrollRef.current.scrollTop = 0;
                    }
                    driverObj.destroy();
                    setStartTour();
                    updateTourGuideStatus("DASHBOARD");
                    LocalStorage.setStartTour({ 'dashboard': false });
                    if (LocalStorage.getUserData().org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    driverObj.destroy();
                    setStartTour();
                    updateTourGuideStatus("DASHBOARD");
                    LocalStorage.setStartTour({ 'dashboard': false });
                    if (LocalStorage.getUserData().org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    if (opts.config.steps[currentStep].popover.title == 'Mivi icon') {
                        const miviIcon = document.getElementById('suggestion');
                        miviIcon.click()
                        setTimeout(() => { driverObj.moveNext() }, 400)
                    } else if (opts.config.steps[currentStep].popover.title == 'Chat Screen Text Bar') {
                        setOpenPopup(false)
                        driverObj.moveNext();
                    }
                    else {
                        driverObj.moveNext();
                    }
                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });
        if (startTour) {
            setTimeout(() => {
                driverObj.drive()

            }, 500);
        }
        return () => {
            driverObj.destroy();
        };
        // eslint-disable-next-line
    }, [])
    return (
        <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} justifyContent={'center'} pt={2} alignItems='flex-start'>
            <Grid item container lg={8} md={8} sm={12} xs={12} pt={3} pr={3} className={classes.container} ref={scrollRef}>
                <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                    <Text boldblack22>Hi, ABC</Text>
                    <Text black400 sx={{ paddingTop: '6px' }}>Here's how your dashboard is looking today</Text>

                </Grid>



                {/* financial */}
                <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} pt={3} id='Financialchart'>
                    <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center'>
                        <Grid item lg={10} md={9} sm={6} xs={6}>
                            <Text smallLabelblack16>Financial Performance</Text>
                        </Grid>
                        <Grid item lg={2} md={3} sm={6} xs={6}>
                            <CustomSelect
                                name='year'
                                value={yearDropdwn.year}
                                viewDrop
                                scrollTrue={true}
                                options={years}


                            />
                        </Grid>
                    </Grid>
                    <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} spacing={2} columnSpacing={2}>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Box alignItems='center' className={classes.bluebg}>
                                <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                                    <Grid item lg={2} md={2} xs={2}>
                                        <img loading="eager" src={billed} alt='clients' width='48' height='48' />
                                    </Grid>
                                    <Grid item lg={9} md={9} xs={9} ml={2}>
                                        <Text mediumBoldBlack>Total Billed</Text>
                                        <Text boldblack22>{currency}&nbsp;
                                            123456
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Box alignItems='center' className={classes.yellowbg}>
                                <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                                    <Grid item lg={2} md={2} xs={2}>
                                        <img loading="eager" src={pay} alt='clients' width='48' height='48' />
                                    </Grid>
                                    <Grid item lg={9} md={9} xs={9} ml={2}>
                                        <Text mediumBoldBlack>Total Pay</Text>
                                        <Text boldblack22>{currency}&nbsp;
                                            1234
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Box alignItems='center' className={classes.purplebg}>
                                <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                                    <Grid item lg={2} md={2} xs={2}>
                                        <img loading="eager" src={margin} alt='clients' width='48' height='48' />
                                    </Grid>
                                    <Grid item lg={9} md={9} xs={9} ml={2}>
                                        <Text mediumBoldBlack>Margin</Text>
                                        <Text boldblack22>{currency}&nbsp;
                                            51234
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={3} >
                        <ReactApexChart
                            options={financialOptions}
                            series={financialOptions.series}
                            type="area"
                            height='300px'
                        />
                    </Grid>
                    <Grid item container lg={12} md={12} sm={10} xs={10} pt={2} justifyContent='end'>
                        <Box minWidth='70px' textAlign='end'>
                            <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} >View More</Text>
                        </Box>
                    </Grid>
                    <Divider sx={{ width: '100%', margin: '20px 0px 30px 0px', borderColor: '#F5F5F5' }} />
                </Grid>


                {/* emp client contribution */}


                <Grid container spacing={2} pt={2} alignItems='flex-start'>
                    <Grid item container lg={6} md={12} sm={12} xs={12} pb={1} justifyContent='center' id='employeechart'>
                        <Grid item lg={12} md={6} sm={12} xs={12} mb={2}>
                            <Text smallLabelblack16>Employees Data</Text>
                        </Grid>
                        {
                            (totalEmp.consultant == 0 && totalEmp.contractor == 0) ?
                                <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                                    {NoDataFound('')}
                                </Grid> :
                                <Grid item xl={10} lg={12} md={12} sm={12} xs={12} pt={2} container justifyContent={'center'} textAlign='center' sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                                    <Chart
                                        options={pieData.options}
                                        series={pieData.series}
                                        type="donut"
                                        height='210px'
                                        width='210px'
                                    />
                                    <Grid item container lg={12} justifyContent='center' textAlign='center' pt={2}>
                                        <Grid item container lg={6} sm={6} xs={12}>
                                            <Box display='flex' flexDirection='row' gap={1}>
                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                    <Box className={classes.greenDot} />
                                                    <Text BlackExtraDark>Consultants</Text>
                                                </Box>
                                                <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }}>{totalEmp.consultant}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item container lg={6} sm={6} xs={12} textAlign='start'>
                                            <Box display='flex' flexDirection='row' gap={1}>
                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                    <Box className={classes.pinkDot} />
                                                    <Text BlackExtraDark>Contractor</Text>
                                                </Box>
                                                <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }}>{totalEmp.contractor}</Text>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    <Grid item container lg={6} md={12} sm={12} xs={12} pb={1} justifyContent='center' id='clientchart'>
                        <Grid item lg={12} md={12} sm={12} xs={12} pl={2} mb={2}>
                            <Text smallLabelblack16>Clients</Text>
                        </Grid>
                        {
                            companies.length == 0 ?
                                <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                                    {NoDataFound('')}
                                </Grid> :
                                <Grid item container textAlign='center' alignItems='center' lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} pl={2} sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                                    {
                                        companies.length > 0 ? companies.map((item, index) => (
                                            <>
                                                <Grid item lg={1} md={1} sm={1} xs={1}><Text black400>{index + 1}</Text></Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3} textAlign='start'>
                                                    <Text largeBlack>
                                                        {
                                                            capitalizeAndAddSpace(item.name)
                                                        }
                                                    </Text>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={4} justifyContent='center'>
                                                    <BorderLinearProgress variant="determinate" value={item.percentage} barColor={linearProgress[index].bgColor} bgColor={linearProgress[index].barColor} />
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={4} textAlign='right' sx={{ display: 'block' }}>
                                                    <AvatarGroup total={item.placement_count} spacing={18} renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                                        sx={{
                                                            justifyContent: 'flex-end',
                                                            "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                                color: '#0C75EB !important',
                                                                backgroundColor: '#D1E1FF !important',
                                                                font: '12px Quicksand !important',
                                                                fontWeight: `${600} !important`,
                                                            },
                                                            "& .MuiAvatar-root": {
                                                                position: "static !important",
                                                                border: "none !important",
                                                                color: '#0C75EB !important',
                                                                backgroundColor: '#D1E1FF !important',
                                                                font: '12px Quicksand !important',
                                                                fontWeight: `${600} !important`,
                                                                // marginX: '-10px'
                                                            },
                                                            "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                                color: '#0C75EB !important',
                                                                backgroundColor: '#D1E1FF !important',
                                                                font: '12px Quicksand !important',
                                                                fontWeight: `${600} !important`,
                                                                // marginX: '-10px'
                                                            }
                                                        }}
                                                    >
                                                        {item && item.employees && item.employees.map((avt, avtIndex) =>
                                                            <Avatar alt={`Avatar ${avtIndex + 1}`} src={avt ? avt.profile_picture_url : ''} sx={{ width: "35px", height: "35px" }} />)}
                                                    </AvatarGroup>
                                                </Grid>
                                            </>
                                        )) : ''
                                    }
                                </Grid>
                        }
                        {
                            companies.length > 1 &&
                            <Grid item container lg={10} md={10} sm={10} xs={10} pt={3} justifyContent='end'>
                                <Box minWidth='55px' textAlign='end'>
                                    <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }}>View All</Text>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />


                {/* addition attribution */}
                <Grid pl={2} pb={2} id="additionattrition">
                    <Grid container >
                        <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center'>
                            <Grid item lg={10} md={9} sm={8} xs={6}>
                                <Text smallLabelblack16>Addition & Attrition Rate</Text>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={6}>
                                <CustomSelect
                                    name='placementYear'
                                    value={yearDropdwn.placementYear}
                                    viewDrop
                                    scrollTrue={true}
                                    options={years}

                                />
                            </Grid>
                        </Grid>

                        <Grid item lg={12} xs={12} spacing={2} pt={2} >
                            <ReactApexChart
                                options={areaOptions}
                                series={areaOptions.series}
                                type="area"
                                height='300px'
                            />
                        </Grid></Grid>
                    <Grid item container lg={12} xs={12}>
                        <Grid item container lg={6} md={12} sm={12} xs={12} className={classes.borderRight}>
                            <Grid item lg={12} xs={12}>
                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                    <Box className={classes.greenDot} />
                                    <Text overViewLable>Placement Added</Text>
                                </Box>
                            </Grid>
                            <Grid item lg={12} xs={12} py={1}>
                                <Text largeblack40>{placementDetails.total_placements_added}</Text>
                            </Grid>
                            <Grid item container lg={12} xs={12}>
                                {
                                    placementsAdded.length > 0 && placementsAdded.map((item) => (
                                        <Grid item container lg={12} xs={12} py='6px'>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Text smallBlack400>{item.name}</Text>
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                            <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                                <Text black12>{item.count}</Text>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid item container lg={6} className={classes.paddingLeft}>
                            <Grid item lg={12} xs={12}>
                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                    <Box className={classes.yellowDot} />
                                    <Text overViewLable>Placement Completed</Text>
                                </Box>
                            </Grid>
                            <Grid item lg={12} xs={12} py={1}>
                                <Text largeblack40>{placementDetails.total_placements_completed}</Text>
                            </Grid>
                            <Grid item container lg={12} xs={12}>
                                {
                                    placementsCompleted.map((item) => (
                                        <Grid item container lg={12} xs={12} py='6px'>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Text smallBlack400>{item.name}</Text>
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                            <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                                <Text black12>{item.count}</Text>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                        {/* <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent='end'>
                            <Box minWidth='55px' textAlign='end'>
                                <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} >View All</Text>
                            </Box>
                        </Grid> */}
                    </Grid></Grid>
                <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />





                {/* timesheet */}
                <Grid item container lg={12} xs={12} pl={2} pt={2} justifyContent='center' id='timesheets'>
                    <Grid item lg={5} className={classes.borderRight}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Text smallLabelblack16>Timesheets</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                            <ReactApexChart
                                options={timesheetOptions.options}
                                series={timesheetOptions.series}
                                type="pie"
                                height='250px'
                            />
                        </Grid>
                    </Grid>
                    <Grid item container lg={7} md={12} sm={12} xs={12} textAlign='center' justifyContent='center' alignItems='center' className={classes.pendingTSheight}>
                        <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                            <Grid item lg={9} md={8} sm={9} xs={7}>
                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                    <Box className={classes.blueDot} />
                                    <Text blackHeader1>Drafted</Text>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                <Text largeLightBlue className={classes.tsCount} >{tsData.drafted}</Text>
                            </Grid>
                        </Grid>
                        <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                            <Grid item lg={9} md={8} sm={9} xs={7}>
                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                    <Box className={classes.yellowDot} />
                                    <Text blackHeader1>Pending for approval</Text>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                <Text largeLightBlue className={classes.tsCount}  >{tsData.pending_approval}</Text>
                            </Grid>
                        </Grid>
                        <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                            <Grid item lg={9} md={8} sm={9} xs={7}>
                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                    <Box className={classes.greenDot} />
                                    <Text blackHeader1>Approved</Text>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                <Text largeLightBlue className={classes.tsCount}  >{tsData.approved}</Text>
                            </Grid>
                        </Grid>
                        <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                            <Grid item lg={9} md={8} sm={9} xs={7}>
                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                    <Box className={classes.pinkDot} />
                                    <Text blackHeader1>Rejected</Text>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                <Text largeLightBlue className={classes.tsCount}  >{tsData.rejected}</Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>

            {/* side bar */}

            <Grid item container lg={4} md={4} sm={12} xs={12} p={'0px 10px 0px 20px'} id='actionsbar'>
                <Grid item lg={12} sm={12} xs={12} pt={2}>
                    <Text mediumBoldBlack600>Actions</Text>
                </Grid>
                <Grid item container lg={12} sm={12} xs={12} pt={3}>
                    <Box className={classes.cardContainer}>


                        <Grid item lg={12} xs={12} className={classes.cardContainer} >

                            {remainders.map((item) => (
                                <Grid item container ml={.5} lg={11} xs={12}
                                    className={classes.sideCardbg}
                                >
                                    <Grid item container lg={12} xs={12} p={2}>
                                        <Grid item lg={2} md={3} sm={1} xs={4}>
                                            {/* <img src={item.profile_picture_url} alt="profile" style={{ height: '40px', width: '40px', borderRadius: '50%' }} /> */}
                                            <Avatar
                                                src={item.profile_picture_url ? `${item.profile_picture_url}?w=40&h=40&fit=crop` : ''}
                                                alt={capitalizeAndAddSpace(item.display_name[0])}
                                                sx={{ height: '40px', width: '40px', borderRadius: '50%' }}
                                            />
                                        </Grid>
                                        <Grid item lg={7} md={8} sm={7} xs={8}>
                                            <Text dashboardblack14>
                                                {
                                                    capitalizeAndAddSpace(item.display_name)
                                                }
                                                {
                                                    item.employee_e_verified == 1 || item.employee_e_verified == 4 ?
                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verified</Text>}>
                                                            <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} width='20' height='20' />
                                                        </BlackToolTip>
                                                        : item.employee_e_verified == 2 ?
                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is pending</Text>}>
                                                                <img src={Pending} alt='svg' style={{ margin: '0px 0px -3px 3px' }} width='20' height='20' />
                                                            </BlackToolTip> : ''
                                                }
                                            </Text>
                                            {
                                                (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                    <Text greysmallLabel>{item.reference_id}</Text> :
                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                        <Text greysmallLabel>{item.placement_reference_id}</Text>
                                                        <Box className={classes.greyDot} />
                                                        <Text greysmallLabel>{item.reference_id ? item.reference_id : '-'}</Text>
                                                    </Box>
                                            }
                                        </Grid>
                                        <Grid item lg={2.5} md={12} sm={4} xs={12}>
                                            <Box textAlign='center' className={item.status == 'Approved' ? classes.statusBox : item.status == 'Requested' || item.status == 'Pending Approval' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : classes.drafted}>
                                                <Text mediumWhite400>{item.status == 'Pending Approval' ? 'Pending' : item.status}</Text>
                                            </Box>
                                        </Grid>
                                        <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                        {
                                            (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                <>
                                                    <Grid item lg={7} md={6} sm={6} xs={6}>
                                                        <Text greysmallLabel>Employee Type</Text>
                                                        <Text dashboardblack14>{item.employee_type ? item.employee_type : '-'}</Text>
                                                    </Grid>
                                                    <Grid item container lg={5} md={6} sm={6} xs={6} className={classes.textCenter} alignItems='center' justifyContent='end'>
                                                        <Text mediumBlue600>Profile Update</Text>
                                                    </Grid>
                                                </> :
                                                <>
                                                    <Grid item lg={6.5} md={6} sm={6} xs={12}>
                                                        <Text greysmallLabel>Client Name</Text>
                                                        <Text dashboardblack12>
                                                            {
                                                                capitalizeAndAddSpace(item.client_name)
                                                            }
                                                        </Text>
                                                    </Grid>
                                                    <Grid item container lg={5.5} md={6} sm={6} xs={12} alignContent={'center'} justifyContent='end'>
                                                        <Text mediumBlue600>Timesheet</Text>
                                                    </Grid>
                                                </>
                                        }
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                    </Box>

                    <Grid item container lg={11} md={10} sm={10} xs={10} pt={2} justifyContent='center'>
                        <Box minWidth='55px' textAlign='end'>
                            <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} >View All</Text>
                        </Box>
                    </Grid>
                    {/* } */}
                </Grid>
            </Grid>

        </Grid>
    )
}