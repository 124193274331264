import React, { useEffect, useRef, useState } from 'react'
import MainStyles from '../MainStyles'
import { Box, Divider, Grid, Stack, Tooltip, Skeleton, Hidden } from '@mui/material'
import Text from '../../../../components/customText/Text'
import Select from '../../../../components/select/Select'
import { isValid, isValidMulti, validate_emptyField } from '../../../../components/Validation'
import LocalStorage from '../../../../utils/LocalStorage'
import { addErrorMsg, dateFormat, BlackToolTip, NoDataFound, removeLoader, addLoader, addSuccessMsg, NoPermission } from '../../../../utils/utils'
import Date from '../../../../components/datePicker/Date'
import infoCircle from "../../../../assets/images/info-circle.png";
import warning from '../../../../assets/employee/info.svg';
import moment from 'moment'
import { styled } from "@mui/system";
import Button from '../../../../components/customButton/Button'
import LoadingButton from '../../../../components/customButton/LoadingButton'
import blueLoader from '../../../../assets/gif/japfuLoader.gif';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ConfigApi from '../../../../apis/configurations/ConfigApi'
import PayrollConfigApi from '../../../../apis/configurations/payroll/PayrollConfigApi'
import { ErrorMessages } from '../../../../utils/ErrorMessages'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${theme?.classes?.tooltip}`]: {
        backgroundColor: "#696969",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #696969",
        borderRadius: "4px",
    },
    [`& .${theme?.classes?.arrow}`]: {
        color: "#696969",
        "&::before": {
            backgroundColor: "#696969",
            border: "1px solid #696969",
        },
    },
}));

function PayrollSetup() {


    const classes = MainStyles()
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const today = moment().startOf('day').format(dateFormat());

    // let payrollCycles = [
    //   {
    //     "id": 1,
    //     "value": "Monthly"
    //   },
    //   {
    //     "id": 2,
    //     "value": "Semi Monthly"
    //   },
    //   {
    //     "id": 3,
    //     "value": "Bi-Weekly"
    //   },
    //   {
    //     "id": 4,
    //     "value": "Weekly"
    //   }
    // ]

    const [payrollCycles, setPayrollCycles] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [datesError, setDatesError] = useState([]);
    const [activityTotal, setActivityTotal] = useState()
    const activityRef = useRef(null);
    const [activityData, setActivityData] = useState([])
    const recentSection = useRef(null);
    const [recentActivityShow, setRecentActivityShow] = useState(false);
    const [update, setUpdate] = useState(false);
    const [lastCycle, setLastCycle] = useState({ cycle_id: "", edit_from_date: "", cycles: [] });
    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })

    const [state, setState] = useState({
        cycle_id: "",
        edit_from_date: "",
        cycles: [
            // {
            // start_date: "",
            // end_date: "",
            // check_date: "",
            // }
        ]
    });

    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
                setRecentActivityShow(true);
                getPayrollCyclesData();
                handlePayrollCyclesTypes();
                getActivity(activityFilter);
            }
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (!isEditable) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isEditable]);


    /************  API Integrations Starts Here    ***********/


    const getPayrollCyclesData = () => {
        setLoading(true)
        PayrollConfigApi.getPayrollConfig().then((response) => {
            setLoading(false)
            if (response.data.statusCode == 1003) {
                if (response.data?.data && typeof response.data.data === 'object' && Object.keys(response.data.data).length > 0) {
                    setState(response.data?.data);
                    setLastCycle(response.data?.data)
                    setIsEditable(true);
                    setUpdate(true);
                } else {
                    setState({ cycle_id: "", cycles: [], edit_from_date: "" });
                    setIsEditable(false);
                    setUpdate(false);
                }
            }
            else {
                addErrorMsg(response.data.message);
            }
        });
        removeLoader();
    }

    const getActivity = (args, pop) => {
        let id = 37;
        ConfigApi.getActivity(id, "", args).then((response) => {
            if (response.data.statusCode == 1003) {
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
                if (pop) {
                    setRecentActivityShow(true)
                }
            }
            removeLoader();
        });
    };

    const storePayrollCyclesData = (payload) => {
        setButtonLoader(true);
        payload['request_id'] = LocalStorage.uid()
        setLoading(true)
        PayrollConfigApi.postPayrollConfig(payload).then((response) => {
            setLoading(false);
            setButtonLoader(false);
            if (response.data.statusCode == 1003) {
                getPayrollCyclesData();
                setActivityFilter({ ...activityFilter, limit: 10, page: 1 })
                getActivity({ ...activityFilter, limit: 10, page: 1, }, true);
                addSuccessMsg(response.data.message);
            }
            else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const updatePayrollCyclesChange = (payload) => {
        setButtonLoader(true);
        payload['request_id'] = LocalStorage.uid()
        setLoading(true)
        PayrollConfigApi.postPayrollCycleChangeConfig(payload).then((response) => {
            setLoading(false);
            setButtonLoader(false);
            if (response.data.statusCode == 1003) {
                getPayrollCyclesData();
                setActivityFilter({ ...activityFilter, limit: 10, page: 1 })
                getActivity({ ...activityFilter, limit: 10, page: 1, }, true);
                addSuccessMsg(response.data.message);
            }
            else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const updatePayrollCyclesData = (payload) => {
        payload['request_id'] = LocalStorage.uid()
        setLoading(true)
        PayrollConfigApi.updatePayrollConfig(payload).then((response) => {
            setLoading(false)
            if (response.data.statusCode == 1003) {
                getPayrollCyclesData();
                setActivityFilter({ ...activityFilter, limit: 10, page: 1 })
                getActivity({ ...activityFilter, limit: 10, page: 1, }, true);
                addSuccessMsg(response.data.message);
            }
            else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const handlePayrollCyclesTypes = () => {
        PayrollConfigApi.getPayrollCyclesTypes().then((response) => {
            if (response.data.statusCode == 1003) {
                setPayrollCycles(response.data.data)
            }
            else {
                addErrorMsg(response.data.message);
            }
        });
    }


    /************  API Integrations Ends Here    ***********/


    /********    Onchange functions starts here     ***********/

    const handleChange = (e) => {
        const newValue = e.target.value;
        setState(prevState => {
            // Define the cycles structure based on the selected option
            let newDatesData = [];
            if (newValue === "3" || newValue === 3 || newValue === "2" || newValue === 2) {
                newDatesData = [
                    { start_date: "", end_date: "", check_date: "" },
                    { start_date: "", end_date: "", check_date: "" }
                ];
            } else if (newValue === "1" || newValue === 1) {
                newDatesData = [
                    { start_date: "", end_date: "", check_date: "" }
                ];
            } else if (newValue === "4" || newValue === 4) {
                newDatesData = [
                    { start_date: "", end_date: "", check_date: "" },
                    { start_date: "", end_date: "", check_date: "" },
                    { start_date: "", end_date: "", check_date: "" },
                    { start_date: "", end_date: "", check_date: "" }
                ];
            }

            // Return the updated state object
            return {
                ...prevState,
                [e.target.name]: newValue,
                cycles: newDatesData
            };
        }, handleValidate(e));
        setDatesError([])

    };

    const dateChange = (e, name, index) => {
        const date = e == null ? '' : moment(e.$d).format(dateFormat());

        if (name === 'start_date') {
            if (state.cycle_id === "1" || state.cycle_id === 1) {
                state.cycles[index]['start_date'] = date;
                state.cycles[index]['end_date'] = getOneMonthEndDate(date);
                state.cycles[index]['check_date'] = "";

                setState({ ...state })
                //  handleValidateDates({ target: { name, value: date } }, index)
            } else if (state.cycle_id === '2' || state.cycle_id === 2) {
                // state.cycles[index]['start_date'] = date;
                // state.cycles[index]['end_date'] = moment(date, dateFormat()).add(14, 'days').format(dateFormat())
                // state.cycles[index]['check_date'] = "";
                // state.cycles[index + 1]['start_date'] = moment(date, dateFormat()).add(15, 'days').format(dateFormat())
                // state.cycles[index + 1]['end_date'] = getOneMonthEndDate(date);
                // state.cycles[index + 1]['check_date'] = "";
                // setState({ ...state })
                state.cycles[index]['start_date'] = date;
 
                const parsedDate = moment(date, dateFormat());
                const dayOfMonth = parsedDate.date();
 
                // Determine end_date for the current cycle
                if (dayOfMonth <= 15) {
                    state.cycles[index]['end_date'] = parsedDate.startOf('month').add(14, 'days').format(dateFormat());
                } else {
                    state.cycles[index]['end_date'] = parsedDate.endOf('month').format(dateFormat());
                }
 
                state.cycles[index]['check_date'] = "";
                setState({ ...state })
                // Calculate the next cycle's start_date as one day after the current end_date
                const nextStartDate = moment(state.cycles[index]['end_date'], dateFormat()).add(1, 'day');
                const nextStartDay = nextStartDate.date();
 
                // Determine the next cycle's start_date and end_date based on the same logic
                if (nextStartDay <= 15) {
                    state.cycles[index + 1]['start_date'] = nextStartDate.format(dateFormat());
                    state.cycles[index + 1]['end_date'] = nextStartDate.startOf('month').add(14, 'days').format(dateFormat());
                } else {
                    state.cycles[index + 1]['start_date'] = nextStartDate.format(dateFormat());
                    state.cycles[index + 1]['end_date'] = nextStartDate.endOf('month').format(dateFormat());
                }
 
                state.cycles[index + 1]['check_date'] = "";
 
                setState({ ...state })
 
            } else if (state.cycle_id === '3' || state.cycle_id === 3) {
                state.cycles[index]['start_date'] = date;
                state.cycles[index]['end_date'] = moment(date, dateFormat()).add(13, 'days').format(dateFormat())
                state.cycles[index]['check_date'] = "";
                let secDate = moment(date, dateFormat()).add(14, 'days').format(dateFormat())
                state.cycles[index + 1]['start_date'] = secDate
                state.cycles[index + 1]['end_date'] = moment(secDate, dateFormat()).add(13, 'days').format(dateFormat());
                state.cycles[index + 1]['check_date'] = "";
                setState({ ...state })
            }
            else if (state.cycle_id === '4' || state.cycle_id === 4) {
                state.cycles[index]['start_date'] = date;
                state.cycles[index]['end_date'] = moment(date, dateFormat()).add(6, 'days').format(dateFormat())
                state.cycles[index]['check_date'] = "";
                let secDate = moment(date, dateFormat()).add(7, 'days').format(dateFormat())
                state.cycles[index + 1]['start_date'] = secDate
                let secEndDate = moment(secDate, dateFormat()).add(6, 'days').format(dateFormat())
                state.cycles[index + 1]['end_date'] = secEndDate;
                state.cycles[index + 1]['check_date'] = "";

                let thirdDate = moment(secEndDate, dateFormat()).add(1, 'days').format(dateFormat())
                state.cycles[index + 2]['start_date'] = thirdDate
                let thiEndDate = moment(thirdDate, dateFormat()).add(6, 'days').format(dateFormat())
                state.cycles[index + 2]['end_date'] = thiEndDate
                state.cycles[index + 2]['check_date'] = "";
                let fourthDate = moment(thiEndDate, dateFormat()).add(1, 'days').format(dateFormat())
                state.cycles[index + 3]['start_date'] = fourthDate
                let fourthEndDate = moment(fourthDate, dateFormat()).add(6, 'days').format(dateFormat())
                state.cycles[index + 3]['end_date'] = fourthEndDate
                state.cycles[index + 3]['check_date'] = "";
                setState({ ...state })
            }
        } else {
            if ((state.cycle_id === "4" || state.cycle_id === 4) && index === 0) {
                state.cycles[index]['check_date'] = date;
                var difference = diffTwoDates(state.cycles[index].end_date, date)
                state.cycles[index + 1]['check_date'] = adjustToMonday(moment(state.cycles[index + 1]['end_date'], dateFormat()).add(difference, 'days').format(dateFormat()));
                state.cycles[index + 2]['check_date'] = adjustToMonday(moment(state.cycles[index + 2]['end_date'], dateFormat()).add(difference, 'days').format(dateFormat()));
                state.cycles[index + 3]['check_date'] = adjustToMonday(moment(state.cycles[index + 3]['end_date'], dateFormat()).add(difference, 'days').format(dateFormat()));
                setState({ ...state });
            } else if ((state.cycle_id === "3" || state.cycle_id === 3 || state.cycle_id === "2" || state.cycle_id === 2) && index === 0) {
                state.cycles[index]['check_date'] = date;
                var differenceSemi = diffTwoDates(state.cycles[index].end_date, date)
                state.cycles[index + 1]['check_date'] = adjustToMonday(moment(state.cycles[index + 1]['end_date'], dateFormat()).add(differenceSemi, 'days').format(dateFormat()));
                setState({ ...state });
            }
            else {
                state.cycles[index][name] = date;
                setState({ ...state })
            }
        }
        handleValidateDates({ target: { name, value: date } }, index);
        if (index === 0 && name === 'check_date' && state.cycle_id !== "1" && state.cycle_id !== 1) {
            setDatesError([]);
        }

    }


    /*********     Ends here      ********/


    /**
     * Gets the last day of the month for a given date.
 
    * @param {Date|string} date - The input date. Can be a JavaScript Date object or a string in a format supported by moment.js's `dateFormat()` function.
    * @returns {string} The last day of the month for the input date, formatted according to the locale-specific date format.
    *
    * @throws {Error} If the input date is invalid or cannot be parsed by moment.js.
    */
    const getOneMonthEndDate = (date) => {

        const inputDate = moment(date, dateFormat());
        // Ensure inputDate is valid
        if (!inputDate.isValid()) {
            throw new Error('Invalid date format. Check your date or dateFormat.');
        }
        // Add one month and subtract one day
        const newDate = inputDate.clone().add(1, 'month').subtract(1, 'day');
        // Return the new date formatted using dateFormat()
        return newDate.format(dateFormat());
    };

    /**
     * Adjusts a given date to the next Monday, considering locale settings.
     *
     * @param {Date|string} date - The date to be adjusted. Can be a JavaScript Date object or a string in a format supported by moment.js's `dateFormat()` method.
     * @returns {string} The adjusted date formatted according to the locale-specific date format.
     */
    const adjustToMonday = (date) => {
        let dateCnv = moment(date, dateFormat()).format("YYYY-MM-DD");
        let adjustedDate = moment(dateCnv);
        // Check if the selected date is Saturday (6) or Sunday (0)
        if (adjustedDate.day() === 6) {
            adjustedDate.add(2, 'days');
        } else if (adjustedDate.day() === 0) {
            adjustedDate.add(1, 'days');
        }

        return moment(adjustedDate, "YYYY-MM-DD").format(dateFormat());
    };

    /**
     * Calculates the difference between two dates in days.
 
    * @param {Date|string} param1 - The first date. Can be a JavaScript Date object or a string in a format supported by moment.js's `dateFormat()` function.
    * @param {Date|string} param2 - The second date. Can be a JavaScript Date object or a string in a format supported by moment.js's `dateFormat()` function.
    * @returns {number} The difference between the two dates in days, or `null` if either date is invalid.
    */
    const diffTwoDates = (param1, param2) => {
        // Parse the dates using moment
        const date1 = moment(param1, dateFormat());
        const date2 = moment(param2, dateFormat());

        // Ensure they are valid Moment objects
        if (!date1.isValid() || !date2.isValid()) {
            //             console.error("Invalid dates provided:", param1, param2);
            return null; // or handle the error as needed
        }

        // Calculate the difference in days
        const daysDifference = date2.diff(date1, 'days');

        return daysDifference;
    };

    const handleSubmit = async () => {
        let errors = await validateAll();
        let datesErrors = await datesValidations();
        if (isValid(errors) && isValidMulti(datesErrors)) {
            if (update) {
                if (lastCycle.cycle_id === state.cycle_id) {
                    // Update `id` from additionalData into originalObject
                    const updatedObject = {
                        ...state,
                        cycles: state.cycles.map((cycle, index) => {
                            const additionalCycle = lastCycle.cycles[index];
                            return additionalCycle
                                ? { ...cycle, id: additionalCycle.id }
                                : { ...cycle }; // Keep the original cycle if no matching additionalCycle
                        })
                    };
                    updatePayrollCyclesData(updatedObject);
                } else {
                    updatePayrollCyclesChange(state);
                }
            } else {
                storePayrollCyclesData(state);
            }
        } else {
            let s1 = { error };
            s1 = errors;
            let s2 = { error };
            s2 = datesErrors;
            setError(s1);
            setDatesError(s2);
            addErrorMsg(ErrorMessages.genericMessage)
        }
    };

    const handleCancel = () => {
        setState({
            cycle_id: "",
            cycles: [],
            edit_from_date: ""
        })
        setDatesError([]);
        setError({});
        getPayrollCyclesData();
        setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
        getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 });
    }

    const handleValidate = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "cycle_id":
                err.cycle_id = validate_emptyField(input.value);
                break;
            case "start_date":
                err.start_date = validate_emptyField(input.value);
                break;
            case "check_date":
                err.check_date = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...err });
    }

    const validateAll = async () => {
        let { cycle_id } = state;
        let errors = {};
        errors.cycle_id = validate_emptyField(cycle_id);
        setError(errors)
        return errors;
    };

    const handleValidateDates = (e, index) => {
        let input = e.target;
        let error = datesError.length > 0 ? datesError ? datesError[index] : datesError : datesError;
        for (var k = 0; k <= index; k++) {
            datesError.push({});
        }
        let s1 = datesError.length > 0 ? [...datesError] : [{ ...datesError }];
        switch (input.name || input.tagName) {
            case "start_date":
                error.start_date = validate_emptyField(input.value);
                break;
            // case "end_date":
            //     error.end_date = validate_emptyField(input.value);
            //     break;
            case "check_date":
                error.check_date = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setDatesError(s1);
    };

    const datesValidations = async () => {
        let errors = [];
        const validationPromises = state.cycles.map(async (value) => {
            let error = {};
            error.start_date = validate_emptyField(value.start_date);
            // error.end_date = validate_emptyField(value.end_date);
            error.check_date = value.start_date !== "" && validate_emptyField(value.check_date);
            return error;
        });
        // Wait for all the validation promises to resolve
        errors = await Promise.all(validationPromises);
        // Set the state with the accumulated errors
        setDatesError(errors);
        return errors;
    };

    const handleEditClick = () => {
        setIsEditable(!isEditable)
    }

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {

                if (Number(activityTotal) >= activityFilter.limit) {
                    addLoader(true);
                    setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
                    getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 });
                }
            }
        }
    };

    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        });
        setRecentActivityShow(false)
    }
    const HandleScroll = (e) => {
        const scrollTop = e.target.scrollTop;
        if (scrollTop >= 120) {
            setRecentActivityShow(false);
        }
    };
    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                    <Box onScroll={HandleScroll} sx={{
                        height: '70vh',
                        overflow: 'auto',
                        padding: '16px',
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px",
                        },
                    }}>
                        {loading ?
                            <Box sx={{
                                height: '70vh',
                                overflow: 'auto',
                                padding: '16px',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}>
                                <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img src={blueLoader} height={100} width={100} alt='loading' />
                                </Stack>
                            </Box>
                            :
                            <Box className={classes.activeItemBox} >

                                <Box className={classes.activeBoxHeading}>
                                    <Box display='flex' justifyContent='space-between'>
                                        <Text profileTitle >Payroll Setup</Text>
                                        {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true))) ?
                                            <>
                                                {isEditable &&
                                                    <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={handleEditClick} />
                                                }
                                            </>
                                            : ""}
                                    </Box>
                                </Box>

                                <Grid container spacing={2} columnSpacing={4} >
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Select
                                            required
                                            name='cycle_id'
                                            value={state.cycle_id}
                                            onChange={handleChange}
                                            options={payrollCycles}
                                            disabled={isEditable}
                                            label={'Payroll Cycle'}
                                            helperText={error.cycle_id && <Text red>{error.cycle_id ? error.cycle_id : ''}</Text>}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={0} xs={0}></Grid>
                                </Grid>
                                {state.cycles.map((item, key) => (
                                    <React.Fragment key={key}>
                                        {state.cycles.length > 1 &&
                                            <Box pt={2}>
                                                <Text blackFont14>Cycle {key + 1}</Text>
                                                <Divider sx={{ pt: 1 }} />
                                            </Box>
                                        }
                                        <Grid container spacing={2} columnSpacing={4} pt={'20px'}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Date
                                                    labelText={'Start Date'}
                                                    name='start_date'
                                                    value={item.start_date}
                                                    minDate={state.edit_from_date !== "" && state.edit_from_date}
                                                    disabled={isEditable || key > 0}
                                                    onChange={value => dateChange(value, 'start_date', key)}
                                                    height='56px'
                                                    error={key === 0 && datesError.length > 0 && datesError[key]?.start_date !== undefined && datesError[key]?.start_date}
                                                />
                                                {key === 0 &&
                                                    datesError.length > 0 &&
                                                    datesError[key] && (
                                                        <Text red>{datesError[key]?.start_date !== undefined ? datesError[key].start_date : ''}</Text>
                                                    )}
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Date
                                                    labelText={'End Date'}
                                                    name='end_date'
                                                    value={item.end_date}
                                                    disabled={true}
                                                    onChange={value => dateChange(value, 'end_date', key)}
                                                    height='56px'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} columnSpacing={4} pt={'20px'}>
                                            <Grid item lg={6} md={6} sm={10} xs={10}>
                                                <Date
                                                    labelText={'Check Date'}
                                                    name='check_date'
                                                    disabled={isEditable || item.end_date === ""}
                                                    value={item.check_date}
                                                    // minDate={item.end_date && item.end_date < today ? today : item.end_date}
                                                    minDate={
                                                        moment(item.end_date, dateFormat()).isAfter(moment(today, dateFormat()))
                                                            ? moment(item.end_date, dateFormat()).toDate()
                                                            : moment(today, dateFormat()).toDate()
                                                    }

                                                    onChange={value => dateChange(value, 'check_date', key)}
                                                    height='56px'
                                                    error={datesError.length > 0 && datesError[key]?.check_date !== undefined && datesError[key]?.check_date}
                                                    weekDisabled={true}
                                                />
                                                {/**key === 0 &&**/
                                                    datesError.length > 0 &&
                                                    datesError[key] && (
                                                        <Text red>{datesError[key]?.check_date !== undefined ? datesError[key].check_date : ''}</Text>
                                                    )}

                                            </Grid>

                                            <Box className={classes.iconInfo}>
                                                <HtmlTooltip
                                                    placement="right"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Text mediumWhite400>
                                                                    This will be the recurring pay date <br />every month
                                                                </Text>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img
                                                        src={infoCircle}
                                                        alt="infoCircle"
                                                        height={"17px"}
                                                        className={classes.infoIconProperties}
                                                    />
                                                </HtmlTooltip>
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                ))}

                                <Grid item pt={2}>
                                    <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'start', alignItems: 'center', paddingLeft: '20px' }}>
                                        <Box pt={'4px'}>
                                            <img src={warning} alt='warning' />
                                        </Box>
                                        <Box>
                                            <Hidden lgDown>
                                                <Text mediumOrangeRehire
                                                    sx={{
                                                        "@media (min-width: 100px) and (max-width: 700px)": {
                                                            fontSize: '10px  !important',
                                                        },
                                                    }}> Note : If check date falls on a non working day, it will be moved to <br />the next working day</Text>
                                            </Hidden>
                                            <Hidden lgUp>
                                                <Text mediumOrangeRehire
                                                    sx={{
                                                        "@media (min-width: 100px) and (max-width: 700px)": {
                                                            fontSize: '10px  !important',
                                                        },
                                                    }}> Note : If check date falls on a non working day, it will be moved to the next working day</Text>
                                            </Hidden>
                                        </Box>
                                    </Box>
                                </Grid>

                                {!isEditable ?
                                    <Grid container columnGap={2} pt={3} justifyContent={"end"}>
                                        <Button cancelBtnBorder sx={{ height: "42px !important", width: "80px !important" }} onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                        {update ?
                                            <LoadingButton loading={buttonLoader} blueButton className={classes.saveButtonInPlacements} onClick={handleSubmit}>
                                                Save
                                            </LoadingButton>
                                            :
                                            <>
                                                {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true))) ?
                                                        <LoadingButton loading={buttonLoader} blueButton className={classes.saveButtonInPlacements} onClick={handleSubmit}>
                                                            Save
                                                        </LoadingButton>
                                                        :
                                                        <Button addTitleBtnDisable sx={{ height: "42px !important", width: "90px !important" }} >Save</Button>
                                                }
                                            </>
                                        }
                                    </Grid>
                                    : ""}
                            </Box>
                        }

                        <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                            <Box mb={3}>
                                <Text cardTitle>Recent Activities</Text>
                            </Box>
                            <Box sx={{ height: '40vh', overflowY: 'auto' }}
                                ref={activityRef}
                                onScroll={activityHandleScroll}>
                                {loading ?
                                    <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid container key={index} mt={3}>
                                                <Grid item lg={8} md={8} sm={8} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </> :
                                    <>
                                        {activityData.length > 0 ?
                                            <>
                                                {activityData.map((value) => (
                                                    <Box className={classes.descriptionBoxStyle} mb={2}>
                                                        <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                                                            <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                                                <Text mediumBlackColor>{
                                                                    value.user_name && value.user_name.length > 16 ?
                                                                        <BlackToolTip title={value.user_name} placement="top" arrow>
                                                                            {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : value.user_name
                                                                } {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                                                            </Grid>
                                                            <Grid container item lg={8} md={8} sm={6} xs={12} alignItems={"center"}>
                                                                {
                                                                    value.change_log.length > 0 && value.change_log.map((item) => (
                                                                        <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </> :
                                            <>
                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                        </Box>

                        {recentActivityShow ? <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                            <Button addButton
                                // scrollBtn
                                sx={{
                                    position: 'fixed',
                                    bottom: 20,
                                    borderRadius: "30px !important",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    font: "14px Quicksand !important",
                                    fontWeight: `${700} !important`,
                                }}
                                onClick={scrollDown}
                                endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                        </Grid> : ""}
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </div>
    )
}

export default PayrollSetup
