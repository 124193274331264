import React, { useEffect, useState } from 'react'
import { Box, Grid, Divider } from '@mui/material';
import Text from '../../components/customText/Text';
import timelineIcon from '../../assets/svg/timelineIcon.svg';
import Nodata from '../../assets/svg/nodataActivity.svg';
import Loader from '../../assets/gif/japfuLoader.gif';
import UserProfileApi from '../../apis/admin/userprofile/UserProfileApi';



const ActivityLog = ({activityLogDrawer, empId, current_balancePopup }) => {
    const [activityData,setActivityData] = useState([])
    

    useEffect(()=>{
        if (activityLogDrawer) {
            console.log(current_balancePopup,'current_balancePopup')
            if(current_balancePopup){
                UserProfileApi.currenBalanceActivity(empId).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setActivityData([...res.data.data])
                    }
                });
            }
            else{
                UserProfileApi.openingBalanceActivity(empId).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setActivityData([...res.data.data])
                    }
                });
            }
            
        }
    },[activityLogDrawer,empId, current_balancePopup])
return(
    <Grid container lg={12} md={12} xs={12} width={'37vw'} p={4} >
            <Grid item lg={12} md={12} xs={12} >
                <Text largeBlack> Activity log</Text>
            </Grid>
            {console.log(activityData,'activityData')}
            
            <Grid item container lg={12} md={12} xs={12}  >
                {
                    false ?
                        <Box width={"100%"} height={'76vh'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                        </Box>
                        :
                        <Box height={'76vh'} overflow={'scroll'} sx={{ width: '100% !important' }}>
                            {
                                activityData.length == 0 ?
                                    <Grid item lg={12} md={12} xs={12} container justifyContent={'center'} alignContent={'center'} textAlign={'center'}>
                                        <Grid item lg={12} md={12} xs={12} pl={5} container justifyContent={'center'} alignContent={'center'}>
                                            <img src={Nodata} alt='nodata' />
                                        </Grid>
                                        <Grid item lg={12} md={12} xs={12} pl={5}>
                                            <Text popupHead1>No Logs Found Yet!</Text>
                                        </Grid>
                                    </Grid>
                                    :
                                    activityData.map((item, index) => (
                                        <Grid container lg={12} md={12} xs={12}>
                                            <Grid item lg={2.75} md={2.75} xs={2.75}>
                                                <Grid item>
                                                    <Box p={1} pt={1.9}>
                                                        <Text smallBlue sx={{ fontWeight: '600 !important' }}>{item.created_at}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box pl={1}>
                                                        <Text verySmallBlack sx={{ color: "#737373 !important" }}>{item.action_by}</Text>
                                                    </Box>
                                                </Grid>
                                            </Grid >
                                            <Grid item lg={1} md={1} xs={1}>
                                                <Box p={1}>
                                                    <img src={timelineIcon} alt='ic' />
                                                </Box>
                                            </Grid>
                                            <Grid item lg={7} md={7} xs={7}>
                                                <Grid item>
                                                    <Box p={1}>
                                                        <Text mediumBlack>{item.activity_name}</Text>
                                                        <Text mediumLabel  sx={{
                        wordBreak: 'break-all',
                        whiteSpace: 'normal'
                      }}>{item.change_log}</Text>
                                                        <Text mediumLabel  sx={{
                        wordBreak: 'break-all',
                        whiteSpace: 'normal'
                      }}><strong>Reason for modification: </strong>{item.reason_for_change}</Text>
                                                    </Box>
                                                </Grid>
                                                {/* {
                                expand !== index &&
                                <Grid item>
                                    <Box pl={1}>
                                        <Text greysmallLabel>Label Name is updated from from value to To value</Text>
                                    </Box>
                                </Grid>
                            } */}

                                            </Grid>
                                            {/* <Grid item lg={1} md={1} xs={1}>
                                                <Box p={1}>
                                                    {expand === index ?
                                                        <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} /> :
                                                        <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} />
                                                    }
                                                </Box>
                                            </Grid> */}
                                            
                                            {
                                                // expand === index ?
                                                //     <Grid item container pb={2}>
                                                //         <Grid item lg={3.43} md={3.43} xs={3.43}></Grid>
                                                //         <Grid item class="tree" >
                                                //             <ul>
                                                //                 {
                                                //                     item.change_log.map((i) => (
                                                //                         <li>
                                                //                             <Text greysmallLabel>
                                                //                                 {i.length > 70 ?

                                                //                                     <BlackToolTip arrow placement='top' title={
                                                //                                         <Box p={1}>{i}</Box>
                                                //                                     }>
                                                //                                         {i.slice(0, 70)} ...
                                                //                                     </BlackToolTip>

                                                //                                     : i}
                                                //                             </Text>
                                                //                         </li>
                                                //                     ))
                                                //                 }
                                                //             </ul>
                                                                    
                                                //         </Grid>
                                                        

                                                //     </Grid>
                                                //     :
                                                    
                                                    ((activityData.length - 1) == index) ? '' :
                                                        <Grid item container pb={2}>
                                                            <Grid item lg={3.15} md={3.15} xs={3.15}></Grid>
                                                            <Grid item mt={-2.5} md={-0.5} xs={-0.5}>
                                                                <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                                            </Grid>
                                                        </Grid>
                                            }
                                            
                                        </Grid>
                                    ))}
                        </Box>
                }

            </Grid>

        </Grid >
)
}

export default ActivityLog