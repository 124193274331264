import React, { useEffect, useState, useRef } from 'react'
import { Box, Grid, Slide, Skeleton, Hidden, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import CustomMenu from '../../configComponents/customMenu/Menu'
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg'
import Button from '../../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Input from '../../../../../components/input/Input';
import LocalStorage from '../../../../../utils/LocalStorage';
import { BlackToolTip, ListLoadingIcon, NoDataFound, NoPermission, addErrorMsg, addSuccessMsg, addWarningMsg, } from '../../../../../utils/utils';
import { isValid, onCharactersUppercase, onNumberOnlyChange, validate_charWithSpace, validate_emptyField, } from '../../../../../components/Validation';
import PrefixApi from '../../../../../apis/configurations/prefixes/PrefixApi';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import EditIcon from '../../../../../assets/svg/newEdit.svg';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        // height: '586px',
        width: '692px',
        padding: '0px !important',
        borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
        padding: '0px !important',
    },
    "& .MuiDialogActions-root": {
        padding: '0px !important'
    },
    "& .MuiDialog-container": {
        background: 'rgba(0, 0, 0, 0.55) !important'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={200} />;
});

function Prefixes({ current }) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [activityData, setActivityData] = useState([])
    const [getData, setGetData] = useState([]);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [btnloading, setBtnLoading] = useState(false);
    const activityRef = useRef(null);
    const [activityTotal, setActivityTotal] = useState()
    const recentSection = useRef(null);
    const [isEditable, setIsEditable] = useState(false);
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        id: "",
        module: "",
        prefix_name: "",
        separator: "",
        number: "",
        slug: ""
    })
    const [recentActivityShow, setrecentActivityShow] = useState(false)

    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })
    const [listLoading, setListLoading] = useState(false);

    const [chromeAlert, setChromeAlert] = useState(false);
    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))) {
                getApi();
                getActivity(activityFilter)
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (chromeAlert) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [chromeAlert]);

    const getActivity = (args, popup) => {
        if (activityFilter.limit <= 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        ConfigApi.getActivity(24, "", args).then((response) => {
            // removeLoader();
            setListLoading(false);
            if (response.data.statusCode == 1003) {
                setLoading(false)
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
                if (popup) { }
                else {
                    setrecentActivityShow(true)
                }
            }
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (e) => {
        if (e.target.name === "prefix_name") {
            setState({ ...state, [e.target.name]: e.target.value !== "" ? e.target.value.toUpperCase() : e.target.value, }, handleValidate(e.target));
        } else {
            setState({ ...state, [e.target.name]: e.target.value, }, handleValidate(e.target));
        }

    };

    const handleValidate = (input) => {

        let updatedErrors = { ...error };
        switch (input.name || input.tagName) {
            // case 'module':
            //     updatedErrors.module = validate_charWithSpace(input.value)
            //     break
            case 'prefix_name':
                updatedErrors.prefix_name = validate_charWithSpace(input.value)
                break
            // case 'separator':
            //     updatedErrors.separator = validate_emptyField(input.value)
            //     break
            case 'number':
                updatedErrors.number = validate_emptyField(input.value)
                break
            default:
                break
        }
        setError(updatedErrors)
    }

    const handleDialogClose = () => {
        setOpen(false);
        setLoading(false)
        setError({});
        setChromeAlert(false);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // const handleViewClick = (data) => {
    //     if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))) {
    //         setOpen(true);
    //         setAnchorEl(null);
    //         setIsEditable(true);
    //         setState(data);
    //     } else {
    //         addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    //     }
    // }

    const updateApi = (args) => {
        // let data = {
        //     request_id: LocalStorage.uid(),
        //     prefixes: [{
        //         id: state.id,
        //         prefix_name: state.prefix_name,
        //         separator: state.separator,
        //         number: state.number,
        //     }],

        // }
        state.request_id = LocalStorage.uid();
        setState({ ...state });
        setLoading(true)
        setBtnLoading(true)
        PrefixApi.updatePrefix(state).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                setLoading(false)
                setBtnLoading(false)
                setOpen(false);
                getApi();
                activityFilter.limit = 10;
                activityFilter.page = 1;
                setActivityFilter({ ...activityFilter })
                getActivity(activityFilter);
            } else {
                addErrorMsg(res.data.message);
                setLoading(false)
                setBtnLoading(false)
                setOpen(false);
            }
        })
    }

    const handleEditClick = (args) => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_edit" && item.is_allowed == true))) {
            setState(args);
            setOpen(true);
            setAnchorEl(null);
            setIsEditable(false);
            setChromeAlert(true);
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    };

    const validateAll = () => {
        let { prefix_name, number } = state;
        let errors = {};
        // errors.module = validate_emptyField(module);
        errors.prefix_name = validate_emptyField(prefix_name)
        // errors.separator = validate_emptyField(separator)
        errors.number = validate_emptyField(number)
        return errors;
    };

    const handleSubmit = (e) => {
        let errors = validateAll();
        if (isValid(errors)) {
            updateApi(state)
        } else {
            let s1 = { error };
            s1 = errors;
            setError(s1);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const getApi = () => {
        setLoading(true)
        PrefixApi.getPrefix(LocalStorage.uid(), LocalStorage.getAccessToken()).then((response) => {
            setLoading(false)
            if (response.data.statusCode == 1003) {
                setGetData(response.data.data);
            }
        });
    };

    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        });
        setrecentActivityShow(false)
    }

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                if (Number(activityTotal) >= activityFilter.limit) {
                    // addLoader(true);
                    setListLoading(true);
                    activityFilter.limit = activityFilter.limit + 10;
                    setActivityFilter({ ...activityFilter })
                    getActivity(activityFilter, true);
                }
            }
        }
    };

    const HandleScroll = (e) => {
        const scrollTop = e.target.scrollTop;
        if (scrollTop >= 210) {
            setrecentActivityShow(false);
        }
    };

    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))) ?
                    <Box onScroll={HandleScroll} sx={{
                        height: '70vh', padding: '16px', overflow: 'auto',
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px",
                        },
                    }}>
                        <Box className={classes.activeItemBox} >
                            <Box className={classes.activeBoxHeading}><Text RegularBlack1 >{current} Settings</Text></Box>
                            <Box >
                                {loading ? <>
                                    {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                                        <Grid container key={index} mt={3} className={classes.descriptionBoxStyle}>
                                            <Grid item lg={3} md={3}>
                                                <Skeleton animation="wave" width="130px" />
                                            </Grid>
                                            <Grid item lg={3} md={3}>
                                                <Skeleton animation="wave" width="80px" />
                                            </Grid>
                                            <Grid item lg={2} md={2}>
                                                <Skeleton animation="wave" width="30px" />
                                            </Grid>
                                            <Grid item lg={2} md={2}>
                                                <Skeleton animation="wave" width="20px" />
                                            </Grid>
                                            <Grid item lg={2} md={2}>
                                                <Skeleton animation="wave" width="30px" />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </>
                                    :
                                    <>
                                        <Hidden smDown>

                                            <Grid container alignItems='center' columnSpacing={2}>
                                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                                    <Text greyLabel>Module Item</Text>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={12} >
                                                    <Text greyLabel>Prefix</Text>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={12} >
                                                    <Text greyLabel>Separator</Text>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={12}>
                                                    <Text greyLabel>Number</Text>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={12} >
                                                    <Text greyLabel>Actions</Text>
                                                </Grid>
                                            </Grid>
                                            {
                                                getData.length > 0 ?
                                                    <Box sx={{ height: '60vh', overflow: 'auto', /*'&::-webkit-scrollbar': { display: 'none', },*/ }}>
                                                        {getData.map((item, index) => (
                                                            <Box className={classes.descriptionBoxStyle} key={index} mt={2}>
                                                                <Grid container alignItems="center" columnSpacing={5}>
                                                                    <Grid item lg={4} md={4} sm={4} xs={12} >
                                                                        <Text mediumBlackColor> {item.module}</Text>
                                                                    </Grid>
                                                                    <Grid item lg={2} md={2} sm={2} xs={12} >
                                                                        <Text greyLabel>{item.prefix_name}</Text>
                                                                    </Grid>
                                                                    <Grid item lg={2} md={2} sm={2} xs={12} >
                                                                        <Text greyLabel>{item.separator}</Text>
                                                                    </Grid>
                                                                    <Grid item lg={2} md={2} sm={2} xs={12} >
                                                                        <Text greyLabel>{item.number}</Text>
                                                                    </Grid>
                                                                    <Grid item lg={2} md={2} sm={2} xs={12}>
                                                                        <CustomMenu
                                                                            Icon={<MenuIcon />}
                                                                            handleMenuClick={handleMenuClick}
                                                                            anchorEl={anchorEl}
                                                                            isOpen={Boolean(anchorEl)}
                                                                            onClose={handleClose}
                                                                            children={
                                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_edit" && item.is_allowed == true))) ?
                                                                                    [
                                                                                        { color: '#0C75EB', label: "Edit", Click: () => handleEditClick(item) },
                                                                                    ] :
                                                                                    []
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        ))}
                                                    </Box> :
                                                    <>
                                                        {NoDataFound("", "No Data Found", "50%", "35")}
                                                    </>
                                            }
                                        </Hidden>
                                        <Hidden smUp>
                                            <TableContainer component={Paper} sx={{ height: '60vh' }}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            {['Module Item', 'Prefix', 'Separator', 'Number', 'Actions'].map((header, index) => (
                                                                <TableCell key={index} style={{ border: 'none' }}>
                                                                    <Text greyLabel>{header}</Text>
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody style={{ background: '#fffff !important' }}>
                                                        {getData.length > 0 ? (
                                                            getData.map((item, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{
                                                                        // backgroundColor:  '#FBFBFB' ,
                                                                        margin: '30px', // Space between rows
                                                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Adds shadow for visual separation
                                                                        borderRadius: '8px', // Optional: Rounded corners
                                                                    }}
                                                                >
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        <Text mediumBlackColor>{item.module}</Text>
                                                                    </TableCell>
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        <Text greyLabel>{item.prefix_name}</Text>
                                                                    </TableCell>
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        <Text greyLabel>{item.separator}</Text>
                                                                    </TableCell>
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        <Text greyLabel>{item.number}</Text>
                                                                    </TableCell>
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        <CustomMenu
                                                                            Icon={<MenuIcon />}
                                                                            handleMenuClick={handleMenuClick}
                                                                            anchorEl={anchorEl}
                                                                            isOpen={Boolean(anchorEl)}
                                                                            onClose={handleClose}
                                                                            children={
                                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(permission => permission.slug === "organization_details_edit" && permission.is_allowed))) ?
                                                                                    [
                                                                                        { color: '#0C75EB', label: "Edit", Click: () => handleEditClick(item) },
                                                                                    ] :
                                                                                    []
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={5} align="center">
                                                                    {NoDataFound("", "No Data Found", "50%", "35")}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>



                                        </Hidden>
                                    </>
                                }
                                {listLoading && (
                                    <Box display='flex' justifyContent='center' width='100%' alignItems='center'>
                                        <ListLoadingIcon />
                                    </Box>
                                )}
                            </Box>

                            <BootstrapDialog
                                keepMounted
                                onClose={handleDialogClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                                fullWidth={true}
                                maxWidth={"md"}
                                TransitionComponent={Transition}
                            >
                                <DialogContent >
                                    {
                                        isEditable ?
                                            <Box padding={'30px'}>
                                                <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Text mediumViewAmt>Prefix</Text>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12} textAlign={"end"}>
                                                        {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_edit" && item.is_allowed == true))) ? <img onClick={() => { setIsEditable(false) }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                        <Text mediumGreyHeader1>Module Name</Text>
                                                        <Text mediumBlack14 pt={0.8} noWrap>{state.module ? state.module : "--"}</Text>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12} mt={2}>
                                                        <Text mediumGreyHeader1>Prefix</Text>
                                                        <Text mediumBlack14 pt={0.8} noWrap>{state.prefix_name ? state.prefix_name : "--"}</Text>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12} mt={2}>
                                                        <Text mediumGreyHeader1>Separator</Text>
                                                        <Text mediumBlack14 pt={0.8} noWrap>{state.separator ? state.separator : ""}</Text>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={4} xs={12} mt={2}>
                                                        <Text mediumGreyHeader1>Number</Text>
                                                        <Text mediumBlack14 pt={0.8} noWrap>{state.number ? state.number : "--"}</Text>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            :
                                            <Box padding={{ lg: '38px 30px 35px 30px', md: '38px 30px 35px 30px', sm: '30px', xs: '16px 12px' }}>
                                                <Box mb={4}>
                                                    <Text blackHeader1>Prefix</Text>
                                                </Box>
                                                <Grid container spacing={'32px'}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'module',
                                                                value: state.module,
                                                                type: 'text'
                                                            }}
                                                            disabled={true}
                                                            clientInput
                                                            handleChange={handleInputChange}
                                                            labelText={'Module Name'}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'prefix_name',
                                                                value: state.prefix_name,
                                                                type: 'text',
                                                                inputProps: { maxLength: 5 }
                                                            }}
                                                            handleChange={handleInputChange}
                                                            onKeyPress={onCharactersUppercase}
                                                            clientInput
                                                            labelText={'Prefix'}
                                                            error={error.prefix_name}
                                                        />
                                                        {error.prefix_name ? <Text red>{error.prefix_name}</Text> : ""}

                                                    </Grid>

                                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'separator',
                                                                value: state.separator,
                                                                type: 'text'
                                                            }}
                                                            disabled={true}
                                                            handleChange={handleInputChange}
                                                            clientInput
                                                            labelText={'Separator'}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'number',
                                                                value: state.number,
                                                                type: 'text',
                                                                inputProps: { maxLength: 4 }
                                                            }}
                                                            onKeyPress={onNumberOnlyChange}
                                                            handleChange={handleInputChange}
                                                            clientInput
                                                            labelText={'Number'}
                                                            error={error.number}
                                                        />
                                                        {error.number ? <Text red>{error.number}</Text> : ""}

                                                    </Grid>
                                                </Grid>
                                                <Box display={'flex'} justifyContent={'end'} gap={'20px'} pt={4}>
                                                    <Button sx={{ width: "108px !important" }} cancelOutlineBlue onClick={handleDialogClose} >Cancel</Button>
                                                    <LoadingButton saveBtn loading={btnloading} onClick={() => handleSubmit()}>Save</LoadingButton>
                                                </Box>
                                            </Box>
                                    }
                                </DialogContent>
                            </BootstrapDialog>

                        </Box>
                        <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                            <Box mb={3}>
                                <Text RegularBlack1>Recent Activities</Text>
                            </Box>
                            <Box sx={{ height: '40vh', overflowY: 'auto' }} ref={activityRef} onScroll={activityHandleScroll}>
                                {loading ?
                                    <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid container key={index} mt={3}>
                                                <Grid item lg={8} md={8} sm={8} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />

                                                </Grid>
                                            </Grid>
                                        ))}
                                    </> :
                                    <>
                                        {activityData.length > 0 ?
                                            <>
                                                {activityData.map((value) => (
                                                    <Box className={classes.descriptionBoxStyle} mb={2}>
                                                        <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                                                            <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                                                <Text mediumBlackColor>{
                                                                    value.user_name && value.user_name.length > 16 ?
                                                                        <BlackToolTip title={value.user_name} placement="top" arrow>
                                                                            {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : value.user_name
                                                                } {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                                                            </Grid>
                                                            <Grid container item lg={8} md={8} sm={6} xs={12} alignItems={"center"}>
                                                                {
                                                                    value.change_log.length > 0 && value.change_log.map((item) => (
                                                                        <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </> :
                                            <>
                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                            </>
                                        }
                                    </>
                                }
                                {listLoading && (
                                    <Box display='flex' justifyContent='center' width='100%' alignItems='center'>
                                        <ListLoadingIcon />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {recentActivityShow ?
                            <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                                <Button addButton
                                    sx={{
                                        position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`,
                                        "@media (min-width: 300px) and (max-width: 1420px)": {
                                            font: "12px Quicksand !important",
                                            fontWeight: `${700} !important`
                                        }
                                    }}
                                    onClick={scrollDown}
                                    endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                            </Grid>
                            : ''}
                    </Box >
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </div >

    )
}

export default Prefixes;
