import { makeStyles } from "@mui/styles";

const Styles = makeStyles((theme) => ({
    avatar: {
        width: "100px !important",
        height: "100px !important",
    },
    profileName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "18px !important",
        textAlign: "center"
    },
    listItems: {
        minHeight: "57px !important",
        margin: "15px 0px !important",
        color: '#092333',
        borderRadius: "8px !important",
        font: "14px Quicksand !important",
        transition: "all .3s ease",
        backgroundColor: '#F5F6F6 !important',
    },

    listItemsActive: {
        color: '#FFFFFF !important',
        backgroundColor: '#0C75EB !important',
        transition: "all .3s ease",
        '&:hover': {
            backgroundColor: '#0C75EB !important',
            color: '#FFFFFF !important',
        },
    },
    myDetails: {
        fontFamily: "Quicksand !important",
        color: "#171717 !important",
        fontSize: "15px !important",
        fontWeight: "400 !important"
    },
    optional: {
        font: '14px Quicksand !important',
        color: '#C7CCD3 !important',
        marginLeft: '4px !important'
    },
    endAdornmentControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#C7CCD3",
                borderRadius: "8px",
                font: "14px Quicksand !important"
            },
            "&:hover fieldset": {
                borderColor: "#C7CCD3"
            },
        },
    },
    endAdornmentInput: {
        "& .MuiInputBase-input": {
            font: "14px Quicksand !important",
        }

    },
    visiblityBtn: {
        width: '22px',
        height: '22px'

    },
    saveBtn: {
        marginTop: "5px !important",
        height: "35px !important",
        fontSize: "14px !important"
    },
    uploadImage: {
        width: "100%",
        height: "100%",
        opacity: 0,
        background: "#303030CC",
        borderRadius: "50%",
        "&:hover": {
            opacity: 1
        },
        transition: "0.3s ease"
    },
    uploadImageText: {
        font: "12px Quicksand !important",
        color: "#FFFFFF"
    },
    tabBg: {
        display: "flex",
        background: "#F4F8FF",
        height: '51px',
        width: '100%',
        borderRadius: "12px !important",
        alignItems: 'center',
        textAlign: 'center',
        padding: "12px 10px !important",
        gap: 3
    },
    ActiveBg: {
        background: "#0C75EB",
        height: '35px',
        width: '50%',
        padding: "6px 15px",
        borderRadius: "8px",
        cursor: "pointer",
        alignItems: 'center',
        justifyContent: 'center',
        display:"flex"
    },
    inactiveBg: {
        background: "transparent", width: '50%', padding: "12px 12px 12px 10px", borderRadius: "8px", cursor: "pointer"
    },
    addButton: {
        width: "191px !important", height: "40px !important", font: "18px Quicksand !important", fontWeight: `${600} !important`,
        "@media (min-width: 100px) and (max-width: 599px)": {
            font: '14px Quicksand !important',
        },
    },
    manageButton: {
        width: "163px !important", height: "33px !important", font: "14px Quicksand !important", fontWeight: `${600} !important`, color: "#0C75EB !important", background: "#E2F0FF !important"
    }

}))

export default Styles