import React, { useEffect, useState } from 'react'
import Button from '../../../../components/customButton/Button'
import ReactApexChart from 'react-apexcharts'
import { Box, Grid, Menu, MenuItem, Divider } from '@mui/material'
import Text from '../../../../components/customText/Text'
import PayrollDashboardStyles from './PayrollDashboardStyles'
import disablePlus from '../../../../assets/svg/payroll/disableAddIcon.svg';
import Date from '../../../../components/datePicker/Date'
import { addErrorMsg, addWarningMsg, dateFormat } from '../../../../utils/utils'
import moment from 'moment'
import calendar from '../../../../assets/svg/payroll/calendar.svg';
import closeIcon from '../../../../assets/svg/payroll/close-Icon.svg';
import DashboardApi from '../../../../apis/admin/payroll/DashboardApi'

function PayrollComparison(props) {
    const { state, setState, setOpenChooseComparison, chooseComparison } = props

    const classes = PayrollDashboardStyles()
    const [activePayroll, setActivePayroll] = useState('margin');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const [anchorEl1, setAnchorEl1] = useState(null);
    const open1 = Boolean(anchorEl1)
    const [chooseComparison1, setOpenChooseComparison1] = useState(false);
    const [chooseComparison2, setOpenChooseComparison2] = useState(false);
    const [payrollComparisionData, setPayrollComparisionData] = useState({
        margin_data: [],
        paid_data: []
    })

    useEffect(() => {
        getPayrollComparison('', '', '', '')
        // eslint-disable-next-line
    }, [])

    const selectedData = activePayroll === 'margin'
        ? payrollComparisionData.margin_data
        : payrollComparisionData.paid_data;

    const labels = selectedData.map(item => item.label);
    const values = selectedData.map(item => item.value);

    const payrollComparision = {
        series: [{
            name: activePayroll === 'margin' ? "Margin" : "Paid Amount",
            data: values
        }],
        options: {
            series: values,
            chart: {
                type: 'bar',
                stacked: true,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            xaxis: {
                categories: labels,
                labels: {
                    show: true,
                    style: {
                        fontSize: '10px',
                        fontWeight: 400,
                    }
                }
            },
            dataLabels: {
                enabled: false,
                total: {
                    enabled: true,
                }
            },
            tooltip: {
                enabled: false
            },
            states: {
                hover: { filter: { type: 'none' } },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: { type: 'none' }
                }
            },
            fill: {
                colors: ['#0C75EB', '#0C75EB']
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadiusApplication: 'end', // 'around', 'end'
                    borderRadiusWhenStacked: 'last', // 'all', 'last'
                    dataLabels: {
                        total: {
                            enabled: true,
                            formatter: function (val) {
                                return val != 0 ? '$' + val : '';
                            },
                            style: {
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#1D2129'
                            }
                        }
                    }
                },
            },
            grid: {
                padding: {
                    top: -10
                }
            }
        }
    };

    const handleTabPayroll = (args) => {
        setActivePayroll(args)
    }

    const handleButton = () => {
        setOpenChooseComparison(true);
        setOpenChooseComparison1(true);
        setOpenChooseComparison2(true);
        setAnchorEl(null);
        setAnchorEl1(null);
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        })
    }

    const getPayrollComparison = (previous_from, previous_to, present_from, present_to) => {
        DashboardApi.getPayrollComparison(previous_from, previous_to, present_from, present_to).then((res) => {
            if (res.data.statusCode === 1003) {
                setAnchorEl1(true);
                setOpenChooseComparison(false);
                setOpenChooseComparison2(false);
                if (res.data.data.length > 0) {
                    setPayrollComparisionData(res.data.data[0])
                }
                setAnchorEl1(null);
            } else {
                state['previous_from_date'] = ''
                state['previous_to_date'] = ''
                state['present_from_date'] = ''
                state['present_to_date'] = ''
                setState({ ...state })
                setAnchorEl1(null);
                setOpenChooseComparison1(true);
                setOpenChooseComparison2(true);
                addErrorMsg(res.data.message);
            }
        })
    }

    const applyFilter = (args) => {
        if (args == 1) {
            if (state.previous_from_date !== '' && state.previous_to_date !== '') {
                setAnchorEl(null);
                setOpenChooseComparison1(false);
            }else{
                addWarningMsg('Please select Start and End Date');
            }
        } else {
            if (state.previous_from_date !== '' && state.previous_to_date !== '' && state.present_from_date !== '' && state.present_to_date !== '') {
                getPayrollComparison(state.previous_from_date, state.previous_to_date, state.present_from_date, state.present_to_date);
            }else{
                addWarningMsg('Please select Start and End Date');
            }
        }
    }

    const handleClick = (event, args) => {
        if (args == 1) {
            setAnchorEl(event.currentTarget);
            setAnchorEl1(null);
        } else {
            setAnchorEl1(event.currentTarget);
        }
    };

    const handleClose = (args) => {
        if (args == 1) {
            setAnchorEl(null);
        } else {
            setAnchorEl1(null);
        }
    };

    const handleCloseIcon = (args) => {
        if (args == 1) {
            setOpenChooseComparison1(true);
        } else {
            setOpenChooseComparison2(true);
        }
    }

    return (
        <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '14px', width: '100%' }}>
            <Grid item xs={12} lg={12} md={12} mb={4}>
                <Text largeBlack20>Payroll Comparison</Text>
            </Grid>
            <Grid item xs={12} lg={12} md={12} my={2}>
                <Box className={classes.tabBg}>
                    <Box className={activePayroll == 'margin' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTabPayroll('margin')}>
                        {
                            activePayroll == 'margin' ?
                                <Text largeWhite400>Margin</Text> :
                                <Text blackHeader>Margin</Text>
                        }
                    </Box>
                    <Box className={activePayroll == 'paid_amount' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTabPayroll('paid_amount')}>
                        {
                            activePayroll == 'paid_amount' ?
                                <Text largeWhite400>Paid Amount</Text> :
                                <Text blackHeader>Paid Amount</Text>
                        }
                    </Box>
                </Box>
            </Grid>
            {
                chooseComparison ?
                    <Grid item container xs={12} alignItems='center' columnSpacing={0.5}>
                        {
                            chooseComparison1 ?
                                <Grid item xs={1.2} lg={1.5}>
                                    <Button choosePeriod onClick={(e) => handleClick(e, 1)}>
                                        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                            <Text offBoardBodyFont>Choose period</Text>
                                            <img src={calendar} alt='calendar' />
                                        </Box>
                                    </Button>
                                </Grid> :
                                <Grid item xs={1.8} md={2} sm={2} lg={2.2}>
                                    <Box display='flex' flexDirection='row' gap={1} className={classes.choosePeriod}>
                                        <Box><Text offBoardBodyFont>{state.previous_from_date} - {state.previous_to_date}</Text></Box>
                                        <Box><img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => handleCloseIcon(1)} /></Box>
                                    </Box>
                                </Grid>
                        }
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={() => handleClose(1)}
                            MenuListProps={{
                                'aria-labelledby': 'account-menu',
                            }}
                            style={{ left: "-52px", top: '20px', width: '100%' }}
                        >
                            <MenuItem
                                disableRipple={true}
                                sx={{ backgroundColor: 'transparent !important' }}>
                                <Grid item xs={12}>
                                    <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                        <Box>
                                            <Date
                                                labelText='Start Date'
                                                name='previous_from_date'
                                                value={state.previous_from_date}
                                                onChange={(value => dateChange(value, 'previous_from_date'))}
                                                height='45px'
                                            />
                                        </Box>
                                        <Box>
                                            -
                                        </Box>
                                        <Box>
                                            <Date
                                                labelText='End Date'
                                                name='previous_to_date'
                                                value={state.previous_to_date}
                                                minDate={moment(state.previous_from_date).add(1, 'days').format(dateFormat())}
                                                onChange={(value => dateChange(value, 'previous_to_date'))}
                                                height='45px'
                                            />
                                        </Box>
                                    </Box>
                                    <Grid item xs={12}>
                                        <Box my={2}>
                                            <Divider />
                                        </Box>
                                        <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                            <Button payrollHistoryCancel onClick={() => handleClose(1)}>Cancel</Button>
                                            <Button blueButton onClick={() => applyFilter(1)}>Apply Filter</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Menu>
                        <Grid item xs={0.2}>
                            <Text offBoardBodyFont>vs</Text>
                        </Grid>
                        {
                            chooseComparison2 ?
                                <Grid item xs={1.5}>
                                    <Button choosePeriod onClick={(e) => handleClick(e, 2)}>
                                        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                            <Text offBoardBodyFont>Choose period</Text>
                                            <img src={calendar} alt='calendar' />
                                        </Box>
                                    </Button>
                                </Grid> :
                                <Grid item xs={1.8} md={2} sm={2} lg={2.3}>
                                    <Box display='flex' flexDirection='row' gap={1} className={classes.choosePeriod}>
                                        <Box><Text offBoardBodyFont>{state.present_from_date} - {state.present_to_date}</Text></Box>
                                        <Box><img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => handleCloseIcon(2)} /></Box>
                                    </Box>
                                </Grid>
                        }
                        <Menu
                            anchorEl={anchorEl1}
                            id="account-menu"
                            open={open1}
                            onClose={() => handleClose(2)}
                            MenuListProps={{
                                'aria-labelledby': 'account-menu',
                            }}
                            style={{ left: "-52px", top: '20px', width: '100%' }}
                        >
                            <MenuItem
                                disableRipple={true}
                                sx={{ backgroundColor: 'transparent !important' }}>
                                <Grid item xs={12}>
                                    <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                        <Box>
                                            <Date
                                                labelText='Start Date'
                                                name='present_from_date'
                                                value={state.present_from_date}
                                                onChange={(value => dateChange(value, 'present_from_date'))}
                                                height='45px'
                                            />
                                        </Box>
                                        <Box>
                                            -
                                        </Box>
                                        <Box>
                                            <Date
                                                labelText='End Date'
                                                name='previous_to_date'
                                                value={state.present_to_date}
                                                minDate={moment(state.present_from_date).add(1, 'days').format(dateFormat())}
                                                onChange={(value => dateChange(value, 'present_to_date'))}
                                                height='45px'
                                            />
                                        </Box>
                                    </Box>
                                    <Grid item xs={12}>
                                        <Box my={2}>
                                            <Divider />
                                        </Box>
                                        <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                            <Button payrollHistoryCancel onClick={() => handleClose(2)}>Cancel</Button>
                                            <Button blueButton onClick={() => applyFilter(2)}>Apply Filter</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Menu>
                    </Grid> :
                    <Grid item xs={12} lg={12} md={12} my={2}>
                        <Button addPayroll
                            onClick={handleButton}
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}>
                            <img src={disablePlus} alt='disablePlus' style={{ paddingRight: '10px' }} />Add Payroll to compare
                        </Button>
                    </Grid>
            }
            <Grid item xs={12} lg={12} md={12} my={2}>
                <ReactApexChart options={payrollComparision.options} series={payrollComparision.series} type="bar" height={250} />
            </Grid>
        </Box>
    )
}

export default PayrollComparison
