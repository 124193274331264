import { Box, Grid, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Text from "../../../components/customText/Text";
import DashboardStyles from './DasboardStyles';
import Button from "../../../components/customButton/Button";
import LocalStorage from "../../../utils/LocalStorage"
import { NoPermission, addErrorMsg, addLoaderPlanExpired, capitalizeAndAddSpace, removeExpiredLoader, removeLoader } from "../../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardAPI from "../../../apis/admin/DashboardAPI";
import empty from '../../../assets/svg/dashboard/empty-dashboard.svg';
import Userplus from '../../../assets/svg/user-plus.svg';
import { domain } from "../../../config/Domain";
import LoadingScreen from "../employees/userprofile/document/LoadingScreen"
import SideActions from "./SideActions";
import DetailsDashboard from './DetailsDashboard';
import TourDashboard from "../../../components/tourGuideDetails/TourDashboard";
import TourGuideConfigApi from "../../../apis/configurations/tourguideConfig/TourGuideConfigApi";
import moment from "moment";
export default function Dashboard(props) {
    
    const classes = DashboardStyles();
    const location = useLocation();
    const theme = useTheme();
    var localDataStorage = LocalStorage.getUserData()
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const navigate = useNavigate();
    const currency = LocalStorage.getCurrencySymbol();
    const [startTour, setStartTour] = useState(false);
    const [totalEmp, setTotalEmp] = useState({
        consultant: '',
        contractor: '',
        total_employees: '',
        series: [],
        labels: []
    })
    const [financial, setFinancial] = useState({
        total_billed: '',
        total_pay: '',
        margin: '',
        financialData: {
            dates: [],
            labels: [],
            series: []
        }
    })
    const years = [
        { id: 1, value: '3 Months' },
        { id: 2, value: '6 Months' },
        { id: 3, value: '1 Year' },
        { id: 4, value: 'Custom' }
    ]
    const [yearDropdwn, setYearDropdwn] = useState({
        year: 3,
        year_start_date: "",
        year_end_date: "",
        placementYear: 3,
        placement_start_date: "",
        placement_end_date: "",
    })
    const [placementsAdded, setPlacementsAdded] = useState([]);
    const [placementsCompleted, setPlacementsCompleted] = useState([]);
    const [placementDetails, setPlacementDetails] = useState({});
    const [planDetails, setplanDetails] = useState({
        plan_not_set: '',
        plan_expired: '',
        free_trail: '',
        is_subscription_cancelled_expired: '',
        plan_expires_on: '',
        plan_banner: '',
        employee_exists: ''
    });
    const [tsData, setTsData] = useState({
        drafted: '',
        pending_approval: '',
        approved: '',
        rejected: ''
    })
    const [companies, setCompanies] = useState([
        {
            id: '',
            name: 'sdf',
            placement_count: 'sd',
            percentage: '3'
        }
    ])
    const scrollBox = useRef(null);
    const [Reminders, setReminders] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loaderAction, setLoaderAction] = useState(false);
    const [filterData, setFilterData] = useState({
        limit: 6,
        page: 1
    });
    const [pagination, setPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 6,
            totalPages: ""
        }
    );

    useEffect(() => {
        if (LocalStorage.getUserData().plan_not_set) {
            navigate('/billing');
        }
        else if (LocalStorage.getUserData().org_config_set == false) {
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeFromPage();
            navigate('/configuration');
        }
        else {
            navigate('/dashboard');
        }
        setLoader(true)
        getApi(moment().subtract(1, 'year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
        getPlacementApi(moment().subtract(1, 'year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
        listingApi();
        getPlanCheck();
        // eslint-disable-next-line
    }, [])

    const getTourGuideDetails = async () => {
        let response = await TourGuideConfigApi.getApi();
        if (((LocalStorage.getStartTour() && LocalStorage.getStartTour().dashboard == true) || (response.data.statusCode == 1003 && response.data.data?.DASHBOARD == false)) && LocalStorage.getUserData().admin_login == true) {
            LocalStorage.setStartTour({ 'dashboard': true });
            setStartTour(true);
        }
    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setplanDetails(res.data);
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired();
                    }
                    else {
                        removeExpiredLoader();
                    }
                }
                if (!res.data.plan_not_set && !res.data.plan_expired) {
                    if (localDataStorage.admin_login && (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true))) {
                        getTourGuideDetails();

                    }
                    else {
                        LocalStorage.setStartTour({ 'dashboard': false });

                    }
                }
            }
        })
    }

    const listingApi = () => {
        if (Reminders.length < 6) {
            setLoaderAction(true);
        }
        DashboardAPI.pendingRequestListing(filterData.limit, filterData.page).then((res) => {
            if (res?.data?.statusCode == 1003) {
                setLoaderAction(false);
                removeLoader()
                setReminders(res.data.data);
                setPagination(res.data.pagination);
            } else {
                setLoaderAction(false);
                removeLoader()
                addErrorMsg(res?.data?.message);
            }
        })
    }

    const handleScroll = () => {
        removeLoader()
        const { current } = scrollBox;
        if (current) {
            // const { scrollTop, scrollHeight, clientHeight } = current;
            // if (scrollTop + clientHeight >= scrollHeight - 5) {
            //     if (Number(pagination.total) >= filterData.limit) {
            //         loadMore();
            //     }
            // }
            const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = current;

            // Detect if the screen is small (tab or mobile)
            const isSmallScreen = window.innerWidth <= theme.breakpoints.values.md;

            if (isSmallScreen) {
                // Check for horizontal scroll
                if (scrollLeft + clientWidth >= scrollWidth - 5 && Number(pagination.total) >= filterData.limit) {
                    loadMore();
                }
            } else {
                // Check for vertical scroll
                if (scrollTop + clientHeight >= scrollHeight - 5 && Number(pagination.total) >= filterData.limit) {
                    loadMore();
                }
            }
        }
    };

    const loadMore = () => {
        setLoaderAction(true)
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        setLoaderAction(true);
        listingApi();
    }

    const getApi = (startDate, endDate) => {
        // setLoader(true)
        DashboardAPI.dashboardGetApi(startDate, endDate).then((res) => {
            if (res?.data?.statusCode == 1003) {
                setLoader(false)
                removeLoader();
                if (res.data.data.employeesData.series.length > 0) {
                    totalEmp['consultant'] = res.data.data.employeesData.series[0]
                    totalEmp['contractor'] = res.data.data.employeesData.series[1]
                }
                totalEmp['total_employees'] = res.data.data.employeesData.total_employees
                totalEmp['series'] = res.data.data.employeesData.series
                totalEmp['labels'] = res.data.data.employeesData.labels
                setCompanies(res.data.data.topCompanies);
                setTotalEmp({ ...totalEmp });
                setTsData(res.data.data.timesheetData[0]);
                setFinancial(res.data.data);
                tsSeries.tsColors = res.data.data.timesheetColors
                for (let i = 0; i < res.data.data.timesheetSeries.length; i++) {
                    tsSeries.tsSeriesData.push(Number(res.data.data.timesheetSeries[i]));
                }
                setTsSeries({ ...tsSeries });
            }
            else {
                setLoader(false)
            }
        })
    }

    const getPlacementApi = (startDate, endDate) => {
        DashboardAPI.dashboardPlacementGetApi(startDate, endDate).then((res) => {
            if (res?.data?.statusCode == 1003) {
                removeLoader();
                setPlacementDetails(res.data.data);
                setPlacementsAdded(res.data.data.distinct_placements_added)
                setPlacementsCompleted(res.data.data.distinct_placements_completed)
                cashflowData['addition'] = res.data.data.placement_details.series[0].data
                cashflowData['attrition'] = res.data.data.placement_details.series[1].data
                cashflowData['labels'] = res.data.data.placement_details.labels
                setCashflowData({ ...cashflowData })
            }
        })
    }

    
    const [cashflowData, setCashflowData] = useState({
        addition: [],
        attrition: [],
        labels: []
    })

    // const monthsOptions = [
    //     {
    //         id: 1,
    //         value: 'January'
    //     },
    //     {
    //         id: 2,
    //         value: 'Febraury'
    //     },
    //     {
    //         id: 3,
    //         value: 'March'
    //     },
    //     {
    //         id: 4,
    //         value: 'April'
    //     },
    //     {
    //         id: 5,
    //         value: 'May'
    //     },
    //     {
    //         id: 6,
    //         value: 'June'
    //     },
    //     {
    //         id: 7,
    //         value: 'July'
    //     },
    //     {
    //         id: 8,
    //         value: 'August'
    //     }, {
    //         id: 9,
    //         value: 'September'
    //     },
    //     {
    //         id: 10,
    //         value: 'October'
    //     },
    //     {
    //         id: 11,
    //         value: 'November'
    //     },
    //     {
    //         id: 12,
    //         value: 'December'
    //     }
    // ]

    const areaOptions = {
        series: [
            {
                name: 'Addition',
                data: cashflowData.addition
            },
            {
                name: 'Attrition',
                data: cashflowData.attrition
            }
        ],
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#14C9C9', '#F7BA1E'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#14C9C9', '#F7BA1E'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            // x: {
            //     formatter: function (val) {
            //         return monthsOptions[val - 1].value + ' ' + yearDropdwn.year;
            //     }
            // },
            // colors: ['#14C9C9', '#F7BA1E'],
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: cashflowData.labels
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        colors: ['#14C9C9', '#F7BA1E'],
        // tooltip: {
        //     x: {
        //         format: 'dd/MM/yy HH:mm'
        //     },
        // },
    }

    const [tsSeries, setTsSeries] = useState({
        tsSeriesData: [],
        tsColors: []
    })

    const navigatePending = (args) => {
        if (args.placement_id == '' || args.placement_id == null || args.placement_id == undefined) {
            navigate('/pending-actions', { state: { id: args.id, empId: args.employee_id, placementId: '', from: 'employee', employee: 'employee' } })
        } else {
            navigate('/pending-actions', { state: { id: '', timesheet_id: args.id, empId: args.employee_id, placementId: args.placement_id, from: 'dashboard', employee: '' } })
        }
    }

    const viewAnalytics = () => {
        LocalStorage.setAnalyticsRoute('dashboard')
        navigate('/placement/timesheets-analytics', { state: { from: 'dashboard' } })
    }

    const addEmployee = () => {
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeFromPage();
        navigate('/employees', { state: { from: 'dashboard' } })
    }

    const manageSubscription = () => {
        LocalStorage.setlocationPath(location.pathname)
        navigate('/billing', {
            state: {
                renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
                    ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
                        (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
                renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
                autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
                autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
                autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
            }
        })
    }

    const navigateToTimesheet = (statusTo) => {
        navigate('/timesheet', { state: { status: statusTo } })
    }



    return (
        <Grid container lg={12} md={12} sm={12} xs={12} pl={2} sx={{
            "@media (min-width: 968px)": {
                paddingLeft: '90px',
            },
            "@media (min-width: 1025px)": {
                paddingLeft: '130px',
            }

        }}>
            {startTour ? <TourDashboard setOpenPopup={props.setOpenPopup} startTour={startTour} setStartTour={() => setStartTour(false)} /> : <>
                {
                    (localDataStorage.super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true))) ?
                        <Grid container lg={12} sm={12} md={12} xs={12}>
                            {
                                loader ?
                                    <LoadingScreen />
                                    :
                                    planDetails.employee_exists == false && !loader ?
                                        <>
                                            <Grid item lg={12} md={12} sm={12} xs={12} mb={2} textAlign='start' alignContent='start'>
                                                <Text boldblack22>Hi, {localDataStorage.full_name}</Text>
                                                <Text mediumBlack14 sx={{ paddingTop: '6px' }}>Start your journey by adding "Employee"</Text>
                                            </Grid>
                                            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center' textAlign='center'>
                                                <Box textAlign='center' alignItems='center' sx={{ height: '70vh', alignContent: 'center' }}>
                                                    <img loading="eager" src={empty} alt="nodata" style={{ marginBottom: '20px' }} width='222' height='163' />
                                                    <Text smallLabelblack16 sx={{ marginBottom: '20px' }}>The trail is clear, but the journey has yet to begin.</Text>
                                                    <Button addButton startIcon={<img loading="eager" src={Userplus} alt="Userplus" style={{ padding: "0px 4px" }} />} onClick={() => addEmployee()}>
                                                        Add Employee
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </>
                                        :
                                        <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} justifyContent={'center'} pt={2} alignItems='flex-start'>
                                            <DetailsDashboard tsData={tsData} tsSeries={tsSeries} viewAnalytics={viewAnalytics} years={years} placementsCompleted={placementsCompleted} totalEmp={totalEmp} localDataStorage={localDataStorage} planDetails={planDetails} manageSubscription={manageSubscription} rolePermission={rolePermission} financial={financial} classes={classes} currency={currency}
                                                capitalizeAndAddSpace={capitalizeAndAddSpace} companies={companies} yearDropdwn={yearDropdwn} areaOptions={areaOptions} placementsAdded={placementsAdded} placementDetails={placementDetails} navigateToTimesheet={navigateToTimesheet} navigate={navigate} setOpenPopup={props.setOpenPopup}
                                                 getPlacementApi={getPlacementApi} getApi={getApi} setYearDropdwn={setYearDropdwn}
                                            />
                                            <SideActions loaderAction={loaderAction} Reminders={Reminders} navigatePending={navigatePending} handleScroll={handleScroll} scrollBox={scrollBox} capitalizeAndAddSpace={capitalizeAndAddSpace} />
                                        </Grid>
                            }
                        </Grid> :
                        <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} pl={15} pt={2} alignItems='flex-start'>
                            <Text boldblack22>Hi, {localDataStorage.full_name}</Text>
                            {NoPermission()}
                        </Grid>
                } </>}

        </Grid >
    )
};
