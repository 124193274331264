import { Box, Breadcrumbs, Dialog, Grid, Skeleton, Slide, Typography, Table, TableBody, TableHead, TableRow, TableContainer, DialogContent, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../components/customText/Text'
import AppIntegrationsStyles from './AppIntegrationsStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import Component87 from '../../../assets/svg/Component87.svg';
import LocalStorage from '../../../utils/LocalStorage';
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
import { NoPermission, addErrorMsg, addLoader, addSuccessMsg, removeLoader } from '../../../utils/utils';
// import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
// import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
// import { ReactComponent as CheckedDisabled } from '../../../assets/svg/CheckedDisabled1.svg';
import Button from '../../../components/customButton/Button';
import { styled } from "@mui/material/styles";
// import EditIcon from '../../../assets/svg/newEdit.svg';
import cloud from '../../../assets/svg/black-cloud-zoho.svg';
import DeleteIcon from '../../../assets/svg/deleteSquare.svg';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import settings from '../../../assets/svg/settings.svg';
import settings from '../../../assets/svg/zoho-updated.svg';
import warning from '../../../assets/svg/orange-warning-zoho.svg';
import FileSaver from 'file-saver';
import Sure from '../../../assets/svg/ExpenseDeleteIcon.svg'
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import LoadingButton from '../../../components/customButton/LoadingButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "orange",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#EAF9FF",
    },
    '&:nth-of-type(2n)': {
        backgroundColor: "#CAF0FF", // Background color for even rows
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function EditAppIntegrations() {

    const classes = AppIntegrationsStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location && location.state && location.state.from;
    const appName = location && location.state && location.state.app_key
    const quickbooksLogo = location && location.state && location.state.logo_url
    const id = location && location.state && location.state.id
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [listLoad, setListLoad] = useState(false);
    const [listLoadZoho, setListLoadZoho] = useState(false);
    const [connectedList, setConnectedList] = useState([]);
    const [step, setStep] = useState(1);
    const [fieldsData, setFieldsData] = useState([]);// eslint-disable-next-line
    const [checkedAll, setCheckedAll] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selected, setSelected] = useState("");
    const [finishLoad, setFinishLoad] = useState(false);
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [orgData, setOrgData] = useState({
        company_name: '',
        date: ''
    })
    const [openQuickDelete, setOpenQuickDelete] = useState(false);
    const [deregisterLoader, setDeregisterLoader] = useState(false);

    useEffect(() => {
        if (LocalStorage.getAppIntegrationId()) {
            getAppData(LocalStorage.getAppIntegrationId());
            getConnectedOrganizations();
        }
        getOrganizationFields();
        if (appName == 'quickbooks') {
            getOrgList();
        }// eslint-disable-next-line
    }, [])

    const getOrgList = () => {
        setListLoad(true);
        InstalledAppsApi.getQuickbooksOrgList().then((res) => {
            setTimeout(() => {
                setListLoad(false);
                if (res.data.statusCode === 1003) {
                    setOrgData(res.data.data);
                }
            }, 800)
        })
    }

    const getAppData = (param) => {
        setLoading(true);
        InstalledAppsApi.getSingleApp(param).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setData(res.data.data[0]);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const getConnectedOrganizations = () => {
        setListLoadZoho(true);
        InstalledAppsApi.getZohoOrganizationList({ authorization_code: "", status: "registered" }).then((res) => {
            setListLoadZoho(false);
            if (res.data.statusCode === 1003) {
                setConnectedList(res.data.data);
            }
        })
    }

    const getOrganizationFields = () => {
        setLoading(true);
        InstalledAppsApi.getOrganizationFields().then((res) => {
            setLoading(false);
            removeLoader();
            if (res.data.statusCode === 1003) {
                const allChecked = res.data.data.every(item => item.checked === true);
                if (allChecked) {
                    setCheckedAll(true);
                } else {
                    setCheckedAll(false);
                }
                setFieldsData(res.data.data);
            } 
            // else {
            //     addErrorMsg(res.data.message)
            // }
        })
    }
    // eslint-disable-next-line
    const handleEdit = (param) => {
        addLoader(true);
        getOrganizationFields(param);
        setSelected(param);
    }

    const exportApi = () => {
        addLoader(true);
        if (appName == 'quickbooks') {
            InstalledAppsApi.getQuickbookExport('Error').then((res) => {
                if (res.data.statusCode == 1003) {
                    removeLoader();
                    FileSaver.saveAs(res.data.data[0].file_path);
                } else {
                    removeLoader();
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            InstalledAppsApi.getExport('Error').then((res) => {
                if (res.data.statusCode == 1003) {
                    removeLoader();
                    FileSaver.saveAs(res.data.data[0].file_path);
                } else {
                    removeLoader();
                    addErrorMsg(res.data.message);
                }
            })
        }
    }

    const [deleteId, setDeleteId] = useState("")
    const handleDeleteOpen = (item) => {
        setDeleteId(item);
        setDeleteOpen(true);
    }

    const quickbooksDeregister = () => {
        setDeregisterLoader(true);
        const updateData = {
            request_id: LocalStorage.uid(),
            app_integration_id: id
        }
        InstalledAppsApi.deregisterQuickbooks(updateData).then((res) => {
            setDeregisterLoader(false);
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                setOpenQuickDelete(false);
                navigate('/app-integrations');
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const deRegister = () => {
        setFinishLoad(true);
        var data = {
            request_id: LocalStorage.uid(),
            organization_ids: [deleteId],
            // de_register:true,
        }
        InstalledAppsApi.deregisterOrganization(data).then((res) => {
            setFinishLoad(false);
            if (res.data.statusCode === 1003) {
                setDeleteId("");
                setDeleteOpen(false);
                getConnectedOrganizations();
                navigate('/app-integrations');
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }
    // eslint-disable-next-line
    const handleChangeCheckBox = (e, index) => {
        fieldsData[index].checked = !fieldsData[index].checked;
        setFieldsData([...fieldsData]);

        const filterData = fieldsData.some((item) => item.checked != true);
        if (filterData) {
            setCheckedAll(false);
        } else {
            setCheckedAll(true);
        }
    }
    // eslint-disable-next-line
    const handelCheckAll = (e) => {
        setCheckedAll(e.target.checked);
        for (var i in fieldsData) {
            if (!fieldsData[i].disabled) {
                fieldsData[i].checked = e.target.checked
            }
        }
        setFieldsData([...fieldsData])
    }
    // eslint-disable-next-line
    const handleSave = () => {
        // const filterData = fieldsData.some((item) => item.checked === true);
        // if (filterData) {
        addLoader(true);
        if (step == 1) {
            var data = {
                request_id: LocalStorage.uid(),
                finish: true,
            }
        } else { // eslint-disable-next-line
            var data = {
                organization_id: selected,
                request_id: LocalStorage.uid(),
                finish: true,
                fields_list: fieldsData,
            }
        }
        InstalledAppsApi.storeOrganizationFields(data).then((res) => {
            removeLoader();
            if (res.data.statusCode === 1003) {
                setOpen(true);
                getConnectedOrganizations();
                setStep(1);
                setSelected("");
                setCheckedAll(false);
            } else {
                addErrorMsg(res.data.message)
            }
        })
        // } else {
        //     addErrorMsg("Invalid response.please select any checkbox to continue...");
        // }
    }

    return (
        <Box className={classes.mainBox1}>
            <Box mx={2}>
                {step == 1 ?
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                        <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>App Integrations</Typography>
                        <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>All Apps</Typography>
                        <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Configure</Text>
                    </Breadcrumbs> :
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                        <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>App Integrations</Typography>
                        <Typography onClick={() => { navigate("/app-integrations", { state: { from: 'Installed' } }) }} className={classes.breadcrumbsLink}>Installed Apps</Typography>
                        <Typography onClick={() => { navigate("/app-integrations/selected-app") }} className={classes.breadcrumbsLink}>Select Organization</Typography>
                        <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Edit</Text>
                    </Breadcrumbs>
                }
            </Box>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true))) ?
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={70} width={300} />
                                    :
                                    <Box className={classes.logoBox}>
                                        <img src={orgData.company_name !== '' ? quickbooksLogo : data.logo_url} alt="logo" height={"75px"} width={"198px"} />
                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                        <Box >
                                            <Text mediumOverView pt="5px">Connected</Text>
                                        </Box>
                                    </Box>
                            }
                        </Grid>
                        <Grid container alignItems={"center"} justifyContent={"end"} item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={70} width={300} />
                                    :
                                    <a rel="noopener noreferrer" style={{ color: "#0C75EB" }} href={data && data.help_documentation_url && data.help_documentation_url} target="_blank" className={classes.helpText}>Help Documentation</a>
                            }
                        </Grid>

                        {step == 1 && <Box sx={{ height: "50vh", overflow: "auto", paddingTop: "20px", width: "100%", paddingLeft: "5px" }}>
                            {
                                listLoad || listLoadZoho ?
                                    [1, 2, 3, 4].map((item) => (
                                        <Box sx={{ width: "100%", height: "74px !important", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", display: "flex", alignItems: "center" }} mt={2}>
                                            <Grid container item lg={12} md={12} alignItems='center' padding={"0px 40px"}>
                                                <Grid item lg={9} md={9} sm={9} xs={7}>
                                                    <Skeleton variant="rounded" width={250} height={25} />
                                                    <Skeleton sx={{ marginTop: "5px" }} variant="rounded" width={150} height={14} />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={5} textAlign={"end"}>
                                                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                                                        <Skeleton variant="rounded" width={20} height={25} />
                                                        <Skeleton variant="rounded" width={20} height={25} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box >
                                    )) :
                                    orgData.company_name !== '' ?
                                        <Box sx={{ width: "100%", height: "74px !important", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", display: "flex", alignItems: "center" }} mt={2}>
                                            <Grid container item lg={12} md={12} alignItems='center' padding={"0px 40px"}>
                                                <Grid item lg={9} md={9} sm={9} xs={7}>
                                                    <Box display='flex' flexDirection='row' gap={3} alignItems='center'>
                                                        <Box>
                                                            <Text mediumBoldBlack>{orgData.company_name}</Text>
                                                            <Text greyLabel pt={"5px"}>Configured on: {orgData.date}</Text>
                                                        </Box>
                                                        {
                                                            from == 'Error' ?
                                                                <Box>
                                                                    <img src={warning} alt='warning' />
                                                                </Box> : ''
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={5} textAlign={"end"}>
                                                    <Box sx={{ display: "flex", justifyContent: "end" }} gap={1}>
                                                        <>
                                                            {
                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_delete" && item.is_allowed == true))) ?
                                                                    <img onClick={() => setOpenQuickDelete(true)} src={DeleteIcon} alt="DeleteIcon" style={{ cursor: "pointer" }} /> : ''
                                                            }
                                                        </>
                                                        <img onClick={() => exportApi()} src={cloud} alt={"icon"} style={{ marginRight: "10px", cursor: "pointer" }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box > :
                                        <>
                                            {
                                                connectedList.length > 0 ?
                                                    <>
                                                        {
                                                            connectedList.map((item) => (
                                                                <Box sx={{ width: "100%", height: "74px !important", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", display: "flex", alignItems: "center" }} mt={2}>
                                                                    <Grid container item lg={12} md={12} alignItems='center' padding={"0px 40px"}>
                                                                        <Grid item lg={9} md={9} sm={9} xs={7}>
                                                                            <Box display='flex' flexDirection='row' gap={3} alignItems='center'>
                                                                                <Box>
                                                                                    <Text mediumBoldBlack>{item.value}</Text>
                                                                                    <Text greyLabel pt={"5px"}>Configured on: {item.configured_date}</Text>
                                                                                </Box>
                                                                                {
                                                                                    from == 'Error' ?
                                                                                        <Box>
                                                                                            <img src={warning} alt='warning' />
                                                                                        </Box> : ''
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item lg={3} md={3} sm={3} xs={5} textAlign={"end"}>
                                                                            <Box sx={{ display: "flex", justifyContent: "end" }} gap={1}>
                                                                                <>
                                                                                    {
                                                                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_delete" && item.is_allowed == true))) ?
                                                                                            <img onClick={() => { handleDeleteOpen(item.id) }} src={DeleteIcon} alt="DeleteIcon" style={{ cursor: "pointer" }} /> : ''
                                                                                    }
                                                                                </>
                                                                                <img onClick={() => exportApi()} src={cloud} alt={"icon"} style={{ marginRight: "10px", cursor: "pointer" }} />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box >
                                                            ))
                                                        }
                                                    </> : ""
                                            }
                                        </>
                            }
                        </Box>}

                        {step == 2 &&
                            <Grid container >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box sx={{ height: "60vh", overflow: "auto" }}>
                                        {
                                            fieldsData.length > 0 ?
                                                <Box sx={{ marginTop: "20px" }}>
                                                    <TableContainer >
                                                        <Table size="small" sx={{ borderRadius: "8px !important" }}>

                                                            <TableHead>
                                                                <TableRow sx={{ height: '47px' }} key={1}>
                                                                    <TableCell sx={{ width: '50px', textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: "#ffffff", borderTopLeftRadius: "8px" }}>
                                                                        <Text mediumBlack>Sno</Text>
                                                                    </TableCell>
                                                                    {['Module in Zoho', 'Fields in Zoho Books', 'Field Type', 'Module in Japfu', 'Fields in Japfu'].map((header) => (
                                                                        <TableCell sx={{ borderBottom: " 1px solid #B0B0B0", textAlign: "center", backgroundColor: "#ffffff", }} key={header}>
                                                                            <Text mediumBlack >{header}</Text>
                                                                        </TableCell>
                                                                    ))}
                                                                    {/* <TableCell sx={{ width: '140px', textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: "#ffffff", borderTopRightRadius: "8px" }}>
                                                                        <FormControlLabel
                                                                            key={1}
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"checkedAll"}
                                                                                    value={checkedAll}
                                                                                    onChange={(e) => { handelCheckAll(e) }}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={checkedAll} />}
                                                                            label={<Text mediumBlack>Merge</Text>}
                                                                        />
                                                                    </TableCell> */}
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody style={{ borderRadius: '8px' }}>
                                                                {fieldsData.map((item, index) => (
                                                                    <StyledTableRow sx={{ height: '47px' }} key={index}>
                                                                        <StyledTableCell sx={{ padding: 0, textAlign: "center", borderLeft: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : "1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Quicksand !important" }}>{index + 1}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0", padding: 0, textAlign: "center", }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Quicksand !important" }}>{item.module_in_zoho}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Quicksand !important" }}>{item.fields_in_zoho}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ padding: 0, textAlign: "center", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Quicksand !important" }}>{item.field_type}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0", padding: 0, textAlign: "center", }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Quicksand !important" }}>{item.module_in_japfu}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Quicksand !important" }}>{item.fields_in_japfu}</Text>
                                                                        </StyledTableCell>
                                                                        {/* <StyledTableCell sx={{ padding: 0, textAlign: "center", borderRight: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>

                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={"placement_ids"}
                                                                                        value={item.checked}
                                                                                        disabled={item.disabled}
                                                                                        onChange={(e) => { handleChangeCheckBox(e, index) }}
                                                                                        icon={<CheckBorderIcon />}
                                                                                        checkedIcon={item.disabled ? <CheckedDisabled /> : <CheckedIcon />}
                                                                                        checked={item.checked} />}
                                                                                label={""}
                                                                            />
                                                                        </StyledTableCell> */}
                                                                    </StyledTableRow>
                                                                ))}

                                                            </TableBody>

                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                                :
                                                ""
                                        }
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12} pt={3}>
                                    <Button onClick={() => { setStep(1); setSelected("") }} cancelOutlineBlue className={classes.configBackBtn}>Back</Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} pt={3} textAlign={"end"}>
                                    <Button sx={{ width: "169px !important" }} onClick={() => { handleSave() }} saveBtn >Save Changes</Button>
                                </Grid>
                            </Grid>
                        }
                        {step == 1 &&
                            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"space-between"}>
                                <Button onClick={() => { navigate("/app-integrations") }} cancelOutlineBlue className={classes.configBtn}>Cancel</Button>
                                {/* <Button saveBtn className={classes.configBtn} onClick={() => { navigate('/app-integrations') }}>Finish</Button> */}
                            </Grid>}
                    </Grid>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
            <ReusablePopup openPopup={deleteOpen} setOpenPopup={setDeleteOpen} white iconHide fixedWidth>
                <Grid container>
                    <Grid item md={12} sm={12} textAlign={"center"}>
                        <img src={Sure} alt='Sure' />
                    </Grid>
                    <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                        <Text mediumViewAmt>Are you Sure?</Text>
                    </Grid>
                    <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                        <Text offBoardBodyFont>Do you want to deregister the organization<br /> from the application?</Text>
                    </Grid>
                    <Grid container item md={12} sm={12} justifyContent={"center"} columnGap={2} pt={3}>
                        <Button cancelOutlineBlue onClick={() => { setDeleteOpen(false) }} className={classes.popupButtons}>Cancel</Button>
                        <LoadingButton onClick={() => { deRegister() }} saveBtn className={classes.popupButtons} loading={finishLoad} >Deregister</LoadingButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
            {/* <ReusableDelete loading={deleteLoad} open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deRegister() }} />} */}

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "0px", }}>
                    <Grid container>
                        <Grid item md={12} sm={12} textAlign={"center"}>
                            <img src={settings} alt='settings' />
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                            <Text popupHead1>Integration Initiated</Text>
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                            <Text offBoardBodyFont>Integration process has started. Please wait until the process is complete.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={3}>
                            <Button saveBtn onClick={() => { setOpen(false); setStep(1); setSelected(""); }} className={classes.doneBtn}>Done</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
            <ReusablePopup openPopup={openQuickDelete} setOpenPopup={setOpenQuickDelete} onClose={() => setOpenQuickDelete(false)} white iconHide statusWidth>
                <Grid container>
                    <Grid item md={12} sm={12} textAlign={"center"}>
                        <img src={Sure} alt='Sure' />
                    </Grid>
                    <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                        <Text mediumViewAmt>Are you sure you want to de-register?</Text>
                    </Grid>
                    <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                        <Text offBoardBodyFont>If you re-register later, you'll need to sync all your data again.</Text>
                    </Grid>
                    <Grid item container md={12} sm={12} pt={3}>
                        <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between' sx={{ width: '100% !important' }}>
                            <LoadingButton redButton onClick={() => setOpenQuickDelete(false)} >Cancel</LoadingButton>
                            <LoadingButton loading={deregisterLoader} blueborder onClick={quickbooksDeregister}>Proceed with De-registration</LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
        </Box>
    )
}

export default EditAppIntegrations