import { Avatar, AvatarGroup, Box, Divider, Grid, Menu, MenuItem, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Text from "../../../components/customText/Text";
import LocalStorage from "../../../utils/LocalStorage"
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { addErrorMsg, BlackToolTip, dateFormat, NoDataFound } from "../../../utils/utils";
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import moment from "moment";
import Date from "../../../components/datePicker/Date";
import Button from "../../../components/customButton/Button";
import closeIcon from '../../../assets/svg/payroll/close-Icon.svg';
import blueLoader from '../../../assets/gif/japfuLoader.gif';
import CommonApi from "../../../apis/CommonApi";
import DashboardAPI from "../../../apis/admin/DashboardAPI";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function EmployeeAndClientAttribution(props) {
    const { classes, placementsCompleted, years, totalEmp, pieData,
        capitalizeAndAddSpace, yearDropdwn, areaOptions, placementsAdded, placementDetails, navigate, setYearDropdwn, getPlacementApi }
        = props


    const [anchorEl, setAnchorEl] = useState(null);
    const [enableDate, setEnableDates] = useState(false);
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [yearsSelect, setYearsSelect] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [filterYear, setFilterYear] = useState({
        year: 2025,
        type: 1
    });

    const options = [1, 2, 3]
    // { id: 1, value: "Active Placements" }, { id: 2, value: "Placement Frequency" }, { id: 3, value: "Avg Bill rate" }]

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        setYearDropdwn({
            ...yearDropdwn,
            [name]: date == '' ? '' : moment(date).format("YYYY-MM-DD")
        })
    }

    useEffect(() => {
        defaultYears();
        getTopCompaniesApi('2025', '1');
    }, [])

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYearsSelect(res.data.data);
            }
        })
    }

    const getTopCompaniesApi = (year, type) => {
        // setLoader(true)
        DashboardAPI.dashboardTopCompaniesGetApi(year, type).then((res) => {
            if (res?.data?.statusCode == 1003) {
                setCompanies(res?.data?.data)
            }
        })
    }

    const dropDownChangeHandler = (e) => {
        let value = e.target.value
        yearDropdwn[e.target.name] = e.target.value
        setYearDropdwn({ ...yearDropdwn });
        if (value === 4) {
            setAnchorEl(true);
            setOpen(true);
        } else {
            switch (value) {
                case 1:
                    var start_date1f = moment().subtract(3, 'months').format("YYYY-MM-DD");
                    var end_date1f = moment().format("YYYY-MM-DD");
                    getPlacementApi(start_date1f, end_date1f);
                    setTimeout(() => {
                        setLoad(false);
                    }, 100)
                    break;
                case 2:
                    var start_date2f = moment().subtract(6, 'months').format("YYYY-MM-DD");
                    var end_date2f = moment().format("YYYY-MM-DD");
                    getPlacementApi(start_date2f, end_date2f);
                    setTimeout(() => {
                        setLoad(false);
                    }, 100)
                    break;
                case 3:
                    var start_date3f = moment().subtract(1, 'year').format("YYYY-MM-DD");
                    var end_date3f = moment().format("YYYY-MM-DD");
                    getPlacementApi(start_date3f, end_date3f);
                    setTimeout(() => {
                        setLoad(false);
                    }, 100)
                    break;
                default:
                    break;
            }
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setLoad(false);
        yearDropdwn.placementYear = 3;
        yearDropdwn.placement_start_date = '';
        yearDropdwn.placement_end_date = '';
        setYearDropdwn({ ...yearDropdwn });

    };

    const applyFilter = () => {
        if (yearDropdwn.placement_start_date !== '' && yearDropdwn.placement_end_date !== '') {
            const startDate = moment(yearDropdwn.placement_start_date);
            const endDate = moment(yearDropdwn.placement_end_date);
            if (startDate.isSameOrBefore(endDate)) {
                setEnableDates(true);
                getPlacementApi(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
                setAnchorEl(null);
                setOpen(false);
                setTimeout(() => {
                    setLoad(false);
                }, 100)
            } else {
                addErrorMsg('Please ensure that the start date is before the end date.');
            }
        } else {
            addErrorMsg('Please select Start and End Date');
        }
    }

    const clearDate = () => {
        setEnableDates(false);
        handleClose();
        getPlacementApi(moment().subtract(1, 'year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
        setTimeout(() => {
            setLoad(false);
        }, 100)
    }

    const handleSelectYear = (e) => {
        setFilterYear({ ...filterYear, [e.target.name]: e.target.value });
        getTopCompaniesApi(e.target.value, filterYear.type);
    }

    const [anchorElM, setAnchorElM] = React.useState(null);
    const openMenu = Boolean(anchorElM);
    const handleClickListItem = (event) => {
        setAnchorElM(event.currentTarget);
    };

    const handleMenuItemClick = (event, val) => {
        setFilterYear({ ...filterYear, type: val });
        setAnchorElM(null);
        getTopCompaniesApi(filterYear.year, val);
    };

    const handleCloseMenu = () => {
        setAnchorElM(null);
    };

    return (
        <>
            <Grid container spacing={2} pt={2} alignItems='flex-start'>
                <Grid item container lg={6} md={12} sm={12} xs={12} justifyContent='center' id='employeechart'>
                    <Grid item lg={12} md={6} sm={12} xs={12} mb={2}>
                        <Text smallLabelblack16>Employees Data</Text>
                    </Grid>
                    {
                        (totalEmp.consultant == 0 && totalEmp.contractor == 0) ?
                            <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                                {NoDataFound('')}
                            </Grid> :
                            <Grid item xl={10} lg={12} md={12} sm={12} xs={12} pt={2} container justifyContent={'center'} textAlign='center' sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                                <Chart
                                    options={pieData.options}
                                    series={pieData.series}
                                    type="donut"
                                    height='210px'
                                    width='210px'
                                />
                                <Grid item container lg={12} justifyContent='center' textAlign='center' pt={2}>
                                    <Grid item container lg={6} sm={6} xs={12}>
                                        <Box display='flex' flexDirection='row' gap={1}>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                <Box className={classes.greenDot} />
                                                <Text BlackExtraDark>Consultants</Text>
                                            </Box>
                                            <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => {
                                                LocalStorage.removeRedirectedModule();
                                                LocalStorage.removeFromPage();
                                                navigate('/employees', { state: { from: 'consultant' } })
                                            }}
                                            >{totalEmp.consultant}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container lg={6} sm={6} xs={12} textAlign='start'>
                                        <Box display='flex' flexDirection='row' gap={1}>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                <Box className={classes.pinkDot} />
                                                <Text BlackExtraDark>Contractor</Text>
                                            </Box>
                                            <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => {
                                                LocalStorage.removeRedirectedModule();
                                                LocalStorage.removeFromPage();
                                                navigate('/employees', { state: { from: 'contractor' } })
                                            }}>{totalEmp.contractor}</Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                </Grid>
                <Grid item container lg={6} md={12} sm={12} xs={12} justifyContent='center' id='clientchart'>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} justifyContent={"space-between"} alignItems={"center"}>
                        <Text smallLabelblack16>Top Performing Clients</Text>
                        <CustomSelect
                            name='year'
                            value={filterYear.year}
                            onChange={handleSelectYear}
                            viewDrop
                            options={yearsSelect}
                        />
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"}>
                        <Text black400>Based on</Text>
                        <div>
                            <List
                                component="nav"
                                aria-label="Device settings"
                                sx={{ bgcolor: 'background.paper' }}
                            >
                                <ListItemButton
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    aria-controls="lock-menu"
                                    aria-label="when device is locked"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickListItem}
                                    sx={{ "&:hover": { background: "none" } }}
                                >
                                    <ListItemText primary={<Text smallBlue1>{filterYear.type == 1 ? "Active Placements" : filterYear.type == 2 ? "Placement Frequency" : "Avg Bill rate"}</Text>} />
                                    {openMenu ? (
                                        <KeyboardArrowUpIcon sx={{ marginLeft: 1, color: "#0C75EB" }} />
                                    ) : (
                                        <KeyboardArrowDownIcon sx={{ marginLeft: 1, color: "#0C75EB" }} />
                                    )}

                                </ListItemButton>
                            </List>
                            <Menu
                                id="lock-menu"
                                anchorEl={anchorElM}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {options
                                    .filter((option) => option !== filterYear.type) // Filter out the matching option
                                    .map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={option === filterYear.type}
                                            onClick={(event) => handleMenuItemClick(event, option)}
                                        >
                                            <Text black14>
                                                {option === 1
                                                    ? "Active Placements"
                                                    : option === 2
                                                        ? "Placement Frequency"
                                                        : "Avg Bill rate"}
                                            </Text>
                                        </MenuItem>
                                    ))}

                            </Menu>
                        </div>
                    </Grid>
                    <Grid item container alignItems='center' lg={12} md={12} sm={12} xs={12} spacing={2} pb={1} columnSpacing={'2px'} sx={{ borderBottom: "1px solid #EAECF0" }}>
                        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}><Text black400>S.No</Text></Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} textAlign='start'><Text black400>Client Name</Text></Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4} textAlign='start'>
                              {filterYear.type == 3 ? <Text black400>Avg Bill Rate</Text> : filterYear.type == 2 ? <Text black400>Placement Feq</Text> :""}
                        </Grid>
                        <Grid item lg={3.5} md={3.5} sm={3.5} xs={3.5} ><Text black400>Placements</Text></Grid>
                    </Grid>
                    {
                        companies.length == 0 ?
                            <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                                {NoDataFound('')}
                            </Grid> :
                            <Grid item container alignItems='center' lg={12} md={12} sm={12} xs={12} spacing={2} mt={'0px'} sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                                {
                                    companies.length > 0 ? companies.map((item, index) => (
                                        <>
                                            <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}><Text black400>{index + 1}</Text></Grid>
                                            <Grid item lg={3} md={3} sm={3} xs={3} textAlign='start'>
                                                <Text largeBlack sx={{fontWeight:`${500} !important`}}>
                                                    {
                                                        item.name ? item.name.length > 12 ?
                                                            <BlackToolTip title={capitalizeAndAddSpace(item.name)} placement="top" arrow>
                                                                {capitalizeAndAddSpace(item.name.slice(0, 12)) + capitalizeAndAddSpace(item.name.length > 12 ? "..." : "")}
                                                            </BlackToolTip>
                                                            : capitalizeAndAddSpace(item.name) : '--'
                                                    }
                                                </Text>
                                            </Grid>
                                            {/* <Grid item lg={4} md={4} sm={4} xs={4} justifyContent='center'>
                                                <BorderLinearProgress variant="determinate" value={item.percentage} barColor={linearProgress[index].bgColor} bgColor={linearProgress[index].barColor} />
                                            </Grid> */}
                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='start'>
                                                 {filterYear.type == 3 ? <Text largeBlack sx={{fontWeight:`${500} !important`}}>{LocalStorage.getCurrencySymbol()}&nbsp;{item.avg_bill_rate}</Text> : filterYear.type == 2 ? <Text largeBlack>{item.placement_frequency_percentage}%</Text> :""}
                                            </Grid>
                                            <Grid item lg={3.5} md={3.5} sm={3.5} xs={3.5} textAlign='start'>
                                                <AvatarGroup total={item.placement_count} renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                                    sx={{
                                                        justifyContent: 'flex-end',
                                                        "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                            color: '#0C75EB !important',
                                                            backgroundColor: '#D1E1FF !important',
                                                            font: '12px Quicksand !important',
                                                            fontWeight: `${600} !important`,
                                                        },
                                                        "& .MuiAvatar-root": {
                                                            position: "static !important",
                                                            border: "none !important",
                                                            color: '#0C75EB !important',
                                                            backgroundColor: '#D1E1FF !important',
                                                            font: '12px Quicksand !important',
                                                            fontWeight: `${600} !important`,
                                                            marginLeft: {
                                                                xs: '-25px',
                                                                sm: '-18px',
                                                                md: '-18px',
                                                                lg: '-18px',
                                                            },
                                                            // marginX: '-10px'
                                                        },
                                                        "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                            color: '#0C75EB !important',
                                                            backgroundColor: '#D1E1FF !important',
                                                            font: '12px Quicksand !important',
                                                            fontWeight: `${600} !important`,
                                                            // marginX: '-10px'
                                                        }
                                                    }}
                                                >
                                                    {item && item.employees && item.employees.map((avt, avtIndex) =>
                                                        <Avatar alt={`Avatar ${avtIndex + 1}`} src={avt ? avt.profile_picture_url : ''} sx={{ width: "35px", height: "35px" }} />)}
                                                </AvatarGroup>
                                            </Grid>
                                        </>
                                    )) : ''
                                }
                            </Grid>
                    }

                    {/* {
                        companies.length > 1 &&
                        <Grid item container lg={10} md={10} sm={10} xs={10} pt={3} justifyContent='end'>
                            <Box minWidth='55px' textAlign='end'>
                                <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/clients')}>View All</Text>
                            </Box>
                        </Grid>
                    } */}
                </Grid>
            </Grid>
            <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />
            <>  <Grid id="additionattrition" container>
                <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} alignItems='center'>
                    <Grid item lg={enableDate ? 8 : 10} md={enableDate ? 8 : 9} sm={8} xs={6}>
                        <Text smallLabelblack16>Addition & Attrition Rate</Text>
                    </Grid>
                    {
                        enableDate ?
                            <Grid item lg={enableDate ? 4 : 2} md={enableDate ? 4 : 3} sm={3} xs={8} textAlign={"-webkit-right"} >
                                <Box mt={{ xs: 1 }} className={classes.choosePeriod}>
                                    <Text offBoardBodyFont>{moment(yearDropdwn.placement_start_date, "YYYY-MM-DD").format(dateFormat())} - {moment(yearDropdwn.placement_end_date, "YYYY-MM-DD").format(dateFormat())}</Text>
                                    <img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => { clearDate() }} />
                                </Box>
                            </Grid> :
                            <Grid item lg={2} md={3} sm={4} xs={6}>
                                <CustomSelect
                                    name='placementYear'
                                    value={yearDropdwn.placementYear}
                                    viewDrop
                                    scrollTrue={true}
                                    options={years}
                                    onChange={(e) => dropDownChangeHandler(e, '')}
                                    labelId={'simple-select-filled-label'}
                                    onClick={(event) => setAnchorEl(event.currentTarget)}
                                />
                                <Menu
                                    id="simple-select-filled-label"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => handleClose('financial')}
                                    MenuListProps={{
                                        'aria-labelledby': 'simple-select-filled-label',
                                    }}
                                    style={{ width: '100%' }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem disableRipple={true} sx={{ backgroundColor: 'transparent !important' }}>
                                        <Grid item xs={12}>
                                            <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                <Box>
                                                    <Date
                                                        labelText="Start Date"
                                                        name="placement_start_date"
                                                        value={yearDropdwn.placement_start_date}
                                                        onChange={(value) => dateChange(value, 'placement_start_date')}
                                                        height="45px"
                                                    />
                                                </Box>
                                                <Box>-</Box>
                                                <Box>
                                                    <Date
                                                        labelText="End Date"
                                                        name="placement_end_date"
                                                        value={yearDropdwn.placement_end_date}
                                                        minDate={moment(yearDropdwn.placement_start_date).add(1, 'days').format(dateFormat())}
                                                        onChange={(value) => dateChange(value, 'placement_end_date')}
                                                        height="45px"
                                                    />
                                                </Box>
                                            </Box>
                                            <Grid item xs={12}>
                                                <Box my={2}>
                                                    <Divider />
                                                </Box>
                                                <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
                                                    <Button payrollHistoryCancel onClick={() => handleClose()}>Cancel</Button>
                                                    <Button blueButton onClick={() => applyFilter()}>Apply Filter</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Menu>
                            </Grid>
                    }
                </Grid>

                <Grid item lg={12} xs={12} spacing={2} pt={2} id="additionattrition">
                    {load ?
                        <Box sx={{
                            height: '30vh',
                            overflow: 'auto',
                            padding: '16px',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                            <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <img src={blueLoader} height={100} width={100} alt='loading' />
                            </Stack>
                        </Box>
                        : <ReactApexChart
                            options={areaOptions}
                            series={areaOptions.series}
                            type="area"
                            height='300px'
                        />
                    }
                </Grid>
            </Grid>
                <Grid item container lg={12} xs={12}>
                    <Grid item container lg={6} md={12} sm={12} xs={12} className={classes.borderRight}>
                        <Grid item lg={12} xs={12}>
                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                <Box className={classes.greenDot} />
                                <Text overViewLable>Placement Added</Text>
                            </Box>
                        </Grid>
                        <Grid item lg={12} xs={12} py={1}>
                            <Text largeblack40>{placementDetails?.total_placements_added}</Text>
                        </Grid>
                        <Grid item container lg={12} xs={12}>
                            {
                                placementsAdded?.length > 0 && placementsAdded?.map((item) => (
                                    <Grid item container lg={12} xs={12} py='6px'>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Text smallBlack400>{item.name}</Text>
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                        <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                            <Text black12>{item.count}</Text>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item container lg={6} className={classes.paddingLeft}>
                        <Grid item lg={12} xs={12}>
                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                <Box className={classes.yellowDot} />
                                <Text overViewLable>Placement Completed</Text>
                            </Box>
                        </Grid>
                        <Grid item lg={12} xs={12} py={1}>
                            <Text largeblack40>{placementDetails?.total_placements_completed}</Text>
                        </Grid>
                        <Grid item container lg={12} xs={12}>
                            {
                                placementsCompleted?.map((item) => (
                                    <Grid item container lg={12} xs={12} py='6px'>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Text smallBlack400>{item.name}</Text>
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                        <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                            <Text black12>{item.count}</Text>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent='end'>
                        <Box minWidth='55px' textAlign='end'>
                            <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/placements')}>View All</Text>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />
            </>
        </>

    )
}
export default EmployeeAndClientAttribution