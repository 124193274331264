import { Box, Grid } from '@mui/material'
import React from 'react'
import Button from '../../../components/customButton/Button';
import { useState } from 'react'; // eslint-disable-next-line
import { addErrorMsg, addSuccessMsg, capitalize } from '../../../utils/utils';
import { isValid, validate_charWithSpace, validate_charWithSpaceHyphen } from '../../../components/Validation';
import Text from '../../../components/customText/Text';
import LocalStorage from "../../../utils/LocalStorage";
import AddClientEndClientApi from '../../../apis/admin/placements/AddClientEndClientApi';
import Input from '../../../components/input/Input';
import LoadingButton from '../../../components/customButton/LoadingButton';

function AddRecruiter(props) {
    const [value, setValue] = useState({
        request_id: LocalStorage.uid(),
        id: "",
        first_name: "",
        last_name: "",
        role_name: "",
        is_active: 1,
    });
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState({});

    const changes = (e) => {
        setValue({
            ...value,
            [e.target.name]: capitalize(e.target.value)
        }, handleValidate(e))
    };
    const handleClose = () => {
        props.closePopup(false);
        props.recruiterDropdown();
    }

    const handleSubmit = () => {
        let errors = finalValidations();
        if (isValid(errors)) {
            createJobTitle();
        } else {
            let s1 = { error };
            s1 = errors;
            setError(s1);
        }
    }

    const createJobTitle = () => {
        setLoading(true);
        AddClientEndClientApi.addRecruiter(value).then((response) => {
            if (response.data.statusCode == 1003) {
                handleClose();
                addSuccessMsg(response.data.message);
                setLoading(false);
            } else {
                addErrorMsg(response.data.message);
                setLoading(false);
            }
        });
    };

    const handleValidate = (e) => {
        let input = e.target;
        let s1 = { ...error };
        switch (input.name || input.tagName) {
            case "first_name":
                error.first_name = validate_charWithSpaceHyphen(input.value, 'first');
                break;
            case "last_name":
                error.last_name = validate_charWithSpaceHyphen(input.value, 'last');
                break;
            case "role_name":
                error.role_name = validate_charWithSpace(input.value, 'role');
                break;
            default:
                break;
        }
        setError(s1);
    };

    const finalValidations = () => {
        let { first_name, last_name, role_name } = value;
        let errors = {};
        errors.first_name = validate_charWithSpaceHyphen(first_name, 'first');
        errors.last_name = validate_charWithSpaceHyphen(last_name, 'last');
        errors.role_name = validate_charWithSpace(role_name, 'role');
        return errors;
    };

    return (
        <Grid container spacing={3} p={'0px 10px'}>
            <Grid item lg={12}>
                <Text largeBlue>Add Recruiter</Text>
            </Grid>
            <Grid item lg={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'first_name',
                        value: value.first_name,
                        inputProps: { maxLength: 40 }
                    }}
                    clientInput
                    handleChange={changes}
                    error={error.first_name}
                    labelText='First Name'
                />
                <Text errorText> {error.first_name ? error.first_name : ""}</Text>
            </Grid>
            <Grid item lg={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'last_name',
                        value: value.last_name,
                        inputProps: { maxLength: 40 }
                    }}
                    clientInput
                    handleChange={changes}
                    error={error.last_name}
                    labelText='Last Name'
                />
                <Text errorText> {error.last_name ? error.last_name : ""}</Text>
            </Grid>
            <Grid item lg={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'role_name',
                        value: value.role_name,
                        inputProps: { maxLength: 33 }
                    }}
                    clientInput
                    handleChange={changes}
                    error={error.role_name}
                    labelText='Role'
                />
                <Text errorText> {error.role_name ? error.role_name : ""}</Text>
            </Grid>
            <Grid item lg={12}>
                <Box display='flex' flexDirection='row' gap={2}>
                    <Button blackCancel100 onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={loading} blueButton onClick={handleSubmit}>Save</LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddRecruiter

