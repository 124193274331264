import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../components/customText/Text';
import ArrowLeft from '../../../assets/svg/fi_arrow-left.svg';
// import info from '../../../assets/svg/fi_info.svg'
import AITimesheetStyles from './AITimesheetStyles';
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../../../utils/LocalStorage'; // eslint-disable-next-line
import { addErrorMsg, addLoader, addSuccessMsg, removeLoader } from '../../../utils/utils';
import AnalyticsApiTimesheet from '../../../apis/admin/timesheets/AnalyticsApiTimesheet';
import { domain } from '../../../config/Domain';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import LoadingScreen from '../../employee/profile/documents/LoadingScreen';
import logo from '../../../assets/svg/dashboard/New_Logo_Black.png';

function AITimesheet() {
    // const [active, setActive] = useState(1);
    const classes = AITimesheetStyles();
    const navigate = useNavigate();
    const [activePlan, setActivePlan] = useState({
        ai_ts_payment_slug: '',
        prompt_payment_slug: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPlanCheck();// eslint-disable-next-line
    }, [])

    const getPlanCheck = () => {
        // addLoader(true);
        setLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setLoading(false);
                setActivePlan(res.data)
            } else {
                setLoading(false);
                removeLoader();
            }
        })
    }

    // eslint-disable-next-line
    const upgradePlan = (args) => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            plan_slug: args
        }
        AnalyticsApiTimesheet.buyPlan(data).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                if (res.data.data.redirection == true) {
                    window.open(res.data.data.payment_url);
                }
                else if (res.data?.data?.redirection == false) {
                    navigate('/plan-status')
                }
                else if (res.data.data.payment_processing == true && res.data.data.redirection == false) {
                    navigate('/myProfile');
                } else {
                    navigate('/plan-upgrade');
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const upgradeMivi = (args) => {

        let data = {
            request_id: LocalStorage.uid(),
            plan_slug: args
        }
        addLoader(true);
        AnalyticsApiTimesheet.upgradeMIVIPlan(data).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                // addSuccessMsg(res.data.message)
                if (res.data?.data?.redirection == true) {
                    window.open(res.data.data.payment_url);
                }
                else if (res.data?.data?.redirection == false) {
                    navigate('/plan-status')
                } 
                else if (res.data?.data?.payment_processing == true && res.data?.data?.redirection == false) {
                    navigate('/myProfile');
                } else {
                    setTimeout(()=>{
                        getPlanCheck();
                    },500)
                    navigate('/plan-upgrade');
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <Grid container lg={12} md={12} sm={11.5} xs={12} className={classes.paddingLeft} justifyContent='center'>
            {
                loading ? <LoadingScreen /> :
                    <Grid item container lg={10} md={10} sm={12} xs={12} mt={2}>
                        <Grid item xs={12} mb={1}>
                            <img src={logo} alt='logo' style={{ height: '51px', width: '173px' }} />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} justifyContent='center' my={2}>
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => navigate('/myprofile',{state:{redirection: true}})}></img>
                                <Text blackHeader600 sx={{paddingLeft: '10px'}} >MIVI AI Prompt</Text>
                            </Box>
                        </Grid>
                        {/* <Grid item container lg={12} md={12} sm={12} xs={12} pt={3}>
                            <Grid item lg={6} md={6} sm={6} xs={6} onClick={() => tabHandler(1)}>
                                <Box sx={{
                                    color: active == 1 ? '#fff' : '#181A18',
                                    background: active == 1 ? '#0C75EB' : '#F4F8FF',
                                }} className={classes.Active}>
                                    AI Timesheet
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} onClick={() => tabHandler(2)}>
                                <Box className={classes.Active} sx={{
                                    color: active == 2 ? '#fff' : '#181A18',
                                    background: active == 2 ? '#0C75EB' : '#F4F8FF',
                                    borderRadius: active == 2 ? '0px 8px 8px 0px' : '8px 0px 0px 8px'
                                }}>
                                    MIVI AI Prompt
                                </Box>
                            </Grid>
                        </Grid>
                        {
                            active == 1 ?
                                <Grid item container md={12} sm={12} xs={12} pt={3}>
                                    <Box display='flex' flexDirection='row' gap={2} p={'10px 0px'}>
                                        <img src={info} alt='info' />
                                        <Text largeLabel>AI timesheets recur on a monthly basis.</Text>
                                    </Box>
                                </Grid> : ''
                        } */}
                        {/* {
                            active == 1 ?
                                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} pt={5} spacing={2} pb={3}>
                                    <Grid item container xl={4} lg={4} md={4} sm={4} xs={12}>
                                        <Box className={activePlan.ai_ts_payment_slug == 'ai-timesheet-basic' ? classes.boxContainerActive : classes.boxContainer}>
                                            <Grid container xl={12}>
                                                <Grid item xl={9} lg={9} md={8} sm={8} xs={9} pl={2}>
                                                    <Text largeBlack28>AI Timesheet</Text>
                                                </Grid>
                                                <Grid item xl={3} lg={3} md={3} sm={3} xs={3} textAlign='end'>
                                                    <Box className={classes.basic}>
                                                        Basic
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={3}>
                                                    <Text veryLargeBlue>$ 4.99</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={2}>
                                                    <Text mediumBoldBlack600>Per month</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center'>
                                                    <Text smallnavyBlue>Get 150 additional AI timesheets each<br /> month. Ideal for small teams needing<br /> extra capacity.</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} my={4}>
                                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                        <Box className={classes.greyDot} />
                                                        <Text mediumBoldBlack700>Credits 150</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} xs={12} className={activePlan.ai_ts_payment_slug == 'ai-timesheet-basic' ? classes.blueButton : classes.greyButton} onClick={() => upgradePlan('ai-timesheet-basic')}>
                                                    Buy Now
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item container xl={4} lg={4} md={4} sm={4} xs={12}>
                                        <Box className={activePlan.ai_ts_payment_slug == 'ai-timesheet-pro' ? classes.boxContainerActive : classes.boxContainer}>
                                            <Grid container xl={12}>
                                                <Grid item xl={9} lg={9} md={8} sm={8} xs={9} pl={2}>
                                                    <Text largeBlack28>AI Timesheet</Text>
                                                </Grid>
                                                <Grid item xl={3} lg={3} md={3} sm={3} xs={3} textAlign='end'>
                                                    <Box className={classes.pro}>
                                                        Pro
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={3}>
                                                    <Text veryLargeBlue>$ 7.99</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={2}>
                                                    <Text mediumBoldBlack600>Per month</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center'>
                                                    <Text smallnavyBlue>Get 250 additional AI timesheets each<br /> month. Perfect for growing teams with<br /> increased needs.</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} my={4}>
                                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                        <Box className={classes.greyDot} />
                                                        <Text mediumBoldBlack700>Credits 250</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} xs={12} className={activePlan.ai_ts_payment_slug == 'ai-timesheet-pro' ? classes.blueButton : classes.greyButton} onClick={() => upgradePlan('ai-timesheet-pro')}>
                                                    Buy Now
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item container xl={4} lg={4} md={4} sm={4} xs={12}>
                                        <Box className={activePlan.ai_ts_payment_slug == 'ai-timesheet-elite' ? classes.boxContainerActive : classes.boxContainer}>
                                            <Grid container xl={12}>
                                                <Grid item lg={9} md={8} sm={8} xs={9} pl={2}>
                                                    <Text largeBlack28>AI Timesheet</Text>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3} textAlign='end'>
                                                    <Box className={classes.elite}>
                                                        Elite
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={3}>
                                                    <Text veryLargeBlue>$ 13.99</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={2}>
                                                    <Text mediumBoldBlack600>Per month</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} textAlign='center' justifyContent='center'>
                                                    <Text smallnavyBlue>Get 500 additional AI timesheets each<br /> month. Best for large teams requiring<br /> maximum capacity.</Text>
                                                </Grid>
                                                <Grid item lg={12} xs={12} my={4}>
                                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                        <Box className={classes.greyDot} />
                                                        <Text mediumBoldBlack700>Credits 500</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} xs={12} className={activePlan.ai_ts_payment_slug == 'ai-timesheet-elite' ? classes.blueButton : classes.greyButton} onClick={() => upgradePlan('ai-timesheet-elite')}>
                                                    Buy Now
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={"center"} pt={4}>
                                        <Text mediumBoldGrey>For customized plans contact <a href={'mailto:Support@japfu.com'} style={{ color: "#0C75EB", cursor: "pointer", paddingLeft: '3px' }}>sales@japfu.ai</a></Text>
                                    </Grid>
                                </Grid> : */}
                        <Grid container lg={12} md={12} sm={12} xs={12} pt={7} spacing={{lg:2,md:2,sm:4,xs:5}} pb={3}>
                            <Grid item container xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box className={activePlan.prompt_payment_slug == 'mivi-prompt-basic' ? classes.boxContainerActive : classes.boxContainer}>
                                    <Grid container xl={12}>
                                        <Grid item container lg={12} xs={12}>
                                            <Grid item lg={9} md={8} sm={8} xs={9} pl={2}>
                                                <Text largeBlack28>MIVI AI Prompt</Text>
                                            </Grid>
                                            <Grid item lg={3} md={3} sm={3} xs={3} textAlign='end'>
                                                <Box className={classes.basicMivi}>
                                                    Basic
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={3}>
                                            <Text veryLargeBlue>$ 15.00</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={2}>
                                            <Text mediumBoldBlack600>One Time Payment</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center'>
                                            <Text smallnavyBlue>Get 150 additional Mivi AI prompts. Ideal<br /> for small teams seeking extra AI<br /> interactions.</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} my={4}>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                <Box className={classes.greyDot} />
                                                <Text mediumBoldBlack700>Credits 150</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} xs={12} className={activePlan.prompt_payment_slug == 'mivi-prompt-basic' ? classes.blueButton : classes.greyButton} onClick={() => { upgradeMivi('mivi-prompt-basic') }}>
                                            Buy Now
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                            <Grid item container xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box className={activePlan.prompt_payment_slug == 'mivi-prompt-pro' ? classes.boxContainerActive : classes.boxContainer}>
                                    <Grid container xl={12}>
                                        <Grid item lg={9} md={8} sm={8} xs={9} pl={2}>
                                            <Text largeBlack28>MIVI AI Prompt</Text>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} textAlign='end'>
                                            <Box className={classes.proMivi}>
                                                Pro
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={3}>
                                            <Text veryLargeBlue>$ 22.99</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={2}>
                                            <Text mediumBoldBlack600>One Time Payment</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center'>
                                            <Text smallnavyBlue>Get 250 additional Mivi AI prompts.<br /> Perfect for growing teams needing more<br /> AI engagement.</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} my={4}>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                <Box className={classes.greyDot} />
                                                <Text mediumBoldBlack700>Credits 250</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} xs={12} className={activePlan.prompt_payment_slug == 'mivi-prompt-pro' ? classes.blueButton : classes.greyButton} onClick={() => { upgradeMivi('mivi-prompt-pro') }}>
                                            Buy Now
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item container xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box className={activePlan.prompt_payment_slug == 'mivi-prompt-elite' ? classes.boxContainerActive : classes.boxContainer}>
                                    <Grid container xl={12}>
                                        <Grid item lg={9} md={8} sm={8} xs={9} pl={2}>
                                            <Text largeBlack28>MIVI AI Prompt</Text>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} textAlign='end'>
                                            <Box className={classes.eliteMivi}>
                                                Elite
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={3}>
                                            <Text veryLargeBlue>$ 40.99</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center' my={2}>
                                            <Text mediumBoldBlack600>One Time Payment</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} textAlign='center' justifyContent='center'>
                                            <Text smallnavyBlue>Get 500 additional Mivi AI prompts. Best<br /> for large teams requiring extensive AI<br /> use.</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} my={4}>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                                <Box className={classes.greyDot} />
                                                <Text mediumBoldBlack700>Credits 500</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} xs={12} className={activePlan.prompt_payment_slug == 'mivi-prompt-elite' ? classes.blueButton : classes.greyButton} onClick={() => { upgradeMivi('mivi-prompt-elite') }}>
                                            Buy Now
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {/* <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={"center"} pt={4}>
                                <Text largeLabel>For customized plans contact <a href={'mailto:Support@japfu.com'} style={{ color: "#0C75EB", cursor: "pointer", paddingLeft: '3px' }}>sales@japfu.ai</a></Text>
                            </Grid> */}
                        </Grid>
                        {/* } */}
                    </Grid>
            }
        </Grid>
    )
}

export default AITimesheet