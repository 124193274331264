import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class GeneratePayrollApi {

    getAllEmployees(data,payrollConfigId) {   
            data.request_id = LocalStorage.uid();
            data.payroll_configuration_id=payrollConfigId;
        return BaseApi.postWithToken(APIURL.org_URL+`payroll/listing`,data,LocalStorage.getAccessToken());
    }

    getPayrollConfigId(){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll-config/payroll-cycles/index?request_id=${LocalStorage.uid()}`,LocalStorage.getAccessToken())
    }
    

    AddComment(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL+`payroll/comment`,data,LocalStorage.getAccessToken());
    }

    UpdateStandardpay(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.putWithToken(APIURL.org_URL+`payroll/update-standard-pay`,data,LocalStorage.getAccessToken());
    }

    DeductionTypeDropdown(){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll-config/expense-management/deduction/dropdown?request_id=${LocalStorage.uid()}`,LocalStorage.getAccessToken());
    }

    ReimbursementTypeDropdown(){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll-config/expense-management/reimbursement/dropdown?request_id=${LocalStorage.uid()}`,LocalStorage.getAccessToken());
    }

    ReimbursementPayPeriodDropdown(){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll-config/pay-period/dropdown?request_id=${LocalStorage.uid()}`,LocalStorage.getAccessToken());
    }

    Comments(data){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll/comments?request_id=${LocalStorage.uid()}&payroll_payment_details_id=${data}`,LocalStorage.getAccessToken());
    }

    Timesheet(data,empId){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll/timesheet-summary?request_id=${LocalStorage.uid()}&payroll_configuration_id=${data}&employee_id=${empId}`,LocalStorage.getAccessToken());
    }

    GetBalanceSheet(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL+ `balance-sheet/employee-transactions`,data,LocalStorage.getAccessToken())
    }

    GetReimbursementDeductions(data,empId){
        return BaseApi.getWithParams(APIURL.org_URL+`payroll/expense-summary?request_id=${LocalStorage.uid()}&payroll_configuration_id=${data}&employee_id=${empId}`,LocalStorage.getAccessToken());
    }

    exportGeneratepayroll(data,payrollConfigId){
        data.request_id = LocalStorage.uid();
        data.payroll_configuration_id=payrollConfigId;
        return BaseApi.postDownloadExcel(APIURL.org_URL+`payroll/export`,data,LocalStorage.getAccessToken());
    }

    AddDeductionOrReimbursmentType(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL + `payroll-config/expense-management/store`, data, LocalStorage.getAccessToken());
    }

    SaveDeductionOrReimbursement(data){
        data.request_id = LocalStorage.uid();

        return BaseApi.postWithToken(APIURL.org_URL + `expense-management/store`, data, LocalStorage.getAccessToken());
    }
    
    ExportBalanceSheet(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.postDownloadExcel(APIURL.org_URL+`balance-sheet/export`,data,LocalStorage.getAccessToken());
    }
    
    UpdateReimbursemntOrDeductions(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.putWithToken(APIURL.org_URL+`expense-management/update-status`,data,LocalStorage.getAccessToken())
    }

    FinalizeEmployeePayroll(data,id){
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL+`payroll/finalize/${id}`,data,LocalStorage.getAccessToken())
    }

    SubmitEmployeePayroll(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL+`payroll/submit`,data,LocalStorage.getAccessToken())
    }

    GetBalanceSheetCurrent(data){
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL+ `balance-sheet/employee-current-transactions`,data,LocalStorage.getAccessToken())
    }
    
}
// eslint-disable-next-line
export default new GeneratePayrollApi();

