import React, { useState, useRef, useEffect } from 'react'
import { Box, Grid, Skeleton, Slide, Stack } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import CustomMenu from '../../configComponents/customMenu/Menu'
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg'
import Button from '../../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Input from '../../../../../components/input/Input';
import { isValid, validate_usContact, onCharactersOnlyChange, validate_emptyField, validates_emailId, gender_validations, validate_charWithSpaceHyphen, validate_charWithSpaceHyphen_optional } from "../../../../../components/Validation";
import InternalEmployeeApi from '../../../../../apis/configurations/employee/InternalEmployeeApi';
import { BlackToolTip, NoDataFound, NoPermission, addErrorMsg, addWarningMsg, capitalize } from '../../../../../utils/utils';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalStorage from "../../../../../utils/LocalStorage";
import { addSuccessMsg } from '../../../../../utils/utils';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import genderList from '../../../../../utils/jsons/Gender.json';
import Select from '../../../../../components/select/Select';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import cancelImg from '../../../../../assets/svg/OffBoardPop.svg';
import transferImg from "../../../../../assets/svg/transferImg.svg"
import CommonApi from '../../../../../apis/CommonApi';
import transferEmployee from "../../../../../assets/svg/transferEmployee.svg"
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import deleteIcon from '../../../../../assets/employee/deleteImg.svg';
import reassigned from '../../../../../assets/svg/re-assigned.svg';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper ": {
		width: '692px',
		padding: '0px !important',
		borderRadius: "12px",
	},
	"& .MuiDialogContent-root": {
		padding: '0px !important',
	},
	"& .MuiDialogActions-root": {
		padding: '0px !important'
	},
	"& .MuiDialog-container": {
		background: 'rgba(0, 0, 0, 0.55) !important'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} timeout={500} />;
});

function InternalEmployee({ current }) {

	var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

	const classes = MainStyles();
	const navigate = useNavigate();
	const recentSection = useRef(null);
	const [open, setOpen] = useState(false);
	const [transfer, setTransfer] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [isEditable, setIsEditable] = useState(false);
	const [error, setError] = useState({});
	const [getData, setGetData] = useState([]);
	const [drop, setDrop] = useState([]);
	const [loading, setLoading] = useState(true);// eslint-disable-next-line 
	const [activityData, setActivityData] = useState([])
	const innerBoxRef = useRef(null);
	const activityRef = useRef(null);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [roleTransfer, setroleTransfer] = useState(false);
	const [roleTransfer1, setroleTransfer1] = useState(false);
	const [roleTransferId, setroleTransferId] = useState('');
	const [newOpen, setnewOpen] = useState(false);
	const [deleteData, setDeleteData] = useState(null);
	const [employeeName, setemployeeName] = useState('');
	const [employeeId, setemployeeId] = useState('');
	const [cancelPopup3, setCancelPopup3] = useState(false);
	const [enable_adding_internal_users, setEnableAdding] = useState(false);
	const [limitExceed, setLimitExceed] = useState(false);
	const [openRehired, setOpenRehired] = useState(false);
	const [editRole, setEditRole] = useState(false);
	const [state, setState] = useState({
		id: '',
		first_name: '',
		middle_name: '',
		last_name: '',//category name
		role_id: '',
		role_name: '',
		gender: '',
		contact_number: '',
		email_id: '',
		transfer_employee_id: "",
		transfer_employee_name: "",
		gender_description: ''
	});
	const [filter, setFilter] = useState({
		limit: 10,
		page: 1,
		search: ""
	});
	const [activityFilter, setActivityFilter] = useState({
		limit: 10,
		page: 1,
	})
	const [total, setTotal] = useState()
	const [activityTotal, setActivityTotal] = useState();
	const [recentActivityShow, setrecentActivityShow] = useState(false)
	const [chromeAlert, setChromeAlert] = useState(false);

	useEffect((id) => {
		if (!LocalStorage.getStartTour()?.orgconfig) {
			if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) {
				setTimeout(() => {
					getEmployee(filter)
					getRoleDropdown()
					getActivity(activityFilter)
				}, 300)
			}
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const handleBeforeUnload = (event) => {

			if (chromeAlert) {
				event.preventDefault();
				event.returnValue = 'Are you sure you want to leave?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [chromeAlert]);


	const [employeeType, setemployeeType] = useState([])
	const [type, setType] = useState('');
	useEffect(() => {
		if (employeeId != '') {
			CommonApi.InternalEmployeeDetailsdropdown(employeeId).then(
				(response) => {
					if (response.data.statusCode == 1003) {
						setemployeeType(response.data.data)
					}
				}
			)
		}// eslint-disable-next-line
	}, [employeeId])
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const addInternalEmployee = () => {
		if (enable_adding_internal_users) {
			handleClickOpen();
		} else {
			setLimitExceed(true);
		}
	}

	const upgradePlan = () => {
		let planDetails = LocalStorage.getPlanCheckLocal()
		navigate('/billing', {
			state: {
				renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
					((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
						(planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
				renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
				autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
				autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
				autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
			}
		})
	}

	const convertFormat = (e) => {
		const value = e.target.value;
		const name = e.target.name
		const input = value.replace(/\D/g, '').substring(0, 10);

		// Divide numbers in 3 parts :"(123) 456-7890" 
		const first = name == 'contact_number' ? input.substring(0, 3) : input.substring(0, 3);
		const middle = name == 'contact_number' ? input.substring(3, 6) : input.substring(3, 5);
		const last = name == 'contact_number' ? input.substring(6, 10) : input.substring(5, 9);

		if (input.length > (name == 'contact_number' ? 6 : 5)) {
			setState(
				{
					...state,
					[e.target.name]: `${first}-${middle}-${last}`
				}, handleValidations(e));
		}
		else if (input.length > 3) {
			setState(
				{
					...state,
					[e.target.name]: `${first}-${middle}`
				}, handleValidations(e));
		}
		else if (input.length >= 0) {
			setState(
				{
					...state,
					[e.target.name]: input
				}, handleValidations(e));
		}
	}

	const handleChange = (e, args) => {
		if (e.target.name == 'contact_number') {
			convertFormat(e);
		} else if (args == 'transfer_employee_id') {
			let name = employeeType.filter((i) => i.id == e.target.value)
			state['transfer_employee_name'] = name[0].value
			setState({ ...state, [e.target.name]: e.target.value });
			handleValidations(e);
		}
		else if (e.target.name == 'role_id' && newOpen == false) {
			// setroleTransfer(true);
			setState({ ...state, [e.target.name]: e.target.value })
			setemployeeId(state.id);
			setroleTransferId(e.target.value);
			setEditRole(true);
		}
		else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
			setState({
				...state,
				[e.target.name]: capitalize(e.target.value)
			})
			handleValidations(e)
		}
		else {
			setState({ ...state, [e.target.name]: e.target.value })
			handleValidations(e);
		}
	};

	const handleValidations = (e) => {
		let input = e.target;
		let err = error;
		switch (input.name || input.tagName) {
			case "first_name":
				error.first_name = validate_charWithSpaceHyphen(input.value, 'first');
				break;
			case "middle_name":
				error.middle_name = validate_charWithSpaceHyphen_optional(input.value, 'middle ');
				break;
			case "last_name":
				error.last_name = validate_charWithSpaceHyphen(input.value, 'last');
				break;
			case 'gender':
				error.gender = validate_emptyField(input.value)
				break
			case 'email_id':
				if ((input.value != "" || input.value != null)) {
					error.email_id = validates_emailId(input.value)
				} else {
					error.email_id = "Please enter Email ID"
				}
				break
			case 'contact_number':
				if ((input.value != "" || input.value != null) && input.value.length == 12) {
					error.contact_number = validate_usContact(input.value, 'Mobile Number')
				} else {
					error.contact_number = "Please enter Mobile Number"
				}
				break
			case 'role_id':
				error.role_id = validate_emptyField(input.value)
				break
			case 'transfer_employee_id':
				error.transfer_employee_id = validate_emptyField(input.value)
				break
			case 'gender_description':
				error.gender_description = gender_validations(input.value)
				break
			default:
				break;
		}
		setError(err);
	}

	const validateAll = () => {
		let { first_name, last_name, contact_number, email_id, gender, role_id, middle_name, gender_description } = state;
		let errors = {};
		errors.first_name = validate_charWithSpaceHyphen(first_name, 'first');
		errors.last_name = validate_charWithSpaceHyphen(last_name, 'last');
		errors.role_id = validate_emptyField(role_id);
		errors.gender = validate_emptyField(gender);
		errors.email_id = validates_emailId(email_id);
		errors.middle_name = validate_charWithSpaceHyphen_optional(middle_name, 'middle ');
		errors.contact_number = validate_usContact(contact_number, 'Mobile Number');
		errors.gender_description = state.gender == 'Prefer to self-describe' ? gender_validations(gender_description) : '';
		return errors;

	};

	const handleSubmit = () => {
		let errors = validateAll();
		if (isValid(errors)) {
			if (state.id != "") {
				updateEmployee(state.id)
			} else {
				storeInternalEmployee(state)
			}
			// handleDialogClose();
		} else {
			setError(errors);
			setLoading(false)
			addWarningMsg(ErrorMessages.genericMessage);
		}
	}


	const handleClickOpen = () => {
		setnewOpen(true)
		setOpen(true);
		setError({});
		setState({
			id: '',
			first_name: '',
			last_name: '',
			middle_name: '',
			gender: '',
			role_id: '',
			contact_number: '',
			email_id: '',
		})
		setChromeAlert(true);
	};

	const handleDialogClose = () => {
		setOpen(false);
		if (isEditable) {
			setIsEditable(isEditable)
		}
		setIsEditable(false);
		setError({});
		setChromeAlert(false);
		// setOpen(false);

		var dropDown = drop;
		// Find the index of the object with value: 'Super Admin'
		const index = dropDown.findIndex(obj => obj.value === 'Super Admin');
		// If the object exists, remove it using splice
		if (index !== -1) {
			dropDown.splice(index, 1);
		}
		setDrop([...dropDown]);
	};

	const handleEditClick = (data) => {
		if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_edit" && item.is_allowed == true)))) {
			setemployeeName(data.display_name);
			setOpen(true);
			setnewOpen(false);
			setAnchorEl(null);
			setIsEditable(false);
			getIndividualIndex(data.id);
			data['transfer_employee_id'] = ''
			data['transfer_employee_name'] = ''
			setState({
				...data
			});
			setChromeAlert(true);
		}
		else {
			addWarningMsg("You don't have permission to perform this action. Please contact the admin")
		}
	};

	const handleViewClick = (data) => {
		if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true)))) {
			if (data.role_id === 1) {
				var supObj = { id: data.role_id, value: 'Super Admin' }
				var dropDown = drop;
				dropDown.push(supObj);
				setDrop([...dropDown]);
			}
			setOpen(true);
			setAnchorEl(null);
			getIndividualIndex(data.id)
			setIsEditable(true);
			setState({
				...data
			});
		} else {
			addWarningMsg("You don't have permission to perform this action. Please contact the admin")
		}

	}


	const handleSaveAndEdit = () => {
		if (isEditable) {
			setIsEditable(false);
		} else {
			if (editRole && state.id != "") {
				setroleTransfer(true);
				setOpen(false);
			} else {
				handleSubmit();
			}
		}
	}

	const handleDeleteClick = (args) => {
		state.transfer_employee_id = ''
		error.transfer_employee_id = ''
		setState({ ...state })
		setError({ ...error })
		setDeleteData(args.id);
		setemployeeName(args.display_name);
		setType(args.role_name)
		setemployeeId(args.id)
		setDeleteOpen(true);
		// if ((LocalStorage.getUserData().super_admin || (rolePermission!==""&&rolePermission.some(item => item.slug == "employee_delete" && item.is_allowed == true) && rolePermission!==""&&rolePermission.some(item => item.slug == "configuration_employee" && item.is_allowed == true)))) {
		// 	setDeleteData(args.id);
		// 	setDeleteOpen(true);
		// } else {
		// 	addWarningMsg("You don't have permission to perform the action. Please contact the admin")
		// }
	}

	const scrollDown = () => {
		window.scroll({
			top: recentSection.current.scrollIntoView(),
			behavior: 'smooth'
		});
		setrecentActivityShow(false)
	}

	const getEmployee = (args) => {
		// if (filter.limit <= 10) {
		setLoading(true)
		// } else {
		//   setLoading(false)
		// }
		InternalEmployeeApi.getInternalEmployees(args).then((response) => {
			setTimeout(() => {
				if (response.data.statusCode == 1003) {
					setLoading(false)
					setGetData(response.data.data);
					setTotal(response.data.pagination.total);
					setEnableAdding(response.data.enable_adding_internal_users);
				} else {
					setLoading(false);
					addErrorMsg(response.data.message);
				}
			}, 300)
		});
	};

	const getActivity = (args, popup) => {
		// setLoading(true)
		ConfigApi.getInternalActivity(args).then((response) => {
			setTimeout(() => {
				if (response.data.statusCode == 1003) {
					// setLoading(false)
					setActivityData(response.data.data);
					setActivityTotal(response.data.pagination.total)
					if (popup) { }
					else {
						setrecentActivityShow(true)
					}
				}
			}, 300)

		});
	};

	const updateEmployee = (dataId) => {
		let data = { ...state, request_id: LocalStorage.uid() };
		setLoading(true)
		setButtonLoader(true);
		InternalEmployeeApi.updateEmployee(data, dataId).then((response) => {
			setTimeout(() => {
				setLoading(false);
				setButtonLoader(false);
				if (response.data.statusCode == 1003) {
					getEmployee(filter)
					handleDialogClose();
					setnewOpen(false)
					getActivity(activityFilter);
					if (editRole) {
						setOpenRehired(true);
						setroleTransfer1(false);
					} else {
						addSuccessMsg(response.data.message);
					}
				} else {
					addErrorMsg(response.data.message);
					handleDialogClose();
				}
			}, 600)

		});
	};


	const deleteEmployee = (dataId) => {
		setButtonLoader(true)
		let data = { request_id: LocalStorage.uid(), id: employeeId, employee_id: state.transfer_employee_id };
		InternalEmployeeApi.deleteEmployee(data).then((response) => {
			if (response.data.statusCode == 1003) {
				setButtonLoader(false)
				setDeleteOpen(false);
				getEmployee(filter);
				setTransfer(false);
				setCancelPopup3(true);
				getActivity(activityFilter)
				// addSuccessMsg(response.data.message);
			} else {
				setButtonLoader(false)
				setDeleteOpen(false);
				setTransfer(false)
				addErrorMsg(response.data.message);
			}
		});
	};

	const storeInternalEmployee = (data) => {
		data.request_id = LocalStorage.uid()
		setLoading(true)
		InternalEmployeeApi.postEmployee(data).then((response) => {
			setTimeout(() => {
				setLoading(false)
				if (response.data.statusCode == 1003) {
					getEmployee(filter);
					setnewOpen(false);
					handleDialogClose();
					getActivity(activityFilter)
					addSuccessMsg(response.data.message);
				}
				else {

					addErrorMsg(response.data.message);
				}
			}, 600)

		});
	};

	const getRoleDropdown = () => {
		let search = "";
		InternalEmployeeApi.getRoleDropDown(search).then((response) => {
			if (response.data.statusCode == 1003) {
				setDrop(response.data.data)
			}
		});
	};

	const getIndividualIndex = (id) => {
		InternalEmployeeApi.getEmploymentIndex(
			id
		).then((response) => {
			setTimeout(() => {
				// setLoading(false)
				if (response.data.statusCode == 1003) {
					setState(response.data.data)
					// console.log(response.data.data, " 66666666666666");
					// setIndexData(response.data.data);
				} else {
					addErrorMsg(response.data.message);
				}
			}, 400)
		});
	}
	window.addEventListener('auto', () => {
		// console.log('????');
	});


	const handleScroll = () => {
		const { current } = innerBoxRef;
		if (current) {
			const { scrollTop, scrollHeight, clientHeight } = current;
			if (scrollTop + clientHeight >= scrollHeight) {
				// getEmployee({...filter})
				if (total >= filter.limit) {
					setFilter({ ...filter, limit: filter.limit + 10, page: 1 })
					getEmployee({ ...filter, limit: filter.limit + 10, page: 1, });
				}


				// Reached the bottom of the inner box
				// console.log('Reached end of inner box, calling a function...');
				// Call your function here
			}
		}
	};
	const activityHandleScroll = () => {
		const { current } = activityRef;
		if (current) {
			const { scrollTop, scrollHeight, clientHeight } = current;
			if (scrollTop + clientHeight >= scrollHeight) {
				// getEmployee({...filter})
				if (activityTotal >= activityFilter.limit) {
					setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
					getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1, }, true);
				}


				// Reached the bottom of the inner box
				// console.log('Reached end of inner box, calling a function...');
			}
		}
	};

	const getNameofEmployee = () => {
		let find = employeeType.find((i) => { return i.id == state.transfer_employee_id })
		return find ? find.value : ''
	}

	const getRoleName = (args) => {
		let find = drop.find((i) => { return i.id == roleTransferId })
		return find ? find.value : ''
	}

	const HandleScrolling = (e) => {
		const scrollTop = e.target.scrollTop;
		if (scrollTop >= 140) {
			setrecentActivityShow(false);
		}
	};

	return (
		<div>
			{LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
				(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_view" && item.is_allowed == true))) ?
					<Box onScroll={HandleScrolling} sx={{
						height: '70vh',
						overflow: 'auto',
						padding: '16px',
						"@media (min-width: 100px) and (max-width: 499px)": {
							padding: "10px 3px !important",
						},
					}}>

						<Box className={classes.activeItemBox}>
							<Box className={classes.activeBoxHeading}>
								<Grid item my={3} lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
									<Text blackHeader600>{current}</Text>
								</Grid>
							</Box>

							<Box sx={{
								height: '35vh',
								overflow: 'auto',
								// padding: '16px',
								// '&::-webkit-scrollbar': {
								//     display: 'none',
								// },
							}}
								ref={innerBoxRef}
								onScroll={handleScroll}
							// onScroll={handleInnerBoxScroll}
							>
								{loading ? <>
									{[1, 2].map((item, index) => (
										<Grid spacing={2} container key={index} mt={3}>
											<Grid item lg={3} md={3} sm={3} xs={12}>
												<Skeleton animation="wave" />
												<Skeleton animation="wave" />
											</Grid>
											<Grid item lg={3} md={3} sm={3} xs={12}>
												<Skeleton animation="wave" />
												<Skeleton animation="wave" />
											</Grid>
											<Grid item lg={3} md={3} sm={3} xs={12}>
												<Skeleton animation="wave" />
												<Skeleton animation="wave" />
											</Grid>
											<Grid item lg={2} md={2} sm={2} xs={12}>
												<Skeleton animation="wave" height={40} />
											</Grid>
											<Grid item lg={1} md={1} sm={1} xs={12}>
												<Skeleton animation="wave" />
											</Grid>
										</Grid>
									))}
								</>
									:
									<>
										{
											getData.length > 0 ?
												getData.map((items) => (
													<Box className={classes.descriptionBoxStyle} key={items.id} ref={innerBoxRef}>
														<Grid container alignItems="center">
															<Grid item lg={4} md={5.5} sm={5.5} xs={12} container direction={'column'} gap={1}>
																<Text mediumBlackColor>
																	<BlackToolTip arrow title={items.display_name}>{items.display_name ? items.display_name.length > 16 ? items.display_name.slice(0, 16) + '...' : items.display_name : "--"}
																	</BlackToolTip>
																</Text>
																<Text greyLabel>{items.role_name ? items.role_name : "--"}</Text>
															</Grid>
															<Grid item lg={6} md={5.5} sm={5.5} xs={12} container direction={'column'} gap={1} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
																<Text mediumBlackColor>Created By</Text>
																<Text greyLabel>
																	{
																		items.created_by && items.created_by.length > 16 ?
																			<BlackToolTip title={items.created_by} placement="top" arrow>
																				{items.created_by.slice(0, 16) + (items.created_by.length > 16 ? "..." : "")}
																			</BlackToolTip>
																			: items.created_by ? items.created_by : '--'
																	}

																</Text>
															</Grid>
															<Grid item lg={1} md={1} sm={1} xs={12} container alignItems={'center'} justifyContent={'center'}>
																<CustomMenu
																	Icon={<MenuIcon />}
																	handleMenuClick={handleMenuClick}
																	anchorEl={anchorEl}
																	isOpen={Boolean(anchorEl)}
																	onClose={handleClose}
																	children={
																		((LocalStorage.getUserData().super_admin && items.is_super_admin == false) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_edit" && item.is_allowed == true)) && items.is_super_admin == false)) ?
																			[{ color: '#171717', label: "View", Click: () => handleViewClick(items) },
																			{ color: '#171717', label: "Edit", Click: () => handleEditClick(items) },
																			((LocalStorage.getUserData().super_admin && items.is_super_admin == false) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_delete" && item.is_allowed == true)) && items.is_super_admin == false))
																			&& { color: '#171717', label: "Delete", Click: () => handleDeleteClick(items) },
																			]
																			:
																			[{ color: '#171717', label: "View", Click: () => handleViewClick(items) }]
																	}
																/>
															</Grid>
														</Grid>
													</Box>
												))
												:
												<Box textAlign='center' p={'0px 20px 0px 20px'}>
													<img src={cancelImg} alt='success' />
													<Text largeLabel700 sx={{ paddingTop: '25px !important' }}>No Internal Employees to Show</Text>
													<Text mediumOverView sx={{ padding: '10px 0px 30px 0px !important' }}>Please add an internal employee by<br /> clicking on the button below</Text>
												</Box>
										}
									</>
								}
							</Box>
							{
								loading ?
									<Skeleton animation="wave" />
									:
									(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "internal_employee_create" && item.is_allowed == true))) ?
										<Button addTitleBtn sx={{ width: '100%' }} onClick={() => addInternalEmployee()}>Add Internal Employee</Button> :
										<Button addTitleBtnDisable>Add Internal Employee</Button>
							}

							<BootstrapDialog
								TransitionComponent={Transition}
								keepMounted
								onClose={handleDialogClose}
								aria-labelledby="customized-dialog-title"
								open={open}
								fullWidth={true}
								maxWidth={"md"}
							>
								<DialogContent>

									<Box padding={{ lg: '38px 30px 35px 30px', md: '38px 30px 35px 30px', sm: '30px', xs: '16px 12px' }}>
										<Box mb={4}>
											<Text mediumViewAmt>Internal Employee</Text>
										</Box>
										<Grid container spacing={3} columnSpacing={'32px'}>
											<Grid item lg={6} md={6} sm={6} xs={12} >
												<Input
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														name: 'first_name',
														value: state.first_name,
														type: 'text',
														inputProps: { maxLength: 40 }
													}}
													handleChange={(e) => handleChange(e)}
													onKeyPress={onCharactersOnlyChange}
													disabled={isEditable || !newOpen}
													clientInput
													labelText={'First Name'}
													error={error.first_name}
												/>
												<Text errorText> {error.first_name ? error.first_name : ""}</Text>
											</Grid>
											<Grid item lg={6} md={6} sm={6} xs={12} >
												<Input
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														name: 'middle_name',
														value: state.middle_name,
														type: 'text',
														disabled: isEditable,
														inputProps: { maxLength: 40 }
													}}
													handleChange={(e) => handleChange(e)}
													disabled={isEditable || !newOpen}
													onKeyPress={onCharactersOnlyChange}
													clientInput
													labelText={'Middle Name (Optional)'}
													error={error.middle_name}
												/>
												<Text errorText> {error.middle_name ? error.middle_name : ""}</Text>
											</Grid>
											<Grid item lg={6} md={6} sm={6} xs={12} >
												<Input
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														name: 'last_name',
														value: state.last_name,
														type: 'text',
														disabled: isEditable,
														inputProps: { maxLength: 40 }
													}}
													disabled={isEditable || !newOpen}
													handleChange={(e) => handleChange(e)}
													onKeyPress={onCharactersOnlyChange}
													clientInput
													labelText={'Last Name'}
													error={error.last_name}
												/>
												<Text errorText> {error.last_name ? error.last_name : ""}</Text>
											</Grid>
											<Grid item lg={6} md={6} sm={6} xs={12}>
												<Select
													name='gender'
													value={state.gender}
													commonSelect
													label={'Gender'}
													disabled={isEditable || !newOpen}
													options={genderList}
													onChange={handleChange}
													error={error.gender}
												/>
												<Text errorText> {error.gender ? error.gender : ""}</Text>
											</Grid>
											{
												state.gender == 'Prefer to self-describe' ?
													<Grid item lg={12} md={12} sm={12} xs={12} py={'4px'}>
														<Input
															formControlProps={{
																fullWidth: true
															}}
															rows={3}
															inputProps={{
																name: 'gender_description',
																value: state.gender_description,
																inputProps: { minLength: 3, maxLength: 50 }
															}}
															handleChange={handleChange}
															clientInput
															labelText={'Gender Description'}
															error={error.gender_description}
														/>
														{
															error.gender_description &&
															<Text red>{error.gender_description ? error.gender_description : ''}</Text>
														}
													</Grid> : ''
											}
											<Grid item lg={6} md={6} sm={6} xs={12} >
												<Input
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														name: 'email_id',
														value: state.email_id,
														type: 'text',
														disabled: isEditable,
													}}
													handleChange={handleChange}
													disabled={isEditable || !newOpen}
													clientInput
													labelText={'Email ID'}
													error={error.email_id}
												/>
												<Text errorText> {error.email_id ? error.email_id : ""}</Text>
											</Grid>
											<Grid item lg={6} md={6} sm={6} xs={12} >
												<Input
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														name: 'contact_number',
														value: state.contact_number,
														type: 'text',
														disabled: isEditable,
														inputProps: { maxLength: 12 }
													}}
													disabled={isEditable}
													handleChange={handleChange}
													clientInput
													labelText={'Mobile Number'}
													error={error.contact_number}
												/>
												<Text errorText> {error.contact_number ? error.contact_number : ""}</Text>
											</Grid>
											<Grid item lg={6} md={6} sm={6} xs={12}>
												<Select
													name='role_id'
													value={state.role_id}
													commonSelect
													label={'Role'}
													options={drop}
													disabled={isEditable}
													onChange={handleChange}
													error={error.role_id}
												/>
												<Text errorText> {error.role_id ? error.role_id : ""}</Text>
											</Grid>
										</Grid>
										{
											!isEditable &&
											<Box display={'flex'} justifyContent={'end'} gap={'20px'} pt={3}>
												<Button cancelOutlineBlue sx={{ width: '130px !important' }} onClick={() => handleDialogClose()} >Cancel</Button>
												{newOpen ?
													<LoadingButton mediumInvite sx={{
														background: '#0C75EB !important', color: 'white !important', width: '130px !important', textTransform: 'capitalize !important', font: "18px Quicksand !important",
														"@media (min-width: 100px) and (max-width: 499px)": {
															fontSize: '14px  !important',
														},
													}} loading={loading} onClick={() => handleSaveAndEdit()}>{newOpen ? loading ? 'Sending' : 'Send Invite' : "Save"}</LoadingButton>
													:
													<LoadingButton saveBtn loading={loading} sx={{ width: '130px !important' }} onClick={() => handleSaveAndEdit()}>{newOpen ? loading ? 'Sending' : 'Send Invite' : "Continue"}</LoadingButton>
												}
											</Box>
										}
									</Box>
								</DialogContent>
							</BootstrapDialog>
							{/* } */}


						</Box>
						<Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
							<Box mb={3}>
								<Text RegularBlack1>Recent Activities</Text>
							</Box>
							<Box sx={{
								height: '40vh', overflowY: 'auto',
								//  '&::-webkit-scrollbar': {
								//   display: 'none',
								// },
							}}
								ref={activityRef}
								onScroll={activityHandleScroll}>
								{loading ?
									<>
										{[1, 2, 3].map((item, index) => (
											<Grid container key={index} mt={3}>
												<Grid item lg={8} md={8} sm={8} xs={12}>
													<Skeleton animation="wave" width="200px" />
													<Skeleton animation="wave" width="200px" />
												</Grid>
												<Grid item lg={3} md={3} sm={3} xs={12}>
													<Skeleton animation="wave" width="200px" />
													<Skeleton animation="wave" width="200px" />
												</Grid>
											</Grid>
										))}
									</> :
									<>
										{activityData.length > 0 &&
											activityData.map((value) => (
												<Box className={classes.descriptionBoxStyle} mb={2}>
													<Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
														<Grid item lg={4} md={4} sm={6} xs={12} direction={'column'} gap={1} >
															<Text mediumBlackColor>
																{
																	value.user_name && value.user_name.length > 16 ?
																		<BlackToolTip title={value.user_name} placement="top" arrow>
																			{value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
																		</BlackToolTip>
																		: value.user_name
																} {value.action}
															</Text>
															<Text greyLabel>{value.created_at}</Text>
														</Grid>
														<Grid item lg={8} md={8} sm={6} xs={12} flexDirection={"column"}>
															{
																value.change_log.length > 0 && value.change_log.map((item) => (
																	<Text sx={{
																		fontWeight: `${400} !important`, font: '14px Quicksand !important',
																		"@media (min-width: 900px) and (max-width: 1400px)": {
																			fontSize: "12px !important",
																			fontWeight: "400 !important"
																		}
																	}} BrowmnMnStepperText className={classes.descriptionText}>{item}.</Text>
																))
															}
														</Grid>
													</Grid>
												</Box>
											))}
									</>
								}
							</Box>
						</Box>
						{roleTransfer && <ReusableDelete delete={true} content={`By re-assigning this employee to another role all the existing responsibilities of the current role must be transferred to another employee`} open={roleTransfer} setOpen={setroleTransfer} onClick={() => { setroleTransfer1(true); setroleTransfer(false) }} />}

						{deleteOpen && <ReusableDelete delete={true} content={"By deleting this internal employee the responsibilities will be transferred. Do you agree?"} open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { setTransfer(true); setDeleteOpen(false) }} />}
						<BootstrapDialog
							TransitionComponent={Transition}
							keepMounted
							aria-labelledby="customized-dialog-title"
							open={cancelPopup3}
							fullWidth={true}
							maxWidth={"md"}
						>
							<DialogContent sx={{ margin: "40px", }}>
								<Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
									<img src={transferEmployee} alt='noactive' />
								</Grid>
								<Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
									<Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Transferred Successfully</Text>
								</Grid>
								<Grid container justifyContent={'center'} alignContent={'center'}>
									<Text offBoardBodyFont>You have successfully deleted <span style={{ color: '#404040', fontWeight: '600', fontSize: '14px' }}>{employeeName}</span> and re-</Text>
								</Grid>
								<Grid container justifyContent={'center'} alignContent={'center'}>
									<Text offBoardBodyFont>assigned his responsibilities to <span style={{ color: '#404040', fontWeight: '600', fontSize: '14px' }}>{getNameofEmployee()}</span>.</Text>
								</Grid>
								<Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
									<Stack direction={"row"} spacing={3}>
										<Button finishFilledQuicksandLargeWidth350 onClick={() => { setCancelPopup3(false) }}>
											Done
										</Button>
									</Stack>
								</Grid>
							</DialogContent>
						</BootstrapDialog>
						<BootstrapDialog
							TransitionComponent={Transition}
							keepMounted
							aria-labelledby="customized-dialog-title"
							open={transfer}
							fullWidth={true}
							maxWidth={"xs"}
						>
							<DialogContent sx={{ margin: "10px", }}>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'} pt={1}>
									<img src={transferImg} alt='noactive' />
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'} pt={2}>
									<Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Transfer Responsibilities</Text>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
									<Text offBoardBodyFont>To delete <span style={{ color: '#404040', fontWeight: '600', fontSize: '14px' }}><BlackToolTip placement="top" arrow title={employeeName}>{employeeName ? employeeName.length > 30 ? employeeName.slice(0, 30) + '...' : employeeName : "--"}</BlackToolTip></span> from {type}</Text>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
									<Text offBoardBodyFont>please select other employee to transfer</Text>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
									<Text offBoardBodyFont>their responsibilities.</Text>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
									<Box p={1} pt={3} width={{ lg: '80%', md: '80%', sm: '80%', xs: '100%' }}>
										<Select
											fullWidth
											name='transfer_employee_id'
											options={employeeType}
											value={state.transfer_employee_id}
											label={'Internal Employee'}
											onChange={handleChange}
											error={error.transfer_employee_id}
										/>
										<Text errorText> {error.transfer_employee_id ? error.transfer_employee_id : ""}</Text>
									</Box>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'} mt={3}>
									<Stack direction={"row"} spacing={3} justifyContent={"center"}>
										<Button smallBlueOutline onClick={() => { setTransfer(false) }}>
											Cancel
										</Button>
										<LoadingButton loading={buttonLoader} smallBlue sx={{ width: '160px !important' }} redBackground onClick={() => {
											if (state.transfer_employee_id == '' || state.transfer_employee_id == null || state.transfer_employee_id == undefined) {
												error.transfer_employee_id = 'This field is required'
												setError({ ...error });
											} else { deleteEmployee(deleteData); }
										}}>
											Transfer
										</LoadingButton>
									</Stack>
								</Grid>
							</DialogContent>
						</BootstrapDialog>
						<BootstrapDialog
							TransitionComponent={Transition}
							keepMounted
							aria-labelledby="customized-dialog-title"
							open={roleTransfer1}
							fullWidth={true}
							maxWidth={"xs"}
						>
							<DialogContent sx={{ margin: "10px", }}>
								<Grid container justifyContent={'center'} alignContent={'center'} >
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'} pt={1}>
										<img src={transferImg} alt='noactive' />
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'} pt={2}>
										<Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Transfer Responsibilities</Text>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
										<Text offBoardBodyFont>In order to re-assign &nbsp;
											<BlackToolTip arrow title={employeeName}>{employeeName ? employeeName.length > 30 ? employeeName.slice(0, 30) + '...' : employeeName : "--"}
											</BlackToolTip>
											&nbsp;from {state.role_name}</Text>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
										<Text offBoardBodyFont>to {getRoleName()} the responsibilities</Text>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
										<Text offBoardBodyFont>of current role needs to be transferred.</Text>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'}>
										<Box p={1} pt={3} width={{ lg: '80%', md: '80%', sm: '80%', xs: '100%' }} >
											<Select
												fullWidth
												name='transfer_employee_id'
												options={employeeType}
												value={state.transfer_employee_id}
												label={'Internal Employee'}
												onChange={(e) => handleChange(e, "transfer_employee_id")}
												error={error.transfer_employee_id}
											/>
											<Text errorText> {error.transfer_employee_id ? error.transfer_employee_id : ""}</Text>
										</Box>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12} textAlign={'-webkit-center'} mt={3}>
										<Stack direction={"row"} spacing={3} justifyContent={"center"}>
											<Button smallBlueOutline onClick={() => { setroleTransfer1(false); setEditRole(false) }}>
												Cancel
											</Button>
											<LoadingButton loading={buttonLoader} smallBlue sx={{ width: '170px !important' }} onClick={() => {
												if (state.transfer_employee_id == '' || state.transfer_employee_id == null || state.transfer_employee_id == undefined) {
													error.transfer_employee_id = 'This field is required'
													setError({ ...error });
												} else {
													// setroleTransfer1(false); setState({
													// 	...state,
													// 	role_id: roleTransferId,
													// 	employee_id: state.transfer_employee_id
													// })											
													handleSubmit();
												}
											}}>
												Transfer & Save
											</LoadingButton>
										</Stack>
									</Grid>
								</Grid>
							</DialogContent>
						</BootstrapDialog>
						{recentActivityShow ?
							<Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
								<Button addButton
									// scrollBtn
									sx={{
										position: 'fixed',
										bottom: 20,
										borderRadius: "30px !important",
										paddingLeft: "20px",
										paddingRight: "20px",
									}}
									onClick={scrollDown}
									endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
							</Grid>
							: ''}
					</Box>
					:
					<>
						{NoPermission()}
					</>
			}
			<ReusablePopup openPopup={limitExceed} setOpenPopup={setLimitExceed} iconHide white fixedWidth>
				<Grid container justifyContent='center'>
					<Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
						<img src={deleteIcon} alt='noactive' />
					</Grid>
					<Grid container justifyContent={'center'} alignContent={'center'} pt={3}>
						<Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Limit Exceeded!</Text>
					</Grid>
					<Grid container justifyContent={'center'} alignContent={'center'} textAlign='center' pt={2}>
						{
							LocalStorage.getUserData().super_admin ?
								<Text offBoardBodyFont>You have exceeded the maximum limit of<br /> adding employees in this plan. Please upgrade<br /> to add more Employees.</Text> :
								<Text offBoardBodyFont>You have exceeded the maximum limit of<br /> adding employees in this plan. Please contact<br /> your super admin to upgrade.</Text>
						}
					</Grid>
					<Grid container lg={11} justifyContent={'center'} textAlign='center' alignContent={'center'} mt={3}>
						{
							LocalStorage.getUserData().super_admin ?
								<Grid item lg={8} container>
									<Grid item lg={6}>
										<Button outlineBlue sx={{ width: '100px !important', height: '42px !important' }} onClick={() => { setLimitExceed(false) }}>Cancel</Button>
									</Grid>
									<Grid item lg={6}>
										<Button sx={{ width: '100px !important' }} blueButton onClick={() => upgradePlan()}>Upgrade</Button>
									</Grid>
								</Grid> :
								<Button blueButton onClick={() => { setLimitExceed(false) }}>
									Cancel
								</Button>
						}
					</Grid>
				</Grid>
			</ReusablePopup>
			<ReusablePopup openPopup={openRehired} setOpenPopup={setOpenRehired} iconHide white fixedWidth>
				<Grid container justifyContent='center'>
					<Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
						<img src={reassigned} alt='noactive' />
					</Grid>
					<Grid container justifyContent={'center'} alignContent={'center'} pt={3}>
						<Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Re-Assigned Successfully</Text>
					</Grid>
					<Grid container justifyContent={'center'} alignContent={'center'} textAlign='center' pt={2}>
						<Text offBoardBodyFont>You have successfully re-assigned&nbsp;
							<BlackToolTip arrow title={employeeName}>{employeeName ? employeeName.length > 30 ? employeeName.slice(0, 30) + '...' : employeeName : "--"}
							</BlackToolTip>
							&nbsp;from {state.role_name} to {getRoleName()} & the responsibilities of previous role were transferred to {state.transfer_employee_name}.</Text>
					</Grid>
					<Grid container lg={11} justifyContent={'center'} textAlign='center' alignContent={'center'} mt={3}>
						<Button blueButton onClick={() => { setOpenRehired(false); setroleTransfer1(false) }}>Done</Button>
					</Grid>
				</Grid>
			</ReusablePopup>
		</div >
	)
}

export default InternalEmployee;
