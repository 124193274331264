import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Checkbox, Skeleton, Slide } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles';
import CustomMenu from '../../configComponents/customMenu/Menu';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg';
import Button from '../../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Input from '../../../../../components/input/Input';
import { ReactComponent as CheckedIcon } from '../../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../../assets/svg/CheckedBorderIcon.svg';
import { isValid, validate_Description, validate_Onboarding_Doc, } from "../../../../../components/Validation";
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import OnboardDocumentsApi from '../../../../../apis/configurations/employee/OnboardDocumentsApi';
import LocalStorage from "../../../../../utils/LocalStorage";
import { addSuccessMsg, addErrorMsg, addWarningMsg, NoDataFound, NoPermission, BlackToolTip, ListLoadingIcon } from '../../../../../utils/utils';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import EditIcon from '../../../../../assets/svg/newEdit.svg';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={200} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper ": {
    // height: '440px',
    width: '692px',
    padding: '0px !important',
    borderRadius: "12px",
  },
  "& .MuiDialogContent-root": {
    padding: '0px !important',
  },
  "& .MuiDialogActions-root": {
    padding: '0px !important'
  },
  "& .MuiDialog-container": {
    background: 'rgba(0, 0, 0, 0.55) !important'
  }
}));

export default function Department({ current }) {
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const classes = MainStyles()
  const recentSection = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState({});
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([])
  const [recentActivityShow, setrecentActivityShow] = useState(false)
  const [state, setState] = useState({
    id: '',
    name: '',
    status: '',
    is_mandatory: false,
    is_editable: true,
    description: '',
  });
  const [listLoading, setListLoading] = useState(false);
  // eslint-disable-next-line
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    search: "",
  });
  const [activityFilter, setActivityFilter] = useState({
    limit: 10,
    page: 1,
  })
  const [activityTotal, setActivityTotal] = useState()
  const innerBoxRef = useRef(null);
  const activityRef = useRef(null);

  const [chromeAlert, setChromeAlert] = useState(false);
  useEffect(() => {
    if (!LocalStorage.getStartTour()?.orgconfig) {
      if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_view" && item.is_allowed == true))) {
        setTimeout(() => {
          getOnboardDocumentsListing(filter)
          getActivity(activityFilter)
        }, 300)
      }
    }// eslint-disable-next-line
  }, [])


  useEffect(() => {
    const handleBeforeUnload = (event) => {

      if (chromeAlert) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chromeAlert]);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    if (e.target.name == 'is_mandatory') {
      setState({ ...state, [e.target.name]: e.target.checked })
    } else {
      setState({ ...state, [e.target.name]: e.target.value })
    }
    handleValidations(e);
  };

  const handleValidations = (e) => {
    let input = e.target;
    let err = error;
    switch (input.name || input.tagName) {
      case "name":
        err.name = validate_Onboarding_Doc(input.value);
        break;
      case "description":
        err.description = validate_Description(input.value);
        break;
      default:
        break;
    }
    setError(err);
  }

  const validateAll = () => {
    let { name, description } = state;
    let errors = {};
    errors.name = validate_Onboarding_Doc(name);
    errors.description = validate_Description(description);
    return errors;

  };

  const handleDialogClose = () => {
    setOpen(false);
    if (isEditable) {
      setIsEditable(isEditable)
    }
    setIsEditable(false);
    setError({});
    setChromeAlert(false);
  };

  const handleEditClick = (data) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_edit" && item.is_allowed == true)))) {
      setOpen(true);
      setAnchorEl(null);
      setIsEditable(false);
      setState({ ...data });
      setChromeAlert(true);
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
    // indexApi(data.id)
  };

  const handleViewClick = (data) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_view" && item.is_allowed == true)))) {
      setOpen(true);
      setAnchorEl(null);
      setIsEditable(true);
      setState({ ...data });
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
    // indexApi(data.id)
  }

  const handleSaveAndEdit = () => {
    if (isEditable) {
      setIsEditable(false);
    } else {
      let errors = validateAll();
      if (isValid(errors)) {
        if (state.id != "") {
          updateOnboardingDocumentApi(state.id)
        }
      } else {
        setError(errors);
        setLoading(false)
        addWarningMsg(ErrorMessages.genericMessage);
      }
    }
  }

  const scrollDown = () => {
    window.scroll({
      top: recentSection.current.scrollIntoView(),
      behavior: 'smooth'
    });
    setrecentActivityShow(false)
  }

  const activityHandleScroll = () => {
    const { current } = activityRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        if (activityTotal >= activityFilter.limit) {
          // addLoader(true);
          setListLoading(true);
          setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
          getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1, });
        }
      }
    }
  };

  // const handleScroll = () => {
  //   const { current } = innerBoxRef;
  //   if (current) {
  //     const { scrollTop, scrollHeight, clientHeight } = current;
  //     if (scrollTop + clientHeight >= scrollHeight) {
  //       if (total >= filter.limit) {
  //         // addLoader(true);
  //         setListLoading(true);
  //         setFilter({ ...filter, limit: filter.limit + 10, page: 1 })
  //         getOnboardDocumentsListing({ ...filter, limit: filter.limit + 10, page: 1, });
  //       }
  //     }
  //   }
  // };

  const getActivity = (args, popup) => {
    if (activityFilter.limit <= 10) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    let id = 31;
    ConfigApi.getActivity(id, "", args).then((response) => {
      setTimeout(() => {
        if (response.data.statusCode == 1003) {
          setLoading(false)
          // console.log(response.data.data, "res")
          setActivityData(response.data.data);
          setActivityTotal(response.data.pagination.total);
          if (popup) { }
          else {
            setrecentActivityShow(true)
          }
        }
      }, 300)
      // removeLoader();
      setListLoading(false);
    });
  };

  const getOnboardDocumentsListing = (args) => {
    if (filter.limit <= 10) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    OnboardDocumentsApi.listingApi(args).then((response) => {
      setLoading(false)
      if (response.data.statusCode == 1003) {
        setGetData(response.data.data);
      }
      else {
        addErrorMsg(response.data.message)
      }
      // removeLoader();
      setListLoading(false);
    });
  };

  const updateOnboardingDocumentApi = (dataId) => {
    let data = { ...state, request_id: LocalStorage.uid() };
    setLoading(true)
    OnboardDocumentsApi.updateApi(data, dataId).then((response) => {
      setLoading(false)
      if (response.data.statusCode == 1003) {
        getOnboardDocumentsListing(filter)
        getActivity(activityFilter)
        handleDialogClose();
        addSuccessMsg(response.data.message);
      } else {
        handleDialogClose();
        addErrorMsg(response.data.message);
      }
    });
  };

  const HandleScrolling = (e) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop >= 150) {
      setrecentActivityShow(false);
    }
  };

  return (
    <div>
      {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_view" && item.is_allowed == true))) ?
          <Box onScroll={HandleScrolling} sx={{
            height: '70vh', overflow: 'auto', padding: '16px',
            "@media (min-width: 100px) and (max-width: 499px)": {
              padding: "10px 3px",
            },
          }}>

            <Box className={classes.activeItemBox}>
              <Box className={classes.activeBoxHeading}>
                <Grid container>
                  <Grid item lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                    <Text profileTitle>{current}</Text>
                  </Grid>
                </Grid>

              </Box>
              <Box sx={{ height: '40vh', overflow: 'auto' }} ref={innerBoxRef} /**onScroll={handleScroll}**/>
                {
                  loading ? <>
                    {[1, 2, 3].map((item, index) => (
                      <Grid spacing={2} container key={index} mt={3}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={12}>
                          <Skeleton animation="wave" height={40} />
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} xs={12}>
                          <Skeleton animation="wave" />
                        </Grid>
                      </Grid>
                    ))}
                  </>
                    : <>
                      {getData.map((item, index) => (
                        <Box className={classes.descriptionBoxStyle} key={index}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item lg={7} md={6} sm={6} xs={12} container direction={'column'} gap={1}>
                              <Text mediumBlackColor > {item?.name ? item?.name.length > 40 ? <BlackToolTip arrow title={item?.name}>{item?.name.slice(0, 22) + '...'}</BlackToolTip> : item?.name : "--"}</Text>
                              <Text greyLabel>{item?.description ? item?.description.length > 40 ? <BlackToolTip arrow title={item?.description}>{item?.description.slice(0, 40) + '...'}</BlackToolTip> : item?.description : "--"} </Text>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={12} container direction={'column'} gap={1} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                              <Text mediumBlackColor>Modified By</Text>
                              <Text greyLabel>
                                {item.updated_by && item.updated_by.length > 16 ?
                                  <BlackToolTip title={item.updated_by} placement="top" arrow>
                                    {item.updated_by.slice(0, 16) + (item.updated_by.length > 16 ? "..." : "")}
                                  </BlackToolTip>
                                  : item.updated_by ? item.updated_by : '--'
                                }

                              </Text>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={12} container alignItems={'center'} justifyContent={'center'}>
                              <CustomMenu
                                Icon={<MenuIcon />}
                                handleMenuClick={handleMenuClick}
                                anchorEl={anchorEl}
                                isOpen={Boolean(anchorEl)}
                                onClose={handleClose}
                                children=
                                {
                                  ((LocalStorage.getUserData().super_admin || ((rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_view" && item.is_allowed == true))))
                                    &&
                                    (LocalStorage.getUserData().super_admin || ((rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_edit" && item.is_allowed == true))))) ?
                                    [{ color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                    (LocalStorage.getUserData().super_admin || ((rolePermission !== "" && rolePermission.some(item => item.slug == "onboarding_document_types_edit" && item.is_allowed == true)))) && { color: '#171717', label: "Edit", Click: () => handleEditClick(item) },
                                    ]
                                    :
                                    [{ color: '#171717', label: "View", Click: () => handleViewClick(item) }]
                                }
                              // {
                              //   item.is_editable ?
                              //     [
                              //       { color: '#171717', label: "View", Click: () => handleViewClick(item) },
                              //       { color: '#171717', label: "Edit", Click: () => handleEditClick(item) }
                              //     ] :
                              //     [
                              //       { color: 'black', label: "View", Click: () => handleViewClick(item) },
                              //     ]}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </>
                }
                {listLoading && (
                  <Box >
                    <ListLoadingIcon />
                  </Box>
                )}
              </Box>

              <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
              >
                <DialogContent >
                  {
                    isEditable ?
                      <Box padding={{ lg: '30px', md: "30px", sm: "28px", xs: "12px" }}>
                        <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                          <Grid item lg={6} md={6} sm={6} xs={9}>
                            <Text mediumViewAmt>Onboarding Documents</Text>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={3} textAlign={"end"}>
                            {state.is_editable ? <img onClick={() => { setIsEditable(false); setChromeAlert(true) }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12} mt={1}>
                            <Text mediumGreyHeader1>Document Name</Text>
                            <Text mediumBlack14 pt={0.8} noWrap>{state.name ? state.name : "--"}</Text>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12} mt={1}>
                            <Text mediumGreyHeader1>Status</Text>
                            <Text mediumBlack14 pt={0.8} noWrap>{state.status ? "Active" : "In Active"}</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Text mediumGreyHeader1>Description (Optional)</Text>
                            <Text mediumBlack14 pt={0.8} className={classes.descriptionText}>{state.description ? state.description : "--"}</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <FormGroup>
                              <FormControlLabel
                                sx={{ borderRadius: "10px !important" }}
                                disabled
                                name='is_mandatory'
                                value={state.is_mandatory}
                                control={<Checkbox sx={{ borderRadius: "10px !important" }} disabled={true} checked={state.is_mandatory} />}
                                label={<Text popupHead2 sx={{
                                  font: '14px Quicksand !important', color: "#262626 !important",
                                  "@media (min-width: 100px) and (max-width: 499px)": {
                                    font: '10px Quicksand !important',
                                  },
                                }}>Make This Document Mandatory.</Text>}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Box>
                      :
                      <Box padding={{ lg: '38px 30px 35px 30px', md: '38px 30px 35px 30px', sm: '30px', xs: '16px 12px' }}>
                        <Box mb={4}>
                          <Text mediumViewAmt>Onboarding Documents</Text>
                        </Box>
                        <Grid container spacing={3} columnSpacing={'32px'}>
                          <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Input
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                name: 'name',
                                value: state.name,
                                type: 'text',
                                inputProps: { maxLength: 100 }
                              }}
                              handleChange={handleChange}
                              clientInput
                              labelText={'Document Name'}
                              error={error.name}
                            />
                            <Text errorText> {error.name ? error.name : ""}</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input
                              formControlProps={{
                                fullWidth: true
                              }}
                              multiline={true}
                              rows={3}
                              inputProps={{
                                name: 'description',
                                value: state.description,
                                type: 'text',
                                inputProps: { maxLength: 500 },
                              }}
                              handleChange={handleChange}
                              // descriptionFormControl1
                              // descriptionInput
                              labelText={'Description (Optional)'}
                              placeholder={'Type Something'}
                              commentsField
                              error={error.description ? <Text red>{error.description ? error.description : ''}</Text> : ''}
                            />
                            <Text errorText> {error.description ? error.description : ""}</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <FormGroup>
                              <FormControlLabel
                                name='is_mandatory'
                                value={state.is_mandatory}
                                onChange={handleChange}
                                control={<Checkbox disabled={isEditable} icon={<CheckBorderIcon />} checkedIcon={<CheckedIcon />} checked={state.is_mandatory} />}
                                label={<Text popupHead2 sx={{
                                  font: '15px Quicksand !important', color: "#525252 !important",
                                  "@media (min-width: 100px) and (max-width: 499px)": {
                                    font: '10px Quicksand !important',
                                  },
                                }}>Make This Document Mandatory.</Text>}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                        <Box display={'flex'} justifyContent={'end'} gap={'20px'} pt={2}>
                          <Button sx={{ width: "108px !important" }} cancelOutlineBlue onClick={handleDialogClose} >Cancel</Button>
                          <LoadingButton saveBtn loading={loading} onClick={() => handleSaveAndEdit()}>Save</LoadingButton>
                        </Box>
                      </Box>
                  }
                </DialogContent>
              </BootstrapDialog>
            </Box>

            <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
              <Box mb={3}>
                <Text RegularBlack1>Recent Activities</Text>
              </Box>
              <Box sx={{ height: '40vh', overflowY: 'auto' }}
                ref={activityRef}
                onScroll={activityHandleScroll}>
                {loading ?
                  <>
                    {[1, 2, 3].map((item, index) => (
                      <Grid container key={index} mt={3}>
                        <Grid item lg={8} md={8} sm={8} xs={12}>
                          <Skeleton animation="wave" width="200px" />
                          <Skeleton animation="wave" width="200px" />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Skeleton animation="wave" width="200px" />
                          <Skeleton animation="wave" width="200px" />
                        </Grid>
                      </Grid>
                    ))}
                  </> :
                  <>
                    {activityData.length > 0 ?
                      <>
                        {activityData.map((value) => (
                          <Box className={classes.descriptionBoxStyle} mb={2}>
                            <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                              <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                <Text mediumBlackColor> {
                                  value.user_name && value.user_name.length > 16 ?
                                    <BlackToolTip title={value.user_name} placement="top" arrow>
                                      {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    : value.user_name
                                } {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                              </Grid>
                              <Grid container item lg={8} md={8} sm={6} xs={12} alignItems={"center"}>
                                {
                                  value.change_log.length > 0 && value.change_log.map((item) => (
                                    <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                  ))
                                }
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </> :
                      <>
                        {NoDataFound("", "No Data Found", "50%", "35")}
                      </>
                    }
                  </>
                }
                {listLoading && (
                  <Box >
                    <ListLoadingIcon />
                  </Box>
                )}
              </Box>
            </Box >
            {recentActivityShow ?
              <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                <Button addButton
                  sx={{ position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`, }}
                  onClick={scrollDown}
                  endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
              </Grid>
              : ''}
          </Box >
          :
          <>
            {NoPermission()}
          </>
      }
    </div>
  )
}
