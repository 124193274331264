import { Box, Grid, Divider, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../../components/customText/Text'
import CustomSelect from '../../../../components/customSelect/CustomSelect'
import ReactApexChart from 'react-apexcharts'
import PayrollDashboardStyles from './PayrollDashboardStyles'
import { addErrorMsg, addWarningMsg, dateFormat } from '../../../../utils/utils'
import DashboardApi from '../../../../apis/admin/payroll/DashboardApi'
import moment from 'moment'
import Button from '../../../../components/customButton/Button'
import Date from '../../../../components/datePicker/Date'
import closeIcon from '../../../../assets/svg/payroll/close-Icon.svg';
import LocalStorage from '../../../../utils/LocalStorage'

function PayrollAnalysis() {

    const classes = PayrollDashboardStyles();
    const payrollAnalysisTypes = ['Earnings', 'Reimbursements', 'Deductions', 'Margin', 'Balance']
    const [year, setYear] = useState(1);
    const [checkActive, setCheckActive] = useState('Earnings');
    const [state, setState] = useState({
        start_date: moment().subtract(3, 'months').startOf('month').format(dateFormat()),
        end_date: moment().format(dateFormat()),
        analyticsEndDate: '',
        analyticsStartDate: ''
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [enableDate, setEnableDates] = useState(false);
    var currency = LocalStorage.getCurrencySymbol()

    useEffect(() => {
        payrollAnalysisApi(state.start_date, state.end_date);
        // eslint-disable-next-line 
    }, [])

    const payrollAnalysisApi = (startDate, endDate) => {
        DashboardApi.getPayrollAnalysis(startDate, endDate).then((res) => {
            if (res.data.statusCode === 1003) {
                if (res.data.data.length > 0) {
                    setPayrollAnalsisList(res.data.data[0]);
                    setAnchorEl(null);
                    setOpen(false);
                    if (year == 4 && state.analyticsStartDate !== '' && state.analyticsEndDate !== '') {
                        setEnableDates(true);
                    }
                    state['analyticsStartDate'] = ''
                    state['analyticsEndDate'] = ''
                    setState({ ...state })
                }
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const [payrollAnalysisList, setPayrollAnalsisList] = useState({
        analytics: {
            series: [],
            total_balance_amounts: [],
            total_deductions: [],
            total_earned_amounts: [],
            total_margins: [],
            total_reimbursements: []
        },
        colors: [],
        total_balance_amount: '',
        total_deduction_amount: '',
        total_margin_amount: '',
        total_reimbursed_amount: '',
        total_earned_amount: ''
    })

    const payrollComparison = [
        { id: 1, value: '3 Months' },
        { id: 2, value: '6 Months' },
        { id: 3, value: '1 Year' },
        { id: 4, value: 'Custom' }
    ]

    const payrollAnalysis = {
        series: [{
            name: checkActive, // Dynamically set the name based on the active tab
            data: checkActive === 'Earnings' ? payrollAnalysisList.analytics.total_earned_amounts.map(Number) :
                checkActive === 'Reimbursements' ? payrollAnalysisList.analytics.total_reimbursements.map(Number) :
                    checkActive === 'Deductions' ? payrollAnalysisList.analytics.total_deductions.map(Number) :
                        checkActive === 'Margin' ? payrollAnalysisList.analytics.total_margins.map(Number) :
                            checkActive === 'Balance' ? payrollAnalysisList.analytics.total_balance_amounts.map(Number) : []
        }],
        chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false
            }
        },
        xaxis: {
            type: 'category',
            categories: payrollAnalysisList.analytics.series // X-axis categories
        },
        fill: {
            type: 'solid',
            colors: checkActive === 'Earnings' ? ['#FFEFEF'] : checkActive === 'Reimbursements' ? ['#ECFFEC'] : checkActive === 'Deductions' ? ['#FFE7FD'] : checkActive === 'Margin' ? ['#EBF0FF'] : checkActive === 'Balance' ? ['#FFF7E3'] : [''],
        },
        yaxis: [
            {
                rangelabels:
                {
                    formatter: function (value) {
                        return `${value}`;
                    }
                }
            },
        ],
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true
        },
        legend: {
            show: false,
        },
        colors: checkActive === 'Earnings' ? ['#FF4B55'] : checkActive === 'Reimbursements' ? ['#57B556'] : checkActive === 'Deductions' ? ['#FF19E8'] : checkActive === 'Margin' ? ['#004AB2'] : checkActive === 'Balance' ? ['#FFBA19'] : [''],
        stroke: {
            width: 2
        }
    };

    const PayrollAnalytics = [
        {
            color: '#FF4B55',
            name: 'Total Employee Earnings',
            amount: payrollAnalysisList.total_earned_amount,
            status: 'Earnings',
            background: '#FFEFEF'
        },
        {
            color: '#57B556',
            name: 'Total Reimbursements Paid',
            amount: payrollAnalysisList.total_reimbursed_amount,
            status: 'Reimbursements',
            background: '#ECFFEC'
        },
        {
            color: '#FF19E8',
            name: 'Total Deductions Collected',
            amount: payrollAnalysisList.total_deduction_amount,
            status: 'Deductions',
            background: '#FFE7FD'
        },
        {
            color: '#004AB2',
            name: 'Margin Earned',
            amount: payrollAnalysisList.total_margin_amount,
            status: 'Margin',
            background: '#EBF0FF'
        },
        {
            color: '#FFBA19',
            name: 'Balance',
            amount: payrollAnalysisList.total_balance_amount,
            status: 'Balance',
            background: '#FFF7E3'
        }
    ]

    const handleChange = (e) => {
        let value = e.target.value
        setYear(e.target.value);
        if (value == 4) {
            setAnchorEl(true);
            setOpen(true);
        } else {
            switch (value) {
                case 1:
                    state['start_date'] = moment().subtract(3, 'months').startOf('month').format(dateFormat());
                    state['end_date'] = moment().format(dateFormat());
                    setState({ ...state });
                    break;
                case 2:
                    state['start_date'] = moment().subtract(6, 'months').startOf('month').format(dateFormat());
                    state['end_date'] = moment().format(dateFormat());
                    setState({ ...state });
                    break;
                case 3:
                    state['start_date'] = moment().subtract(1, 'year').format(dateFormat());
                    state['end_date'] = moment().format(dateFormat());
                    setState({ ...state });
                    break;
                default:
                    break;
            }
            payrollAnalysisApi(state.start_date, state.end_date)
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        })
    }

    const handleClose = () => {
        payrollAnalysisApi(moment().subtract(3, 'months').startOf('month').format(dateFormat()), moment().format(dateFormat()));
        setAnchorEl(null);
        setOpen(false);
        setYear(1);
    };

    const applyFilter = () => {
        if (state.analyticsStartDate !== '' && state.analyticsEndDate !== '') {
            payrollAnalysisApi(state.analyticsStartDate, state.analyticsEndDate);
        }else{
            addWarningMsg('Please select Start and End Date');
        }
    }

    const clearDate = (event) => {
        setEnableDates(false);
        payrollAnalysisApi(moment().subtract(3, 'months').startOf('month').format(dateFormat()), moment().format(dateFormat()));
        setYear(1);
    }

    return (
        <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '14px', width: '100%' }}>
            <Grid item container mb={4} alignItems='center' rowSpacing={2}>
                <Grid item xs={3} lg={2} md={2}>
                    <Text largeBlack20>Payroll Analysis</Text>
                </Grid>
                <Grid item container xs={12} lg={7.8} md={8} justifyContent='center'>
                    {
                        payrollAnalysisTypes.map((data) => (
                            <Box sx={{ borderRadius: data == 'Earnings' ? '4px 0px 0px 4px' : data == 'Balance' ? '0px 4px 4px 0px' : '' }} className={checkActive == data ? classes.activeName : classes.defaultName} onClick={() => setCheckActive(data)}>
                                <Grid xs={2.4} lg={2.4} md={2.4}>
                                    {data}
                                </Grid>
                            </Box>
                        ))
                    }
                </Grid>
                {
                    enableDate ?
                        <Grid item xs={3} lg={2.2} md={2}>
                            <Box display='flex' flexDirection='row' gap={1} className={classes.choosePeriod}>
                                <Box><Text offBoardBodyFont>{state.start_date} - {state.end_date}</Text></Box>
                                <Box><img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => { clearDate() }} /></Box>
                            </Box>
                        </Grid> :
                        <Grid item xs={3} lg={2} md={2}>
                            <CustomSelect
                                name='year'
                                value={year}
                                viewDrop
                                scrollTrue={true}
                                options={payrollComparison}
                                onChange={(e) => handleChange(e)}
                                labelId={'demo-simple-select-filled-label'}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                            />
                            <Menu
                                id="demo-simple-select-filled-label"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => handleClose()}
                                MenuListProps={{
                                    'aria-labelledby': 'demo-simple-select-filled-label',
                                }}
                                style={{ width: '100%' }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem disableRipple={true} sx={{ backgroundColor: 'transparent !important' }}>
                                    <Grid item xs={12}>
                                        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                            <Box>
                                                <Date
                                                    labelText='Start Date'
                                                    name='analyticsStartDate'
                                                    value={state.analyticsStartDate}
                                                    onChange={(value => dateChange(value, 'analyticsStartDate'))}
                                                    height='45px'
                                                />
                                            </Box>
                                            <Box>
                                                -
                                            </Box>
                                            <Box>
                                                <Date
                                                    labelText='End Date'
                                                    name='analyticsEndDate'
                                                    value={state.analyticsEndDate}
                                                    minDate={moment(state.analyticsStartDate).add(1, 'days').format(dateFormat())}
                                                    onChange={(value => dateChange(value, 'analyticsEndDate'))}
                                                    height='45px'
                                                />
                                            </Box>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Box my={2}>
                                                <Divider />
                                            </Box>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                                <Button payrollHistoryCancel onClick={handleClose}>Cancel</Button>
                                                <Button blueButton onClick={applyFilter}>Apply Filter</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </Grid>
                }
                <Grid item xs={12} lg={12} md={12}>
                    <ReactApexChart options={payrollAnalysis} series={payrollAnalysis.series} type="area" height={250} />
                </Grid>
                <Grid item xs={12} lg={12} md={12} my={2}>
                    <Divider />
                </Grid>
                <Grid item container xs={12} lg={12} md={12} mb={2}>
                    {
                        PayrollAnalytics.map((data) => (
                            <Grid item xs={12} sm={4} lg={2.4} md={3} xl={2.4} justifyContent='end'>
                                <Box textAlign='end' justifyContent='end' sx={{ borderLeft: data.status == checkActive ? `2px solid ${data.color}` : '', borderRight: data.status == 'Balance' ? '' : '1px solid #E2E5E6', background: data.status == checkActive ? data.background : '', padding: '24px' }}>
                                    <Box display='flex' flexDirection='column' gap={1} justifyContent='end' textAlign='end'>
                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' justifyContent='end'>
                                            <Box sx={{ background: data.color, height: '10px', width: '10px', borderRadius: '50%' }} />
                                            <Text mediumBlack14>{data.name}</Text>
                                        </Box>
                                        <Text boldblack22>{currency} {data.amount ? data.amount : '0'}</Text>
                                    </Box>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default PayrollAnalysis
