export const 
filterStorage = {
    setFilter(key, filterObject) {
        // Get the current data from sessionStorage
        const existingData = JSON.parse(sessionStorage.getItem('filter_data')) || {};
        // Update the data with the new filter object
        existingData[key] = filterObject;
        // Save the updated data back to sessionStorage
        sessionStorage.setItem('filter_data', JSON.stringify(existingData));
    },

    getFilter(key) {
        // Get the data from sessionStorage
        const data = JSON.parse(sessionStorage.getItem('filter_data'));
        // Return the specific key's value if it exists
        return data ? data[key] : null;
    },

    removeFilter(key) {
        // Get the current data from sessionStorage
        const existingData = JSON.parse(sessionStorage.getItem('filter_data'));
        if (existingData && key in existingData) {
            // Delete the specific key
            delete existingData[key];
            // Save the updated data back to sessionStorage
            sessionStorage.setItem('filter_data', JSON.stringify(existingData));
        }
    },

    clearAllFilters() {
        // Remove the entire filter_data key from sessionStorage
        sessionStorage.removeItem('filter_data');
    }
};

/**
 * keys for modules:- clients,vendors,end-clients,timesheets,placements,all-employees,onboarders-employees,internal-employees,balancesheet,balancesheet-index,expense
 *
*/

/**
 * Example store:
 * ==============
 * const filterObject = {limit: 10,page: 1,search: ""};
 * filterStorage.setFilter('myFilter', filterObject);
*/

/**
 * Example get based on key:
 * ==============================
 * const myFilter = filterStorage.getFilter('myFilter');
 * Output:{limit: 10,page: 1,search: ""}
*/

/**
 * Example for removing perticular key with assigned value of object:
 * ==================================================================
 * filterStorage.removeFilter('myFilter');
 * console.log(filterStorage.getFilter('myFilter')); // Output: null
*/

/**
 * clear filter:
 * ==================================================================
 * filterStorage.clearAllFilters();
 * console.log(sessionStorage.getItem('filter_data'))// Output: null
*/

/**
 * Explanations
 * =================
 * setFilter: Stores the entire object under the specified key.
 * getFilter: Retrieves the object associated with the specified key.
 * removeFilter: Deletes the object for a specific key.
 * clearAllFilters: Clears all data stored in the filter_data key.
 * This structure is ideal for managing a complex filter object within sessionStorage.
 * 
*/



