import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Skeleton, Slide } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import ToggleSwitch from '../../../../../components/toggle/CustomToggle'
import CustomMenu from '../../configComponents/customMenu/Menu';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg'
import { styled } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Input from '../../../../../components/input/Input';
import Button from '../../../../../components/customButton/Button';
import { isValid, onCharactersNumbersWithSpaceHyChange, validate_Description, validate_Visa_Name, } from "../../../../../components/Validation";
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import VisaTypesApi from '../../../../../apis/configurations/employee/VisaTypesApi';
import LocalStorage from "../../../../../utils/LocalStorage";
import { addSuccessMsg, addErrorMsg, addWarningMsg, NoDataFound, NoPermission, BlackToolTip, ListLoadingIcon } from '../../../../../utils/utils';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import ReusableAlert from '../../../../../components/reuablePopup/ReusableAlert';
import EditIcon from '../../../../../assets/svg/newEdit.svg';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        // height: '350px',
        width: '692px',
        padding: '0px !important',
        borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
        padding: '0px !important',
    },
    "& .MuiDialogActions-root": {
        padding: '0px !important'
    },
    "& .MuiDialog-container": {
        background: 'rgba(0, 0, 0, 0.55) !important'
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={300} />;
});

export default function VisaTypes({ current }) {

    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()
    const recentSection = useRef(null);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [alert, setAlert] = useState(false);
    const [error, setError] = useState({});
    const [getData, setGetData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inactiveOpen, setInactiveOpen] = useState(false);
    const [inActiveData, setInactiveData] = useState(null);
    const [recentActivityShow, setRecentActivityShow] = useState(false)
    const [chromeAlert, setChromeAlert] = useState(false);

    const [state, setState] = useState({
        serial_no: '',
        id: '',
        name: '',
        is_active: true,
        description: ''
    });
    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_view" && item.is_allowed == true)))) {
                getVisaTypesListing(filter)
                getActivity(activityFilter)
            }
        }// eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (chromeAlert) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [chromeAlert]);

    const [activityData, setActivityData] = useState([]);
    const [val, setVal] = useState();
    const [updateOpen, setUpdateOpen] = useState(false);
    const [updateLoadings, setUpdateLoadings] = useState(false);
    const [filter, setFilter] = useState({
        limit: 5,
        page: 1,
        search: "",
    });
    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })
    const [total, setTotal] = useState();
    const [activityTotal, setActivityTotal] = useState();
    const innerBoxRef = useRef(null);
    const activityRef = useRef(null);
    const [listLoading, setListLoading] = useState(false);

    const getActivity = (args, popup) => {
        if (activityFilter.limit <= 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        ConfigApi.getActivity(7, "", args).then((response) => {
            // removeLoader();
            setListLoading(false);
            if (response.data.statusCode == 1003) {
                setLoading(false)
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
                if (popup) { }
                else {
                    setRecentActivityShow(true)
                }
            }
        });
    };


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        handleValidations(e);
    };

    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "name":
                err.name = validate_Visa_Name(input.value);
                break;
            case "description":
                err.description = validate_Description(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const validateAll = () => {
        let { name, description } = state;
        let errors = {};
        errors.name = validate_Visa_Name(name);
        errors.description = validate_Description(description);
        return errors;
    };


    const handleClickOpen = () => {
        setOpen(true);
        setAlert(false);
        setState({
            serial_no: '',
            id: '',
            name: '',
            is_active: true,
            description: ''
        })
        setChromeAlert(true);

    };

    const handleDialogClose = () => {
        setOpen(false);
        if (isEditable) {
            setIsEditable(isEditable)
        }
        setIsEditable(false);
        setError({});
        setChromeAlert(false);
    };

    const handleEditClick = (data) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_edit" && item.is_allowed == true)))) {
            setOpen(true);
            setAlert(false)
            setAnchorEl(null);
            setIsEditable(false);
            setState({ ...data });
            setChromeAlert(true);
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    };

    const handleViewClick = (data) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_view" && item.is_allowed == true)))) {
            setOpen(true);
            setAlert(false)
            setAnchorEl(null);
            setIsEditable(true);
            setState({ ...data });
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const handleDelete = () => {
        deleteVisaTypes(val.id)
        setOpen(false);
    }

    const handleSaveAndEdit = () => {
        if (isEditable) {
            setIsEditable(false);
        } else {
            let errors = validateAll();
            if (isValid(errors)) {
                if (state.id != "") {
                    setUpdateOpen(true);
                    // updateVisaTypes(state.id)
                } else {
                    StoreVisaTypes(state)
                }
            } else {
                setError(errors);
                setLoading(false)
                addWarningMsg(ErrorMessages.genericMessage);
            }
        }
    }

    const handleDeleteClick = (args) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_delete" && item.is_allowed == true)))) {
            setVal(args)
            setOpen(true);
            setAlert(true)
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        });
        setRecentActivityShow(false)
    }

    const handleStatus = (event, items) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_edit" && item.is_allowed == true)))) {
            if (event.target.checked) {
                items['is_active'] = event.target.checked
                updateStatus(items)
            } else {
                items['is_active'] = event.target.checked;
                setInactiveData(items);
                setInactiveOpen(true);
            }
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const getVisaTypesListing = (args) => {
        if (filter.limit <= 5) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        VisaTypesApi.getVisaTypesListing(args).then((response) => {
            setLoading(false);
            // removeLoader();
            setListLoading(false);
            if (response.data.statusCode == 1003) {
                setGetData(response.data.data);
                setTotal(response.data.pagination.total)
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };
    const StoreVisaTypes = (data) => {
        data.request_id = LocalStorage.uid()
        setLoading(true)
        VisaTypesApi.StoreVisaTypes(data).then((response) => {
            setLoading(false)
            if (response.data.statusCode == 1003) {
                getVisaTypesListing(filter)
                getActivity(activityFilter)
                handleDialogClose();
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };
    const updateStatus = (args) => {
        setUpdateLoadings(true);
        let data = {
            request_id: LocalStorage.uid(),
            is_active: args.is_active
        }
        VisaTypesApi.updateVisaTypesStatus(data, args.id).then((response) => {
            setUpdateLoadings(false);
            if (response.data.statusCode == 1003) {
                setInactiveData(null);
                setInactiveOpen(false)
                getVisaTypesListing(filter)
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setInactiveOpen(false);
                getVisaTypesListing(filter);
                getActivity(activityFilter);
                addErrorMsg(response.data.message);
            }
        });
    };

    const handleCloseInActive = () => {
        setInactiveData(null);
        setInactiveOpen(false);
        getVisaTypesListing(filter)
    }

    const updateVisaTypes = (dataId) => {
        let data = { ...state, request_id: LocalStorage.uid() };
        setLoading(true)
        VisaTypesApi.updateVisaTypes(data, dataId).then((response) => {
            setLoading(false)
            if (response.data.statusCode == 1003) {
                setLoading(false);
                setOpen(false);
                setUpdateOpen(false);
                getVisaTypesListing(filter)
                getActivity(activityFilter)
                handleDialogClose();
                addSuccessMsg(response.data.message);
            } else {
                // handleDialogClose();
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };
    const deleteVisaTypes = (dataId) => {
        let data = { request_id: LocalStorage.uid() };
        VisaTypesApi.deleteVisaTypes(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                getVisaTypesListing(filter)
                getActivity(activityFilter)
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                if (Number(activityTotal) >= activityFilter.limit) {
                    // addLoader(true);
                    setListLoading(true);
                    activityFilter.limit = activityFilter.limit + 10;
                    setActivityFilter({ ...activityFilter })
                    getActivity(activityFilter, true);
                }
            }
        }
    };

    const handleScroll = () => {
        const { current } = innerBoxRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                if (Number(total) >= filter.limit) {
                    loadMoreData();
                }
            }
        }
    };

    const loadMoreData = () => {
        // addLoader(true);
        setListLoading(true);
        filter.limit = Number(filter.limit) + 5;
        setFilter({ ...filter });
        getVisaTypesListing(filter);
    }

    const HandleScrolling = (e) => {
        const scrollTop = e.target.scrollTop;
        if (scrollTop >= 120) {
            setRecentActivityShow(false);
        }
    };

    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_view" && item.is_allowed == true))) ?
                    <Box onScroll={HandleScrolling} sx={{
                        height: '70vh', overflow: 'auto', padding: '16px !important',
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px !important",
                        },
                    }}>
                        <Box className={classes.activeItemBox}>
                            <Box className={classes.activeBoxHeading}>
                                <Grid container>
                                    <Grid item lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                                        <Text profileTitle>{current}</Text>
                                    </Grid>
                                </Grid>

                            </Box>
                            <Box sx={{ height: '35vh', overflow: 'auto' }} ref={innerBoxRef} onScroll={handleScroll}>
                                {
                                    loading ? <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid spacing={2} container key={index} mt={3}>
                                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation="wave" />
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={12}>
                                                    <Skeleton animation="wave" height={40} />
                                                </Grid>
                                                <Grid item lg={1} md={1} sm={1} xs={12}>
                                                    <Skeleton animation="wave" />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                        :
                                        <>
                                            {getData.length > 0 ?
                                                <>
                                                    {getData.map((item, index) => (
                                                        <Box className={classes.descriptionBoxStyle} key={index}>
                                                            <Grid container alignItems="center" spacing={2}>
                                                                <Grid item lg={7} md={6} sm={6} xs={12}>
                                                                    <Text mediumBlackColor >
                                                                        <BlackToolTip arrow title={item.name}>{item.name ? item.name.length > 40 ? item.name.slice(0, 40) + '...' : item.name : "--"}</BlackToolTip></Text>
                                                                    <Text greyLabel mt="4px">
                                                                        <BlackToolTip arrow title={item.description}>{item.description ? item.description.length > 40 ? item.description.slice(0, 40) + '...' : item.description : "--"}
                                                                        </BlackToolTip>
                                                                    </Text>
                                                                </Grid>
                                                                <Grid item lg={3} md={3} sm={3} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                    <Text mediumBlackColor>Created By</Text>
                                                                    <Text greyLabel mt="4px">
                                                                        {item.created_by && item.created_by.length > 16 ?
                                                                            <BlackToolTip title={item.created_by} placement="top" arrow>
                                                                                {item.created_by.slice(0, 16) + (item.created_by.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : item.created_by ? item.created_by : '--'
                                                                        }</Text>
                                                                </Grid>
                                                                <Grid item lg={1} md={1.5} sm={1.5} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                    <ToggleSwitch name='is_active' isActive={item.is_active} switchChange={(e) => handleStatus(e, item)} sx={{ height: '24px !important' }} />
                                                                </Grid>
                                                                <Grid item lg={1} md={1.5} sm={1.5} xs={12} textAlign={"center"}>
                                                                    <CustomMenu
                                                                        Icon={<MenuIcon />}
                                                                        handleMenuClick={handleMenuClick}
                                                                        anchorEl={anchorEl}
                                                                        isOpen={Boolean(anchorEl)}
                                                                        onClose={handleClose}

                                                                        children=
                                                                        {
                                                                            item.is_editable ?
                                                                                [{ color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                                                                { color: '#171717', label: "Edit", Click: () => handleEditClick(item) },
                                                                                { color: '#171717', label: "Delete", Click: () => handleDeleteClick(item) }
                                                                                ]
                                                                                :
                                                                                [{ color: '#171717', label: "View", Click: () => handleViewClick(item) }]
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    {NoDataFound("", "No Data Found", "50%", "35")}
                                                </>
                                            }
                                        </>
                                }
                                {listLoading && (
                                    <Box >
                                        <ListLoadingIcon />
                                    </Box>
                                )}
                            </Box>
                            <>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "skill_types_create" && item.is_allowed == true))) ?
                                        <Button addTitleBtn onClick={() => handleClickOpen()}>Add Visa</Button> :
                                        <Button addTitleBtnDisable>Add Visa</Button>
                                }
                            </>
                            {alert ?
                                <ReusableDelete newDelete={true} content={"Do you want to delete this visa type?"} open={open} setOpen={setOpen} onClick={() => { handleDelete() }} />
                                :
                                <BootstrapDialog
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleDialogClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                    fullWidth={true}
                                    maxWidth={"md"}
                                >
                                    <DialogContent >
                                        {
                                            isEditable ?
                                                <Box padding={{ lg: '30px', md: "30px", sm: "25px", xs: "16px 12px" }}>
                                                    <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                                                        <Grid item lg={6} md={6} sm={6} xs={8}>
                                                            <Text mediumViewAmt>Visa</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={4} textAlign={"end"}>
                                                            {state.is_editable ? <img onClick={() => { setIsEditable(false); setChromeAlert(true) }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Visa Name</Text>
                                                            <Text mediumBlack14 pt={0.8} noWrap>{state.name ? state.name : "--"}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Status</Text>
                                                            <Text mediumBlack14 pt={0.8} noWrap>{state.is_active ? "Active" : "In Active"}</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Description (Optional)</Text>
                                                            <Text mediumBlack14 pt={0.8} className={classes.descriptionText}>{state.description ? state.description : "--"}</Text>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                <Box padding={{ lg: '38px 30px 35px 30px', md: '38px 30px 35px 30px', sm: '30px', xs: '16px 12px' }}>
                                                    <Box mb={4}>
                                                        <Text mediumBoldBlack2>{state.id === "" ? "Visa Type" : "Visa Type"}</Text>
                                                    </Box>
                                                    <Grid container spacing={2} columnSpacing={'32px'}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12} >
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'name',
                                                                    value: state.name,
                                                                    type: 'text',
                                                                    inputProps: { maxLength: 50 },
                                                                }}
                                                                handleChange={handleChange}
                                                                clientInput
                                                                onKeyPress={onCharactersNumbersWithSpaceHyChange}
                                                                labelText={'Visa Name'}
                                                                error={error.name}
                                                            />
                                                            <Text errorText> {error.name ? error.name : ""}</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                multiline={true}
                                                                rows={3}
                                                                inputProps={{
                                                                    name: 'description',
                                                                    value: state.description,
                                                                    type: 'text',
                                                                    inputProps: { maxLength: 500 },
                                                                }}
                                                                handleChange={handleChange}
                                                                // descriptionFormControl1
                                                                // descriptionInput
                                                                labelText={'Description (Optional)'}
                                                                placeholder={'Type Something'}
                                                                commentsField
                                                                error={error.description ? <Text red>{error.description ? error.description : ''}</Text> : ''}
                                                            />
                                                            <Text errorText> {error.description ? error.description : ""}</Text>
                                                        </Grid>
                                                    </Grid>
                                                    <Box display={'flex'} justifyContent={'end'} gap={'20px'} pt={3}>
                                                        {loading ? "" : <Button sx={{ width: "108px !important" }} cancelOutlineBlue onClick={handleDialogClose} >Cancel</Button>}
                                                        <LoadingButton saveBtn loading={loading} onClick={() => handleSaveAndEdit()}>Save</LoadingButton>
                                                    </Box>
                                                </Box>
                                        }
                                    </DialogContent>
                                </BootstrapDialog>}
                        </Box>
                        {
                            inactiveOpen && <ReusableAlert message={true} title='visa type' open={inactiveOpen} handleClose={handleCloseInActive} onClick={() => { updateStatus(inActiveData) }} />
                        }
                        {
                            updateOpen &&
                            <ReusableDelete loading={updateLoadings} delete={true} content={`Editing this Visa Type is going to impact ${state?.employee_count ? state.employee_count : '0'} mapped employees. Do you want to proceed?`} open={updateOpen} setOpen={setUpdateOpen} onClick={() => { updateVisaTypes(state.id) }} />
                        }

                        <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                            <Box mb={3}>
                                <Text RegularBlack1>Recent Activities</Text>
                            </Box>
                            <Box sx={{ height: '40vh', overflowY: 'auto', }}
                                ref={activityRef}
                                onScroll={activityHandleScroll}>
                                {loading ?
                                    <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid container key={index} mt={3}>
                                                <Grid item lg={8} md={8} sm={8} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />

                                                </Grid>
                                            </Grid>
                                        ))}
                                    </> :
                                    <>
                                        {activityData.length > 0 ?
                                            <>
                                                {activityData.map((value) => (
                                                    <Box className={classes.descriptionBoxStyle} mb={2}>
                                                        <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                                                            <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                                                <Text mediumBlackColor> {
                                                                    value.user_name && value.user_name.length > 16 ?
                                                                        <BlackToolTip title={value.user_name} placement="top" arrow>
                                                                            {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : value.user_name
                                                                } {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                                                            </Grid>
                                                            <Grid item lg={8} md={8} sm={6} xs={12} display={"flex"} alignItems={"center"}>
                                                                {
                                                                    value.change_log.length > 0 && value.change_log.map((item) => (
                                                                        <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </> :
                                            <>
                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                            </>
                                        }
                                    </>
                                }
                                {listLoading && (
                                    <Box >
                                        <ListLoadingIcon />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {recentActivityShow ?
                            <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                                <Button addButton
                                    sx={{ position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`, }}
                                    onClick={scrollDown}
                                    endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                            </Grid>
                            : ''}
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </div>
    )
}
