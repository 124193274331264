import { Avatar, Box, Card, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import GeneratePayrollStyles from './GeneratePayrollStyles';
import Button from '../../../../components/customButton/Button';
import Timesheet from './amountDetails/Timesheet';
import PayCycleDetails from './amountDetails/PayCycleDetails';
import Reimbursements from './amountDetails/Reimbursements';
import Deductions from './amountDetails/Deductions';
import BalanceSheet from './amountDetails/BalanceSheet';
import Text from '../../../../components/customText/Text';
import { BlackToolTip, formatNumberWithCommas, capitalizeAndAddSpace, addErrorMsg, addSuccessMsg } from '../../../../utils/utils';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Updatestandard from '../../../../assets/svg/updatestandard.svg';
import SuccessStandard from '../../../../assets/svg/successUpdateStandardpay.svg'
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi';

const SalaryAmountDrawer = (props) => {

  const { summary, active, salaryOpen, getAllEmployees, setActive, currentSalaryRowProp, payrollConfigId, timesheetListProp, reimbursementDeductionProp, allBalanceSheetProp, closePopUp, balanceSheetPagination, balanceSheetPaginationTotal } = props;

  const visaTypes = ["H-1B", "E-3", "H-1B1", "E3", "H1B1", "H1B"];
  const classes = GeneratePayrollStyles();
  const [updateStandardPayPopUp, setUpdateStandardPayPopUpDrawer] = useState(false);
  const [successStandardPopUp, setSuccessStandardPopUp] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(currentSalaryRowProp.balance_amount);
  const [isCustomPopUpOpen, setIsCustomPopUpOpen] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [checkBoxIndex,setCheckBoxIndex] = useState(null);

  useEffect(() => {
    if (!salaryOpen) {
      setActive(0)
    } else {
      setCurrentBalance(currentSalaryRowProp.balance_amount)
      // if (currentSalaryRowProp?.make_standard_pay_as_salary && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
      //   setCustomAmount(Number(currentSalaryRowProp?.standard_pay));
      // } else if (currentSalaryRowProp?.lca_wage > 0 && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
      //   setCustomAmount(Number(currentSalaryRowProp?.lca_wage));

      // } else {
      //   setCustomAmount(Number(currentSalaryRowProp?.earned_pay));

      // }

      if ((currentSalaryRowProp?.amount_paid).toFixed(2) === currentSalaryRowProp?.standard_pay && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
        setCustomAmount(Number(currentSalaryRowProp?.standard_pay));
      } else if ((currentSalaryRowProp?.amount_paid).toFixed(2) === (currentSalaryRowProp?.lca_wage).toFixed(2)  && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
        setCustomAmount(Number(currentSalaryRowProp?.lca_wage));
      } else if ((currentSalaryRowProp?.amount_paid).toFixed(2) === currentSalaryRowProp?.earned_pay) {
        setCustomAmount(Number(currentSalaryRowProp?.earned_pay));
      } else {
        setCustomAmount(Number(currentSalaryRowProp?.amount_paid));
      }
    }
    // eslint-disable-next-line
  }, [salaryOpen])

  // const handlePayAmount = (enteredAmount, status, final, sub) => {
  //   if (final == sub) {
  //     if (currentSalaryRowProp?.make_standard_pay_as_salary && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
  //       setCustomAmount(Number(currentSalaryRowProp?.standard_pay));
  //     } else if (currentSalaryRowProp?.lca_wage > 0 && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
  //       setCustomAmount(Number(currentSalaryRowProp?.lca_wage));
  //     } else {
  //       setCustomAmount( Number(currentSalaryRowProp?.earned_pay));
  //     }
  //     setCurrentBalance(Number(currentSalaryRowProp?.balance_amount))
  //   }
  //   if (sub == 0 && final != sub) {
  //     setCustomAmount(Number(currentSalaryRowProp?.standard_pay));
  //     setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(currentSalaryRowProp?.standard_pay))
  //   }
  //   if (sub == 1 && final != sub) {
  //     setCustomAmount(Number(currentSalaryRowProp?.lca_wage));
  //     setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(currentSalaryRowProp?.lca_wage))
  //   }
  //   if (sub == 2 && final != sub) {
  //     setCustomAmount(Number(currentSalaryRowProp.earned_pay));
  //     setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(currentSalaryRowProp?.earned_pay))
  //   }
  //   if (sub == 3) {
  //     setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(enteredAmount));
  //     setIsCustomPopUpOpen(true);
  //     setCustomAmount(Number(enteredAmount));
  //   } else {
  //     setIsCustomPopUpOpen(false);
  //   }

  // }

  const handlePayAmount = (customAmount, selectedCheckbox, intialCheckBox) => {
    if (selectedCheckbox == intialCheckBox) {
      setCustomAmount(Number(currentSalaryRowProp?.amount_paid))
      setCurrentBalance(Number(currentSalaryRowProp?.balance_amount));

    }
    if (selectedCheckbox == 0 && intialCheckBox != selectedCheckbox) {
      setCustomAmount(Number(currentSalaryRowProp?.standard_pay));
      setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(currentSalaryRowProp?.standard_pay))
    }
    if (selectedCheckbox == 1 && intialCheckBox != selectedCheckbox) {
      setCustomAmount(Number(currentSalaryRowProp?.lca_wage));
      setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(currentSalaryRowProp?.lca_wage))
    }
    if (selectedCheckbox == 2 && intialCheckBox != selectedCheckbox) {
      setCustomAmount(Number(currentSalaryRowProp.earned_pay));
      setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(currentSalaryRowProp?.earned_pay))
    }
    if (selectedCheckbox == 3) {
      setCurrentBalance((Number(currentSalaryRowProp?.balance_amount) + Number(currentSalaryRowProp.amount_paid)) - Number(customAmount));
      setIsCustomPopUpOpen(true);
      setCustomAmount(Number(customAmount));
    } else {
      setIsCustomPopUpOpen(false);
    }

    setCheckBoxIndex(selectedCheckbox)
  }


  const handleSubmit = (param) => {
    if (param == 'once') {
      finalizeApi();
    } else if (param == 'every') {
      updateStandardPayApi();
    }

  }


  const updateStandardPayApi = () => {
    const data = {
      standard_pay: customAmount,
      employee_id: currentSalaryRowProp?.employee_id
    }
    GeneratePayrollApi.UpdateStandardpay(data).then((res) => {
      if (res.data.statusCode === 1003) {
        setUpdateStandardPayPopUpDrawer(false);
        finalizeApi();
        addSuccessMsg(res.data.message);
      } else {
        addErrorMsg(res.data.message);
        setUpdateStandardPayPopUpDrawer(false);

      }
    })
  }

  const finalizeApi = () => {
    const data = {
      employee_id: currentSalaryRowProp?.employee_id,
      payroll_payment_details_id: currentSalaryRowProp?.payroll_payment_detail_id,
      earned_amount: currentSalaryRowProp?.earned_pay ? currentSalaryRowProp?.earned_pay : 0,
      amount_paid: customAmount,
      balance_amount: currentBalance,
      deduction_amount: currentSalaryRowProp?.deduction_amount,
      reimbursement_amount: currentSalaryRowProp?.reimbursement_amount,
      payroll_configuration_id: payrollConfigId,
      is_finalized: true,
    }
    GeneratePayrollApi.FinalizeEmployeePayroll(data, currentSalaryRowProp?.payroll_payment_detail_id).then((res) => {
      if (res.data.statusCode === 1003) {
        getAllEmployees();
        setSuccessStandardPopUp(true);
        closePopUp();
        addSuccessMsg(res.data.message);

      } else {
        addErrorMsg(res.data.message);
        setUpdateStandardPayPopUpDrawer(false);


      }
    })
  }



  const handlePopup = () => {
    if(checkBoxIndex==3 && !visaTypes.includes(currentSalaryRowProp?.visa_type)){
      finalizeApi();
      return
    }

    if (isCustomPopUpOpen) {
      setUpdateStandardPayPopUpDrawer(true);
    } else {
      setUpdateStandardPayPopUpDrawer(false);
      finalizeApi();
    }
  }


  const tabs = [
    "Pay Cycle Details",
    "Timesheets",
    "Reimbursements",
    "Deductions",
    "Balance Sheet",
  ];

  const handleChangeTab = (param) => {
    setActive(param);
  }

  return (
    <div>
      <Box width={{ lg: '910px', md: "910px", sm: "700px", xs: "370px" }} height={'100vh'}  >
        <Grid item lg={12} md={12} xs={12} sm={12} p={3}>
          <Box className={classes.mainBox}>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2.5, }}>
              <Avatar
                alt={currentSalaryRowProp?.display_name}
                src={currentSalaryRowProp?.profile_picture_url}
                sx={{ width: 70, height: 72, "@media (min-width: 600px) and (max-width: 1220px)": {
                  width: 50, height: 52
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        width: 30, height: 32
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        width: 20, height: 22
                      },}}
              />
              <Box>
                <Text largeBlack22 sx={{"@media (min-width: 600px) and (max-width: 1220px)": {
                        fontSize: '7px  !important',
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        fontSize: '5px  !important',
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        fontSize: '4px  !important',
                      },}}>
                  {currentSalaryRowProp?.display_name && currentSalaryRowProp?.display_name.length > 16 ?
                    <BlackToolTip title={capitalizeAndAddSpace(currentSalaryRowProp?.display_name)} placement="top" arrow>
                      {capitalizeAndAddSpace(currentSalaryRowProp?.display_name).slice(0, 16) + (capitalizeAndAddSpace(currentSalaryRowProp?.display_name.length > 16 ? "..." : ""))}
                    </BlackToolTip>
                    : capitalizeAndAddSpace(currentSalaryRowProp?.display_name)
                  }
                </Text>
                <Text mediumGreyHeader mt="3px" sx={{"@media (min-width: 600px) and (max-width: 1220px)": {
                        fontSize: '7px  !important',
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        fontSize: '5px  !important',
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        fontSize: '4px  !important',
                      },}}>Emp ID: {currentSalaryRowProp?.emp_reference_id}</Text>
                <Text mediumGreyHeader mt="3px" sx={{"@media (min-width: 600px) and (max-width: 1220px)": {
                        fontSize: '7px  !important',
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        fontSize: '5px  !important',
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        fontSize: '4px  !important',
                      },}}>Email: <span style={{ textDecoration: "underline" }}>{currentSalaryRowProp?.email_id}</span></Text>
                <Text mediumGreyHeader mt="3px" sx={{"@media (min-width: 600px) and (max-width: 1220px)": {
                        fontSize: '7px  !important',
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        fontSize: '5px  !important',
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        fontSize: '4px  !important',
                      },}}>Phone Number:  <span style={{ textDecoration: "underline" }}>{currentSalaryRowProp?.contact_number}</span></Text>
              </Box>
            </Box>

            <Box className={classes.mainSubBox}>
              <Text largeWhite400 sx={{"@media (min-width: 600px) and (max-width: 1220px)": {
                        fontSize: '8px  !important',
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        fontSize: '5px  !important',
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        fontSize: '4px  !important',
                      },}}>Current Balance</Text>
              <Text largeWhite20 sx={{"@media (min-width: 600px) and (max-width: 1220px)": {
                        fontSize: '8px  !important',
                      },
                      "@media (min-width: 300px) and (max-width: 599px)": {
                        fontSize: '5px  !important',
                      },
                      "@media (min-width: 100px) and (max-width: 299px)": {
                        fontSize: '4px  !important',
                      },}}>$ {formatNumberWithCommas(currentBalance)}</Text>
            </Box>
          </Box>
          <Card className={classes.tabsContainer}>
            <Grid item container lg={12} md={12} sm={12} xs={12} className={classes.tabListMargin}>

              {tabs.map((label, index) => (
                <Button key={index} className={active === index ? classes.tabTextActive : classes.tabText} onClick={() => handleChangeTab(index)}>
                  {label}
                </Button>
              ))}

            </Grid>
          </Card>

        </Grid>
        <Box>
          {active === 0 && <PayCycleDetails summary={summary} activeProp={salaryOpen} currentSalaryRowProp={currentSalaryRowProp} closePopUp={closePopUp} setUpdateStandardPayPopUp={handlePopup} handlePayAmount={handlePayAmount} />}
          {active === 1 && <Timesheet summary={summary} currentSalaryRowProp={currentSalaryRowProp} timesheetListProp={timesheetListProp} closePopUp={closePopUp} setUpdateStandardPayPopUp={handlePopup} />}
          {active === 2 && <Reimbursements summary={summary} reimbursementDeductionProp={reimbursementDeductionProp} closePopUp={closePopUp} setUpdateStandardPayPopUp={handlePopup} />}
          {active === 3 && <Deductions summary={summary} currentSalaryRowProp={currentSalaryRowProp} reimbursementDeductionProp={reimbursementDeductionProp} closePopUp={closePopUp} setUpdateStandardPayPopUp={handlePopup} />}
          {active === 4 && <BalanceSheet summary={summary} currentSalaryRowProp={currentSalaryRowProp} allBalanceSheetProp={allBalanceSheetProp} closePopUp={closePopUp} balanceSheetPagination={balanceSheetPagination} balanceSheetPaginationTotal={balanceSheetPaginationTotal} setUpdateStandardPayPopUp={handlePopup} />}
        </Box>
      </Box>
      <ReusablePopup white iconHide statusWidth openPopup={updateStandardPayPopUp} onClose={() => { setUpdateStandardPayPopUpDrawer(false) }} setOpenPopup={setUpdateStandardPayPopUpDrawer} showTitle={true}>
        <Box>
          <Grid container display={"flex"} alignItems={'center'} justifyContent={'center'} sx={{ height: '380px', width: '100%', padding: '24px' }}>
            <Grid item >
              <img src={Updatestandard} alt="update-standard" />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"} alignItems={'center'} justifyContent={'center'} marginTop={'32px'}>
              <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Update Standard Pay?</Text>
              <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>It looks like mentioned amount is different from standard pay, Do you wish to update the standard pay with the mentioned amount?</Text>

            </Grid>
            <Grid item container display={'flex'} justifyContent={'space-around'} spacing={2} marginTop={'32px'} marginLeft={'3px'}>
              <Button blackCancel100 sx={{ width: '50% !important' }} onClick={() => handleSubmit('once')}>No, Only for this payroll</Button>
              <Button blueBtnSave sx={{ width: '40% !important' }} onClick={() => handleSubmit('every')}>Yes, Update</Button>
            </Grid>
          </Grid>
        </Box>

      </ReusablePopup>
      <ReusablePopup white iconHide fixedWidth openPopup={successStandardPopUp} setOpenPopup={setSuccessStandardPopUp} showTitle={true}>
        <Box>
          <Grid container display={"flex"} alignItems={'center'} justifyContent={'center'} sx={{ height: '300px', width: '100%', padding: '24px' }}>
            <Grid item width={'100%'} display={"flex"} alignItems={'center'} justifyContent={'center'}>
              <img src={SuccessStandard} alt="success-update-standard" />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"} alignItems={'center'} justifyContent={'center'} marginTop={'32px'}>
              <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Successful</Text>
              <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>Standard Pay has been Updated</Text>

            </Grid>
            <Grid item container display={'flex'} justifyContent={'space-around'} spacing={2} marginTop={'32px'} marginLeft={'3px'}>

              <Button blueBtnSave sx={{ width: '40% !important' }} onClick={() => { setSuccessStandardPopUp(false) }}>Done</Button>
            </Grid>
          </Grid>
        </Box>

      </ReusablePopup>
    </div>
  )
}

export default SalaryAmountDrawer
