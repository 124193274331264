import BaseApi from './BaseApi';
import APIURL from '../config/development';
import LocalStorage from '../utils/LocalStorage';

class InstalledAppsApi {

    getApps(status) {
        return BaseApi.getWithToken(APIURL.org_URL + `app-integrations?request_id=${LocalStorage.uid()}&status=${status}`, LocalStorage.getAccessToken());
    }
    getSingleApp(id) {
        return BaseApi.getWithToken(APIURL.org_URL + `app-integrations?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    getConfigureLink(region) {
        return BaseApi.getWithToken(APIURL.org_URL + `zoho/configuration?request_id=${LocalStorage.uid()}&domain=${region ? region : ''}`, LocalStorage.getAccessToken());
    }

    getZohoOrganizationList(data) {
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.org_URL + `zoho/organizations/list`, data, LocalStorage.getAccessToken());
    }

    getOrganizationFields() {
        return BaseApi.getWithToken(APIURL.org_URL + `zoho/fields/list?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    storeOrganizationFields(data) {
        return BaseApi.postWithTokenZoho(APIURL.org_URL + `zoho/finishConfiguration`, data, LocalStorage.getAccessToken());
    }
    deregisterOrganization(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho/de-register`, data, LocalStorage.getAccessToken());
    }

    getCountList() {
        return BaseApi.getWithParams(APIURL.org_URL + `app-integration-progress?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    getExport(status) {
        return BaseApi.getWithParams(APIURL.org_URL + `zoho-migration/export?request_id=${LocalStorage.uid()}&status=${status}`, LocalStorage.getAccessToken());
    }

    updateDisconnect(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho/disconnect`, data, LocalStorage.getAccessToken());
    }

    getQuickbooksLink() {
        return BaseApi.getWithParams(APIURL.org_URL + `quick-books/configuration?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    getQuickbooksLists() {
        return BaseApi.getWithParams(APIURL.org_URL + `quick-books/fields/list?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    finishQuickbooks(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `quick-books/finish-configuration`, data, LocalStorage.getAccessToken())
    }

    getQuickbooksOrgList() {
        return BaseApi.getWithParams(APIURL.org_URL + `quick-books/organization-details?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    deregisterQuickbooks(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `quick-books/de-register`, data, LocalStorage.getAccessToken())
    }

    disconnectQuickbooks(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `quick-books/disconnect`, data, LocalStorage.getAccessToken())
    }

    getQuickbookExport(status) {  
        return BaseApi.getWithParams(APIURL.org_URL + `quick-books/export?request_id=${LocalStorage.uid()}&status=${status}`, LocalStorage.getAccessToken());
    }
}
// eslint-disable-next-line
export default new InstalledAppsApi()