import React, { useState, useEffect, Fragment } from 'react';
import { Box, Grid, Divider, Stack } from '@mui/material';
import Input from '../../../../components/input/Input';
import Text from '../../../../components/customText/Text';// eslint-disable-next-line
import { isValid, validate_emptyField, validate_url, validate_url_optional, validates_Integer, } from "../../../../components/Validation";
import Button from '../../../../components/customButton/Button';
import EmployeeAPI from '../../../../apis/admin/employees/EmployeesApi';
import CommonApi from '../../../../apis/CommonApi';
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, openDocumentInNewtab, removeLoader } from '../../../../utils/utils';
import LocalStorage from "../../../../utils/LocalStorage";
// import SkillsSvg from "../../../../assets/svg/skillsSvg.svg";
// import EditSvg from "../../../../assets/svg/editIcon.svg";
// import DeleteSvg from "../../../../assets/svg/deleteIcon.svg";
import FileInput from '../../../../components/muiFileInput/FileInput';
import NoDataImg from "../../../../assets/images/no-data.png";
import { ReactComponent as Plus } from '../../../../assets/svg/plus.svg';
import LoadingButton from '../../../../components/customButton/LoadingButton';
// import FileSvg from "../../../../assets/svg/File.svg";
import FileSaver from 'file-saver';
import Datepicker from '../../../../components/datePicker/Date';
import { dateFormat } from '../../../../utils/utils';
import moment from "moment";
// import DownloadSvg from "../../../../assets/svg/downloadIcon.svg";
import Select from '../../../../components/select/Select';
import disablePlus from '../../../../assets/client/disablePlus.svg';
// import disableFile from '../../../../assets/client/disableDownload.svg';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../assets/svg/File.svg'
import LoadingScreen from '../documents/LoadingScreen';
import Sure from '../../../../assets/svg/placementPop.svg'
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Userplus from '../../../../assets/svg/plus.svg';

const expertiseDropdown = [
    {
        id: "Beginner",
        value: "Beginner"
    },
    {
        id: "Proficient",
        value: "Proficient"
    },
    {
        id: "Expert",
        value: "Expert"
    },
]
export default function Skills(props) {
    const { offBoardButton_enable } = props;
    const [form, setForm] = useState(false);
    const [editdeleteId, setEditdeleteId] = useState(null)
    const [list, setList] = useState([]);
    const [clearPopup, setclearPopup] = useState(false)
    const [state, setState] = useState(
        {
            skill_id: "",
            experience_years: "",
            certification: "",
            expertise: "",
            certification_date: "",
            certification_status: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: "",
                }
            ],
            clear_all: false
        }
    );
    const [error, setError] = useState({ skill_id: '' });
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [skillsDropdown, setSkillsDropdown] = useState([])
    const [employee, setEmployee] = useState(props.employee)
    useEffect(() => {
        setEmployee(props.employee)
    }, [props])
    useEffect(() => {
        getskillsDropdown();
        getSkills();
        // console.log(relationshipDropdown);
        // console.log(visaTypeDropdown);
        // eslint-disable-next-line
    }, []);

    const getSkills = () => {
        setLoading(true);
        EmployeeAPI.getSkillsListConsultant(LocalStorage.getUserData().login_id).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    let valueData = response.data.data
                    setList(response.data.data);
                    if (response.data.data.length > 0) {
                        response.data.data.forEach((i, index) => {
                            if (i.documents.length == 0) {
                                valueData[index].documents = {
                                    id: "",
                                    new_document_id: "",
                                    document_name: "",
                                }
                                setList(valueData);
                            }
                        })
                    }
                    setError({ skill_id: '' })
                } else {
                    addErrorMsg(response.data.message);
                    setError({ skill_id: '' })
                }
            }, 400)
        });
    }

    const getskillsDropdown = () => {
        let search = "";
        CommonApi.skillsDropdown(search).then((response) => {
            if (response.data.statusCode == 1003) {
                setSkillsDropdown(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    /* fun is for Uploading Documents */
    const uploadDocs = (value) => {
        // setLoader(true);
        addLoader(true)
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        CommonApi
            .documentUpload("skill-document", formData, LocalStorage.getAccessToken())
            .then((response) => {
                if (response.data.statusCode == 1003) {
                    let docInfo = response.data.data
                    let newStateObj = state;
                    newStateObj[value.target.name][0].id = '';
                    newStateObj[value.target.name][0].new_document_id = docInfo.id;
                    newStateObj[value.target.name][0].document_name = response.data.data.document_name;
                    newStateObj[value.target.name][0].document_url = response.data.data.document_url;
                    handleDocumentValidations({ name: "documents", value: docInfo.id });
                    state.clear_all = false
                    setState({ ...newStateObj });
                    removeLoader()
                } else {
                    removeLoader()
                    addErrorMsg(response.data.message);
                }
            });
    };



    const handleChange = (e) => {
        state.clear_all = false
        setState({ ...state, [e.target.name]: e.target.value })
        if (e.target.name === 'skill_id') {
            setError({
                ...error,
                skill_id: ''
            })
        }
        handleValidations(e.target);
    };

    const handleChangeDate = (e, name) => {
        state.clear_all = false
        let data = { name: name, value: moment(e.$d).format(dateFormat()) }
        setState({ ...state, [data.name]: data.value })
        // handleValidations(data);
    };

    // eslint-disable-next-line
    const handleValidations = (input) => {
        // let input = e.target;
        let err = error;


        switch (input.name || input.tagName) {
            case "skill_id":
                err.skill_id = '';
                break;
            case "experience_years":
                err.experience_years = '';
                break;
            case "certification":
                err.certification = validate_url_optional(input.value);
                break;
            case "expertise":
                err.expertise = ''
                break;
            case "certification_date":
                err.certification_date = ''
                break;
            case "certification_status":
                err.certification_status = ''
                break;
            default:
                break;
        }
        
        setError(err);
    }

    const handleDocumentValidations = (input) => {
        let err = error;
        switch (input.name) {
            case "documents":
                err.documents = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }
    // eslint-disable-next-line
    const validateAll = () => {
        let {
            skill_id,
            experience_years,
            certification,
            expertise,
            certification_date,
            certification_status,
        } = state;
        let errors = {};
        errors.skill_id = validate_emptyField(skill_id);
        errors.experience_years = validate_emptyField(experience_years);
        errors.certification = validate_emptyField(certification);
        errors.expertise = validate_emptyField(expertise);
        errors.certification_date = validate_emptyField(certification_date);
        errors.certification_status = validate_emptyField(certification_status);
        if (form === "update") {
            if (state.documents[0].id === "" && state.documents[0].new_document_id === "") {
                errors.documents = validate_emptyField("");
            }
        } else {
            errors.documents = validate_emptyField(state.documents[0].new_document_id);
        }

        return errors;
    };

    const handleSubmit = () => {
        // let errors = validateAll();
        // console.log(error,'errors')
        if (error.skill_id !== '' && error.skill_id != undefined) {
            return true
        }
        if (error.certification && error.certification != '') {
            if(state.skill_id == ''){
                error.skill_id = 'This field is required'
                error.certification = 'Please enter a valid URL'
                setError({ ...error })
            }
            return true
        }
        let data = {
            document_id: state.documents[0].new_document_id,
            skill_id: state.skill_id,
            experience_years: state.experience_years,
            certification: state.certification,
            expertise: state.expertise,
            certification_date: state.certification_date,
            certification_status: state.certification_status,
        }
        if (true) {
            if (form === "add") {
                if (isValid(data)) {
                    setForm(false)
                    getSkills()
                    return true
                }
                else {
                    if (state.skill_id === '') {
                        error.skill_id = 'This field is required'
                        setError({ ...error })
                        if (state.certification_date !== "" && state.certification_date !== "Invalid date") {
                            const certifiedData = moment(state.certification_date,dateFormat()).format(dateFormat());
                            if (moment(certifiedData).isSameOrBefore(moment(employee.dob,dateFormat()).format(dateFormat()))) {
                                addErrorMsg('Certified date should be greater than the Employee’s DOB')
                            }
                        }
                    }
                    else {
                        if (state.certification_date !== "" && state.certification_date == "Invalid date") {
                            state.certification_date = "";
                            setState({ ...state })
                        }
                        if (state.certification_date !== "" && state.certification_date !== "Invalid date") {
                            const certifiedData = moment(state.certification_date,dateFormat()).format(dateFormat());
                            if (moment(certifiedData).isSameOrBefore(moment(employee.dob,dateFormat()).format(dateFormat()))) {
                                addErrorMsg('Certified date should be greater than the Employee’s DOB')
                                return false;
                            }
                        }
                        storeSkillsDetails()
                    }
                }
            } else if (form === "update") {
                if (isValid(data)) {
                    state['clear_all'] = true;
                }
                if (state.clear_all == false && state.skill_id === '') {
                    error.skill_id = 'This field is required'
                    setError({ ...error });
                    if (state.certification_date !== "" && state.certification_date !== "Invalid date") {
                        const certifiedData = moment(state.certification_date,dateFormat()).format(dateFormat());
                        if (moment(certifiedData).isSameOrBefore(moment(employee.dob,dateFormat()).format(dateFormat()))) {
                            addErrorMsg('Certified date should be greater than the Employee’s DOB')
                        }
                    }
                }
                else {
                    if (state.certification_date !== "" && state.certification_date == "Invalid date") {
                        state.certification_date = "";
                        setState({ ...state })
                    }
                    if (state.certification_date !== "" && state.certification_date !== "Invalid date") {
                        const certifiedData = moment(state.certification_date,dateFormat()).format(dateFormat());
                        if (moment(certifiedData).isSameOrBefore(moment(employee.dob,dateFormat()).format(dateFormat()))) {
                            addErrorMsg('Certified date should be greater than the Employee’s DOB')
                            return false;
                        }
                    }
                    updateSkillsDetails();
                }
            }
           
        } else {
            // 
            // setError(errors);
        }
    }

    const storeSkillsDetails = () => {
        let data = { ...state, request_id: LocalStorage.uid(), employee_id: LocalStorage.getUserData().login_id };
        // let data = { ...state, education_level_id: 1, state_id: 1, country_id: 1, request_id: LocalStorage.uid(), employee_id: LocalStorage.getUserData().login_id };

        // console.log(data, "  Store data");

        // return false;

        setLoading(true)
        EmployeeAPI.storeSkillsDetailsConsultant(data, LocalStorage.getUserData().login_id).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message)
                    getSkills()
                    setForm(false);
                    props.setformEdit(false);
                    props.getEmployeeApi()
                    props.getActivity()
                    setEditdeleteId(null)

                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const updateSkillsDetails = () => {
        let data = { ...state, employee_id: LocalStorage.getUserData().login_id, request_id: LocalStorage.uid() };
        setLoading(true)
        EmployeeAPI.updateSkillsDetailsConsultant(data, editdeleteId).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    getSkills();
                    setForm(false);
                    setEditdeleteId(null);
                    props.setformEdit(false);
                    props.getEmployeeApi()
                    props.getActivity()
                    addSuccessMsg(response.data.message);
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }
    
    // eslint-disable-next-line
    const handleUpdate = (key) => {
        const data = list[key]; 
        let updateData = {
            ...data, 
            documents: [] 
        };
        if (data.documents && data.documents.length > 0) {
            updateData.documents = [
                {
                    ...data.documents[0],
                    new_document_id: "",
                    document_name: data.documents[0].name,
                }
            ];
        } else {
            updateData.documents = [
                {
                    id: '',
                    new_document_id: "",
                    document_name: '',
                }
            ];
        }
    
        setForm("update");
        setError({});
        setEditdeleteId(data.id);
        setState(updateData);
    };
    

    const openAddForm = () => {
        let obj = {
            skill_id: "",
            experience_years: "",
            certification: "",
            expertise: "",
            certification_date: "",
            certification_status: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: "",
                }
            ]
        };
        setState(obj)
        setError({})
        setForm("add")
    }
    // eslint-disable-next-line
    const downloadDoc = (fileUrl) => {
        FileSaver.saveAs(fileUrl);
    }

    const experienceOptions = [
        {
            id: 1,
            value: '1'
        },
        {
            id: 2,
            value: '2'
        },
        {
            id: 3,
            value: '3'
        },
        {
            id: 4,
            value: '4'
        },
        {
            id: 5,
            value: '5'
        },
        {
            id: 6,
            value: '6'
        },
        {
            id: 7,
            value: '7'
        },
        {
            id: 8,
            value: '7+ years'
        }
    ]

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const clearAll = () => {
        setState({
            ...state,
            skill_id: "",
            experience_years: "",
            certification: "",
            expertise: "",
            certification_date: "",
            certification_status: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: "",
                }
            ],
            clear_all: true
        })
        setError({
            ...error,
            skill_id: ''
        })
        setclearPopup(false)
    }


    return (
        <Box>
            <Box py={2} px={1}>

                <Box pb={2} my={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                    {["add", "update"].includes(form) ? '' :
                        <Text sx={{ paddingLeft: '10px' }} mediumBoldBlack>
                            {form === "add" ? "Add New Skill" : form === "update" ? "Update Skill" : "Skills"}
                        </Text>
                    }
                    {
                        list.length === 0 ? '' :
                            !["add", "update"].includes(form) ?
                                true ?
                                    offBoardButton_enable === true ? '' :
                                        <Box display={"flex"} justifyContent={"end"}>
                                            <Button addNew startIcon={<Plus />} onClick={() => { openAddForm(); props.setformEdit(true); }}> Add Skills</Button>
                                        </Box> :
                                    <Box display={"flex"} justifyContent={"end"}>
                                        <Button addNewDisable startIcon={<img src={disablePlus} alt='add' />}>Add Skills</Button>
                                    </Box> : null
                    }

                </Box>


                {
                    ["add", "update"].includes(form) ? (
                        <Fragment>
                            <Box p={2} sx={{ height: "65vh", overflowY: 'scroll', boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }} >
                                <Grid container spacing={0} pl={1}>
                                    <Grid item xs={12}>
                                        <Box p={1} pl={2} pt={4}>
                                            <Text largeBlue>
                                                {form === "add" ? "New Skill" : form === "update" ? "Update Skill" : "Skills"}
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid lg={4} md={6} sm={6} xs={12}>
                                        <Box p={2}>
                                            <Select
                                                options={skillsDropdown}
                                                name='skill_id'
                                                value={state.skill_id}
                                                onChange={handleChange}
                                                label={'Skill Name (Optional)'}
                                                error={error.skill_id}
                                            />

                                            <Text errorText> {error.skill_id ? error.skill_id : ""}</Text>
                                        </Box>

                                    </Grid>
                                    <Grid lg={4} md={6} sm={6} xs={12}>
                                        <Box p={2}>
                                            <Select
                                                label='Level Of Expertise (Optional)'
                                                options={expertiseDropdown}
                                                name='expertise'
                                                value={state.expertise}
                                                onChange={handleChange}
                                            />
                                            <Text errorText> {error.expertise ? error.expertise : ""}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid lg={4} md={6} sm={6} xs={12}>
                                        <Box p={2}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'certification',
                                                    value: state.certification,
                                                    type: 'text'
                                                }}
                                                handleChange={handleChange}
                                                clientInput
                                                labelText={'Certification Link or URL (Optional)'}
                                            />
                                            <Text errorText> {error.certification ? error.certification : ""}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid lg={4} md={6} sm={6} xs={12}>
                                        <Box p={2}>
                                            <Datepicker
                                                labelText={"Certified Date (Optional)"}
                                                name={"certification_date"}
                                                value={state.certification_date}
                                                maxDate={moment().format(dateFormat())}
                                                onChange={(e) => handleChangeDate(e, "certification_date")}
                                                minDate={moment(employee?.dob,dateFormat()).add(1, 'days').format(dateFormat())}
                                            />
                                            <Text errorText> {error.certification_date ? error.certification_date : ""}</Text>
                                        </Box>
                                    </Grid>

                                    <Grid lg={4} md={8} sm={8} xs={12}>
                                        <Box p={2}>
                                            <Select
                                                label='Years of Experience (Optional)'
                                                options={experienceOptions}
                                                name='experience_years'
                                                value={state.experience_years}
                                                onChange={handleChange}
                                            />

                                            <Text errorText> {error.experience_years ? error.experience_years : ""}</Text>
                                        </Box>
                                    </Grid>

                                    <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Box p={2}>
                                            <FileInput
                                                name={"documents"}
                                                FileName={state.documents[0].document_name}
                                                handleChange={uploadDocs}
                                                label={"Upload Certificate (Optional)"}
                                                handleDelete={() => {
                                                    state.documents[0].document_name = ''
                                                    state.documents[0].document_url = ''
                                                    state.documents[0].new_document_id = ''
                                                    state.documents_deleted_ids = [state.documents[0].id]
                                                    setState({ ...state })
                                                }}
                                                actionState={state.documents[0].document_name ? 1 : ''}
                                                documentUrl={state.documents[0].document_url}
                                                state={state.documents[0]}
                                            />
                                            <Text errorText> {error.documents ? error.documents : ""}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item xs={6}>
                                            <Box my={2} px={1} pl={2} display={"flex"}>
                                                {
                                                    ["add", "update"].includes(form) ?
                                                        <Button cancelOutlineQuicksand onClick={() => { setForm(false); getSkills(); props.setformEdit(false); }}>
                                                            Cancel
                                                        </Button> : null
                                                }

                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} container justifyContent={'flex-end'}>
                                            <Stack direction={'row'}>
                                                <Box my={2} pr={3} px={1} display={"flex"} justifyContent={"end"} gap={3}>
                                                    <Button clearAllRed onClick={() => { setclearPopup(true) }}>Clear all</Button>

                                                </Box>
                                                <Box my={2} pr={3} px={1} display={"flex"} justifyContent={"end"} gap={3}>
                                                    <LoadingButton saveLoader loading={loading} onClick={() => handleSubmit()}>
                                                        Save
                                                    </LoadingButton>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>



                        </Fragment>
                    ) :
                        loading ?
                            <Grid container>
                                <LoadingScreen />
                            </Grid>
                            :
                            (
                                <Fragment>
                                    <Grid pl={2} pr={1} container lg={12} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                                        {
                                            !loading &&
                                            list.map((item, key) => (
                                                <Grid container spacing={0}>
                                                    <Grid item lg={11} md={11} sm={11} xs={11} pt={2}>
                                                        <Box p={1} pl={2} pt={2}>
                                                            <Text largeBlue>
                                                                Skills - {key + 1}
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        ["add", "update"].includes(form) ? '' :

                                                            employee.is_off_boarding_initiated === false ?
                                                                <Grid item lg={1} md={1} sm={1} xs={1} pt={2}>
                                                                    <Box p={1} pr={0}>
                                                                        <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { handleUpdate(key); props.setformEdit(true); }} />
                                                                    </Box>
                                                                </Grid>
                                                                : ''
                                                    }
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Box p={1} pt={1} pl={2}>
                                                            <Text mediumLabel>
                                                                Skill Name (Optional)
                                                            </Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {item.skill_name}
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Box p={1} pt={1} pl={2}>
                                                            <Text mediumLabel>
                                                                Level of Expertise (Optional)
                                                            </Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {item.expertise ? item.expertise : '-'}
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Box p={1} pt={1} pl={2}>
                                                            <Text mediumLabel>
                                                                Certification Link/URL (Optional)
                                                            </Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {
                                                                    item.certification ?
                                                                        <BlackToolTip arrow placement='top' title={item.certification}>
                                                                            {item.certification.slice(0, 16)} {item.certification.length > 16 ? '...' : ''}
                                                                        </BlackToolTip>
                                                                        :
                                                                        '-'
                                                                }

                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Box p={1} pt={1} pl={2}>
                                                            <Text mediumLabel>
                                                                Certified Date (Optional)
                                                            </Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {item.certification_date ? item.certification_date : '-'}
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Box p={1} pt={1} pl={2}>
                                                            <Text mediumLabel>
                                                                Years of Experience (Optional)
                                                            </Text>
                                                            <Text pt={1} mediumBlack14>
                                                                {item.experience_years == 8 ? '7+ Experience' : item.experience_years ? item.experience_years : '-'}
                                                            </Text>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                                        <Box p={1} pt={1} pl={2}>
                                                            <Text mediumLabel>
                                                                Uploaded Certificate (Optional)
                                                            </Text>
                                                            {item.documents && item.documents.length > 0 ?
                                                                item.documents.map((arg, index) => (
                                                                    <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(arg, arg.document_url) }}>
                                                                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                                        <Text mediumBlue>
                                                                            <BlackToolTip arrow placement='top' title={arg.name}>
                                                                                {arg.name && arg.name.slice(0, 16)} {arg.name && arg.name.length > 16 ? '...' : ''}
                                                                            </BlackToolTip>
                                                                        </Text>
                                                                    </Stack>
                                                                )) : '-'}
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        key === list.length - 1 ?
                                                            <Box height={'10vh'}></Box> :
                                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={4} pb={2}>
                                                                <Divider variant='middle' sx={{ color: '#E2E5E6' }} ></Divider>
                                                            </Grid>
                                                    }
                                                </Grid>
                                            ))
                                        }
                                    </Grid>


                                    {
                                        !loading && list.length === 0 ?
                                            <Box sx={{ height: "65vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }} >
                                                <Box>
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                        <img src={NoDataImg} alt='no-data' />
                                                    </Grid>
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                        <Text largeBlack>Skill Details Not Uploaded</Text>
                                                    </Grid>
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                        <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload Skills for this employee by clicking on the below button.</Text>
                                                    </Grid>
                                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                        <Button finishFilledQuicksandLargeWidth onClick={() => { openAddForm(); props.setformEdit(true); }}> <img src={Userplus} alt='plus' /> Add Skills</Button>
                                                    </Grid>
                                                </Box>
                                            </Box> : null
                                    }

                                </Fragment>
                            )}

            </Box>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Box >
    );
}