import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const SidebarStyles = makeStyles(() => ({

    // sidebar

    sidebar: {
        position: "fixed",
        maxWidth: "94px",
        width: "100%",
        background: "#FFFFFF",
        top: 0,
        left: 0,
        height: "100%",
        overflowY: "auto",
        overflowX: 'hidden',
        scrollbarWidth: "none",
        transition: "all .3s ease",
        zIndex: 200,
        boxShadow: "3px 3px 5px -1px rgba(0, 0, 0, 0.05)",
        "&::-webkit-scrollbar": {
            display: 'none',
        },
        "@media (min-width: 800px) and (max-width: 1400px)": {
            maxWidth: "74px"
        }
    },
    sidebar2: {
        position: "fixed",
        maxWidth: "94px",
        width: "100%",
        background: "#FFFFFF",
        top: 0,
        left: 0,
        height: "100%",
        overflowY: "auto",
        overflowX: 'hidden',
        scrollbarWidth: "none",
        transition: "all .3s ease",
        zIndex: 200,
        // boxShadow: "3px 3px 5px -1px rgba(0, 0, 0, 0.05)",
        "&::-webkit-scrollbar": {
            display: 'none',
        },
        "@media (min-width: 800px) and (max-width: 1400px)": {
            maxWidth: "74px"
        }
    },

    sidebarOpen: {
        maxWidth: "260px !important",
        "@media (min-width: 1550px) and (max-width: 1649px)": {
            width: "273px !important",
            maxWidth: "273px !important",
        },
        "@media (min-width: 1650px) and (max-width: 1679px)": {
            width: "284px !important",
            maxWidth: "284px !important",
        },
        "@media (min-width: 1680px) and (max-width: 1729px)": {
            width: "290px !important",
            maxWidth: "290px !important",
        },
        "@media (min-width: 1730px) and (max-width: 1849px)": {
            width: "300px !important",
            maxWidth: "300px !important",
        },
        "@media (min-width: 1850px)": {
            width: "325px !important",
            maxWidth: "325px !important",
        }
    },

    brand: {
        fontSize: "24px",
        display: "flex",
        alignItems: "center",
        height: "64px",
        fontWeight: 600,
        color: "rgba(24, 26, 24, 1)",
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 100,
        background: "rgba(255, 255, 255, 1)",
        transition: "all .3s ease",
        // padding: "0 18px",
        padding: "0 27px",
        "@media (min-width: 800px) and (max-width: 1400px)": {
            padding: "0 5px",
        }
    },

    sideMenu: {
        margin: "30px 0px 30px 5px",
        // padding: "0 20px",
        padding: "0px 20px 0px 15px",
        transition: "all .3s ease",
        backgroundColor: "rgba(255, 255, 255, 1)",
        height: '100%',
        "@media (min-width: 800px) and (max-width: 1400px)": {
            padding: "0 5px",
            height: '60%',
        },
        overflowX: 'hidden',
        overflowY: 'auto',
        "@media (min-height: 0px) and (max-height: 550px)": {
            height: '61%',
        },
        "&::-webkit-scrollbar": {
            display: "none !important"
        },
    },
    sidebarLink: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        color: "rgba(24, 26, 24, 1)",
        padding: "12px 15.5px",
        transition: "all .3s ease",
        borderRadius: "8px",
        margin: "6px 0",
        whiteSpace: "nowrap",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "rgba(12, 117, 235, 1)",
            color: "#FFFFFF !important",
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px rgba(12, 117, 235, 0.30) !important'
        }
    },
    sidebarLinkCodetru: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        color: "rgba(24, 26, 24, 1)",
        padding: "12px 15.5px",
        transition: "all .3s ease",
        borderRadius: "8px",
        margin: "6px 0",
        whiteSpace: "nowrap",
    },

    sidebarLinkActive: {
        backgroundColor: "rgba(12, 117, 235, 1)",
        color: "#FFFFFF",
        transition: "all .3s ease",
        boxShadow: '0px 0px 15px 1px rgba(12, 117, 235, 0.30) !important'
    },

    sidebarLinkTextMs: {
        margin: "0px 16px",
        transition: "all .3s ease",
    },
    sidebarLinkTextMl: {
        margin: "0px 40px",
        transition: "all .3s ease",
    },

    sideDropdown: {
        marginLeft: "42px",
        paddingLeft: "8px",
        maxHeight: "0px",
        overflowY: "hidden",
        transition: "all .3s ease",
        "&::-webkit-scrollbar": {
            display: 'none',
        },

        borderLeft: "1px solid rgba(12, 117, 235, 1)"
    },

    sideDropdownShow: {
        maxHeight: "1000px",
        transition: "all .3s ease",
    },
    sidebarSideDropdownLink: {
        display: "flex",
        alignItems: "center",
        color: "rgba(24, 0, 24, 1)",
        padding: "10px 14px",
        transition: "all .3s ease",
        borderRadius: "10px",
        margin: "4px 0",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontFamily: "Quicksand",
        fontWeight: "500",
    },

    menuSelected: {
        display: "flex",
        alignItems: "center",
        color: "#0C75EB",
        padding: "10px 14px",
        transition: "all .3s ease",
        borderRadius: "10px",
        margin: "4px 0",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontFamily: "Quicksand",
        fontWeight: "500",
    },

    sideTooltip: {
        height: "34px",
        width: "100%",
        display: "flex",
        alignItems: "center",
    },

    sideTooltipText: {
        fontSize: "15px",
        fontFamily: "Quicksand",
        fontWeight: "500",
        color: "#FFFFFF",
    },

    nestedListMainContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    nestedListContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bottomTextPosition: {
        position: "fixed",
        bottom: "0px",
        paddingBottom: 5,
        padding: '0px 45px 0px 0px !important',
        background: 'rgba(255, 255, 255, 1)',
        textAlign: 'center',
    },
    bottomTextPositionCls: {
        position: "fixed",
        bottom: "0px",
        paddingBottom: 5,
        textAlign: 'left',
        padding: '0px',
        background: 'rgba(255, 255, 255, 1)'
    },
    logo: {
        height: '50px !important',
        width: '85px !important'
    },
    logoCls: {
        height: '35px !important',
        width: '55px !important',
    },
    versionText: {
        font: '8px !important',
        textDecoration: '1px underline blue !important',
        color: 'blue !important'
    },
    zohoProgress: {
        width: '100%', paddingRight: '0px', marginLeft: '-15px', marginTop: '10px', marginBottom: '-10px',
        [useTheme().breakpoints.down('xl')]: {
            marginLeft: '0px',
        },
        "@media (min-width: 1401px)": {
            marginLeft: '-15px'
        }
    },
    zohoProgress1: {
        width: '100%', marginLeft: '0px', marginTop: '10px', paddingRight: '10px', marginBottom: '-10px',
        [useTheme().breakpoints.down('xl')]: {
            marginLeft: '0px',
        },
        "@media (min-width: 1401px)": {
            marginLeft: '-15px'
        }
    },
    payrollSideMenuActive: {
        padding: '10px 14px', color: '#fff', background: '#0C75EB', font: '15px Quicksand !important', borderRadius: "10px", cursor: 'pointer'
    },
    payrollSideMenu: {
        padding: '10px 14px', color: 'rgba(24, 26, 24, 1)', font: '15px Quicksand !important', borderRadius: "10px", cursor: 'pointer'
    },
}))


export default SidebarStyles;