import { Box, Grid, Hidden } from "@mui/material";
import React from "react";
import Text from "../../../components/customText/Text";
import Button from "../../../components/customButton/Button";
import { NoDataFound } from "../../../utils/utils";
import banner from '../../../assets/svg/dashboard/dashboard-banner.svg';
import ReactApexChart from 'react-apexcharts';
import FinancialPerformance from './FinancialPerformance';
import EmployeeAndClientAttribution from './EmployeeAndClientAttribution';
import moment from "moment";

function DetailsDashboard(props) {
    const { localDataStorage, planDetails, tsSeries, tsData, manageSubscription, rolePermission, dropDownChangeHandler, financial, classes, currency, placementsCompleted, years, totalEmp, // eslint-disable-next-line
        capitalizeAndAddSpace, companies, yearDropdwn, areaOptions, placementsAdded, placementDetails, navigateToTimesheet, navigate, viewAnalytics, setYearDropdwn, getApi, getPlacementApi }
        = props

    const financialOptions = {
        series: financial.financialData.series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#0C75EB', '#F6BA1E', '#8763FC'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#0C75EB', '#F6BA1E', '#8763FC'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const label = financial?.financialData?.labels.map(label => label.substring(0, 3))[dataPointIndex];
                const dates = financial?.financialData?.dates
                // Extract the values for the current data point across all series
                const data = series.map((values, index) => ({
                    name: w.config.series[index].name,  // Get the name of the series (e.g., "Total Bill")
                    value: parseFloat(values[dataPointIndex]),  // Convert the value to a number
                    color: w.config.colors[index]  // Get the color for the series
                }));
                // Sort the data by value in descending order
                const sortedData = data.sort((a, b) => b.value - a.value);  // Sort descending
                // Create the tooltip content with sorted values and bullet points
                const tooltipContent = sortedData
                    .map(
                        (item) =>
                            `<div style="margin: 2px 0; display: flex; align-items: center;height:25px">
                                <span style="font-size: 35px; color: ${item.color}; margin-right:5px;">&#8226;</span> <!-- Bullet point -->
                                <p style="font-size: 12px;">${item.name}:</p>&nbsp;$ &nbsp;<b >${item.value.toFixed(2)}</b>
                            </div>`
                    )
                    .join('');
                // Return the formatted tooltip content with the header
                return `
                    <div style=" background: #fff; border-radius: 5px;">
                    <div style="background:#FAFAFA;padding: 5px 10px;">
                    <span style="font-size: 12px;color:#262626;">${label} ${moment(dates[dataPointIndex]?.start).format("YYYY")}</span>
                    </div>
                        <div style="padding: 0px 10px">${tooltipContent}</div>
                    </div>
                `;
            }
        },

        // tooltip: {
        // x: {
        //     formatter: function (val) {
        //         return monthsOptions[val - 1].value + ' ' + yearDropdwn.year;
        //     }
        // },
        // y: {
        //     formatter: function (val) {
        //         return "$" + val;
        //     }
        // },

        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: financial.financialData.labels.map(label => label.substring(0, 3))
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return "$ " + val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        colors: ['#0C75EB', '#F6BA1E', '#8763FC']
    }

    const linearProgress = [
        {
            bgColor: '#0095FF',
            barColor: '#CDE7FF'
        },
        {
            bgColor: '#00E096',
            barColor: '#D4FFEB'
        },
        {
            bgColor: '#884DFF',
            barColor: '#E7DBFF'
        },
        {
            bgColor: '#FF8F0D',
            barColor: '#FFEBD4'
        },
        {
            bgColor: '#D34040',
            barColor: '#FFC8C8'
        },
        {
            bgColor: '#0095FF',
            barColor: '#CDE7FF'
        },
        {
            bgColor: '#00E096',
            barColor: '#D4FFEB'
        },
        {
            bgColor: '#884DFF',
            barColor: '#E7DBFF'
        },
        {
            bgColor: '#FF8F0D',
            barColor: '#FFEBD4'
        },
        {
            bgColor: '#D34040',
            barColor: '#FFC8C8'
        },
    ]

    const pieData = {
        options: {
            dataLabels: {
                enabled: false
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false,
            },
            labels: totalEmp.labels,
            colors: ['#9BDFC4', '#F99BAB'],
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    // w is the chart context containing various properties including series
                                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                },
                            },
                        },
                    },
                },
            },
        },
        series: totalEmp.series,
        labels: totalEmp.labels
    };

    const timesheetOptions = {
        series: tsSeries.tsSeriesData,
        options: {
            chart: {
                width: '100%',
                type: 'pie',
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            labels: ["Drafted", "Pending Approval", "Approved", "Rejected"],
            colors: tsSeries.tsColors,
            stroke: {
                width: 0,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: 0
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
    }

    return (
        <Grid item container lg={8} md={8} sm={12} xs={12} pt={3} pr={{ lg: 3, md: 3, sm: 2, xs: 0 }} className={classes.container}>
            <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                <Text boldblack22>Hi, {localDataStorage.full_name}</Text>
                <Text black400 sx={{ paddingTop: '6px' }}>Here's how your dashboard is looking today</Text>
            </Grid>
            {
                localDataStorage.super_admin && planDetails.plan_banner ?
                    <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                        <Box className={classes.bannerbg}>
                            <Grid item container lg={12} md={12} sm={12} xs={12}>
                                <Grid item container xl={9} lg={8} md={7} sm={12} xs={12}>
                                    <Grid item lg={12} xs={12}>
                                        <Text largeWhite18 sx={{ marginBottom: '8px' }}>Plan Expiring Soon!</Text>
                                        <Text mediumBoldWhite400>It looks like your plan will expire on {planDetails.plan_expires_on}, Please renew or<br /> upgrade your plan to use our application</Text>
                                        <Box pt={3} sx={{ width: '190px' }}>
                                            <Button manageSubscription onClick={() => { manageSubscription() }}>Manage Subscription</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Hidden mdDown>
                                    <Grid item container xl={3} lg={4} md={5} textAlign='end'>
                                        <img loading="eager" src={banner} alt="banner" width='224' height='153' />
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Box>
                    </Grid> : ''
            }
            {((localDataStorage.super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true)))) ?
                <>
                    <FinancialPerformance years={years} currency={currency} classes={classes} financialOptions={financialOptions} viewAnalytics={viewAnalytics} financial={financial} dropDownChangeHandler={dropDownChangeHandler} yearDropdwn={yearDropdwn} setYearDropdwn={setYearDropdwn} getApi={getApi} />
                    <EmployeeAndClientAttribution NoDataFound={NoDataFound} dropDownChangeHandler={dropDownChangeHandler} classes={classes} placementsCompleted={placementsCompleted} years={years} totalEmp={totalEmp} pieData={pieData} linearProgress={linearProgress}
                        capitalizeAndAddSpace={capitalizeAndAddSpace} companies={companies} yearDropdwn={yearDropdwn} areaOptions={areaOptions} placementsAdded={placementsAdded} placementDetails={placementDetails} navigate={navigate} getPlacementApi={getPlacementApi} setYearDropdwn={setYearDropdwn} />
                    <Grid item container lg={12} xs={12} pb={5} justifyContent='center'>
                        <Grid item lg={5} className={classes.borderRight}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Text smallLabelblack16>Timesheets</Text>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                <ReactApexChart
                                    options={timesheetOptions.options}
                                    series={timesheetOptions.series}
                                    type="pie"
                                    height='250px'
                                />
                            </Grid>
                        </Grid>
                        <Grid item container lg={7} md={12} sm={12} xs={12} textAlign='center' justifyContent='center' alignItems='center' className={classes.pendingTSheight}>
                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                        <Box className={classes.blueDot} />
                                        <Text blackHeader1>Drafted</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigateToTimesheet('Draft')}>{tsData.drafted}</Text>
                                </Grid>
                            </Grid>
                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                        <Box className={classes.yellowDot} />
                                        <Text blackHeader1>Pending for approval</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigateToTimesheet('Pending Approval')} >{tsData.pending_approval}</Text>
                                </Grid>
                            </Grid>
                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                        <Box className={classes.greenDot} />
                                        <Text blackHeader1>Approved</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigateToTimesheet('Approved')} >{tsData.approved}</Text>
                                </Grid>
                            </Grid>
                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                        <Box className={classes.pinkDot} />
                                        <Text blackHeader1>Rejected</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigateToTimesheet('Rejected')} >{tsData.rejected}</Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : ''}
        </Grid>
    )
}
export default DetailsDashboard