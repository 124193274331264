import { InputAdornment, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl';
// @material-ui/core components
import Check from '@mui/icons-material/Check'
// @material-ui/icons
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
// core components
import validator from 'validator'
import Text from '../customText/Text';
import InputStyles from './InputStyles';
import { BlackToolTip } from '../../utils/utils';
import InfoIcon from '../../assets/svg/Information.svg';
import { makeStyles } from "@mui/styles";
import Eye from '../../assets/svg/eye.svg';
import closeEye from '../../assets/svg/NotVisible.svg'
import { inputLabelClasses } from "@mui/material/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid #C7CCD3 !important`,
    background: "#FFFFFF !important",
    font: '14px Quicksand !important',
    fontWeight: `${600} !important`,
    opacity: 1,
    borderRadius: '8px !important',
    "& .MuiFilledInput-root": {
      background: "white !important",
      font: '14px Quicksand !important',
      fontWeight: `${600} !important`,
    },
    "&.Mui-focused": {
      backgroundColor: "white !important"
    },
    "& .MuiFormLabel-asterisk": {
      color: "red !important"
    },
    "& .MuiFormLabel-root": {
      // color: '#737373 !important',
      font: '14px Quicksand !important',
      marginTop: '2px',
      fontWeight: `${400} !important`,
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
  },
  rootDisabled: {
    background: "#FAFAFA !important",
    borderRadius: '8px !important',
    border: '1px solid #FAFAFA !important',
    '-webkit-text-fill-color': "rgb(16 16 16 / 73%) !important",
    font: '15px Quicksand !important',
    "& .MuiFilledInput-root": {
      background: "#FAFAFA !important",
      '-webkit-text-fill-color': "rgb(16 16 16 / 73%) !important",
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      color: 'rgb(16 16 16 / 73%) !important',
      '-webkit-text-fill-color': "rgb(16 16 16 / 73%) !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "rgb(16 16 16 / 73%) !important",
    },
  },
  commentsError: {
    font: '14px Quicksand !important',
    fontWeight: `${600} !important`,
    opacity: 1,
    borderRadius: '8px !important',
    "&.MuiFormControl-root": {
      background: 'red !important',
    },
    "& .MuiFilledInput-root": {
      background: "white !important",
      font: '14px Quicksand !important',
      fontWeight: `${600} !important`,
    },
    "&.Mui-focused": {
      backgroundColor: "white !important"
    },
    "& .MuiFormLabel-root": {
      // color: '#737373 !important',
      font: '14px Quicksand !important',
      marginTop: '2px',
      fontWeight: `${400} !important`,
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
  },
  error: {
    border: `1px solid red !important`,
    background: "#FFFFFF !important",
    font: '14px Quicksand !important',
    fontWeight: `${600} !important`,
    opacity: 1,
    borderRadius: '8px !important',
    "& .MuiFilledInput-root": {
      background: "white !important",
      font: '14px Quicksand !important',
      fontWeight: `${600} !important`,
    },
    "&.Mui-focused": {
      backgroundColor: "white !important"
    },
    "& .MuiFormLabel-root": {
      // color: '#737373 !important',
      font: '14px Quicksand !important',
      marginTop: '2px',
      fontWeight: `${400} !important`,
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
  }
}));

export default function Input(props) {
  const classes = InputStyles();
  const classes1 = useStyles();
  const [isError, setError] = React.useState(false)
  const [emptyMesaage, setMessage] = React.useState()
  const {
    formControlProps,
    labelText,
    id,
    required,
    inputProps,
    error,
    success,
    placeholder,
    errorMsg,
    maxLength,
    minLength,
    isHttp,
    isMandatory,
    handleChange,
    onKeyPress,
    isNumber,
    mobile,
    password,
    email,
    emailphone,
    formInput,
    fontSize,
    formInput2,
    className,
    clientInput,
    multiline,
    rows,
    onKeyDown,
    descriptionFormControl1,
    iconText,
    disabled,
    eyeCloseIcon,
    descriptionInput, helperText, icon, tooltipTitle, smallWhiteInput, eyeIcon, eyeHandleChange, clientInput1,
    name,
    value,
    smallWhiteBg, borderOrange,
    onCut, onCopy, onPaste,
    selectBesideInput,
    commentsField,
    onClick
  } = props

  // const underlineClasses = classNames({
  //   [classes.underlineError]: error || isError,
  //   [classes.underlineSuccess]: success && (!error || !isError),
  //   [classes.underline]: true,
  //   [classes.marginTop]: true,
  // })
  // const marginTop = classNames({
  //   [classes.marginTop]: labelText,
  // })
  // eslint-disable-next-line
  const formControlClasses = classNames({
    [' ' + classes.formControl]: formControlProps,
    [classes.descriptionFormControl1]: descriptionFormControl1,
  })

  // CSS CLASSES WHICH APPLY FOR INPUT
  const fieldClasses = classNames({
    [classes.formInput]: formInput,
    [classes.fontSize]: fontSize,
    [classes.formInput2]: formInput2,
    [className]: className,
    [classes.clientInput]: clientInput,
    [classes.descriptionInput]: descriptionInput,
    [classes.smallWhiteInput]: smallWhiteInput,
    [classes.clientInput1]: clientInput1,
    [classes.smallWhiteBg]: smallWhiteBg,
    [classes.borderOrange]: borderOrange,
    [classes.selectBesideInput]: selectBesideInput,
  })

  const onChange = (event) => {
    if (isNumber) {
      if (isNaN(event.target.value)) {
        return
      }
    }

    if (isMandatory == 'true' && event.target.value.length == 0) {
      setError(true)
      setMessage(errorMsg)
      handleChange(event, true)
    } else {
      setError(false)
      handleChange(event, false)
    }

    if (mobile == 'true') {
      if (event.target.value.length != 10) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }
    if (password == 'true') {
      if (event.target.value.length < 6) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }

    if (email == 'true') {
      if (validator.isEmail(event.target.value)) {
        setError(false)
        handleChange(event, false)
      }
      else {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }
    if (emailphone == 'true') {
      if (validator.isEmail(event.target.value)) {
        setError(false)
        handleChange(event, false)
      }
      else if (event.target.value.length != 10) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }

    if (maxLength) {
      if (event.target.value.length > parseInt(maxLength)) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    } else if (minLength) {
      if (event.target.value.length < parseInt(minLength)) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    } else {
      if (handleChange) {
        handleChange(event, false)
      }
    }
    if (isHttp) {
      var link = event.target.value
      var check = link.substring(0, 4)
      if (check == 'http' || check == 'HTTP') {
      } else {
        setError(true)
        setMessage(
          'Please Add (http) else (https) before the URL, For Ex:- http://google.com'
        )
      }
    }
  }

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.classNames + formControlClasses}
    >
      {
        iconText
          ?
          <TextField
            className={disabled ? classes1.rootDisabled : error ? classes1.error : classes1.root}
            label={labelText}
            onClick={onClick}
            required={required}
            name={name}
            value={value}
            placeholder={placeholder}
            onCut={onCut}
            onCopy={onCopy}
            onPaste={onPaste}
            disabled={disabled}
            variant="filled"
            margin="dense"
            InputLabelProps={{
              sx: {
                "& .MuiFormLabel-asterisk": {
                  color: "red !important"
                },
                color: error ? 'red !important' : "#737373 !important",
                font: '15px Quicksand !important',
                [`&.${inputLabelClasses.shrink}`]: {
                  "& .MuiFormLabel-asterisk": {
                    color: "red !important"
                  },
                  color: error ? 'red !important' : "#0C75EB !important",
                  font: '15px Quicksand !important',
                },
              },
            }}
            error={value === '' ? false : true}
            InputProps={{
              classes: { input: fieldClasses },
              disableUnderline: true,
              endAdornment: (
                true &&
                <InputAdornment position="end">
                  {
                    eyeIcon ?
                      <img src={eyeIcon ? Eye : InfoIcon} alt="InfoIcon" style={{ height: '22px', width: '22px', cursor: 'pointer' }} onClick={eyeIcon ? eyeHandleChange : ''} />
                      :
                      eyeCloseIcon ?
                        <img src={eyeCloseIcon ? closeEye : InfoIcon} alt="InfoIcon" style={{ height: '22px', width: '22px', cursor: 'pointer' }} onClick={eyeCloseIcon ? eyeHandleChange : ''} />
                        :
                        <BlackToolTip arrow placement='top' title={
                          <Text mediumWhite sx={{ padding: '5px !important' }}>{tooltipTitle ? tooltipTitle : ''}</Text>
                        }>
                          <img src={InfoIcon} alt="InfoIcon" style={{ height: '22px', width: '22px', cursor: 'pointer' }} />
                        </BlackToolTip>
                  }

                </InputAdornment>
              ),
            }}
            id={id}
            {...inputProps}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            multiline={multiline}
            rows={rows}
            autoComplete='off'
            helperText={<span className={classes.red}>{helperText}</span>}
          />
          : commentsField ?
            <TextField
              label={labelText}
              required={required}
              placeholder={placeholder}
              onClick={onClick}
              name={name}
              value={value}
              onCut={onCut}
              onCopy={onCopy}
              onPaste={onPaste}
              variant="filled"
              margin="dense"
              InputLabelProps={{
                sx: {
                  background: '#FFFFFF !important',
                  "& .MuiFormLabel-asterisk": {
                    color: "red !important"
                  },
                  color: error ? 'red' : "#737373",
                  font: '14px Quicksand !important',
                  fontWeight: `${600} !important`,
                  [`&.${inputLabelClasses.shrink}`]: {
                    "& .MuiFormLabel-asterisk": {
                      color: "red !important"
                    },
                    color: error ? 'red' : props.disabled ? '#737373' : "#0C75EB",
                    font: '14px Quicksand !important',
                  },
                  "&:focus": {
                    border: `1px solid #0C75EB !important`,
                    color: '#0C75EB !important'
                  }
                },
              }}
              InputProps={{
                classes: { input: error ? classes1.commentsError : fieldClasses }, disableUnderline: true,
                sx: {
                  background: '#FFFFFF !important',
                  border: error ? `1px solid #F70D0D !important` : `1px solid #C7CCD3 !important`,
                  font: '14px Quicksand !important',
                  fontWeight: `${600} !important`,
                  "& .MuiFormLabel-asterisk": {
                    color: "red !important"
                  },
                  borderRadius: '8px !important',
                  "&:focus": {
                    border: `1px solid #0C75EB !important`,
                  }
                },
                endAdornment: (
                  icon ?
                    <InputAdornment position="end">
                      <BlackToolTip arrow placement='right' title={
                        <Text mediumWhite sx={{ padding: '5px !important' }}>{tooltipTitle ? tooltipTitle : ''}</Text>
                      }>
                        <img src={eyeIcon ? Eye : InfoIcon} alt="InfoIcon" style={{ height: '13px', width: '13px', margin: '3px 0px 0px 0px', cursor: 'pointer' }} onClick={eyeIcon ? eyeHandleChange : ''} />
                      </BlackToolTip>
                    </InputAdornment> : ''
                ),
              }}
              id={id}
              {...inputProps}
              onChange={onChange}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              multiline={multiline}
              rows={rows}
              autoComplete='off'
              disabled={disabled}
            /> :
            <TextField
              label={labelText}
              required={required}
              placeholder={placeholder}
              onClick={onClick}
              name={name}
              value={value}
              onCut={onCut}
              onCopy={onCopy}
              onPaste={onPaste}
              variant="filled"
              margin="dense"
              InputLabelProps={{
                sx: {
                  "& .MuiFormLabel-asterisk": {
                    color: "red !important"
                  },
                  color: error ? 'red' : "#737373",
                  font: '15px Quicksand !important',
                  [`&.${inputLabelClasses.shrink}`]: {
                    "& .MuiFormLabel-asterisk": {
                      color: "red !important"
                    },
                    color: error ? 'red' : props.disabled ? '#737373' : "#0C75EB",
                    font: '15px Quicksand !important',
                  },
                },
              }}
              InputProps={{
                classes: { input: error ? classes1.error : fieldClasses }, disableUnderline: true,
                sx: {
                  "& .MuiFormLabel-asterisk": {
                    color: "red !important"
                  },
                  borderRadius: '8px !important',
                  "&:focus": {
                    border: `1px solid #737373 !important`
                  }
                },
                endAdornment: (
                  icon ?
                    <InputAdornment position="end">
                      <BlackToolTip arrow placement='right' title={
                        <Text mediumWhite sx={{ padding: '5px !important' }}>{tooltipTitle ? tooltipTitle : ''}</Text>
                      }>
                        <img src={eyeIcon ? Eye : InfoIcon} alt="InfoIcon" style={{ height: '13px', width: '13px', margin: '3px 0px 0px 0px', cursor: 'pointer' }} onClick={eyeIcon ? eyeHandleChange : ''} />
                      </BlackToolTip>
                    </InputAdornment> : ''
                ),
              }}
              id={id}
              {...inputProps}
              onChange={onChange}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              multiline={multiline}
              rows={rows}
              autoComplete='off'
              disabled={disabled}
            // helperText={helperText ? helperText : ''}
            />
      }
      {
        helperText &&
        <Text errorText> {helperText} </Text>
      }
      {error || isError ? (
        <React.Fragment>
          {/* <Clear className={classes.feedback + ' ' + classes.labelRootError} /> */}
          <Text red > {emptyMesaage} </Text>
        </React.Fragment>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  )
}

Input.propTypes = {
  labelText: PropTypes.node,
  required: PropTypes.node,
  labelProps: PropTypes.object,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object.isRequired,
  error: PropTypes.any,
  success: PropTypes.bool,
  formInput: PropTypes.bool,
  errorMsg: PropTypes.string,
  isMandatory: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  mobile: PropTypes.string,
  password: PropTypes.string,
  email: PropTypes.string,
  emailphone: PropTypes.string,
  handleChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  isNumber: PropTypes.bool,
  outlined: PropTypes.bool,
  className: PropTypes.string,
  fontSize: PropTypes.bool,
  onKeyDown: PropTypes.func,
  clientInput: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.any,
  descriptionFormControl1: PropTypes.bool,
  descriptionInput: PropTypes.bool,
  helperText: PropTypes.any,
  icon: PropTypes.any,
  tooltipTitle: PropTypes.any,
  smallWhiteInput: PropTypes.bool,
  iconText: PropTypes.bool,
  eyeIcon: PropTypes.bool,
  eyeHandleChange: PropTypes.func,
  clientInput1: PropTypes.bool,
  disabled: PropTypes.bool,
  eyeCloseIcon: PropTypes.bool,
  smallWhiteBg: PropTypes.bool,
  name: PropTypes.any,
  value: PropTypes.any,
  borderOrange: PropTypes.bool,
  selectBesideInput: PropTypes.bool,
  commentsField: PropTypes.bool
}

