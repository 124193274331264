import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../../../views/admin/clients/userProfile/UserProfileStyles'; // eslint-disable-next-line
import { Box, Grid, Stack, } from '@mui/material';
// eslint-disable-next-line

import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, BlackToolTip, openDocumentInNewtab, removeLoader, rolePermission } from '../../../utils/utils'; // eslint-disable-next-line
import Button from '../../../components/customButton/Button';
import Text from '../../../components/customText/Text';
import EditIcon from '../../../assets/svg/newEdit.svg'
import noDataFound from '../../../assets/svg/NoDataFoundIcon.svg'
import minusCircle from '../../../assets/svg/clients/minus_circle_icon.svg';
import Userplus from '../../../assets/svg/plus.svg';
import Input from '../../../components/input/Input';
import FileInput from '../../../components/muiFileInput/FileInput';
import LoadingButton from '../../../components/customButton/LoadingButton';
import LoadingScreen from '../employees/userprofile/document/LoadingScreen';
import LocalStorage from '../../../utils/LocalStorage';
import CommonApi from '../../../apis/CommonApi';
import { isValidMulti, validate_document_name, validate_emptyField } from '../../../components/Validation';
import ReusableDelete from '../../../components/reuablePopup/ReusableDelete';
import ClientsApi from '../../../apis/admin/clients/ClientsApi';
import NoDataImg from "../../../assets/images/no-data.png";
import { ErrorMessages } from '../../../utils/ErrorMessages';

export default function Document(props) {
    const classes = UserProfileStyles();

    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null)
    const [state, setState] = useState(
        {
            request_id: "",
            documents: [
                // {
                //     new_document_id: "",
                //     document_name: "",
                //     file_name: "",
                //     document_url: "",
                // },
            ],
            delete_document_ids: []
        }
    );
    const [documentData, setDocumentData] = useState([]);
    const [error, setError] = useState([]);

    useEffect(() => {
        getDocuments()
        // eslint-disable-next-line
    }, []);


    const getDocuments = () => {
        setLoading(true)   // slugs:-  client , end-client, vendor
        ClientsApi.cilentDocumentApi(props?.name === "Client" ? 'client' : props?.name === "End Client" ? 'end-client' : 'vendor', props.id).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)
                setDocumentData(response.data.data);
                setState({ ...state, documents: response.data.data, delete_document_ids: [] });
            }
            else {
                setLoading(false)
            }
        });
    }

    const addNewDocument = (action, index) => {
        let newDocument = {
            new_document_id: "",
            document_name: "",
            file_name: "",
            document_url: "",
            id: '',
        };
        if (action === "Add") {
            setState((prev) => ({ ...prev, documents: [...prev.documents, newDocument], }));
        } else if (action === "Remove") {
            const updatedDocuments = state.documents.filter((_, i) => i !== index);
            const documentId = state.documents[index]?.id;
            if (documentId !== "" && documentId !== null && documentId !== undefined) {
                const updatedDeleteDocumentIds = [...state.delete_document_ids, documentId];
                setState({ ...state, documents: updatedDocuments, delete_document_ids: updatedDeleteDocumentIds });
            } else {
                setState({ ...state, documents: updatedDocuments });
            }
            let errorsus = {}; let err = [];
            updatedDocuments.map((value, ind) => {
                errorsus = {};
                errorsus.document_name = (value.document_url !== "" && value.document_name === "") ? validate_emptyField(value.document_name) : validate_document_name(value.document_name);
                errorsus.new_document_id = value.document_name !== "" ? validate_emptyField(value.document_url) : '';
                return err.push(errorsus);
            });
            setError(err);
        }
    };

    const deleteDocument = (index) => {
        const updatedDocuments = [...state.documents];
        updatedDocuments[index].new_document_id = "";
        updatedDocuments[index].file_name = "";
        updatedDocuments[index].document_url = "";
        if (state.documents[index]?.id !== "") {
            const documentId = updatedDocuments[index]?.id
            const updatedDeleteDocumentIds = [...state.delete_document_ids, documentId];
            updatedDocuments[index]['id'] = "";
            setState({
                ...state,
                documents: updatedDocuments,
                delete_document_ids: updatedDeleteDocumentIds,
            });
        }
        setDeleteOpen(false);
        setDeleteIndex(null);
        const event = { target: { name: "new_document_id", value: '' } }
        handleValidateContact(event, index)
    };

    const handleChange = (e, index) => {
        state.documents[index][e.target.name] = e.target.value;
        setState({ ...state });
        handleValidateContact(e, index)
    };

    const handleValidateContact = (e, index) => {
        const input = e.target;

        setError((prevError) => {
            // Ensure prevError is an array
            let updatedErrors = Array.isArray(prevError) ? [...prevError] : [];

            // Initialize the error object for this index if it doesn't exist
            if (!updatedErrors[index]) {
                updatedErrors[index] = {};
            }

            // Validate input based on its name or tag
            switch (input.name || input.tagName) {
                case "document_name":
                    updatedErrors[index].document_name = (state.documents[index].document_url !== "" && input.value === "") ? validate_emptyField(input.value) : validate_document_name(input.value);
                    if (state.documents[index].document_url === "" && input.value === "") {
                        updatedErrors[index].new_document_id = '';
                    }
                    break;
                case "new_document_id":
                    updatedErrors[index].new_document_id = (state.documents[index].document_name === "" && input.value === "") ? "" : validate_emptyField(input.value);
                    if (state.documents[index].document_name === "" && input.value === "") {
                        updatedErrors[index].document_name = '';
                    }
                    break;
                default:
                    break;
            }

            return updatedErrors; // Return the updated errors array
        });
    };


    const uploadDocument = (e, index) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'pdf', 'jpeg'].includes(r))) {
            if (Number(((e.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("company-document", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            const updateddocument = { ...state };

                            // Update the specific document in the nested array
                            updateddocument.documents[index] = {
                                ...updateddocument.documents[index],
                                id: "",
                                new_document_id: res.data?.data?.id,
                                // document_name: updateddocument.documents[index].document_name == "" ? res.data?.data?.document_name : updateddocument.documents[index].document_name,
                                file_name: res.data?.data?.document_name,
                                document_url: res.data?.data?.document_url,
                            };

                            // Update the state with the modified copy
                            setState(updateddocument);
                            const event = {
                                target: {
                                    name: 'new_document_id',
                                    value: res.data?.data?.id
                                }
                            }
                            handleValidateContact(event, index)
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPEG or PDF format only.");
        }
    }

    const validateContacts = () => {
        let errorsus = {};
        let err = [];
        state.documents.map((value, ind) => {
            errorsus = {};
            errorsus.document_name = (value.document_url !== "" && value.document_name === "") ? validate_emptyField(value.document_name) : validate_document_name(value.document_name);
            errorsus.new_document_id = value.document_name !== "" ? validate_emptyField(value.document_url) : '';
            return err.push(errorsus);
        });
        return err;
    }


    const handleSubmit = () => {
        let errors = validateContacts();
        setLoading(true);
        if (isValidMulti(errors)) {
            state['request_id'] = LocalStorage.uid();
            state.documents = handleRemoveEmptyObjects(state.documents);
            state.delete_document_ids = Array.from(new Set([...(state.delete_document_ids || [])]));
            setState(state); // slugs:- client, end-client, vendor            
            ClientsApi.clientStoreDocumentApi(props?.name === "Client" ? 'client' : props?.name === "End Client" ? 'end-client' : 'vendor', props.id, state).then((res) => {
                setLoading(false);
                if (res.data.statusCode === 1003) {
                    props.setformEdit(false);
                    if (Array.isArray(state.documents) &&
                        (
                            (state.documents.length > 0 && state.documents.some(i => i.id !== "")) ||
                            state.delete_document_ids.length > 0
                        )
                    ) {
                        addSuccessMsg("Documents Updated Successfully");
                    } else {
                        addSuccessMsg("Documents Upload Successfully");
                    }
                    getDocuments();
                    props.getActivity();
                    setFormOpen(false);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            let s4 = { error };
            s4 = errors;
            setLoading(false);
            setError(s4);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    };

    const handleRemoveEmptyObjects = (documents) => {
        const filteredDocuments = documents.filter(
            (doc) => doc.document_name.trim() !== "" && doc.document_url.trim() !== ""
        );
        return filteredDocuments;
    }


    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const documentFirstOpen = () => {
        state.documents.push({
            id: "",
            new_document_id: "",
            document_name: "",
            file_name: "",
            document_url: "",
        })
        setState({ ...state });
        setFormOpen(true);
        props.setformEdit(true);
    }

    return (
        <Grid container>
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={2} padding={2} className={classes.boxShadow} sx={{ height: documentData.length > 0 ? 'auto' : '60vh' }} >

                        <Grid item lg={10} md={10} sm={10} xs={10} py={2}>
                            <Text largeBlue>{props?.name} documents </Text>
                        </Grid>

                        {
                            props?.status === "In Active" ?
                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"end"}>
                                </Grid>
                                : <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"end"}>
                                    {formOpen ? '' : (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) &&
                                        documentData.length > 0 &&
                                        < img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { setFormOpen(true); props.setformEdit(true); setError([]) }} />
                                    }
                                </Grid>
                        }
                        {
                            formOpen ? null : (
                                documentData.length > 0 ? (
                                    documentData.map((item, index) => (
                                        <Grid item container lg={3} md={4} sm={6} xs={12} key={index}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box py={1}>
                                                    <Text mediumBlue sx={{ color: '#525252 !important' }}>
                                                        {item?.document_name?.length > 16 ? (
                                                            <BlackToolTip arrow placement="top" title={item?.document_name}>
                                                                {item?.document_name.slice(0, 16)}{" "}
                                                                {item?.document_name.length > 16 ? "..." : ""}
                                                            </BlackToolTip>
                                                        ) : (
                                                            item?.document_name
                                                        )}
                                                    </Text>
                                                </Box>
                                                <Box py={1} >
                                                    {state.documents.length > 0 && item?.file_name !== "" ? (
                                                        <Stack
                                                            direction={"row"}
                                                            sx={{ cursor: "pointer" }}
                                                            onClick={() => openInNewTab(item, item?.document_url)}
                                                        >
                                                            <Text mediumBlue>
                                                                {item?.file_name.length > 16 ? (
                                                                    <BlackToolTip arrow placement="top" title={item?.file_name}>
                                                                        {item?.file_name.slice(0, 16)}{" "}
                                                                        {item?.file_name.length > 16 ? "..." : ""}
                                                                    </BlackToolTip>
                                                                ) : (
                                                                    item?.file_name
                                                                )}
                                                            </Text>
                                                        </Stack>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <>

                                        {props?.status === "In Active" ?
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                                    <img src={NoDataImg} alt='no-data' />
                                                </Grid>
                                                <Grid item lg={12} sm={12} md={12} xs={12} textAlign={'center'} pt={2}>
                                                    <Text largeBlack>Documents Not Uploaded</Text>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container justifyContent={"center"} alignContent={"center"}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                    <img src={noDataFound} alt="nodata" />
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} pt={1}>
                                                    <Text largeBlack>No documents Uploaded</Text>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} pt={2}>
                                                    <Text mediumLabel sx={{ fontSize: "14px !important" }}>
                                                        Upload a document for this {props?.name === "Client" ? 'client' : props?.name === "End Client" ? 'end client' : 'vendor'} by
                                                    </Text>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} pt={1}>
                                                    <Text mediumLabel sx={{ fontSize: "14px !important" }}>
                                                        clicking on the add button.
                                                    </Text>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} pt={2}>
                                                    <Button finishFilledQuicksandLargeWidth onClick={() => { documentFirstOpen() }} >
                                                        <img src={Userplus} alt="plus" /> Add New document
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                )
                            )
                        }

                        {formOpen ?
                            <>
                                <Box p={2} sx={{
                                    minHeight: state?.documents.length > 4 ? '48vh' : 'fit-content',
                                    maxHeight: state?.documents.length > 4 ? '48vh' : 'fit-content',
                                    width: '100%',
                                    overflowY: state?.documents.length > 4 ? 'auto' : 'visible',
                                }}>
                                    {
                                        state?.documents?.map((item, index) => (
                                            <Grid container spacing={3} mt={'1px'}>
                                                <Grid item lg={state.documents.length > 1 ? 5.5 : 6} md={6} sm={6} xs={12}>
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'document_name',
                                                            value: item?.document_name,
                                                            inputProps: { maxLength: 50 }
                                                        }}
                                                        handleChange={(e) => handleChange(e, index)}
                                                        clientInput
                                                        labelText='Document Name'
                                                        error={error.length > 0 && error[index] && error[index].document_name}
                                                    />
                                                    <Text errorText>{error.length > 0 && error[index] && error[index].document_name && error[index].document_name}</Text>
                                                </Grid>
                                                <Grid item lg={state.documents.length > 1 ? 6.5 : 6} md={6} sm={6} xs={12}>
                                                    <Box display="flex" alignItems="center" gap={2}>
                                                        <FileInput
                                                            name='Upload_document'
                                                            FileName={item?.file_name ? item?.file_name : ''}
                                                            handleChange={(e) => uploadDocument(e, index)}
                                                            label='Upload Document'
                                                            handleDelete={() => { setDeleteOpen(true); setDeleteIndex(index) }}
                                                            actionState={item?.file_name ? 1 : ''}
                                                            documentUrl={item?.document_url ? item?.document_url : ''}
                                                            state={item}
                                                            error={error.length > 0 && error[index] && error[index].new_document_id && error[index].new_document_id}
                                                        />
                                                        {state.documents.length > 1 && (
                                                            <img
                                                                src={minusCircle}
                                                                alt="minus"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => addNewDocument('Remove', index)}
                                                            />
                                                        )}
                                                    </Box>
                                                    <Text errorText>{error.length > 0 && error[index] && error[index].new_document_id && error[index].new_document_id}</Text>
                                                </Grid>
                                            </Grid>

                                        ))
                                    }
                                </Box>
                                {
                                    state.documents.length <= 9 &&
                                    <Grid item lg={12} md={12} sm={12} xs={12} m={state.length > 1 ? '25px 30px 0px 0px !important' : '25px 0px 0px 0px !important'}>
                                        <Button lightBlue onClick={() => addNewDocument('Add')}>Add New Document</Button>
                                    </Grid>
                                }
                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} pl={2}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={2}>
                                        {loading ? '' : <Button cancelOutlineQuicksand onClick={() => { setFormOpen(false); props.setformEdit(false); getDocuments(); setError([]) }}>Cancel</Button>}
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={2} justifyContent={'flex-end'}>
                                        <LoadingButton loading={loading} smallBlue onClick={() => { handleSubmit() }}>Save</LoadingButton>
                                    </Grid>
                                </Grid>
                            </>
                            : ''
                        }
                    </Grid>
            }
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDocument(deleteIndex) }} />}
        </Grid>

    );
}