import React from 'react'
import CustomAccordion from '../../../../../components/accordion/CustomAccordion';
import { useState } from 'react';
import LocalStorage from '../../../../../utils/LocalStorage';
import { Box, Grid, Stack } from '@mui/material';
import Button from '../../../../../components/customButton/Button';// eslint-disable-next-line
import { isValid, validate_emptyField, validate_ssn } from '../../../../../components/Validation';
import Text from '../../../../../components/customText/Text';
import PropTypes from 'prop-types';// eslint-disable-next-line
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, dateFormat, openDocumentInNewtab, removeLoader } from '../../../../../utils/utils';
import PersonalDocsForm from './PersonalDocsForm';
import CommonApi from '../../../../../apis/CommonApi';
import { useEffect } from 'react';// eslint-disable-next-line
import info from '../../../../../assets/employee/blue-info.svg';// eslint-disable-next-line
import { TooltipIndicator } from '../../../../../theme';
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import EmployeeCreateAPI from '../../../../../apis/admin/employees/EmployeesApi';
import editIcon from '../../../../../assets/svg/square-edit.svg';
import moment from 'moment';
import SsnForm from './SsnForm';
import DocumentStyles from './DocumentStyles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Dropzone from 'react-dropzone';
import { blue } from '@mui/material/colors';
import download from '../../../../../assets/employee/filedownload.svg';
import LoaderIcon from '../../../../../assets/gif/japfuLoader.gif';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import ReusableClearAll from '../../../../../components/reuablePopup/ReusableClearAll';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
// eslint-disable-next-line
function PersonalDocuments({ docStepper, setDocStepper, mainStep, setMainStep, value, setValue, inviteData, setInviteData, ssn }) {
    const emp_id = LocalStorage.getEmployeeId();
    const classes = DocumentStyles();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(false);
    const [formLoader, setFormLoader] = useState(false);
    const [state, setState] = useState({
        id: '',
        document_type_id: '',
        valid_from: '',
        valid_till: '',
        document_number: '',
        documents: [
            {
                document_name: '',
                id: '',
                new_document_id: '',
                document_url: '',
                slug: ""
            }
        ]
    })

    const [ssnState, setSsnState] = useState({
        id: '',
        document_type_id: 2,
        valid_from: '',
        valid_till: '',
        document_number: ssn ? ssn : '',
        documents: [
            {
                document_name: '',
                id: '',
                new_document_id: '',
                document_url: '',
                slug: ""
            }
        ]
    })

    const [otherState, setOtherState] = useState({
        id: '',
        valid_from: '',
        valid_till: '',
        document_type_id: 4,
        documents: []
    })

    const [error, setError] = useState({});
    const [ssnError, setSsnError] = useState({})
    const [editForm, setEditForm] = useState(true);// eslint-disable-next-line
    const [docsList, setDocslist] = useState([
        {
            "id": 1,
            "value": "Driving Licence",
        },
        {
            "id": 3,
            "value": "State ID",
        }
    ]);
    const [deletefiles, setDeletefiles] = useState([]);
    const [ssnExpand, setSsnExpand] = useState(false);
    const [otherExpand, setOtherExpand] = useState(false);
    const [deleteOther, setDeleteOther] = useState(false)
    const [addExpand, setAddExpand] = useState(false);
    const [docError, setDocError] = useState([]);
    const [edit, setEdit] = useState(true);
    const [ssnEdit, setSsnEdit] = useState(false);
    const [otherEdit, setOtherEdit] = useState(false);
    const [fileErr, setFileErr] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [clearAllSsn, setClearAllSsn] = useState(false);
    const [personalDocLoader, setPersonalDocLoader] = useState(false);

    useEffect(() => {
        if (emp_id) {
            getPersonalIndex(emp_id);
        }// eslint-disable-next-line 
    }, [])

    const handleFormStates = (param) => {

        for (const key in param.upload_documents) {
            const dataArray = param.upload_documents[key].data;
            if (dataArray.length >= 1) {
                const item = dataArray[0];
                if (item.name === "drivers_license" && item.approval_status == "Approved") {

                    setState({
                        // ...state,
                        id: '',
                        document_type_id: 1,
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        document_number: item.document_number,
                        documents: [
                            {
                                document_name: item.document_name,
                                id: '',
                                new_document_id: item.id,
                                document_slug: item.name,
                                slug: "invite_via_link",
                                document_url: item.document_url
                            }
                        ]
                    });

                } else if (item.name == "signed_ssn" && item.approval_status == "Approved") {
                    setSsnState({
                        ...ssnState,
                        id: '',
                        document_type_id: 2,
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        document_number: item.document_number,
                        documents: [
                            {
                                document_name: item.document_name,
                                id: '',
                                new_document_id: item.id,
                                slug: "invite_via_link",
                                document_slug: item.name,
                                document_url: item.document_url
                            }
                        ]
                    })
                }
                // else if (item.name == "counter_signed_offer_letter" && item.approval_status == "Approved") {
                //     // otherState.documents = [];
                //     setOtherState({ ...otherState });
                //     otherState.documents.push({
                //         document_name: item.document_name,
                //         id: '',
                //         new_document_id: item.id,
                //         slug: "invite_via_link",
                //         document_slug: item.name,
                //         document_url: item.document_url
                //     })
                //     setOtherState({ ...otherState });
                // }
                // else if (item.name == "all_previous_i20s" && item.approval_status == "Approved") {
                //     // otherState.documents = [];
                //     setOtherState({ ...otherState });
                //     otherState.documents.push({
                //         document_name: item.document_name,
                //         id: '',
                //         new_document_id: item.id,
                //         slug: "invite_via_link",
                //         document_slug: item.name,
                //         document_url: item.document_url
                //     })
                //     setOtherState({ ...otherState });
                // }
            }
        }
    }

    const handleEmptyState = () => {
        // state.id = "";
        state.document_type_id = "";
        // state.valid_from = "";
        // state.valid_till = "";
        // state.document_number = "";
        // state.documents = [
        //     {
        //         document_name: '',
        //         id: '',
        //         slug: '',
        //         new_document_id: '',
        //         document_url: ''
        //     }
        // ]
        setState({ ...state });
    }

    const handleEmptyStateSave = () => {
        state.id = "";
        state.document_type_id = "";
        state.valid_from = "";
        state.valid_till = "";
        state.document_number = "";
        state.documents = [
            {
                document_name: '',
                id: '',
                slug: '',
                new_document_id: '',
                document_url: ''
            }
        ]
        setState({ ...state });
    }

    const handleEmptySsnStateSave = () => {
        ssnState.id = "";
        ssnState.document_type_id = 2;
        ssnState.valid_from = "";
        ssnState.valid_till = "";
        ssnState.document_number = "";
        ssnState.documents = [
            {
                document_name: '',
                id: '',
                slug: '',
                new_document_id: '',
                document_url: ''
            }
        ]
        setSsnState({ ...ssnState });
    }

    const handleEmptySsnState = () => {
        // ssnState.id = "";
        ssnState.document_type_id = 2;
        // ssnState.valid_from = "";
        // ssnState.valid_till = "";
        // ssnState.document_number = "";
        // ssnState.documents = [
        //     {
        //         document_name: '',
        //         id: '',
        //         slug: '',
        //         new_document_id: '',
        //         document_url: ''
        //     }
        // ]
        setSsnState({ ...ssnState });
    }

    const handleChangeAccordion = (param) => {
        if (param == 'dlsi') {
            if (state.id !== "") {
                setEdit(false);
                getPersonalIndex(emp_id);
            } else {
                setEdit(true);
                if (inviteData != null) {
                    for (const key in inviteData.upload_documents) {
                        const dataArray = inviteData.upload_documents[key].data;
                        if (dataArray.length >= 1) {
                            const item = dataArray[0];
                            if (item.name === "drivers_license" && item.approval_status == "Approved") {
                                setState({
                                    ...state,
                                    id: '',
                                    document_type_id: 1,
                                    valid_from: item.valid_from,
                                    valid_till: item.valid_till,
                                    document_number: item.document_number,
                                    documents: [
                                        {
                                            document_name: item.document_name,
                                            id: '',
                                            new_document_id: item.id,
                                            document_slug: item.name,
                                            slug: "invite_via_link",
                                            document_url: item.document_url
                                        }
                                    ]
                                })
                            }
                        }
                    }
                } else {
                    handleEmptyState();
                }
            }
            setError({ ...error });
            setAddExpand(!addExpand);
        }
        else if (param == 'ssn') {
            if (ssnState.id !== "") {
                getPersonalIndex(emp_id);
                setSsnEdit(true);
            }
            else {
                if (inviteData != null) {
                    for (const key in inviteData.upload_documents) {
                        const dataArray = inviteData.upload_documents[key].data;
                        if (dataArray.length >= 1) {
                            const item = dataArray[0];
                            if (item.name == "signed_ssn" && item.approval_status == "Approved") {
                                setSsnState({
                                    ...ssnState,
                                    id: '',
                                    document_type_id: 2,
                                    valid_from: item.valid_from,
                                    valid_till: item.valid_till,
                                    document_number: item.document_number,
                                    documents: [
                                        {
                                            document_name: item.document_name,
                                            id: '',
                                            new_document_id: item.id,
                                            document_slug: item.name,
                                            slug: "invite_via_link",
                                            document_url: item.document_url
                                        }
                                    ]
                                })
                            }
                        }
                    }
                } else {
                    handleEmptySsnState();
                }
            }
            setSsnError({ ...ssnError });
            setSsnExpand(!ssnExpand)
        } else if (param == 'other') {
            if (otherState.id !== "") {
                getPersonalIndex(emp_id);
                setOtherEdit(true);
            } else {
                if (inviteData != null) {
                    for (const key in inviteData.upload_documents) {
                        const dataArray = inviteData.upload_documents[key].data;
                        if (dataArray.length >= 1) {
                            for (let i = 0; i < dataArray.length; i++) {
                                const item = dataArray[i];
                                if ((item.name == "counter_signed_offer_letter" || item.name == "all_previous_i20s") && item.approval_status == "Approved") {
                                    // otherState.documents = [];
                                    otherState.documents.push({
                                        document_name: item.document_name,
                                        id: '',
                                        new_document_id: item.id,
                                        slug: "invite_via_link",
                                        document_slug: item.name,
                                        document_url: item.document_url
                                    })
                                    setOtherState({ ...otherState });
                                }
                            }

                        }
                    }
                } else {
                    setOtherState({
                        id: '',
                        valid_from: '',
                        valid_till: '',
                        document_type_id: 4,
                        documents: []
                    })
                }

            }
            setFileErr(false);
            setOtherExpand(!otherExpand);
        }
    };

    const handleEditIcon = (e) => {
        setEdit(true);
        // setAddExpand(false);
        e.stopPropagation();
    }

    const handleSsnEdit = (e) => {
        setSsnEdit(false);
        e.stopPropagation();
    }

    const handleOtherEdit = (e) => {
        setOtherEdit(false);
        e.stopPropagation();
    }

    const changeHandler = (e, val, index) => {
        let name = e.target.name;
        if (index >= 0) {
            if (name == "new_document_id") {
                uploadDocs(e, index);
            } else {
                state.documents[index][name] = e.target.value;
            }
            setState({ ...state }, handleValidateDoc(e.target, index));
        }
        else {
            state[name] = e.target.value;
        }
        setState({ ...state });
        handleValidate(e.target);
        if (state.document_type_id == "" && state.document_number == "" && state.valid_from == "" && state.valid_till == "" && state.documents[0].document_url == "") {
            error['document_type_id'] = "";
            error['document_number'] = "";
            setError({ ...error });
        }
    };

    const changeHandlerSsn = (e) => {
        let name = e.target.name;
        if (name == 'document_number') {
            convertFormat(e);
        }
        else {
            ssnState[name] = e.target.value;
            setSsnState({ ...ssnState }, handleValidateSsn(e.target));
        }
        if (ssnState.document_number == "" && ssnState.valid_till == "" && ssnState.valid_from == "" && ssnState.documents[0].document_url == "") {
            ssnError['document_number'] = "";
            setSsnError({ ...ssnError })
        }
        // if(ssnState.document_number == "" && (ssnState.valid_till == "" || ssnState.valid_from == "" || ssnState.documents[0].document_url == "")){
        //     ssnError['document_number'] = ErrorMessages.fieldRequired;
        //     setSsnError({ ...ssnError })
        // }
    };

    const convertFormat = (e) => {

        const value = e.target.value;
        const name = e.target.name;
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > 5) {
            ssnState[e.target.name] = `${first}-${middle}-${last}`
            setSsnState({ ...ssnState }, handleValidateSsn(e.target));
            if (name == 'document_number') {
                if (last == '0000') {
                    ssnError.document_number = ErrorMessages.invalidSSN
                    setSsnError(ssnError)
                    return true
                }
            }
        }
        else if (input.length > 3) {
            ssnState[e.target.name] = `${first}-${middle}`
            setSsnState({ ...ssnState }, handleValidateSsn(e.target));
            if (name == 'document_number') {
                if (middle == '00') {
                    ssnError.document_number = ErrorMessages.invalidSSN
                    setSsnError(ssnError)
                    return true
                }
            }
        }
        else if (input.length >= 0) {
            ssnState[e.target.name] = input
            setSsnState({ ...ssnState }, handleValidateSsn(e.target));
            if (name == 'document_number') {
                if (first == '000' || first == '666' || first == '900') {
                    ssnError.document_number = ErrorMessages.invalidSSN
                    setSsnError(ssnError)
                    return true
                }
            }
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        if (event.target.name == 'valid_from') {
            setState({
                ...state,
                [name]: date == '' ? '' : moment(date).format(dateFormat()),
                valid_till: ''
            }, handleValidate(event.target))
        } else {
            setState({
                ...state,
                [name]: date == '' ? '' : moment(date).format(dateFormat()),
            }, handleValidate(event.target))
        }
    }

    const dateChangeSsn = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        if (event.target.name == 'valid_from') {
            setSsnState({
                ...ssnState,
                [name]: date == '' ? '' : moment(date).format(dateFormat()),
                valid_till: ''
            }, /*handleValidate(event.target)*/)
        } else {
            setSsnState({
                ...ssnState,
                [name]: date == '' ? '' : moment(date).format(dateFormat()),
            }, /*handleValidate(event.target)*/)
        }
    }

    const handleValidateDoc = (e, index) => {
        let input = e;
        // let error = docError.length > 0 ? (docError ? docError[index] : docError) : docError;
        for (var k = 0; k <= index; k++) {
            docError.push({});
        }
        let s1 = docError.length > 0 ? [...docError] : [{ ...docError }];
        switch (input.name || input.tagName) {
            // case "new_document_id":
            //     error.new_document_id = validate_emptyField(value.new_document_id);
            //     break;
            // case "document_name":
            //   error.document_name = validate_emptyField(input.value);
            //   break;
            default:
                break;
        }
        setDocError(s1);
    };

    const uploadDocs = (value, index, param) => {
        const formData = new FormData();
        formData.append('files', value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (value.target.files[0].type == "image/png" || value.target.files[0].type == "image/jpeg" || value.target.files[0].type == "application/jpeg" || value.target.files[0].type == "application/png" || value.target.files[0].type == "application/jpg" || value.target.files[0].type == "application/pdf") {
            let size = ((value.target.files[0].size / (1024 * 1024)).toFixed(2))
            if (Number(size) <= 25) {
                addLoader(true);
                CommonApi.documentUpload("personal-document", formData, LocalStorage.getAccessToken()).then((response) => {
                    if (response.data.statusCode == 1003) {
                        removeLoader()
                        if (param === 'ssn') {
                            let deleteArr = deletefiles;
                            ssnState.documents[0].document_name = value.target.files[0].name;
                            ssnState.documents[0].id = ssnState.documents[index].id;
                            ssnState.documents[0].new_document_id = response.data.data.id;
                            ssnState.documents[0].document_url = response.data.data.document_url;
                            ssnState.documents[0].slug = '';
                            const data = {
                                url: response.data.data.document_url,
                                id: response.data.data.id
                            }
                            addLoader()
                            CommonApi.ocrDocumentUpload('ssn-data', data).then((res) => {
                                if (res.data.statusCode === 1003) {
                                    removeLoader()
                                    ssnState.document_number = res.data.data.social_security_number ? res.data.data.social_security_number : ssnState.document_number
                                    setSsnState({ ...ssnState })
                                }
                                else {
                                    removeLoader()
                                }
                            }
                            )
                            setDeletefiles([...deleteArr]);
                            setSsnState({ ...ssnState }, docValidations(value));
                        } else {
                            let deleteArr = deletefiles;
                            const data = {
                                url: response.data.data.document_url,
                                id: response.data.data.id
                            }
                            addLoader()
                            CommonApi.ocrDocumentUpload('dl-data', data).then((res) => {
                                if (res.data.statusCode === 1003) {
                                    removeLoader()
                                    state.document_number = res.data.data.driving_license_number
                                    state.valid_from = moment(res.data.data.issued_date).format(dateFormat())
                                    state.valid_till = moment(res.data.data.expiry_date).format(dateFormat())
                                    state.document_type_id = res.data.data.is_dl ? 1 : 3
                                    state.documents[0].new_document_id = response.data.data.id
                                    state.documents[0].document_url = response.data.data.document_url
                                    state.documents[0].document_name = value.target.files[0].name
                                    setState({ ...state })
                                }
                                else {
                                    removeLoader()
                                    state.documents[0].document_name = value.target.files[0].name;
                                    state.documents[0].id = state.documents[index].id;
                                    state.documents[0].new_document_id = response.data.data.id;
                                    state.documents[0].document_url = response.data.data.document_url;
                                    state.documents[0].slug = '';
                                    state.document_number = response.data.data.driving_license_number
                                    addErrorMsg(res.data.error.detail);
                                }
                            }
                            )
                            setDeletefiles([...deleteArr]);
                            setState({ ...state }, docValidations(value));
                        }
                    }
                    else {
                        addErrorMsg(response.data.message);
                    }
                })
            } else {
                addErrorMsg(ErrorMessages.invalidFilesize);
            }
        } else {
            addErrorMsg(ErrorMessages.invalidFormatPdfImg);
        }
    }

    const multiDocsUpload = (files) => {
        for (const key in files) {
            if (files.length > 5) {
                setFileErr(true);
            }
            if (otherState.documents.length < 5) {
                if (files[key].type == "application/msword" || files[key].type == "application/doc" || files[key].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files[key].type == "application/ms-doc" || files[key].type == "application/pdf") {
                    var fileSize = files[key].size / (1024 * 1024);
                    if (fileSize.toFixed(2) <= 25) {
                        const formData = new FormData();
                        formData.append('files', files[key]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("other-document", formData, LocalStorage.getAccessToken()).then((response) => {
                            if (response.data.statusCode == 1003) {
                                var obj = {
                                    document_name: response.data.data.document_name,
                                    new_document_id: response.data.data.id,
                                    document_url: response.data.data.document_url,
                                    slug: ""
                                }
                                if (otherState.documents.length < 5) {
                                    var newDocuments = otherState.documents;
                                    newDocuments.push(obj);
                                    if (deleteOther) {
                                        otherState.id = ''
                                        setOtherState({ ...otherState, documents: newDocuments });
                                        setDeleteOther(false)
                                    }
                                    else {
                                        setOtherState({ ...otherState, documents: newDocuments });
                                        setDeleteOther(false)
                                    }
                                } else {
                                    setFileErr(true);
                                    return false;
                                }
                            }
                            else {
                                addErrorMsg(response.data.message);
                            }
                        })
                    } else {
                        addErrorMsg(ErrorMessages.invalidFilesize);
                    }
                } else {
                    addErrorMsg(`Upload document Format PDF/Docs`);
                }
            } else {
                addErrorMsg("Upload 5 Documents Only")
            }
        }
    }

    const otherDocumentRemove = (index) => {

        if (!otherEdit) {
            setDeleteOpen(true);
            setDeleteIndex(index);
        }
        setFileErr(false);
    }

    const deleteDoc = (index) => {
        if (otherState.id !== "" && otherState.documents[index].id !== "") {
            otherState['documents_deleted_ids'].push(otherState.documents[index].id);
        }
        otherState.documents.splice(index, 1);
        setOtherState({ ...otherState });
        setDeleteIndex(null);
        setDeleteOpen(false);
    }
    const [clearAllOpen, setClearAllOpen] = useState(false);

    const clearAllOtherDocuments = () => {
        setFileErr(false);
        if (inviteData != null) {
            inviteData.upload_documents.counter_signed_offer_letter.data = [];
            setInviteData({ ...inviteData });
        }
        otherState.documents = [];
        setOtherState({ ...otherState });
        setClearAllOpen(false);
    }

    const handleValidate = (e) => {
        let input = e;
        switch (input.name || input.targetName) {
            case 'document_number':
                error['document_number'] = (input.value !== '' && input.value.length < 4) ? ErrorMessages.documentNumberDrivingAndStateID : input.value == "" && (state.document_type_id != "" || state.valid_from !== "" || state.valid_till !== "" || state.documents[0].document_url !== "") ? validate_emptyField(input.value) : "";
                break;
            case 'document_type_id':
                error['document_type_id'] = input.value !== '' ? validate_emptyField(input.value) : input.value == "" && (state.document_number !== "" || state.valid_from !== "" || state.valid_till !== "" || state.documents[0].document_url !== "") ? validate_emptyField(input.value) : "";
                break;
            default:
                break;
        }
        setError({ ...error })
    }

    const handleValidateSsn = (e) => {
        let input = e;
        switch (input.name || input.targetName) {
            case 'document_number':
                ssnError.document_number = input.value != "" ? validate_ssn(input.value) : (input.value == "" && (ssnState.valid_from !== "" || ssnState.valid_till !== "" || ssnState.documents[0].document_url !== "")) ? validate_emptyField(input.value) : "";
                break;
            default:
                break;
        }
        setSsnError({ ...ssnError })
    }

    const docValidations = () => {
        let errors = {};
        let err = []; // eslint-disable-next-line
        state.documents.map((value) => {
            errors = {};
            errors.new_document_id = '';
            err.push(errors);
            setDocError(err);
        });
        return err;
    };

    const handleSubmit = () => {
        state['request_id'] = LocalStorage.uid();
        state['employee_id'] = emp_id;

        if (state.document_number !== "" && state.document_number.length < 4) {
            error['document_number'] = ErrorMessages.documentNumberDrivingAndStateID;
            if (state.document_type_id == "") {
                error['document_type_id'] = ErrorMessages.fieldRequired;
            }
            setError({ ...error });
            addErrorMsg(ErrorMessages.documentNumberDrivingAndStateID);
            return false;
        }

        if (state.document_number == "" && state.document_type_id == "" && (state.valid_from !== "" || state.valid_till !== "" || state.documents[0].document_url !== "")) {
            error['document_number'] = ErrorMessages.fieldRequired;
            error['document_type_id'] = ErrorMessages.fieldRequired;
            setError({ ...error });
            addErrorMsg(ErrorMessages.documentRequired);
            return false;
        }
        if (state.document_number == "" && (state.valid_from !== "" || state.valid_till !== "" || state.document_type_id !== "" || state.documents[0].document_url !== "")) {
            error['document_number'] = ErrorMessages.fieldRequired;
            setError({ ...error });
            addErrorMsg(ErrorMessages.documentRequired);
            return false;
        }
        if (state.document_type_id == "" && (state.valid_from !== "" || state.valid_till !== "" || state.document_number !== "" || state.documents[0].document_url !== "")) {
            error['document_type_id'] = ErrorMessages.fieldRequired;
            if (state.document_number == "") {
                error['document_number'] = ErrorMessages.fieldRequired;
            }
            setError({ ...error });
            addErrorMsg(ErrorMessages.documentTypeRequired);
            return false;
        }
        if (state.id !== "") {
            if (clearAll || ((state.document_type_id == "" || state.document_type_id == null) && state.valid_from == "" && state.valid_till == "" && state.document_number == "" && state.documents[0].document_url == "")) {
                var deleteObj = {
                    request_id: LocalStorage.uid(),
                    employee_id: emp_id,
                    id: state.id
                }
                setPersonalDocLoader(true)
                EmployeeCreateAPI.deletePersonalDocuments(deleteObj, state.id ? state.id : '', LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        handleEmptyStateSave();
                        addSuccessMsg(`Documents Updated Successfully`);
                        getPersonalIndex(emp_id);

                        setAddExpand(!addExpand);
                        setEdit(true);
                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
                setClearAll(false);
            } else {
                setPersonalDocLoader(true)

                OnBoardApi.documentUpdate(state, state.id ? state.id : '', LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        addSuccessMsg(`Documents Updated Successfully`);
                        getPersonalIndex(emp_id);

                        setAddExpand(!addExpand);
                        setEdit(false);

                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
            }
        } else {

            if ((state.document_type_id == "" || state.document_type_id == null) && state.valid_from == "" && state.valid_till == "" && state.document_number == "" && state.documents[0].new_document_id == "") {
                setAddExpand(!addExpand);
                setError({})
            } else {
                setPersonalDocLoader(true)

                OnBoardApi.documentsStore(state, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        if (inviteData != null) {
                            inviteData.upload_documents.drivers_license.data = [];
                            setInviteData({ ...inviteData });
                        }
                        addSuccessMsg(`Documents Added Successfully`);
                        getPersonalIndex(emp_id);
                        setAddExpand(!addExpand);
                        setEdit(false);

                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
            }

        }

    }

    const handleSubmitSsn = () => {
        ssnState['request_id'] = LocalStorage.uid();
        ssnState['employee_id'] = emp_id;
        if (ssnState.document_number !== "" && validate_ssn(ssnState.document_number)) {
            ssnError['document_number'] = validate_ssn(ssnState.document_number);
            setSsnError({ ...ssnError })
            addErrorMsg("In Valid SSN Number");
            return false;
        }
        if (ssnState.document_number == "" && (ssnState.valid_till !== "" || ssnState.valid_from !== "" || ssnState.documents[0].document_url !== "")) {
            ssnError['document_number'] = 'This field is required';
            setSsnError({ ...ssnError })
            addErrorMsg("SSN Number is required");
            return false;
        }
        if (ssnState.id !== "") {
            if (clearAllSsn || (ssnState.document_number == "" && ssnState.valid_till == "" && ssnState.valid_from == "" && ssnState.documents[0].document_url == "")) {
                var deleteObj = {
                    request_id: LocalStorage.uid(),
                    employee_id: emp_id,
                    id: ssnState.id
                }
                setPersonalDocLoader(true)

                EmployeeCreateAPI.deletePersonalDocuments(deleteObj, ssnState.id ? ssnState.id : '', LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        handleEmptySsnStateSave();
                        addSuccessMsg(`Documents Updated Successfully`);
                        getPersonalIndex(emp_id);
                        setSsnExpand(!ssnExpand);
                        // setEdit(true);;
                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
                setClearAllSsn(false);
            } else {
                setPersonalDocLoader(true)

                OnBoardApi.documentUpdate(ssnState, ssnState.id ? ssnState.id : '', LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        addSuccessMsg(`Documents Updated Successfully`);
                        getPersonalIndex(emp_id);

                        setSsnExpand(!ssnExpand);
                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
            }

        } else {
            if (ssnState.document_number == "" && ssnState.valid_till == "" && ssnState.valid_from == "" && ssnState.documents[0].new_document_id == "") {
                setSsnExpand(!ssnExpand);
            } else {
                setPersonalDocLoader(true)

                OnBoardApi.documentsStore(ssnState, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        if (inviteData != null) {
                            inviteData.upload_documents.signed_ssn.data = [];
                            setInviteData({ ...inviteData });
                        }
                        addSuccessMsg(`Documents Added Successfully`);
                        getPersonalIndex(emp_id);
                        ;
                        setSsnExpand(!ssnExpand);
                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
    }

    const handleSubmitOtherDocs = () => {

        if (otherState.id !== "") {
            if (otherState.documents.length == 0) {
                var deleteObj = {
                    request_id: LocalStorage.uid(),
                    employee_id: emp_id,
                    id: otherState.id
                }
                setPersonalDocLoader(true)

                EmployeeCreateAPI.deletePersonalDocuments(deleteObj, otherState.id ? otherState.id : '', LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        addSuccessMsg(`Documents Updated Successfully`);
                        setDeleteOther(true);
                        otherState.id = ''
                        setOtherState({ ...otherState })
                        getPersonalIndex(emp_id);

                        setOtherExpand(!otherExpand);
                        setDeleteOther(true)
                        // setEdit(true);
                    } else {
                        addErrorMsg(res.data.message);
                        setPersonalDocLoader(false)

                    }
                })
                setClearAll(false);
            } else {
                otherState['request_id'] = LocalStorage.uid();
                otherState['employee_id'] = emp_id;
                setPersonalDocLoader(true)

                OnBoardApi.documentUpdate(otherState, otherState.id ? otherState.id : '', LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        addSuccessMsg(`Documents Updated Successfully`);
                        getPersonalIndex(emp_id);

                        setOtherExpand(!otherExpand);
                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
            }

        } else {
            if (otherState.documents.length == 0) {
                setOtherExpand(!otherExpand);
            } else {
                otherState['request_id'] = LocalStorage.uid();
                otherState['employee_id'] = emp_id;
                setPersonalDocLoader(true)

                OnBoardApi.documentsStore(otherState, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setPersonalDocLoader(false)

                        if (inviteData != null) {
                            inviteData.upload_documents.counter_signed_offer_letter.data = [];
                            setInviteData({ ...inviteData });
                        }
                        addSuccessMsg(`Documents Added Successfully`);
                        getPersonalIndex(emp_id);
                        setOtherExpand(!otherExpand);
                    } else {
                        setPersonalDocLoader(false)

                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
        setFileErr(false);
    }

    const handleSaveAndContinue = () => {
        if (isValid(error) && isValid(ssnError)) {
            if (ssnState.document_number != '' || clearAllSsn) {
                handleSubmitSsn();
            }
            if (state.document_type_id != '' || clearAll) {
                handleSubmit();
            }
            handleSubmitOtherDocs();

            setOtherExpand(false);
            setSsnExpand(false);
            setAddExpand(false);
            setMainStep(mainStep + 1);
            setValue(0);
        } else {
            addErrorMsg(ErrorMessages.genericMessage)
        }
    }

    const back = () => {
        setValue(value - 1);
        setDocStepper(docStepper - 1);
        setEditForm(true);
    }

    const nextStep = () => {
        setMainStep(mainStep + 1);
        setValue(0);
    }


    const getPersonalIndex = (id) => {
        setFormLoader(true);
        OnBoardApi.documentIndex(LocalStorage.uid(), id ? id : '', LocalStorage.getAccessToken()).then((res) => {
            setFormLoader(false);
            if (res.data.statusCode === 1003) {
                if (res.data.statusCode === 1003) {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach(item => {
                            switch (item.document_type_id) {
                                case 1:
                                    if (item.documents.length === 0) {
                                        item.documents.push({
                                            document_name: '',
                                            id: '',
                                            slug: '',
                                            new_document_id: '',
                                            document_url: ''
                                        });
                                    }
                                    setState(item);
                                    setEdit(false);
                                    break;
                                case 2:
                                    if (item.documents.length === 0) {
                                        item.documents.push({
                                            document_name: '',
                                            id: '',
                                            slug: '',
                                            new_document_id: '',
                                            document_url: '',
                                        });
                                    }
                                    setSsnState(item);
                                    setSsnEdit(true);
                                    break;
                                case 3:
                                    if (item.documents.length === 0) {
                                        item.documents.push({
                                            document_name: '',
                                            id: '',
                                            slug: '',
                                            new_document_id: '',
                                            document_url: ''
                                        });
                                    }
                                    setState(item);
                                    setEdit(false);
                                    break;
                                case 4:
                                    setOtherState({
                                        id: item.id,
                                        documents: item.documents,
                                        document_type_id: item.document_type_id,
                                        valid_from: '',
                                        valid_till: '',
                                        documents_deleted_ids: []
                                    });
                                    setOtherEdit(true);
                                    break;
                                default:

                                    break;
                            }
                        });
                    } else {
                        if (inviteData != null) {
                            handleFormStates(inviteData);
                        } else {
                            handleEmptyState();
                            handleEmptySsnState();
                            setOtherState({ id: '', valid_from: '', valid_till: '', document_type_id: 4, documents: [] });
                        }
                    }

                    setDeleteOther(true);
                    setClearAll(false);
                    setClearAllSsn(false);
                }
            }
        })
    }

    const cancel = () => {
        setOtherExpand(!otherExpand);
    }

    const handleClearAll = () => {
        setClearAll(true);
        if (inviteData != null) {
            inviteData.upload_documents.drivers_license.data = [];
            setInviteData({ ...inviteData });
        }
        setState({
            ...state,
            document_type_id: '',
            valid_from: '',
            valid_till: '',
            document_number: '',
            documents: [{
                document_name: '',
                id: '',
                new_document_id: '',
                document_url: '',
                slug: ""
            }]
        })
    }
    const handleClearSnn = () => {
        setClearAllSsn(true);
        if (inviteData != null) {
            inviteData.upload_documents.signed_ssn.data = [];
            setInviteData({ ...inviteData });
        }
        setSsnState({
            ...ssnState,
            valid_from: '',
            valid_till: '',
            document_number: '',
            documents: [{
                document_name: '',
                id: '',
                new_document_id: '',
                document_url: '',
                slug: ''
            }]
        })
    }

    const handleCancelForm = () => {
        setAddExpand(!addExpand);
        setEdit(!edit);
        getPersonalIndex(emp_id);
        setError({})
    }

    const handleCancelSsn = () => {
        setSsnExpand(!ssnExpand);
        setSsnEdit(false);
        getPersonalIndex(emp_id);
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    return (
        <Grid container item lg={12}>
            <Text headerBlack>Personal Documents</Text>
            <Grid item lg={12} p={'20px 0px 10px 0px'}>
                <CustomAccordion
                    icon={addExpand ? '' : state.id !== "" ? 2 : 1}
                    expanded={addExpand}
                    handlechangeaccordion={() => { handleChangeAccordion('dlsi') }}
                    AccordionHeader={
                        <Box justifyContent='space-between' display='flex' flexDirection='row' width='100% !important' p={'0px 0px 0px 10px'}>
                            <Box>
                                <Text headerBlack>Driving License / State ID</Text>
                            </Box>
                            <Box textAlign='end'>
                                {(addExpand && state.id !== "" && !edit) && <img src={editIcon} alt='editIcon' className={classes.edit} onClick={(e) => handleEditIcon(e)} />}
                            </Box>
                        </Box>
                    }>
                    {
                        formLoader ?
                            <Box className={classes.ViewContainer}>
                                <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                    <Text mediumBlue>Please Wait </Text>
                                </Stack>
                            </Box> :
                            <Grid container lg={12} md={12} sm={12} xs={12} spacing={2} p={'0px 0px 10px 25px !important'}>
                                <PersonalDocsForm
                                    inviteData={inviteData}
                                    state={state}
                                    setState={setState}
                                    docError={docError}
                                    error={error}
                                    setError={setError}
                                    changeHandler={changeHandler}
                                    dateChange={dateChange}
                                    handleSubmit={handleSubmit}
                                    uploadDocs={uploadDocs}
                                    editForm={editForm}
                                    edit={edit}
                                    docsList={docsList}
                                    handleClearAll={handleClearAll}
                                    handleCancelForm={handleCancelForm}
                                    personalDocLoader={personalDocLoader}
                                />
                            </Grid>
                    }
                </CustomAccordion>
            </Grid>
            <Grid item lg={12} p={'20px 0px 10px 0px'}>
                {
                }
                <CustomAccordion
                    icon={ssnExpand ? '' : ssnState.id == null ? 1 : ssnState.id !== "" ? 2 : 1}
                    expanded={ssnExpand}
                    handlechangeaccordion={() => { handleChangeAccordion('ssn') }}
                    AccordionHeader={<Box justifyContent='space-between' display='flex' flexDirection='row' width='100% !important' p={'0px 0px 0px 10px'}>
                        <Box>
                            <Text headerBlack>Social Security Number (SSN)</Text>
                        </Box>
                        <Box textAlign='end'>
                            {(ssnExpand && ssnState.id !== "" && ssnEdit) && <img src={editIcon} alt='editIcon' className={classes.edit} onClick={(e) => handleSsnEdit(e)} />}
                        </Box>
                    </Box>}>
                    {
                        formLoader ?
                            <Box className={classes.ViewContainer}>
                                <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                    <Text mediumBlue>Please Wait </Text>
                                </Stack>
                            </Box> :
                            <Grid container lg={12} md={12} sm={12} xs={12} spacing={2} p={'10px 0px 10px 25px !important'}>
                                <SsnForm
                                    inviteData={inviteData}
                                    state={ssnState}
                                    setState={setSsnState}
                                    docError={docError}
                                    error={ssnError}
                                    setError={setSsnError}
                                    changeHandler={changeHandlerSsn}
                                    dateChange={dateChangeSsn}
                                    handleSubmit={handleSubmitSsn}
                                    uploadDocs={uploadDocs}
                                    editForm={editForm}
                                    edit={ssnEdit}
                                    handleClear={handleClearSnn}
                                    handleCancelSsn={handleCancelSsn}
                                    personalDocLoader={personalDocLoader}
                                />
                            </Grid>
                    }
                </CustomAccordion>
            </Grid>
            <Grid item lg={12} p={'20px 0px 10px 0px'}>
                <CustomAccordion
                    icon={otherExpand ? '' : otherState.id !== "" ? 2 : 1}
                    expanded={otherExpand}
                    handlechangeaccordion={() => { handleChangeAccordion('other') }}
                    AccordionHeader={
                        <Box justifyContent='space-between' display='flex' flexDirection='row' width='100% !important' p={'0px 0px 0px 10px'}>
                            <Box>
                                <Text headerBlack>Other Documents</Text>
                            </Box>
                            <Box textAlign='end'>
                                {(otherExpand && otherState.id !== "" && otherEdit) && <img src={editIcon} alt='editIcon' className={classes.edit} onClick={(e) => handleOtherEdit(e)} />}
                            </Box>
                        </Box>}>
                    {
                        formLoader ?
                            <Box className={classes.ViewContainer}>
                                <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                    <Text mediumBlue>Please Wait </Text>
                                </Stack>
                            </Box> :
                            <Grid container lg={12} md={12} spacing={2} p={'10px 0px 10px 25px !important'}>
                                {(!otherEdit) && <Grid item lg={12} md={12}>
                                    <Box className={classes.dropzoneMainBox}>
                                        <Box className={classes.dropBox1}>
                                            <Dropzone onDrop={acceptedFiles => { multiDocsUpload(acceptedFiles) }}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section className={otherState.documents.length < 5 ? classes.dropZone : classes.dropZoneDisable}>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {
                                                                isDragActive ?
                                                                    <Grid container spacing={1} justifyContent='center'>
                                                                        <Text normal my={10} className={classes.dropzoneText}>drop field and uploaded documents similar to work authorization and educational documents</Text>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container spacing={1} textAlign='center'>
                                                                        <Grid item lg={12} marginTop={'-8px'}>
                                                                            <img src={download} alt='download' />
                                                                        </Grid>
                                                                        <Grid item lg={12} textAlign='center'>
                                                                            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                                <Text largeBlack>Drag & Drop Documents</Text>
                                                                                <Text smallGrey>or</Text>
                                                                                {
                                                                                    otherState.documents.length < 5 ?
                                                                                        <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text> :
                                                                                        <Text largeBlack sx={{ color: "#525252 !important", textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                                                                }

                                                                                <Text largeBlack>To Upload</Text>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item lg={12} mt={1}>
                                                                            <Text mediumOrange>Add any other documentation like Countersigned Offer Letter,<br /> Other Personal and Visa related documents.</Text>
                                                                        </Grid>
                                                                        <Grid item lg={12} mt={1}>
                                                                            <Text smallGreyFont>Document Size 25MB, Format - PDF, Docs</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            {/* <DropZone callApi={(e)=>{multiDocsUpload(e)}} multiDocs={true} otherDocuments={true} disableDrop={otherState.documents.length >= 5 ? true : false} /> */}
                                        </Box>
                                    </Box>
                                </Grid>
                                }

                                <Grid item lg={12} md={12}>
                                    {
                                        otherState.documents.length > 0 ?
                                            <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                                <Text smallLabel sx={{ font: "12px Quicksand !important" }}>Uploaded Documents</Text>
                                                <Grid item container lg={12} md={12} columnGap={1.5}>
                                                    {
                                                        otherState.documents.map((item, index) => (
                                                            <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                                <Text sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important` }}>
                                                                    <Text mediumBlue sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item, item.document_url) }}>
                                                                        <BlackToolTip arrow placement='top' title={item.name}>
                                                                            {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                        </BlackToolTip>
                                                                    </Text>
                                                                </Text>
                                                                <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { otherDocumentRemove(index) }} />
                                                            </Box>
                                                        ))
                                                    }
                                                </Grid>
                                            </Box> :
                                            <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                                <Text smallLabel sx={{ font: "14px Quicksand !important" }}>Uploaded Documents</Text>
                                                <Grid item container lg={12} columnGap={1.5}>
                                                </Grid>
                                            </Box>
                                    }
                                </Grid>
                                {
                                    fileErr &&
                                    <Grid item lg={12} md={12}>
                                        <Text red sx={{ fontWeight: `${600} !important`, font: "12px Quicksand !important" }}>The document upload limit has been reached ( max limit 5 ).</Text>
                                    </Grid>
                                }
                                {
                                    !otherEdit
                                    &&
                                    <Grid item container m={'25px 0px 10px 0px'}>
                                        <Grid item lg={6} md={6}>
                                            <Button blackCancel onClick={cancel}>Cancel</Button>
                                        </Grid>
                                        <Grid item lg={6} md={6} textAlign='end'>
                                            {otherState.documents.length > 0 && <Button clearall onClick={() => { setClearAllOpen(true) }} sx={{ marginRight: '10px' }}>Clear all</Button>}
                                            <LoadingButton loading={personalDocLoader} brownMnSave onClick={() => handleSubmitOtherDocs()}>Save</LoadingButton>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                    }
                </CustomAccordion>
            </Grid>
            <Grid item container m={'25px 0px 10px 0px'}>
                <Grid item lg={6} md={6}>
                    <Button blackCancel onClick={back}>Back</Button>
                </Grid>
                <Grid item lg={6} md={6} textAlign='end'>
                    <Button blackCancel sx={{ marginRight: '10px', border: "none !important" }} onClick={() => { nextStep() }} >Skip</Button>
                    <LoadingButton loading={personalDocLoader} saveNcontinue onClick={() => handleSaveAndContinue()}>Save & Continue</LoadingButton>
                </Grid>
            </Grid>
            {clearAllOpen && <ReusableClearAll open={clearAllOpen} setOpen={setClearAllOpen} onClick={() => { clearAllOtherDocuments() }} />}
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(deleteIndex) }} />}
        </Grid >
    )
}

export default PersonalDocuments