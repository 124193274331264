import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import Text from '../../../../components/customText/Text';
import MainStyles from '../MainStyles'
import Input from '../../../../components/input/Input';// eslint-disable-next-line
import CustomSelect from "../../../../components/customSelect/CustomSelect";// eslint-disable-next-line
import Button from '../../../../components/customButton/Button';
import BaseTextareaAutosize from '@mui/material/TextareaAutosize';
import { styled } from "@mui/material/styles";
import LocalStorage from '../../../../utils/LocalStorage';
import TemplateApi from '../../../../apis/configurations/templates/TemplateApi';
import { isValid, validate_Char, validate_emptyField, } from '../../../../components/Validation';// eslint-disable-next-line
import SearchSelect from '../../../../components/selectField/SearchSelect';
import RichTextEditor from 'react-rte';
import { NoDataFound, NoPermission, addErrorMsg, addLoader, addSuccessMsg, removeLoader } from '../../../../utils/utils';
import LoaderIcon from '../../../../assets/gif/japfuLoader.gif';// eslint-disable-next-line
import LoadingButton from '../../../../components/customButton/LoadingButton';
import Select from '../../../../components/select/Select';
// eslint-disable-next-line
const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 415px;
    font-family: 'Quicksand', Quicksand;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
   
    border: 1px solid #C7CCD3;
    &:focus-visible {
        outline: 0;
      }
  `,
);

function AddTemplateConfig() {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles();
    const [error, setError] = useState({});
    const [prefix, setPrefix] = useState([]);// eslint-disable-next-line
    const [params, setParams] = useState([]);
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [btnloading, setBtnLoading] = useState(false);
    const [templateType, setTemplateType] = useState({
        templateType: '',
        templateText: "",
    });

    const [state, setState] = useState({
        name: "external-emp-transfer-ownership-in-mail",
        subject: "",
        description: "",
        template: RichTextEditor.createValueFromString("", "html"),
    });
    // external-emp-transfer-ownership-in-mail
    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "email_templates_view" && item.is_allowed == true))) {
                getAllTemplates();

            }
        }
        // eslint-disable-next-line
    }, [])
    // eslint-disable-next-line
    const handleCancel = () => {
        setState({
            slug: "",
            name: "",
            subject: "",
            description: "",
            template: RichTextEditor.createValueFromString("", "html"),

        });
        setEditorValue(RichTextEditor.createValueFromString("", "html"))
        setTemplateType({
            templateText: '',
            templateType: ''
        })

    };// eslint-disable-next-line
    const [editorValue, setEditorValue] = useState(
        RichTextEditor.createValueFromString("", "html")
    );
    // eslint-disable-next-line
    const handleSubmit = (e) => {
        e.preventDefault();
        // const newState = state;
        // newState['request_id'] = LocalStorage.uid();

        // setState({ ...newState });
        setLoading(true)
        setBtnLoading(true)
        let errors = validateAll();
        state.template = editorValue.toString("html");
        setState({ ...state });
        if (isValid(errors)) {
            let newState = {
                request_id: LocalStorage.uid(),
                description: state.description,
                subject: state.subject,
                template: state.template,
                slug: state.slug
            }
            TemplateApi.updateTemplateIndex(newState, LocalStorage.getAccessToken()).then((res) => {
                setTimeout(() => {
                    if (res.data.statusCode == 1003) {
                        addSuccessMsg(`${name} ${res.data.message}`);
                        setState({
                            slug: "",
                            name: "",
                            subject: "",
                            description: "",
                            template: RichTextEditor.createValueFromString("", "html"),

                        });
                        setLoading(false)
                        setBtnLoading(false)
                        setEditorValue(RichTextEditor.createValueFromString("", "html"))
                        setTemplateType({
                            templateText: '',
                            templateType: ''
                        })
                    } else {
                        addErrorMsg(res.data.message);
                    }
                }, 300)

            });
        }
    };


    const getAllTemplates = () => {
        setLoading(true);
        setBtnLoading(true)
        TemplateApi.getTemplates(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            setLoading(false);
            setBtnLoading(false)
            if (res.data.statusCode == 1003) {
                setPrefix(res.data.data);
                getTemplateIndex("external-emp-transfer-ownership-in-mail")
            }
        }
        )
    }
    // eslint-disable-next-line
    const handleSelectTemplate = (e) => {
        setTemplateType({ ...templateType, [e.target.name]: e.target.value })
    }// eslint-disable-next-line 
    const handleValidationsEditor = (value) => {
        let err = error;
        err.template = validate_emptyField(value);
        setError(err);
    }
    // eslint-disable-next-line
    const options = [
        { id: 1, value: 'Add to Subject' },
        { id: 2, value: 'Add to Template' },
    ];

    const getTemplateIndex = (id, value) => {
        addLoader(true);
        TemplateApi.getTemplateIndex(LocalStorage.uid(), id, LocalStorage.getAccessToken()).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                setState({
                    ...res.data.data[0],
                    name: id,
                    template: RichTextEditor.createValueFromString(`${res.data.data[0].template}`, "html")
                });
                setEditorValue(RichTextEditor.createValueFromString(`${res.data.data[0].template}`, "html"))
                setParams(res.data.data[0].params);
            }
        }
        );
    };

    // const handleInputChange = (e) => {
    //     let { name, value } = e.target;
    //     if (name === 'name') {

    //         // eslint-disable-next-line             
    //         setState({ ...state, [name]: value });
    //        
    //     }
    //     setState({ ...state, [name]: value }, handleValidate(e.target));
    // };

    const [name, setName] = useState('');

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === 'name') {
            setState({ ...state, [name]: value });
            if (value !== "") {
                let slugName = prefix.filter(item => item.id == value)[0].value
                setName(slugName)
                getTemplateIndex(value);
            }
        }
        setState({ ...state, [name]: value }, () => handleValidate(e.target));
    };


    const handleValidate = (input) => {
        // let input = e.target;
        let updatedErrors = { ...error };
        switch (input.name || input.tagName) {
            case "name":
                updatedErrors.name = validate_emptyField(input.value);
                break;
            case "subject":
                updatedErrors.subject = validate_Char(input.value);
                break;
            case "template":
                updatedErrors.template = validate_emptyField(input.value);
                break;
            case "description":
                updatedErrors.description = validate_Char(input.value);
                break;
            default:
                break;
        }
        setError(updatedErrors);
    };

    const validateAll = () => {
        let { name, subject, template, } = state;
        let errors = {};
        errors.name = validate_emptyField(name);
        errors.subject = validate_emptyField(subject);
        errors.template = validate_emptyField(template);
        // errors.description = validate_emptyField(description);

        return errors;
    }

    function onChangeEditor(value) {
        setState({ ...state, template: value })
        if (value.toString("html") == "<p><br></p>" || value.toString("html") == "<p></p>" || value.toString("html") == "<p></p><br>") {
            handleValidationsEditor("");
        } else {
            handleValidationsEditor(value.toString("html"));
        }
    }

    const MailToLink = ({ to, subject, body }) => {
        const handleClick = () => {
            const mailtoUrl = `mailto:${to}?subject=${subject}&body=${body}`;
            window.location.href = mailtoUrl;
        };

        return (
            <Box display={"flex"} >
                <Text sx={{
                    font: "18px Quicksand !important", color: "#737373 !important", fontWeight: `${600} !important`, borderBottom: "1px solid #737373 !important",
                    "@media (min-width: 100px) and (max-width: 499px)": {
                        font: "13px Quicksand !important",
                    },
                }}>Contact us at <span style={{ color: "#0C75EB", cursor: "pointer", borderBottom: "1px solid #0C75EB" }} onClick={handleClick}>support@japfu.ai</span></Text>
            </Box>
        );
    };
    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            <Box padding={'20px 0px 0px 0px'} width={{ xs: "100%", sm: "100%", md: '85%', lg: '85%' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "email_templates_view" && item.is_allowed == true))) ?
                                <Box sx={{
                                    height: '70vh', padding: '16px', overflow: 'auto',
                                    "@media (min-width: 100px) and (max-width: 499px)": {
                                        padding: "10px 3px !important",
                                    },
                                }}>

                                    <>
                                        <Box className={classes.activeItemBox3}>
                                            <Box className={classes.activeBoxHeading}><Text profileTitle >E-mail Template</Text></Box>
                                            <Box sx={{ height: state.name ? "65vh" : "25vh", overflow: "auto", padding: "15px 0px" }}>
                                                {loading ?
                                                    <Box >
                                                        <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                                            <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                                        </Stack>
                                                    </Box>
                                                    :
                                                    <Grid container spacing={'32px'}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Select
                                                                name='name'
                                                                value={state.name ? state.name : ""}
                                                                onChange={handleInputChange}
                                                                label='Template Name'
                                                                options={prefix}
                                                                disabled={true}
                                                            />
                                                            <Text red>
                                                                {error.name ? error.name : ""}
                                                            </Text>
                                                        </Grid>
                                                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box >
                                    <Textarea className={classes.textarea} onChange={handleInputChange} type="text" value={state.description} name="description" aria-label="minimum height" minRows={2} placeholder="Description (optional)" />

                                </Box>
                                {error.description ?
                                    <Text red>
                                        {error.description ? error.description : ""}
                                    </Text>
                                    : null
                                }

                            </Grid> */}

                                                        {/* <Grid item container lg={12} spacing={2} display='flex' justifyContent='space-between'>
                                <Grid item lg={4.2}>
                                    <CustomSelect
                                        name='templateText'
                                        value={templateType.templateText}
                                        onChange={handleSelectTemplate}
                                        options={params}
                                        label={<Text largeLabel>Text</Text>}
                                        commonSelect
                                    />
                                    <Text red>
                                        {error.templateText ? error.templateText : ""}
                                    </Text>
                                </Grid>
                                <Grid item lg={4.2}>
                                    <SearchSelect
                                        name='templateType'
                                        value={templateType.templateType}
                                        onChange={handleSelectTemplate}
                                        options={options}
                                        labelText={<Text largeLabel>Text</Text>}
                                        scrollTrue
                                    />
                                    <Text red>
                                        {error.templateType ? error.templateType : ""}
                                    </Text>
                                </Grid>

                                <Grid item lg={2}>
                                    {
                                        (templateType.templateType == '' || templateType.templateText == '') ?
                                            <Button addHeighDisabletButton>Add</Button> :
                                            <Button addHeightButton onClick={
                                                () => {
                                                    let err = error;
                                                    if (templateType.templateType == 1) {
                                                        let sub = state.subject + " " + params.filter(i => i.id == templateType.templateText)[0].parameter;
                                                        setState({ ...state, subject: sub });
                                                        err.subject = validate_emptyField(sub);
                                                    }
                                                    if (templateType.templateType == 2) {
                                                        let tempText = state.template.toString("html") + " " + params.filter(i => i.id == templateType.templateText)[0].parameter;
                                                        let text = RichTextEditor.createValueFromString(tempText, "html");
                                                        setState({ ...state, template: text });
                                                        if (tempText == "" || tempText == "<p><br></p>" || tempText == "<p></p>" || tempText == "<p></p><br>") {
                                                            err.template = validate_emptyField("");
                                                        } else {
                                                            err.template = validate_emptyField(tempText.toString("html"));
                                                        }
                                                    }
                                                    setError(err);
                                                }
                                            }>Add</Button>
                                    }
                                </Grid>
                            </Grid> */}
                                                        {state.name && <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'subject',
                                                                    type: 'textarea',
                                                                    value: state.subject,
                                                                }}
                                                                // handleChange={handleInputChange}
                                                                disabled={true}
                                                                clientInput
                                                                labelText={'Subject'}
                                                            />
                                                            <Text red>{error.subject ? error.subject : ''}</Text>
                                                        </Grid>}
                                                        {state.name &&
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <Box sx={{ padding: "10px", border: "1px solid #C7CCD3", borderRadius: "8px", zIndex: 1, background: "#ffffff" }}>
                                                                    <Text sx={{ color: "#0C75EB !important", font: "12px Quicksand !important" }}>Template</Text>
                                                                    <RichTextEditor
                                                                        disabled={true}
                                                                        // readOnly
                                                                        onChange={onChangeEditor}
                                                                        value={state.template}
                                                                        editorClassName={classes.editorHeight}
                                                                        toolbarClassName={classes.toolBar}
                                                                        // placeholder={}
                                                                        // labelText={'Template'}
                                                                        className={`${classes.customEditorStyle} ${classes.disabledEditor}`}

                                                                    />

                                                                </Box>
                                                                {/* <Textarea className={classes.textarea} onChange={handleInputChange} type="text" name="template"  value= {state.template} aria-label="minimum height" minRows={5} placeholder="Template" /> */}
                                                                <Text red>{error.template ? error.template : ''}</Text>
                                                            </Grid>}

                                                    </Grid>
                                                }
                                            </Box>

                                        </Box>
                                        <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={"center"} pt={4}>
                                            <MailToLink to="support@example.com" subject="" body="" />
                                        </Grid>
                                        {/* <Box display={'flex'} justifyContent={'end'} gap={'20px'} mt={'32px'}>

                        {state.name !== '' ?
                            <>
                                <Button cancelOutline onClick={handleCancel}>Cancel</Button>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "configurations_create" && item.is_allowed == true) && rolePermission !== "" && rolePermission.some(item => item.slug == "configuration_template" && item.is_allowed == true))) ||
                                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "configurations_edit" && item.is_allowed == true) && rolePermission !== "" && rolePermission.some(item => item.slug == "configuration_template" && item.is_allowed == true))) ?
                                        <LoadingButton loading={btnloading} saveVerySmall onClick={handleSubmit}>{btnloading ? 'saving' : 'save'}</LoadingButton> :
                                        <LoadingButton saveLoaderDisable sx={{ height: '40px !important', minWidth: '100px !important' }}>{btnloading ? 'saving' : 'save'}</LoadingButton>
                                }
                            </>
                            : ''
                        }

                    </Box> */}
                                    </>
                                </Box>
                                :
                                <>
                                    {NoPermission()}
                                </>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AddTemplateConfig;
