import { Box, ListItemButton, Grid } from "@mui/material";
import { useEffect } from "react";
import MainStyles from "../MainStyles";
import Text from "../../../../components/customText/Text";
import OrganizationDetails from "./Preferences/OrganizationDetails";
// import PayrollSettings from './Preferences/PayrollSettings';
import Prefixes from "./Preferences/Prefixes";
import Announcements from "./Preferences/Announcements";
import Placements from "./Preferences/Placements";
import '../../../../index.css';
import LocalStorage from "../../../../utils/LocalStorage";

const prefernces = [
  "Organization Details",
  "Working Hours",
  "Prefixes",
  "Web Announcements",
  //"Placements",
  // 'Payroll Settings',
];

function OrganizationConfig(props) {
  const classes = MainStyles();
  const { setCurrent, current,setOrgConfigEdit, orgConfigEdit} = props;
  
  useEffect(()=>{
    if( !LocalStorage.getStartTour()?.orgconfig){
    setCurrent('Organization Details')};
    // eslint-disable-next-line
  },[])
  
  return (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      <Box
        padding={"20px 0px 0px 0px"}
        width={{ xs:"100%",sm: "100%", md: "95%", lg: "85%" }}
      >
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Box className={classes.mainListBox}>
              <Box className={classes.prefTitle}>
                <Text blackHeader600>Preference</Text>
              </Box>
              <Box className={classes.listContainer} sx={{ maxHeight: "60vh" }}>
                {prefernces.map((item, key) => (
                  <ListItemButton
                    key={key}
                    className={`${classes.listItems} ${current === item ? classes.listItemsActive : null
                      }`}
                      onClick={()=>setCurrent(item)}
                      id={item.replace(' ','')}
                    >
                    {item}
                  </ListItemButton>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <Box>
              {current === "Organization Details" ? (
                <OrganizationDetails
                  setLogoUrl={props.setLogoUrl}
                  current={current}
                  orgConfigEdit={orgConfigEdit}
                  setOrgConfigEdit={setOrgConfigEdit}
                />
              ) : null}
              {/* {
                                current === "Payroll Settings" ? <PayrollSettings current={current} /> : null
                            }*/}
              {current === "Prefixes" ? <Prefixes current={current} /> : null}
              {current === "Web Announcements" ? (
                <Announcements current={current} />
              ) : null}
              {current === "Working Hours" ? (
                <Placements current={current} />
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default OrganizationConfig;
