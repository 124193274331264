import React, {useEffect } from 'react'
import MainStyles from '../MainStyles';
import PayrollSetup from './PayrollSetup';
import { Box, Grid, ListItemButton } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Reimbursements from './Reimbursements';
import Deductions from './Deductions';
import LocalStorage from "../../../../utils/LocalStorage";


const prefernces = ['Payroll Setup', 'Reimbursements', 'Deductions'];
function PayrollConfig(props) {
  const { setCurrent, current} = props;

  const classes = MainStyles();

  useEffect(()=>{
    if( !LocalStorage.getStartTour()?.orgconfig){
    setCurrent('Payroll Setup')};
    // eslint-disable-next-line
  },[])
  

  return (
    <Box display={'flex'} justifyContent={'center'} width={'100%'}>
      <Box padding={'20px 0px 0px 0px'} width={{ xs: "100%", sm: "100%", md: '95%', lg: '85%' }}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Box className={classes.mainListBox} >
              <Box className={classes.prefTitle}>
                <Text blackHeader600>Preference</Text>
              </Box>
              <Box className={classes.listContainer} sx={{ maxHeight: '58vh' }}>
                {
                  prefernces.map((item, key) => (
                    <ListItemButton
                      key={key}
                      className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                      onClick={()=>setCurrent(item)}
                      id={item.replace(' ','')}
                    >
                      {item}
                    </ListItemButton>
                  ))
                }
              </Box>
            </Box>
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={12}>
            {
              current === 'Payroll Setup' ? <PayrollSetup /> : current === 'Reimbursements' ? <Reimbursements /> : current === 'Deductions' ? <Deductions /> : null
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PayrollConfig
